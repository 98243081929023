/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {
  Container,
  Card,
  CardHeader,
  Row,
  Col,
  CardBody,
  Button,
} from "reactstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isEmpty, isNull } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import Switch from "../../../components/switch";
import Axios from "services/axios";
import Cookies from "universal-cookie";
import fetchSchemeLineBySchemeId from "../../../services/fetchSchemeLineBySchemeId";
import fetchAllSchemes from "../../../services/fetchAllSchemes";
import fetchAllSalesArea from "../../../services/fetchAllAreaSales";
import fetchTableQuery from "../../../services/fetch/constructor/fetchTableQuery";
import fetchAllDCs from "../../../services/fetchAllDCs";
import fetchSchemeAllParamByUniqueId from "../../../services/fetch/scheme/fetchSchemeAllParamByID";

const columns = [
  { dataField: "year", text: "Year" },
  { dataField: "month", text: "Month" },
  { dataField: "ba_name", text: "Nama BA" },
  { dataField: "partner_name", text: "Nama Toko" },
  { dataField: "dc", text: "DC" },
  { dataField: "type", text: "Type" },
  { dataField: "target", text: "Target" },
  { dataField: "achievement", text: "Achievement" },
  { dataField: "percentAch", text: "Percent Ach (%)" },
];

const columnsWithSalesArea = [
  { dataField: "year", text: "Year" },
  { dataField: "month", text: "Month" },
  { dataField: "ba_name", text: "Nama BA" },
  { dataField: "partner_name", text: "Nama Toko" },
  { dataField: "dc", text: "DC" },
  { dataField: "salesarea_name", text: "Sales Area" },

  { dataField: "type", text: "Type" },
  { dataField: "target", text: "Target" },
  { dataField: "achievement", text: "Achievement" },
  { dataField: "percentAch", text: "Percent Ach (%)" },
];

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

function ReportingAchievement() {
  const [schemeList, setSchemeList] = useState({});
  const [tabActive, setTabActive] = useState("Region");
  const [scheme, setScheme] = useState({});
  const [reportingData, setReportingData] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [parameterActive, setParameterActive] = useState("");
  const [filterSelected, setFilterSelected] = useState({
    scheme: {},
    yearMonth: [],
    dc: [],
    salesarea_selected: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dcList, setDcList] = useState([]);
  const [areasales, setAreaSales] = useState([]);

  const moneyFormatter = (value) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      currency: "IDR",
    });

    return `Rp ${formatter.format(value)}`;
  };

  const submitDataHandler = async ({ type }) => {
    let data = [];
    setIsLoading(true);

    let responseAPI = "";

    try {
      if (scheme.id) {
        const response = await fetchSchemeAllParamByUniqueId(scheme.id);
        setParameters([
          ...response,
          {
            parameter_name: "All",
          },
        ]);

        if (response.length) {
          setParameterActive(type || response[0].parameter_name);
        }

        const isSalesArea = tabActive === "Region";

        let queryEndpoint = "";
        let table = "";
        if (isSalesArea) {
          const queries = filterSelected.salesarea_selected.map((item) =>
            item.value.replace(/ /g, "%20")
          );

          table = "v-ach-monthly-all-complete-by-salesarea";

          const paramType = type
            ? type === "All"
              ? ""
              : `&type=${type.replace(/ /g, "%20")}`
            : `&type=${response[0].parameter_name.replace(" ", "%20")}`;
          queryEndpoint = `salesarea_name=[${queries}]&year_month=[${filterSelected.yearMonth[0]}]${paramType} `;
        } else {
          const queriesDC = filterSelected.dc.map((item) =>
            item.partner_name.replace(/ /g, "%20")
          );
          table = "v-ach-monthly-all-complete/list";

          const paramType = type
            ? type === "All"
              ? ""
              : `&type=${type.replace(/ /g, "%20")}`
            : `&type=${response[0].parameter_name.replace(" ", "%20")}`;

          queryEndpoint = `v-ach-monthly-all-complete?dc=[${queriesDC}]&year_month=[${filterSelected.yearMonth[0]}]${paramType}`;
        }
        responseAPI = await fetchTableQuery(table, queryEndpoint);
      }

      if (responseAPI) {
        const newReportingData = responseAPI.map((item) => {
          if (item.target) {
            return {
              ...item,
              percentAch: `${Math.floor(
                (item.achievement / item.target) * 100
              )}%`,
              target:
                item.type === "Total Visitor"
                  ? `${item.target} Orang`
                  : item.type === "Conversion Rate"
                    ? `${item.target}%`
                    : moneyFormatter(item.target),
              achievement: item.achievement
                ? item.type === "Total Visitor"
                  ? `${item.achievement} Orang`
                  : item.type === "Conversion Rate"
                    ? `${item.achievement}%`
                    : moneyFormatter(item.achievement)
                : "-",
            };
          }
          return {
            ...item,
            percentAch: "-",
            target: "-",
            achievement: item.achievement
              ? item.type === "Total Visitor"
                ? `${item.achievement} Orang`
                : item.type === "Conversion Rate"
                  ? `${item.achievement}%`
                  : moneyFormatter(item.achievement)
              : "-",
          };
        });
        setReportingData(newReportingData);
        setIsLoading(false);
      }
    } catch (error) {
      setReportingData([]);
      setIsLoading(false);
    }
  };

  const onChangeParameter = (value) => {
    setParameterActive(value);
    submitDataHandler({ value: 0, type: value });
  };

  const resetFilter = () => {
    setFilterSelected({
      scheme: {},
      yearMonth: [],
      dc: [],
      salesarea_selected: [],
    });
    setReportingData([]);
    setParameters([]);
    setParameterActive("");
  };

  const getSelectedAreaList = async () => {
    try {
      const response = await fetchAllSalesArea();

      setAreaSales(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllDCList = async () => {
    try {
      const response = await fetchAllDCs();
      setDcList(response);
    } catch (err) {
      console.log(err);
    }
  };

  const filterHandler = ({ value, type }) => {
    setIsLoading(false);
    // resetFilter();
    try {
      if (type === "scheme") {
        setScheme(value);
        if (isNull(value)) {
          resetFilter();
        } else {
          let dummy = value;
          // dummy.areasales = dummy.areasales_id.map((item) => item.toString());
          setFilterSelected({
            yearMonth: [],
            dc: [],
            scheme: dummy,
            salesarea_selected: [],
          });
        }
      } else {
        setFilterSelected({
          ...filterSelected,
          [type]: value,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSchemeList = async () => {
    try {
      const response = await fetchAllSchemes();

      setSchemeList(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSchemeList();
    getSelectedAreaList();
    getAllDCList();
  }, []);

  const changeAreasalesHandler = (value) => {
    filterHandler({ value, type: "salesarea_selected" });
  };

  return (
    <Container fluid className="mt-4">
      <Card>
        <CardHeader
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3 className="mb-0">Filter</h3>
          {reportingData.length > 0 && (
            <CSVLink
              data={reportingData}
              filename={`scheme-${scheme.name}.csv`}
            >
              <div
                style={{
                  display: "flex",
                  color: "#4eceef",
                }}
              >
                <p
                  style={{
                    marginRight: "8px",
                    fontSize: "14px",
                  }}
                >
                  Download CSV
                </p>
                <GetAppIcon />
              </div>
            </CSVLink>
          )}
        </CardHeader>
        <CardBody>
          <Row className="mb-4">
            <Col className="col-6">
              <Autocomplete
                options={schemeList}
                getOptionLabel={(option) => option.name}
                onChange={(_, value) =>
                  filterHandler({ value, type: "scheme" })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    key={params.name}
                    label="Select Schema"
                    variant="outlined"
                  />
                )}
                disabled={Object.keys(schemeList).length === 0}
                value={filterSelected.scheme}
              />
            </Col>
            <Col className="col-6">
              <Autocomplete
                options={!isEmpty(scheme) && scheme.year_month}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Month Year"
                    variant="outlined"
                  />
                )}
                multiple
                onChange={(_, value) =>
                  filterHandler({ value, type: "yearMonth" })
                }
                disabled={isEmpty(scheme)}
                value={filterSelected.yearMonth}
                cursor={isEmpty(scheme) ? "pointer" : "click"}
              />
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <h3 className="mb-3">Region</h3>
              {/* <Switch
                tabList={{ left: "Region", right: "DC" }}
                onChange={(value) => {
                  setTabActive(value);
                }}
              /> */}
            </Col>
          </Row>
          <Row>
            <Col className="col-6">
              {/* {tabActive === "DC" ? (
                <Autocomplete
                  options={dcList}
                  getOptionLabel={(option) => option.partner_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select DC"
                      variant="outlined"
                      key={params.value}
                    />
                  )}
                  multiple
                  onChange={(_, value) => filterHandler({ value, type: "dc" })}
                  value={filterSelected.dc}
                  disabled={isEmpty(scheme)}
                  cursor={isEmpty(scheme) ? "pointer" : "click"}
                />
              ) : ( */}

              <Autocomplete
                options={areasales}
                getOptionLabel={(option) => option.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Area"
                    variant="outlined"
                    key={params.value}
                  />
                )}
                multiple
                onChange={(_, value) => changeAreasalesHandler(value)}
                value={filterSelected.salesarea_selected}
                cursor={isEmpty(scheme) ? "pointer" : "click"}
                disabled={isEmpty(scheme)}
              />
              {/* )} */}
            </Col>
          </Row>
          <Row className="d-flex justify-content-end pr-4">
            <Button
              className="bg-danger text-white border-0"
              onClick={resetFilter}
            >
              Reset
            </Button>
            <Button
              className="bg-blue text-white border-0"
              onClick={() => submitDataHandler({ value: 0, type: "" })}
              disabled={
                isEmpty(filterSelected.yearMonth) ||
                (isEmpty(filterSelected.dc) &&
                  isEmpty(filterSelected.salesarea_selected))
              }
            >
              Apply
            </Button>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h3 className="mb-4">Reporting Achievement</h3>
          <div style={{ display: "flex" }}>
            {parameters.length > 0 &&
              parameters.map((parameter) => {
                return (
                  <div
                    style={{
                      marginRight: "20px",
                      borderBottom:
                        parameter.parameter_name === parameterActive
                          ? "3px solid #f5365c"
                          : "none",
                      paddingBottom: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => onChangeParameter(parameter.parameter_name)}
                  >
                    {parameter.parameter_name}
                  </div>
                );
              })}
          </div>
        </CardHeader>
        {!Boolean(reportingData.length) && !isLoading && (
          <div
            style={{
              width: "100%",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "grey",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            Data tidak ditemukan
          </div>
        )}
        {!!reportingData.length && !isLoading && (
          <>
            <ToolkitProvider
              data={reportingData}
              keyField="partner_name"
              columns={tabActive === "Region" ? columnsWithSalesArea : columns}
              search
            >
              {(props) => (
                <div className="py-4 table-responsive">
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    pagination={pagination}
                    bordered={false}
                  />
                </div>
              )}
            </ToolkitProvider>
          </>
        )}
        {isLoading && (
          <div
            style={{
              width: "100%",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="info" />
          </div>
        )}
      </Card>
    </Container>
  );
}

export default ReportingAchievement;

import months from "./months.json";

export default function toYearMonthString(yearMonth) {
  const yearMonthDictionary = {
    January: "Januari",
    Febuary: "Februari",
    March: "Maret",
    April: "April",
    May: "Mei",
    June: "Juni",
    July: "Juli",
    August: " Agustus",
    September: "September",
    October: "Oktober",
    November: "November",
    December: "Desember",
  };

  const [year, month] = yearMonth.split("-");
  const foundMonth = months.find((elem) => elem.id === month).name;
  return `${yearMonthDictionary[foundMonth]} ${year}`;
}

import React, { useEffect, useState } from "react";
import Axios from "services/axios";
import Cookies from "universal-cookie";

// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  CardBody,
} from "reactstrap";

// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.jsx";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
// import Swal from "sweetalert2";

import fetchAllSchemes from "../../../services/fetchAllSchemes";

// pagination
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

// search bar
const { SearchBar } = Search;

const ParameterList = () => {
  const [listScheme, setListScheme] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validate, setIsValid] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        let result = await fetchAllSchemes();
        setListScheme(result);
        setIsLoading(false);
        setIsValid(true);
      } catch (error) {
        setListScheme([]);
      }
    };
    fetch();
  }, [validate]);

  // const updateStatus = (status, id) => {
  //   Swal.fire({
  //     title: `Are you sure to ${
  //       status ? "deactivate" : "activate"
  //     } this Schema Status?`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     allowOutsideClick: false,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       var parameter = {
  //         is_active: !status,
  //       };
  //       axios
  //         .patch(
  //           "https://training-api.pti-cosmetics.com/ach_master_scheme?id=eq." +
  //             id,
  //           parameter
  //         )
  //         .then((response) => {
  //           Swal.fire({
  //             icon: "success",
  //             text: "Beauty Tutorial Status has been updated.",
  //           });
  //           setIsValid(false);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           Swal.fire({
  //             icon: "error",
  //             text: "Fail to update Attendee status (Server Error)",
  //           });
  //         });
  //     }
  //   });
  // };

  // const SchemaStatus = (status, id) => {
  //   if (status) {
  //     return (
  //       <Button
  //         color="success"
  //         title="timebased"
  //         onClick={() => {
  //           updateStatus(status, id);
  //         }}
  //       >
  //         <span>Active</span>
  //       </Button>
  //     );
  //   } else {
  //     return (
  //       <Button
  //         color="danger"
  //         title="not timebased"
  //         onClick={() => {
  //           updateStatus(status, id);
  //         }}
  //       >
  //         <span>Nonactive</span>
  //       </Button>
  //     );
  //   }
  // };

  const ShowList = (list, id) => {
    return list.map((row) => {
      return <span style={{ display: "block" }}>{row}</span>;
    });
  };

  const populateAreaSales = (arr) => {
    if (!!arr.salesarea_name) {
      return arr.salesarea_name.map((item) => {
        return <span style={{ display: "block" }}>{item}</span>;
      });
    }
  };

  return (
    <>
      <AlternativeHeader parentName="Manage Schema" name="Schema List" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Schema List</h3>
                </CardHeader>
                {!isLoading ? (
                  <ToolkitProvider
                    data={listScheme}
                    keyField="listScheme"
                    columns={[
                      {
                        dataField: "id",
                        text: "ID Param",
                        sort: true,
                      },
                      {
                        dataField: "name",
                        text: "Schema",
                        sort: true,
                        style: {
                          whiteSpace: "unset",
                          textAlign: "justify",
                        },
                      },
                      {
                        dataField: "description",
                        text: "Description",
                        sort: true,
                        style: {
                          width: "300px",
                          whiteSpace: "unset",
                          textAlign: "justify",
                        },
                      },
                      {
                        dataField: "dc_names",
                        editable: false,
                        text: "DC",
                        formatter: (cell, row) => {
                          return <>{ShowList(row.dc_names, row.id)}</>;
                        },
                      },
                      {
                        dataField: "salesarea_name",
                        editable: false,
                        text: "Area Sales",
                        formatter: (cell, row) => {
                          return <>{populateAreaSales(row)}</>;
                        },
                      },
                      {
                        dataField: "year_month",
                        editable: false,
                        text: "Waktu Aktif",
                        formatter: (cell, row) => {
                          return <>{ShowList(row.year_month, row.id)}</>;
                        },
                      },
                      {
                        dataField: "date_created",
                        text: "Create Date",
                        sort: true,
                        formatter: (cell, row) => {
                          return (
                            <>
                              {moment(row.createdAt).format(
                                "DD MMM YYYY hh:mm:ss"
                              )}
                            </>
                          );
                        },
                      },
                      // {
                      //   dataField: 'is_active',
                      //   editable: false,
                      //   text: 'Active',
                      //   formatter: (cell, row) => {
                      //     return <>{SchemaStatus(row.is_active, row.id)}</>;
                      //   },
                      // },
                      {
                        dataField: "id",
                        editable: false,
                        text: "Action",
                        formatter: (cell, row) => {
                          return (
                            <>
                              {/* <Link
                                className='btn btn-info'
                                title='More info'
                                to={`/admin/detail-schema/${row.id}`}
                              >
                              <i className='fa fa-info-circle' />
                              </Link> */}
                              <Link
                                className="btn btn-secondary"
                                title="Edit"
                                to={`/admin/edit-schema/${row.id}`}
                              >
                                <i className="fas fa-user-edit" />
                              </Link>
                            </>
                          );
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1"
                        >
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <CardBody>
                    <div className="text-center" style={{ minHeight: "50vh" }}>
                      <Spinner />
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ParameterList;

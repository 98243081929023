import axios from "../api/axios";
import qs from "querystring";

export default async function fetchBAByDCNames(DCs) {
  const query = qs.stringify({
    dc: `in.(${DCs.join(",")})`,
  });

  const { data } = await axios.get(`/v_details_ba_historical?${query}`);

  return data;
}

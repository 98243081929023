import Axios from "services/axios";
import Cookies from "universal-cookie";
import qs from "querystring";

export default async function fetchAllBrand() {
  const cookies = new Cookies();
  const userToken = cookies.get("userToken");

  try {
    const query = qs.stringify({
        select: `name`,
        order: `name.asc`,
        id: `neq.5`,
    });

    let { data } = await Axios({
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + `/v1/brand/list?`+query,
      headers: {
        "Authorization": "Bearer "+ userToken
      },
      data: {},
    });
    return data.data.data;
  } catch (err) {
    throw err;
  }
}

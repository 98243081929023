/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import Cookies from 'universal-cookie';
import Axios from "api/axios";

import { Autocomplete } from "@material-ui/lab";
import { Button, TextField, Switch } from "@material-ui/core";
// reactstrap components
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
// core components
import { isEmpty } from "lodash";
import LoadingProvider from "../../../../components/ba-achievement/LoadingProvider";
import SnackbarAlert from "../../../../components/ba-achievement/SnackbarAlert";

const RegisterCompetitor = () => {
  const [competitorObj, setCompetitorObj] = useState({
    is_popup: false,
    is_prohibit_sellout: false,
  });
  const [focusedName, setFocusedName] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const fetchCategory = async () => {
      const cookies = new Cookies();
      const userToken = cookies.get("userToken");

      const { data } = await Axios({
        method: "GET",
        url: "competitor_category?sort=id.asc",
        headers: { Authorization: `Bearer ${userToken}` },
      });

      setCategoryList(data.data.data);
    };
    fetchCategory();
  }, []);

  const inputHandler = (e) => {
    let tempObj = { ...competitorObj };
    tempObj[e.target.name] = e.target.value;
    setCompetitorObj(tempObj);
  };

  const onCategorySelected = (_, value) => {
    let tempObj = { ...competitorObj };
    tempObj.category = value;
    setCompetitorObj(tempObj);
  };

  const switchChanged = (type) => {
    let tempObj = { ...competitorObj };
    tempObj[type] = !tempObj[type];
    setCompetitorObj(tempObj);
  };

  const getDuplicate = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    let { data } = await Axios({
      method: "GET",
      url: `/master_competitor?brand=${competitorObj.brand}`,
      headers: { Authorization: `Bearer ${userToken}` },
    });

    return data.data.data;
  };

  const isValid = async () => {
    const result = await getDuplicate();
    if (isEmpty(competitorObj.brand)) {
      return {
        isValid: false,
        errorMessage: "Mohon isi nama brand kompetitor",
      };
    } else if (isEmpty(competitorObj.category)) {
      return {
        isValid: false,
        errorMessage: "Mohon pilih kategori kompetitor",
      };
    } else if (result && result.length > 0) {
      return {
        isValid: false,
        errorMessage: `Kompetitor ${competitorObj.brand} sudah ada`,
      };
    }

    return {
      isValid: true,
    };
  };

  const submitCompetitor = async () => {
    setIsLoading(true);
    let validationResponse = await isValid();

    if (validationResponse.isValid) {
      try {
        const cookies = new Cookies();
        var user = cookies.get("userdashboard");
        let submitObj = { ...competitorObj };
        submitObj.category = submitObj.category.map((item) => item.id);

        const userToken = cookies.get("userToken");

        await Axios({
          method: "POST",
          url: "/master_competitor",
          data: {
            ...submitObj,
            user_id: user.id,
          },
          headers: { Authorization: `Bearer ${userToken}` },
        });

        setToastMessage("Kompetitor berhasil disubmit");
        setToastSeverity("success");
        setIsToastOpen(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        setToastMessage("Terjadi kesalahan mohon coba lagi");
        setToastSeverity("error");
        setIsToastOpen(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setToastMessage(validationResponse.errorMessage);
      setToastSeverity("error");
      setIsToastOpen(true);
      setIsLoading(false);
    }
  };

  const closeAlert = () => {
    setIsToastOpen(false);
    setToastMessage("");
  };

  return (
    <>
      <LoadingProvider isLoading={isLoading} />
      <SnackbarAlert
        isVisible={isToastOpen}
        severity={toastSeverity}
        message={toastMessage}
        closeEvent={() => closeAlert()}
      />
      <Row className="justify-content-center">
        <Col lg="6" md="8">
          <Card className="bg-secondary border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup
                  className={classnames({
                    focused: focusedName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Nama Brand
                  </label>
                  <InputGroup
                    className="input-group-merge input-group-alternative mb-3"
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                    }}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="brand"
                      placeholder="Name"
                      type="text"
                      onFocus={() => setFocusedName(true)}
                      onBlur={() => setFocusedName(false)}
                      onChange={(e) => inputHandler(e)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Kategori
                  </label>
                  <Autocomplete
                    multiple
                    freeSolo
                    options={categoryList || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => onCategorySelected(_, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{
                          background: "white",
                          boxShadow:
                            "0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)",
                        }}
                        variant="outlined"
                        placeholder="Pilih Kategori"
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: focusedName,
                  })}
                >
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlSelect1"
                  >
                    Jika laporan kompetitor ini belum diisi, maka...
                  </label>
                  <Row>
                    <Col xl={6} l={6} md={6}>
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Muncul pengingat
                      </label>
                      <br />
                      <Switch
                        color="primary"
                        onChange={() => switchChanged("is_popup")}
                      />
                    </Col>
                    <Col xl={6} l={6} md={6}>
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Tidak bisa iisi sell out
                      </label>
                      <br />
                      <Switch
                        color="primary"
                        onChange={() => switchChanged("is_prohibit_sellout")}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitCompetitor}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RegisterCompetitor;

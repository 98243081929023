import Axios from "services/axios";
import Cookies from "universal-cookie";
import qs from "querystring";

export default async function fetchSchemeById(SchemeId) {
  try {
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");
    
    const query = qs.stringify({
      id: `${SchemeId}`,
    });

    

    let { data } = await Axios({
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + `/v1/ach-master-scheme?`+query,
      headers: {
        "Authorization": "Bearer "+ userToken
      },
      data: {},
    });
    return data.data.data[0];
  } catch (err) {
    throw err;
  }
}

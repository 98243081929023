import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default React.memo(function AccordionAlert({ sheetError }) {
  const classes = useStyles();
  const { name, errors } = sheetError;

  if (name === "invalidFileExtension") {
    return (
      <Alert className="my-3" severity="error">
        <Typography variant="body1">
          Terjadi kesalahan verifikasi, pastikan ekstensi file yang anda
          masukkan sudah benar (.xlsx)
        </Typography>
      </Alert>
    );
  }

  if (name === "invalidIdentifier") {
    return (
      <Alert className="my-3" severity="error">
        <Typography variant="body1">
          Terjadi kesalahan verifikasi, pastikan file yang anda masukkan sudah
          benar
        </Typography>
      </Alert>
    );
  }

  if (name === "invalidRowValue") {
    return (
      <Alert className="my-3" severity="error">
        <div className={classes.root}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>
                <Typography variant="body1">
                  Terdapat kekurangan/kesalahan data pada spreadsheet untuk BA
                  aktif
                </Typography>
                <Typography variant="caption">
                  Silahkan upload kembali file yang telah diperbaiki (Abaikan
                  pesan ini jika target tidak dibutuhkan)
                </Typography>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {errors.map((error) => {
                  const { ba_id, username, column, errorMessage } = error;
                  return (
                    <Typography className="my-2">
                      ba_id: {ba_id}, username: {username},{" "}
                      <span className="text-primary">{column}</span>,{" "}
                      <span className="text-danger">{errorMessage}</span>
                    </Typography>
                  );
                })}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Alert>
    );
  }

  return null;
});

import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import TextField from '@material-ui/core/TextField';
import {
    Switch,
    Backdrop,
    CircularProgress,
} from '@material-ui/core';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ParameterDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [parameterItem, setParameterItem] = useState({
        is_active: false,
        is_timebased: false,
    });

    const { id } = useParams();
    
    useEffect (() => { 
        const fetch = async() => {
            setIsLoading(true);
            try {
                const result = await axios.get('https://training-api.pti-cosmetics.com/ach_master_parameter?id=eq.'+id)
                setParameterItem(result.data[0]);
                setIsLoading(false);
            } catch (error) {
                setParameterItem([]);
                console.log(error)
            }
        } 
        fetch(); 
    }, [id])
    
    return (
      <>
        <SimpleHeader name="Test" />
        <Container className="mt--6" fluid>
            <Backdrop open={isLoading} style={{zIndex: '1000'}}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Row>
                <Col xl="8">
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <h2 className="mb-0">Test</h2>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl="12" className="mb-4">
                                        <h3>Nama Parameter</h3>
                                        <TextField 
                                            name="name" 
                                            variant="outlined" 
                                            value={parameterItem.name}
                                            style={{width: "100%"}} 
                                        />
                                    </Col>
                                    <Col xl="4" className="mb-4">
                                        <h3>Satuan</h3>
                                        <TextField 
                                            name="unit" 
                                            value={parameterItem.unit}
                                            variant="outlined" 
                                            style={{width: "100%"}} 
                                        />
                                    </Col>
                                    <Col xl="4" className="mb-4">
                                        <h3>Tipe Target Sekarang</h3>
                                        <TextField 
                                            name="unit" 
                                            value={parameterItem.table_name === "ach_ba_target_satuan"
                                                    ? "Satuan"
                                                    : parameterItem.table_name === "ach_ba_target_brand"
                                                        ? "Brand"
                                                        : "Global"
                                                    
                                                }
                                            variant="outlined" 
                                            style={{width: "100%"}} 
                                        />
                                    </Col>
                                    
                                    <Col xl="4">
                                        <h3>Apakah parameter ini bergantung terhadap waktu?</h3>
                                    </Col>
                                    <Col xl="2" className="mb-6">
                                        <Switch
                                            checked={parameterItem.is_timebased}
                                            name="is_timebased"
                                            color="primary"
                                        />
                                    </Col>
                                    <Col xl="4">
                                        <h3>Apakah parameter ini aktif?</h3>
                                    </Col>
                                    <Col xl="2" className="mb-6">
                                        <Switch
                                            checked={parameterItem.is_active}
                                            name="is_active"
                                            color="primary"
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container>
      </>
    );
}

export default ParameterDetails;

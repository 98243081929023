import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { sortBy } from "lodash";
import dateFormat from "dateformat";
import moment from "moment";

import Cookies from "universal-cookie";
import Axios from "services/axios";
import { Input } from "reactstrap";

// reactstrap components
import { Card, Modal, Button, CardHeader, Container, Row } from "reactstrap";

import CardDetail from "./CardDetail.jsx";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;
const cookies = new Cookies();
const userToken = cookies.get("userToken");
const jwt = cookies.get("jwtKey");

class RequestListTable extends React.Component {
  state = {
    correctionReqList: [],
    reqFiltered: [],
    reqDataDetail: [],
    request: {},
    openModalDetailRequest: false,
    openModalRejectRequest: false,
    openModalApproveRequest: false,
    notesRejectWarning: null,
    notesRejection: null,
  };

  async fetchCorrectionAtt() {
    var user = cookies.get("userdashboard");
    var ba_id_requester = [];

    await Axios({
      method: "GET",
      url:
        process.env.REACT_APP_BASE_URL +
        `/v1/user_admin_relation?user_id=${user.id}&is_admin=false`,
      headers: {
        Authorization: "Bearer " + userToken,
      },
      data: {},
    })
      .then(async (response2) => {
        let response = response2.data.data;
        var countI = 0;
        for (let i = 0; i < response.data.length; i++) {
          ba_id_requester.push(response.data[i].subordinate_id);
          countI = i;
        }
        if (countI === response.data.length - 1) {
          await Axios({
            method: "GET",
            url:
              process.env.REACT_APP_BASE_URL +
              `/v1/presence-correction-request-by-ba-id?status=waiting&ba_id=${ba_id_requester}`,
            headers: {
              Authorization: "Bearer " + userToken,
            },
            data: {},
          })
            .then((res2) => {
              let res = res2.data.data;
              console.log(res);
              let tempArray = res.data;
              let array = [];
              if (tempArray.length > 0) {
                tempArray.forEach(async (el) => {
                  const date = new Date(el.write_date);
                  const monthNames = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ];
                  const month = monthNames[date.getMonth()];
                  const fdate =
                    date.getDate() + " " + month + " " + date.getFullYear();
                  var baName;

                  await Axios({
                    method: "GET",
                    url:
                      process.env.REACT_APP_BASE_URL +
                      `/v1/master-ba/list?id=${el.ba_id}`,
                    headers: {
                      Authorization: "Bearer " + userToken,
                    },
                    data: {},
                  })
                    .then((res2) => {
                      let res = res2.data.data;
                      baName = res.data[0].name;
                      el.partner_id = res.data[0].partner_id;
                      array.push({
                        id: el.id,
                        ba_id: el.ba_id,
                        baName: baName,
                        write_date: fdate,
                        status: el.status,
                        actions: (
                          <div className="actions-right">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm ts-buttom"
                              size="sm"
                              onClick={this.showDetail.bind(this, el)}
                            >
                              View Detail
                            </button>
                          </div>
                        ),
                      });
                      if (array.length === tempArray.length) {
                        this.setState({
                          reqFiltered: array,
                        });
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async componentDidMount() {
    this.fetchCorrectionAtt();
  }

  populatePhaseType = (arr, type) => {
    let result = {};
    arr.forEach((item) => {
      if (item.phase === type) {
        result = item;
      }
    });
    return result;
  };

  showDetail = async (el) => {
    await Axios({
      method: "GET",
      url:
        process.env.REACT_APP_BASE_URL +
        `/v1/presence-correction-request-line?req_id=${el.id}&sort=req_dateonly.asc`,
      headers: {
        Authorization: "Bearer " + userToken,
      },
      data: {},
    })
      .then((res2) => {
        let res = res2.data.data;
        let temp = [];
        let dateNow, dateNext, dataAwal, dataAkhir;
        for (let i = 0; i < res.data.length; i++) {
          dateNow = "";
          dateNext = "";
          dateNow = dateFormat(
            new Date(res.data[i].time_edited),
            "dddd, dd mmm yyyy"
          );
          if (i !== res.data.length - 1) {
            dateNext = dateFormat(
              new Date(res.data[i + 1].time_edited),
              "dddd, dd mmm yyyy"
            );
          }

          dataAwal = {};
          dataAkhir = {};
          if (dateNow === dateNext) {
            if (res.data[i].phase === "Awal" || res.data[i].phase === "Izin") {
              dataAwal = res.data[i];
              dataAkhir = res.data[i + 1];
            } else if (res.data[i].phase === "Akhir") {
              dataAkhir = res.data[i];
              dataAwal = res.data[i + 1];
            }
            i++;
          } else {
            if (res.data[i].phase === "Awal" || res.data[i].phase === "Izin") {
              dataAwal = res.data[i];
            } else if (res.data[i].phase === "Akhir") {
              dataAkhir = res.data[i];
            }
          }
          temp.push({
            date: dateNow,
            awal: dataAwal,
            akhir: dataAkhir,
          });
        }

        let tempData = sortBy(temp, (item) => new Date(item.date));

        this.setState({
          openModalDetailRequest: true,
          reqDataDetail: tempData,
          correctionReqList: res.data,
          request: el,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  closeModalDetailRequest = () => {
    this.setState({
      openModalDetailRequest: false,
      reqDataDetail: [],
    });
  };

  openModalUpdateStatusBA = async (cell, ba, rowIndex) => {
    await Axios({
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + `/v1/master-ba/list?id=${ba.ba_id}`,
      headers: {
        Authorization: "Bearer " + userToken,
      },
      data: {},
    })
      .then((response2) => {
        let response = response2.data.data;
        var isBAChoosedActive;
        if (response.data[0].active === true) {
          isBAChoosedActive = 1;
        } else if (response.data[0].active === false) {
          isBAChoosedActive = 0;
        } else {
          isBAChoosedActive = 2; // null
        }
        this.setState({
          openModalUpdateBAStatus: true,
          dataBAUpdateStatus: ba,
          statusBAChoosed: response.data[0].active,
          isBAChoosedActive: isBAChoosedActive,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  ShowPopUpRejectRequest = () => {
    this.setState({
      openModalRejectRequest: true,
    });
  };

  ShowPopUpApproveRequest = () => {
    this.setState({
      openModalApproveRequest: true,
    });
  };

  closeNotesRejection = (e) => {
    this.setState({
      openModalRejectRequest: false,
      notesRejection: null,
      notesRejectWarning: null,
    });
  };

  handleNotesRejection = (e) => {
    this.setState({
      notesRejection: e.target.value,
    });
  };

  reloadPage = () => {
    window.location.reload();
  };

  handleRejectRequest = async () => {
    if (
      this.state.notesRejection === "" ||
      this.state.notesRejection === null
    ) {
      this.setState({
        notesRejectWarning:
          "Reject Gagal, mohon isi Notes dahulu sebelum Reject Request",
      });
    } else {
      var userdashboard = cookies.get("userdashboard");
      var dataUpdated = {
        status: "rejected",
        approver_type: userdashboard.role,
        approver_id: userdashboard.id,
        reason_rejected: this.state.notesRejection,
      };

      await Axios({
        method: "PATCH",
        url:
          process.env.REACT_APP_BASE_URL +
          `/v1/presence-correction-request?id=${this.state.request.id}`,
        headers: {
          Authorization: "Bearer " + userToken,
        },
        data: dataUpdated,
      })
        .then((result2) => {
          let result = result2.data.data;
          this.setState({
            openModalDetailRequest: false,
            openModalRejectRequest: false,
            notesRejection: null,
            notesRejectWarning: null,
            reqDataDetail: [],
            request: {},
          });
          this.reloadPage();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  formattedToISO(date) {
    console.log(date);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }

  handleApproveRequest = async () => {
    try {
      let dataPostPresence = [];
      var dataPresence = {};

      console.log(this.state.correctionReqList);
      // HANDLING UPDATE / POST KE PRESENCE
      for (let i = 0; i < this.state.correctionReqList.length; i++) {
        if (
          this.state.correctionReqList[i].presence_id === null ||
          !this.state.correctionReqList[i].presence_id ||
          this.state.correctionReqList[i].ket_before === "Izin"
        ) {
          dataPresence = {
            ba_id: this.state.request.ba_id,
            write_date: this.formattedToISO(
              this.state.correctionReqList[i].time_edited
            ),
            phase: this.state.correctionReqList[i].phase,
            keterangan: this.state.correctionReqList[i].ket_edited,
            partner_id: this.state.request.partner_id,
          };
          dataPostPresence.push(dataPresence);
        } else {
          dataPresence = {
            write_date: this.formattedToISO(
              this.state.correctionReqList[i].time_edited
            ),
            phase: this.state.correctionReqList[i].phase,
            keterangan: this.state.correctionReqList[i].ket_edited,
          };
          await Axios({
            method: "PATCH",
            url:
              process.env.REACT_APP_BASE_URL +
              `/v1/presence?id=${this.state.correctionReqList[i].presence_id}`,
            headers: {
              Authorization: "Bearer " + userToken,
            },
            data: dataPresence,
          })
            .then((result2) => {
              let result = result2.data.data;
            })
            .catch((error) => {
              console.log(error);
            });
        }

        if (this.state.correctionReqList[i].ket_before === "Izin") {
          await Axios({
            method: "DELETE",
            url:
              process.env.REACT_APP_BASE_URL +
              `/v1/presence?id=${this.state.correctionReqList[i].presence_id}`,
            headers: {
              Authorization: "Bearer " + userToken,
            },
            data: {},
          });
        }
      }

      if (dataPostPresence.length) {
        await Axios({
          method: "POST",
          url: process.env.REACT_APP_BASE_URL + `/v1/presence/bulk`,
          headers: {
            Authorization: "Bearer " + userToken,
          },
          data: dataPostPresence,
        })
          .then((result2) => {
            // this.reloadPage();
          })
          .catch((err) => {
            console.log(err);
          });

        // HANDLING UPDATE STATUS DI REQUEST CORRECTION PRESENCE
        var userdashboard = cookies.get("userdashboard");
        var dataUpdated = {
          status: "approved",
          approver_type: userdashboard.role,
          approver_id: userdashboard.id,
        };

        await Axios({
          method: "PATCH",
          url:
            process.env.REACT_APP_BASE_URL +
            `/v1/presence-correction-request?id=${this.state.request.id}`,
          headers: {
            Authorization: "Bearer " + userToken,
          },
          data: dataUpdated,
        })
          .then((result2) => {
            let result = result2.data;
            this.setState({
              openModalDetailRequest: false,
              openModalApproveRequest: false,
              notesRejection: null,
              notesRejectWarning: null,
              reqDataDetail: [],
              request: {},
            });

            this.reloadPage();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.setState({
          openModalDetailRequest: false,
          openModalApproveRequest: false,
          notesRejection: null,
          notesRejectWarning: null,
          reqDataDetail: [],
          request: {},
        });

        this.reloadPage();
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Request Correction Attendance</h3>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.reqFiltered}
                  keyField="name"
                  columns={[
                    {
                      dataField: "id",
                      text: "ID",
                      sort: true,
                      style: {
                        whiteSpace: "unset",
                      },
                    },
                    {
                      dataField: "ba_id",
                      text: "ID BA",
                      sort: true,
                      style: {
                        whiteSpace: "unset",
                      },
                    },
                    {
                      dataField: "baName",
                      text: "Nama BA",
                      sort: true,
                      style: {
                        whiteSpace: "unset",
                      },
                    },
                    {
                      dataField: "write_date",
                      text: "Reqest Date",
                      sort: true,
                      style: {
                        whiteSpace: "unset",
                      },
                    },
                    {
                      text: "Status",
                      dataField: "status",
                      style: {
                        whiteSpace: "unset",
                      },
                    },
                    {
                      text: "Action",
                      dataField: "actions",
                      style: {
                        whiteSpace: "unset",
                      },
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          className="modal-dialog-centered"
          contentClassName="bg-white"
          isOpen={this.state.openModalDetailRequest}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Detail Request Correction Attendance
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModalDetailRequest.bind(this)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div
              className="py-3 text-center"
              style={{ padding: "0px !important" }}
            >
              {this.state.reqDataDetail.length > 0 &&
                this.state.reqDataDetail.map((item) => {
                  return <CardDetail data={item} key={item.date} />;
                })}
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="text-white ml-auto"
              color="default"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({ openModalDetailRequest: false })}
            >
              Kembali
            </Button>
            <Button
              className="text-white"
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={this.ShowPopUpRejectRequest.bind(this)}
            >
              Reject
            </Button>
            <Button
              className="text-white"
              color="success"
              type="button"
              onClick={this.ShowPopUpApproveRequest.bind(this)}
            >
              Approve
            </Button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          contentClassName="bg-white"
          isOpen={this.state.openModalRejectRequest}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Please Give Notes
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.closeNotesRejection.bind(this)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <Input
                type="text"
                style={{ width: "100%", margin: "auto" }}
                value={this.state.notesRejection}
                onChange={this.handleNotesRejection.bind(this)}
              />
              <p color="red">{this.state.notesRejectWarning}</p>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="text-white ml-auto"
              color="default"
              type="button"
              onClick={this.closeNotesRejection.bind(this)}
            >
              Kembali
            </Button>
            <Button
              className="text-white"
              color="danger"
              type="button"
              onClick={this.handleRejectRequest.bind(this)}
            >
              Reject Request
            </Button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          contentClassName="bg-white"
          isOpen={this.state.openModalApproveRequest}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Please Give Notes
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({ openModalApproveRequest: false })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading mt-4">
                Apakah anda yakin akan approve request correction ini?
              </h4>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="text-white ml-auto"
              color="default"
              type="button"
              onClick={() => this.setState({ openModalApproveRequest: false })}
            >
              Kembali
            </Button>
            <Button
              className="text-white"
              color="success"
              type="button"
              onClick={this.handleApproveRequest}
            >
              Ya, Approve
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default RequestListTable;

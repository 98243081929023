import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";

export default function LoadingProvider({ children, isLoading }) {
  return (
    <>
      {isLoading ? (
        <Backdrop open={isLoading} style={{ zIndex: "99999" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

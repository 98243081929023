import React, { useEffect, useState } from 'react';

// react component for creating dynamic tables
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Spinner,
  Button,
  CardBody,
} from 'reactstrap';

// core components
import AlternativeHeader from 'components/Headers/AlternativeHeader.jsx';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import fetchAllParameters from '../../../services/fetch/parameter/fetchAllParameters';
import fetchAllTargetTable from '../../../services/fetch/target/fetchAllTargetTable';
import PatchParameterById from '../../../services/patch/patchParameterById';
// pagination
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

// search bar
const { SearchBar } = Search;

const ParameterList = () => {
  const [listParameter, setListParameter] = useState([]);
  const [listTableTarget, setListTableTarget] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validate, setIsValid] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const result = await fetchAllParameters()
        console.log(result)
        setListParameter(result);

        const target = await fetchAllTargetTable()
        setListTableTarget(target);

        setIsLoading(false);
        setIsValid(true);
      } catch (error) {
        setListParameter([]);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {

        const result = await fetchAllParameters()
        setListParameter(result);

        setIsLoading(false);
        setIsValid(true);
      } catch (error) {
        setListParameter([]);
      }
    };
    fetch();
  }, [validate]);

  const updateStatus = (status, id, field) => {
    Swal.fire({
      title: `Are you sure to ${status ? "deactivate" : "activate"} this ${
        field === "is_timebased" ? "Timebased" : "Parameter"
      } Status?`,
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      var parameter;
      if (result.isConfirmed) {
        if (field === "is_timebased") {
          parameter = {
            is_timebased: !status,
          };
        } else if (field === "is_active") {
          parameter = {
            is_active: !status,
          };
        }
        PatchParameterById(id, parameter).then((response) => {
          Swal.fire({
            icon: "success",
            text: "Beauty Tutorial Status has been updated.",
          });
          setIsValid(false);
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            text: "Fail to update Attendee status (Server Error)",
          });
        });
      }
    });
  };

  const TimebasedStatus = (status, id) => {
    if (status) {
      return (
        <Button
          color="default"
          title="timebased"
          onClick={() => {
            updateStatus(status, id, "is_timebased");
          }}
        >
          <span>Yes</span>
        </Button>
      );
    } else {
      return (
        <Button
          color="warning"
          title="not timebased"
          onClick={() => {
            updateStatus(status, id, "is_timebased");
          }}
        >
          <span>No</span>
        </Button>
      );
    }
  };

  const ParameterStatus = (status, id) => {
    if (status) {
      return (
        <Button
          color="success"
          title="active"
          onClick={() => {
            updateStatus(status, id, "is_active");
          }}
        >
          <span>Active</span>
        </Button>
      );
    } else {
      return (
        <Button
          color="danger"
          title="nonactive"
          onClick={() => {
            updateStatus(status, id, "is_active");
          }}
        >
          <span>Nonactive</span>
        </Button>
      );
    }
  };

  const getTargetType = (tableName) => {
    let tempListTableTarget = [...listTableTarget];
    let targetType = "";
    let found = false;
    let i = 0;

    while (i < tempListTableTarget.length && !found) {
      if (tempListTableTarget[i].table_name === tableName) {
        targetType = tempListTableTarget[i].name;
        found = true;
      } else {
        i += 1;
      }
    }

    return targetType;
  };

  return (
    <>
      <AlternativeHeader parentName="Manage Parameter" name="Parameter List" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Parameter List</h3>
                </CardHeader>
                {!isLoading ? (
                  <ToolkitProvider
                    data={listParameter}
                    keyField="listParameter"
                    columns={[
                      {
                        dataField: "id",
                        text: "ID Param",
                        sort: true,
                      },
                      {
                        dataField: "name",
                        text: "Parameter",
                        sort: true,
                        style: {
                          whiteSpace: "unset",
                          textAlign: "justify",
                        },
                      },
                      {
                        dataField: "unit",
                        text: "Unit",
                        sort: true,
                        style: {
                          whiteSpace: "unset",
                          textAlign: "justify",
                        },
                      },
                      {
                        dataField: "table_name",
                        text: "Tipe Target",
                        sort: true,
                        style: {
                          width: "300px",
                          whiteSpace: "unset",
                          textAlign: "justify",
                        },
                        formatter: (cell, row) => {
                          return <>{getTargetType(row.table_name)}</>;
                        },
                      },
                      {
                        dataField: "date_created",
                        text: "Create Date",
                        sort: true,
                        formatter: (cell, row) => {
                          return (
                            <>
                              {moment(row.createdAt).format(
                                "DD MMM YYYY hh:mm:ss"
                              )}
                            </>
                          );
                        },
                      },
                      {
                        dataField: "is_timebased",
                        editable: false,
                        text: "Timebased",
                        formatter: (cell, row) => {
                          return (
                            <>{TimebasedStatus(row.is_timebased, row.id)}</>
                          );
                        },
                      },
                      {
                        dataField: "is_active",
                        editable: false,
                        text: "Active",
                        formatter: (cell, row) => {
                          return <>{ParameterStatus(row.is_active, row.id)}</>;
                        },
                      },
                      {
                        dataField: "id",
                        editable: false,
                        text: "Action",
                        formatter: (cell, row) => {
                          return (
                            <>
                              <Link
                                className="btn btn-info"
                                title="More info"
                                to={`/admin/detail-parameter/${row.id}`}
                              >
                                <i className="fa fa-info-circle" />
                              </Link>
                              <Link
                                className="btn btn-secondary"
                                title="Edit"
                                to={`/admin/edit-parameter/${row.id}`}
                              >
                                <i className="fas fa-user-edit" />
                              </Link>
                            </>
                          );
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1"
                        >
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <CardBody>
                    <div className="text-center" style={{ minHeight: "50vh" }}>
                      <Spinner />
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ParameterList;

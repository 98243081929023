/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin that prints a given react component
//import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import Cookies from "universal-cookie";

import Axios from "api/axios";
// reactstrap components
import { Button, Card, CardHeader, Container, Row } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class ReactBSTables extends React.Component {
  constructor(props) {
    super(props);
    this.GetActionFormat = this.GetActionFormat.bind(this);
  }
  state = {
    alert: null,
    dataPartner: [],
  };

  componentDidMount() {
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    Axios({
      method: "GET",
      url: "/dashboard/v-master-toko/list",
      headers: {
        Authorization: "Bearer " + userToken,
      },
    })
      .then((response) => {
        this.setState({
          dataPartner: response.data.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onPartnerClick = (cell, partner, rowIndex) => {
    const cookies = new Cookies();
    cookies.set("selectedPartner", partner, { path: "/" });
  };

  buttonAction(cell, row, enumObject, rowIndex) {
    return (
      <Button
        className="buttons-copy buttons-html5"
        color="default"
        size="sm"
        id="copy-tooltip"
        onClick={this.onPartnerClick(cell, row, rowIndex)}
      >
        <span>Action</span>
      </Button>
    );
  }

  GetActionFormat(cell, row) {
    return (
      <div>
        <Link
          to={`/admin/dashboard-partner?partner_id=${row.partner_id}&brand=${row.brand}`}
        >
          <button
            type="button"
            className="btn btn-outline-primary btn-sm ts-buttom"
            size="sm"
          >
            View
          </button>
        </Link>
      </div>
    );
  }
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => this.setState({ alert: null })}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Copied to clipboard!
        </ReactBSAlert>
      ),
    });
  };
  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Master Partner" parentName="Tables" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Details Toko</h3>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.dataPartner}
                  keyField="name"
                  columns={[
                    {
                      dataField: "partner_id",
                      text: "ID",
                      sort: true,
                    },
                    {
                      dataField: "partner_name",
                      text: "Nama Store",
                      sort: true,
                    },
                    {
                      dataField: "partner_ref",
                      text: "Kode",
                      sort: true,
                    },
                    {
                      dataField: "address",
                      text: "Address",
                      sort: true,
                    },
                    {
                      dataField: "city",
                      text: "City",
                      sort: true,
                    },
                    {
                      dataField: "dc",
                      text: "DC",
                      sort: true,
                    },
                    {
                      dataField: "postcode",
                      text: "Post",
                      sort: true,
                    },
                    {
                      dataField: "jml_ba",
                      text: "Jumlah BA",
                      sort: true,
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        onClick={this.onPartnerClick.bind(this)}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default ReactBSTables;

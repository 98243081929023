import Axios from "api/axios";
import Cookies from "universal-cookie";
import qs from "querystring";

export default async function postTargetBrand(data, query) {
  const cookies = new Cookies();
  const userToken = cookies.get("userToken");

  try {

    await Axios({
      method: "DELETE",
      url: `ach_ba_target_brand/bulk`,
      headers: {
        Authorization: "Bearer " + userToken,
      },
      data: {
        ...query,
      },
    });

    await Axios({
      method: "POST",
      url: `ach_ba_target_brand/bulk`,
      data,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  } catch (err) {
    throw err;
  }
}

import React, { useState, useEffect } from "react";
import md5 from "md5";
import qs from "querystring";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

import AuthHeader from "components/Headers/AuthHeader.jsx";
import SnackbarAlert from "../../../components/digitalize-current-report/SnackbarAlert";
import LoadingProvider from "../../../components/digitalize-current-report/LoadingProvider";

import listDCRegion1 from "../../../helpers/listDCRegion1.json";
import listDCRegion2 from "../../../helpers/listDCRegion2.json";
import listDCRegion3 from "../../../helpers/listDCRegion3.json";
import Cookies from 'universal-cookie'
import Axios from "services/axios";

let cookies = new Cookies();

export default function UserRegistration() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFetchSuperior, setIsLoadingFetchSuperior] = useState(false);
  const [alert, setAlert] = useState({
    isVisible: false,
    severity: "error",
    message: "",
  });
  const [listDC, setListDC] = useState([]);

  const [userData, setUserData] = useState({
    name: "",
    nip: "",
    username: "",
    password: "",
  });
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDC, setSelectedDC] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState("");

  const [superiorList, setSuperiorList] = useState([]);
  const [selectedSuperiors, setSelectedSuperiors] = useState([]);
  
  const userToken = cookies.get("userToken");

  // event handlers
  const handleUserDataChange = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleRoleChange = (event) => {
    resetRoleParameters();
    setSelectedRole(event.target.value);
  };

  const handleRegionChange = (event) => setSelectedRegion(event.target.value);
  const handleDCChange = (_, value) => {
    setSelectedSuperiors([]);
    setSelectedDC(value);
  };

  const handleSuperiorChange = (_, value) => {
    setSelectedSuperiors(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // validate userData empty input
    const userDataKeys = Object.keys(userData);
    let isValidUserData = true;
    userDataKeys.forEach((key) => {
      if (!userData[key]) isValidUserData = false;
    });
    if (!isValidUserData) {
      fireAlert("error", "Pastikan semua field sudah terisi");
      return;
    }

    // validate empty role
    if (!selectedRole) {
      fireAlert("error", "Pilih role terlebih dahulu");
      return;
    }

    // validate empty DC
    if (DCNeededRoles.indexOf(selectedRole) !== -1) {
      if (!selectedDC) {
        fireAlert("error", `Pilih DC terlebih dahulu untuk role ${selectedRole}`);
        return;
      }
    }

    // validate empty region
    if (selectedRole === "RMDM" && !selectedRegion) {
      fireAlert("error", `Pilih region terlebih dahulu untuk role ${selectedRole}`);
      return;
    }

    setIsLoading(true);

    try {
      // validate unique username
      const res =  await Axios({
        method: "GET",
        url: process.env.REACT_APP_BASE_URL + `/v1/user_admin?username=${userData.username}`,
        headers: {
          "Authorization": "Bearer "+ userToken
        },
        data: {},
      });
      
      const checkedUsername = res.data.data.data;

      if (checkedUsername[0]) {
        fireAlert("error", `User dengan username ${userData.username} sudah pernah dibuat`);
        setIsLoading(false);
        return;
      }
    } catch (error) {
      fireAlert("error", `Terjadi kesalahan, periksa koneksi terlebih dahulu`);
    }

    try {
      const payload = {
        ...userData,
        password: md5(userData.password),
        role: selectedRole,
        dc_id: selectedDC ? selectedDC.partner_id : null,
        region: selectedRegion ? selectedRegion : null,
        is_ldap: false,
        is_active: true,
      };

      const res =  await Axios({
        method: "POST",
        url: process.env.REACT_APP_BASE_URL + `/v1/user_admin`,
        headers: {
          "Authorization": "Bearer "+ userToken
        },
        data: payload,
      });
    
      const result = res.data.data.data;

      if (DCNeededRoles.indexOf(selectedRole) !== -1 && selectedSuperiors) {
        const createdUser = result;

        const subordinate_id = createdUser;
        try{
          const superiorRelations = selectedSuperiors.map((elem) => {
            const { id: id } = elem;
  
            return { user_id: id, subordinate_id, is_admin: true };
          });
          if (selectedSuperiors.length) {
            for (let i = 0; i < superiorRelations.length; i++){
              await Axios({
                method: "POST",
                url: process.env.REACT_APP_BASE_URL + `/v1/user_admin_relation`,
                headers: {
                  "Authorization": "Bearer "+ userToken
                },
                data: superiorRelations[i],
              }).then((response) => console.log(response))
            }
          }
        }
        catch(e){
          console.log(e)
        }
      }
      fireAlert("success", `Registrasi user berhasil`);
    } catch (error) {
      console.log(error)
      fireAlert("error", `Terjadi kesalahan, periksa koneksi terlebih dahulu`);
    } finally {
      resetInput();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await Axios({
          method: "GET",
          url: process.env.REACT_APP_BASE_URL + `/v1/master-dc/list`,
          headers: {
            "Authorization": "Bearer "+ userToken
          },
          data: {},
        });
				const data = res.data.data.data;

        setListDC(data);
      } catch (error) {
        fireAlert("error", `Terjadi kesalahan, periksa koneksi terlebih dahulu`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (DCNeededRoles.indexOf(selectedRole) === -1) {
        setSuperiorList([]);
        return;
      }

      try {
        setIsLoadingFetchSuperior(true);

        if (DCNeededRoles.indexOf(selectedRole) !== -1 && selectedDC && !selectedRegion) {
          const superiorRole = superiorMap[selectedRole];
          const { partner_name: dc_name, partner_id: dc_id } = selectedDC;
          const queryParameters = {
            role: `${superiorRole}`,
          };

          if (selectedRole === "AMDM") {
            let region;
            if (listDCRegion1.indexOf(dc_name) !== -1) region = "Region 1";
            if (listDCRegion2.indexOf(dc_name) !== -1) region = "Region 2";
            if (listDCRegion3.indexOf(dc_name) !== -1) region = "Region 3";

            queryParameters.region = `${region}`;
          } else {
            queryParameters.dc_id = `${dc_id}`;
          }

          const res =  await Axios({
            method: "GET",
            url: process.env.REACT_APP_BASE_URL + `/v1/user_admin?${qs.stringify(queryParameters)}`,
            headers: {
              "Authorization": "Bearer "+ userToken
            },
            data: {},
          });

          const data = res.data.data.data;

          setSuperiorList(data);
        }
      } catch (error) {
        fireAlert("error", `Terjadi kesalahan, periksa koneksi terlebih dahulu`);
      } finally {
        setIsLoadingFetchSuperior(false);
      }
    })();
  }, [selectedRole, selectedDC, selectedRegion]);

  // HELPERS
  // open alert
  const fireAlert = (severity, message) => {
    setAlert({
      isVisible: true,
      severity,
      message,
    });
  };
  // close alert
  const closeAlert = () => {
    setAlert({
      isVisible: false,
      severity: "error",
      message: "",
    });
  };

  const resetRoleParameters = () => {
    setSelectedDC(null);
    setSelectedRegion("");
    setSelectedSuperiors([]);
  };

  const resetInput = () => {
    resetRoleParameters();
    setSelectedRole("");
    setUserData({
      name: "",
      nip: "",
      username: "",
      password: "",
    });
  };

  const DCNeededRoles = ["AMDM", "FC", "BA"];
  const superiorMap = {
    BA: "FC",
    FC: "AMDM",
    AMDM: "RMDM",
  };

  return (
    <LoadingProvider isLoading={isLoading}>
      <AuthHeader title="User Registration" />
      <SnackbarAlert
        isVisible={alert.isVisible}
        severity={alert.severity}
        message={alert.message}
        closeEvent={() => closeAlert()}
      />

      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={handleSubmit}>
                  <TextInput
                    icon="ni ni-single-02"
                    label="Nama Lengkap"
                    name="name"
                    onChange={handleUserDataChange}
                    value={userData.name}
                  />
                  <TextInput label="NIP" name="nip" onChange={handleUserDataChange} value={userData.nip} />
                  <TextInput
                    icon="ni ni-email-83"
                    label="Username"
                    name="username"
                    onChange={handleUserDataChange}
                    value={userData.username}
                  />
                  <TextInput
                    icon="ni ni-lock-circle-open"
                    label="Password"
                    name="password"
                    onChange={handleUserDataChange}
                    type="password"
                    value={userData.password}
                  />

                  <div className="my-5"></div>

                  {/* select role */}
                  <CustomFormGroup label="Pilih Role">
                    <Input type="select" onChange={handleRoleChange} value={selectedRole}>
                      <option value="">-- Pilih Role --</option>
                      <option value="Report HO">Report HO</option>
                      <option value="RMDM">RMDM</option>
                      <option value="AMDM">AMDM</option>
                      <option value="FC">FC</option>
                      {/* <option value="BA">BA</option> */}
                    </Input>
                  </CustomFormGroup>

                  {selectedRole && selectedRole !== "Report HO" ? (
                    <>
                      {selectedRole === "RMDM" ? (
                        <>
                          {/* select region */}
                          <CustomFormGroup label="Pilih Region">
                            <Input type="select" onChange={handleRegionChange} value={selectedRegion}>
                              <option value="">-- Pilih Region --</option>
                              <option value="Region 1">Region 1</option>
                              <option value="Region 2">Region 2</option>
                              <option value="Region 3">Region 3</option>
                            </Input>
                          </CustomFormGroup>
                        </>
                      ) : (
                        <>
                          {/* select DC */}
                          <CustomFormGroup label="Pilih DC">
                            <Autocomplete
                              value={selectedDC}
                              multiple={false}
                              freeSolo
                              options={listDC}
                              getOptionLabel={(dc) => dc.partner_name}
                              onChange={handleDCChange}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Pilih DC"
                                  style={{ backgroundColor: "#fff" }}
                                />
                              )}
                            />
                          </CustomFormGroup>
                          <CustomFormGroup label={`Pilih ${superiorMap[selectedRole] || "Superior"}`}>
                            {isLoadingFetchSuperior ? (
                              <div>Loading...</div>
                            ) : (
                              <Autocomplete
                                value={selectedSuperiors}
                                multiple={true}
                                freeSolo
                                options={superiorList}
                                getOptionLabel={(superiorItem) => superiorItem.name}
                                onChange={handleSuperiorChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={`Pilih ${superiorMap[selectedRole] || "Superior"}`}
                                    style={{ backgroundColor: "#fff" }}
                                  />
                                )}
                              />
                            )}
                          </CustomFormGroup>
                        </>
                      )}
                    </>
                  ) : null}

                  <div className="my-5"></div>

                  <Row className="justify-content-center">
                    <Col xs="12" sm="6" lg="4">
                      <Button className="w-100" color="primary" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </LoadingProvider>
  );
}

// COMPONENTS

const CustomFormGroup = ({ children, label }) => {
  return (
    <Row className="mb-3">
      <Col xs="12">
        <Label>{label}</Label>
      </Col>
      <Col xs="12">{children}</Col>
    </Row>
  );
};

const TextInput = ({ type = "text", icon = "ni ni-credit-card", label, name, onChange, disabled, value }) => {
  return (
    <Row className="mb-3">
      <Col xs="12">
        <Label>{label}</Label>
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className={icon} />
            </InputGroupText>
          </InputGroupAddon>
          <Input type={type} name={name} onChange={onChange} disabled={disabled} value={value} />
        </InputGroup>
      </Col>
    </Row>
  );
};

import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from "axios";
import {
  Button,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
import Axios from "api/axios";
import Cookies from "universal-cookie";

// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "../../../components/switch";
import { isEmpty } from "lodash";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class ChangeStatusBA extends React.Component {
  constructor(props) {
    super(props);
    this.GetActionSelectFormat = this.GetActionSelectFormat.bind(this);
    this.GetActionDeselectFormat = this.GetActionDeselectFormat.bind(this);
    this.onDCSelectedChanged = this.onDCSelectedChanged.bind(this);
    this.onPresenceStatusSelectedChanged =
      this.onPresenceStatusSelectedChanged.bind(this);
  }
  state = {
    dataBA: [],
    listDC: [],
    selectedDC: [],
    selectedPresenceStatus: null,
    statusBAFilterSelected: null,
    dataSelectedBA: [],
    fullDataBA: [],
    listBAStatusToChoose: ["Active", "Non-Active"],
    createState: false,
    isLoading: false,
    listBrand: [],
    listSelectedBrand: [],
    tabActive: "DC",
    listRegion: [],
    listSalesArea: [],
    selectedSalesArea: [],
    listAreaByRegion: [],
  };

  getAllBrand = async () => {
    var listBrand = [];
    const cookies = new Cookies();

    const userToken = cookies.get("userToken");

    await Axios({
      method: "GET",
      url: "/brand/list?select=name&sort=name.asc&id=ne.5",
      headers: { Authorization: `Bearer ${userToken}` },
    }).then((res) => {
      const response=res.data
      response.data.data.forEach((brand) => {
        listBrand.push(brand);
      });
    });

    this.setState({
      listBrand: listBrand,
    });
  };

  getListDC = async () => {
    var listDC = [];
    const cookies = new Cookies();

    const userToken = cookies.get("userToken");

    await Axios({
      headers: { Authorization: `Bearer ${userToken}` },
      method: "get",
      url: "/master-dc/list",
    }).then((res) => {
      const response=res.data.data
      response.data.forEach((item) => {
        var dcItem = {
          id: item.partner_name,
          text: item.partner_name,
        };
        listDC.push(dcItem);
      });
    });

    this.setState({
      listDC: listDC,
    });
  };

  getDefaultBrand = () => {
    this.setState({
      listSelectedBrand: ["All"],
    });
  };

  onBrandSelectedChanged = (value, event) => {
    var arrayBrand = [];
    value.forEach((item) => {
      arrayBrand.push(item.name);
    });
    this.setState({
      listSelectedBrand: arrayBrand,
    });

    const isSalesArea = this.state.tabActive === "Region";

    const arr = isSalesArea
      ? this.state.selectedSalesArea
      : this.state.selectedDC;

    this.getDataBA(
      this.state.statusBAFilterSelected,
      arr,
      arrayBrand,
      isSalesArea
    );
  };

  getRegionList = async () => {
    try {
      const cookies = new Cookies();

      const userToken = cookies.get("userToken");

      const res = await Axios({
        headers: { Authorization: `Bearer ${userToken}` },
      method: "get",
      url: "/dashboard/v_master_salesregion/list",
      });
      const response =res.data.data.data
      this.setState({
        listRegion: response,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSelectedAreaList = async () => {
    try {
      const cookies = new Cookies();

      const userToken = cookies.get("userToken");

      const res = await Axios({
       headers: { Authorization: `Bearer ${userToken}` },
      method: "get",
      url: "/dashboard/v-sales-region-sales-area/list",
      });
      const response =res.data.data.data
      this.setState({
        listAreaByRegion: response,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onRegionSelected = (arr) => {
    const { listAreaByRegion } = this.state;
    let result = [];
    arr.forEach((item) => {
      const temp = listAreaByRegion.filter(
        (area) => area.sales_region_name === item.value
      );
      result = [...result, ...temp];
    });

    if (arr.length) {
      this.setState({
        listSalesArea: result,
      });
    } else {
      this.setState({
        listSalesArea: [],
      });
    }
  };

  componentDidMount() {
    this.getListDC();
    this.getAllBrand();
    this.getDefaultBrand();
    this.getRegionList();
    this.getSelectedAreaList();
  }

  toggleModal = (state) => {
    var tempState = "bg-gradient-danger";
    var message = "Gagal! Harap Lengkapi Semua Kolom!";
    this.setState({
      [state]: !this.state[state],
      createState: tempState,
      modalMessage: message,
    });
  };

  onPresenceStatusSelectedChanged = (value, event) => {
    this.setState({
      selectedPresenceStatus: value,
    });
  };

  onBAStatusFilterSelectedChanged = (value, event) => {
    this.setState({
      statusBAFilterSelected: value,
    });
    const isSalesArea = this.state.tabActive === "Region";
    const arr = isSalesArea
      ? this.state.selectedSalesArea
      : this.state.selectedDC;
    this.getDataBA(value, arr, this.state.listSelectedBrand, isSalesArea);
  };

  onSalesAreaChangeHandler = (arr) => {
    const arrArea = arr.map((item) => item.sales_area_name);
    this.setState({
      selectedSalesArea: arrArea,
      selectedDC: [],
    });
    this.getDataBA(
      this.state.statusBAFilterSelected,
      arrArea,
      this.state.listSelectedBrand,
      true
    );
  };

  onDCSelectedChanged = (value) => {
    var arrayDC = [];
    value.forEach((item) => {
      arrayDC.push(item.text);
    });
    this.setState({
      selectedDC: arrayDC,
      selectedSalesArea: [],
    });
    this.getDataBA(
      this.state.statusBAFilterSelected,
      arrayDC,
      this.state.listSelectedBrand
    );
  };

  getDataBA = (status, list, brand, isSalesArea) => {
    var active;

    const cookies = new Cookies();

    const userToken = cookies.get("userToken");

    if (status === "Active") {
      active = [true];
    } else if (status === "Non-Active") {
      active = [false];
    } else {
      active = [true, false];
    }
    const table= isSalesArea ? "/dashboard/v_details_ba_partner_areasales_nip/list" : "/v_details_ba_partner_dc_nip"


    list = list.map((item) => item.replace(/ /g, "%20"));

    var query = `sort=dc.asc,name.asc&${isSalesArea ? `salesarea_name=[${list}]` : `dc=[${list}]`
      }&active=[${active}]`;
    if (this.state.dataSelectedBA.length > 0) {
      var listIdBA = [];
      this.state.dataSelectedBA.forEach((item) => {
        listIdBA.push(item.ba_id);
      });
      query += "&ba_id=ni.[" + listIdBA + "]";
    }

    if (brand.length > 0 && brand.indexOf("All") === -1) {
      query += "&ba_brand=[" + brand + "]";
    }
    this.setState({
      isLoading: true,
    });
    Axios({
      headers: { Authorization: `Bearer ${userToken}` },
      method: "get",
      url: `${table}?${query}`,
    })
      .then((response) => {
        response.data.data.data.forEach((item) => {
          item.isSelected = true;
        });
        if (this.state.dataSelectedBA.length > 0) {
          this.setState({
            dataBA: response.data.data.data,
            isLoading: false,
          });
          Axios({
            headers: { Authorization: `Bearer ${userToken}` },
            method: "get",
            url: `${table}?${query}`,
          }).then((full) => {
            this.setState({
              fullDataBA: full.data.data.data,
            });
          });
        } else {
          this.setState({
            dataBA: response.data.data.data,
            fullDataBA: response.data.data.data,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  GetActionSelectFormat(cell, row) {
    return (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ts-buttom"
          size="sm"
          onClick={this.handleCheckBA.bind(this, cell, row)}
        >
          Select
        </button>
      </div>
    );
  }

  GetActionDeselectFormat(cell, row) {
    return (
      <div>
        <button
          type="button"
          className="btn btn-warning btn-sm ts-buttom"
          size="sm"
          onClick={this.handleUncheckBA.bind(this, cell, row)}
        >
          Cancel
        </button>
      </div>
    );
  }
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  handleCheckBox = () => {
    var tempDataBA = [...this.state.dataBA];
    this.setState({
      dataSelectedBA: tempDataBA.length !== 0 ? this.state.fullDataBA : [],
      dataBA: tempDataBA.length !== 0 ? [] : this.state.fullDataBA,
    });
  };

  handleCheckBA = (e, cell, row) => {
    var tempSelectedBA = [...this.state.dataSelectedBA];
    var tempDataBA = [...this.state.dataBA];
    var idx = tempDataBA.indexOf(cell);
    if (idx !== -1) {
      tempSelectedBA.push(tempDataBA[idx]);
      tempDataBA.splice(idx, 1);
      this.setState({
        dataBA: tempDataBA,
        dataSelectedBA: tempSelectedBA,
      });
    }
  };

  handleUncheckBA = (e, cell, row) => {
    var tempSelectedBA = [...this.state.dataSelectedBA];
    var tempDataBA = [...this.state.dataBA];
    var idx = tempSelectedBA.indexOf(cell);
    if (idx !== -1) {
      var newArray = [tempSelectedBA[idx]].concat(tempDataBA);
      tempSelectedBA.splice(idx, 1);
      this.setState({
        dataBA: newArray,
        dataSelectedBA: tempSelectedBA,
      });
    }
  };

  updateStatusBAonDatabase = async () => {
    this.setState({
      isLoading: true,
    });
    var listIdBA = [];
    this.state.dataSelectedBA.forEach((item) => {
      listIdBA.push(item.ba_id);
    });
    var status;
    if (this.state.selectedPresenceStatus === "Active") {
      status = {
        active: true,
      };
    } else if (this.state.selectedPresenceStatus === "Non-Active") {
      status = {
        active: false,
      };
    }

    const cookies = new Cookies();

    const userToken = cookies.get("userToken");

    var query = "id=[" + listIdBA + "]";

    await Axios({
      method: "PATCH",
          url: `/dashboard/master-ba/update-status?${query}`,
          data: {
            active: Boolean(status.active)
          },
          headers: { Authorization: `Bearer ${userToken}` },
    }).then((response) => {
      this.setState({
        isLoading: false,
        dataBA: [],
        dataSelectedBA: [],
        fullDataBA: [],
      });
    });
    const isSalesArea = this.state.tabActive === "Region";
    const arr = isSalesArea
      ? this.state.selectedSalesArea
      : this.state.selectedDC;

    this.getDataBA(this.state.statusBAFilterSelected, arr, "All", isSalesArea);
  };

  verifyInput = () => {
    const verifyAreaSelected =
      this.state.tabActive === "Region"
        ? this.state.selectedSalesArea.length !== 0
        : this.state.selectedDC.length !== 0;

    return (
      verifyAreaSelected &&
      this.state.dataSelectedBA.length > 0 &&
      (this.state.dataSelectedBA !== null ||
        this.state.statusBAFilterSelected !== []) &&
      this.state.selectedPresenceStatus !== null
    );
  };

  processUpdateStatusBA = () => {
    if (this.verifyInput()) {
      this.updateStatusBAonDatabase();
    } else {
      this.toggleModal("notificationModal");
    }
  };

  render() {
    var defaultSelectedBrand = [];
    if (
      this.state.listBrand.length > 0 &&
      this.state.listSelectedBrand.length > 0
    ) {
      this.state.listBrand.forEach((item) => {
        var isFound = false;
        var idx = 0;
        while (idx < this.state.listSelectedBrand.length && !isFound) {
          if (item.name === this.state.listSelectedBrand[idx]) {
            defaultSelectedBrand.push(item);
            isFound = true;
          } else {
            idx++;
          }
        }
      });
    }

    const isOpenListBa =
      this.state.tabActive === "Region"
        ? this.state.selectedSalesArea.length > 0
        : this.state.selectedDC.length > 0;
    return (
      <>
        <SimpleHeader name="Manage" parentName="Change Status BA" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h2 className="mb-0">Change Status BA</h2>
                </CardHeader>

                <CardBody>
                  <Row>
                    <Col xl="12">
                      <h5 className="mb-3">Type</h5>
                      <Switch
                        tabList={{ left: "DC", right: "Region" }}
                        onChange={(value) =>
                          this.setState({
                            tabActive: value,
                            dataBA: [],
                            dataSelectedBA: [],
                            fullDataBA: [],
                            selectedDC: [],
                            selectedSalesArea: [],
                            statusBAFilterSelected: null,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  {this.state.tabActive === "DC" ? (
                    <Row>
                      <Col xl="3">
                        <h3>Filter DC</h3>
                      </Col>
                      <Col xl="6">
                        <Autocomplete
                          multiple
                          id="list-dc"
                          options={this.state.listDC}
                          getOptionLabel={(option) => option.text}
                          freeSolo
                          onChange={(event, value) =>
                            this.onDCSelectedChanged(value)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Pilih DC"
                              placeholder="Pilih DC"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Row className="mb-4">
                        <Col xl="3">
                          <h3>Pilih Region</h3>
                        </Col>
                        <Col xl="6">
                          <Autocomplete
                            multiple
                            id="list-region"
                            options={this.state.listRegion}
                            getOptionLabel={(option) => option.value}
                            freeSolo
                            onChange={(event, value) =>
                              this.onRegionSelected(value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Pilih Region"
                                placeholder="Pilih Region"
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="3">
                          <h3>Pilih Area Sales</h3>
                        </Col>
                        <Col xl="6">
                          <Autocomplete
                            multiple
                            options={this.state.listSalesArea}
                            id="list-areasales"
                            disabled={isEmpty(this.state.listSalesArea)}
                            getOptionLabel={(option) => option.sales_area_name}
                            freeSolo
                            onChange={(event, value) =>
                              this.onSalesAreaChangeHandler(value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Pilih Area Sales"
                                placeholder="Pilih Area Sales"
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                  {isOpenListBa || this.state.dataSelectedBA.length > 0 ? (
                    <>
                      <Row className="mt-4">
                        <Col xl="3">
                          <h3>Filter Status BA</h3>
                        </Col>
                        <Col xl="6">
                          <Autocomplete
                            options={this.state.listBAStatusToChoose}
                            getOptionLabel={(option) => option}
                            freeSolo
                            onChange={(event, value) =>
                              this.onBAStatusFilterSelectedChanged(value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Pilih Status"
                                placeholder="Pilih Status"
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col xl="3">
                          <h3>Filter Brand BA</h3>
                        </Col>
                        <Col xl="6">
                          {this.state.listBrand.length > 0 ? (
                            <Autocomplete
                              multiple
                              id="list-dc"
                              options={this.state.listBrand}
                              defaultValue={defaultSelectedBrand}
                              getOptionLabel={(option) => option.name}
                              freeSolo
                              onChange={(event, value) =>
                                this.onBrandSelectedChanged(value)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Pilih Brand"
                                  placeholder="Pilih Brand"
                                />
                              )}
                            />
                          ) : null}
                        </Col>
                      </Row>
                      {this.state.isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      ) : (
                        <>
                          <Row className="mt-4">
                            <Col xl="3">
                              <h3>Select All</h3>
                            </Col>
                            <Col xl="6">
                              <Checkbox
                                checked={this.state.dataBA.length === 0}
                                onChange={this.handleCheckBox.bind(this)}
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col xl="6">
                              <h3>BA yang belum dipilih</h3>
                            </Col>
                            <Col xl="6">
                              <h3>BA yang sudah dipilih</h3>
                            </Col>
                            <Col xl="6">
                              <ToolkitProvider
                                data={this.state.dataBA}
                                keyField="name"
                                columns={[
                                  {
                                    text: "Action",
                                    dataField: "",
                                    formatter: this.GetActionSelectFormat,
                                  },
                                  {
                                    dataField: "name",
                                    text: "Nama",
                                    sort: true,
                                  },
                                  {
                                    dataField: "active",
                                    text: "Active",
                                    sort: true,
                                  },
                                  {
                                    dataField: "ba_brand",
                                    text: "Brand",
                                    sort: true,
                                  },
                                  {
                                    dataField: "partner_name",
                                    text: "Nama Store",
                                    sort: true,
                                  },
                                  {
                                    dataField: "ref",
                                    text: "Customer ID",
                                    sort: true,
                                  },
                                ]}
                                search
                              >
                                {(props) => (
                                  <div className="py-4 table-responsive">
                                    <div
                                      id="datatable-basic_filter"
                                      className="dataTables_filter px-4 pb-1"
                                    >
                                      <label>
                                        Search:
                                        <SearchBar
                                          className="form-control-sm"
                                          placeholder=""
                                          {...props.searchProps}
                                        />
                                      </label>
                                    </div>
                                    <BootstrapTable
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      pagination={pagination}
                                      bordered={false}
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </Col>
                            <Col xl="6">
                              <ToolkitProvider
                                data={this.state.dataSelectedBA}
                                keyField="waktu_awal"
                                columns={[
                                  {
                                    text: "Action",
                                    dataField: "",
                                    formatter: this.GetActionDeselectFormat,
                                  },
                                  {
                                    dataField: "name",
                                    text: "Nama",
                                    sort: true,
                                  },
                                  {
                                    dataField: "active",
                                    text: "Active",
                                    sort: true,
                                  },
                                  {
                                    dataField: "ba_brand",
                                    text: "Brand",
                                    sort: true,
                                  },
                                  {
                                    dataField: "partner_name",
                                    text: "Nama Store",
                                    sort: true,
                                  },
                                  {
                                    dataField: "ref",
                                    text: "Customer ID",
                                    sort: true,
                                  },
                                ]}
                                search
                              >
                                {(props) => (
                                  <div className="py-4 table-responsive">
                                    <div
                                      id="datatable-basic_filter"
                                      className="dataTables_filter px-4 pb-1"
                                    >
                                      <label>
                                        Search:
                                        <SearchBar
                                          className="form-control-sm"
                                          placeholder=""
                                          {...props.searchProps}
                                        />
                                      </label>
                                    </div>
                                    <BootstrapTable
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      pagination={pagination}
                                      bordered={false}
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col xl="3">
                              <h3>Pilih Status BA Update</h3>
                            </Col>
                            <Col xl="6">
                              <Autocomplete
                                options={this.state.listBAStatusToChoose}
                                getOptionLabel={(option) => option}
                                freeSolo
                                onChange={(event, value) =>
                                  this.onPresenceStatusSelectedChanged(value)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Pilih Status"
                                    placeholder="Pilih Status"
                                  />
                                )}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col xl="3">
                              <Button
                                onClick={this.processUpdateStatusBA.bind(this)}
                                className="mt-4"
                                color="info"
                              >
                                Update Status BA
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  ) : null}
                </CardBody>
              </Card>
            </div>
          </Row>
          {/* Modal component */}
          <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName={this.state.createState}
            isOpen={this.state.notificationModal}
            toggle={() => this.toggleModal("notificationModal")}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-notification"></h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i className="ni ni-bell-55 ni-3x" />
                <h4 className="heading mt-4">{this.state.modalMessage}</h4>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                OK
              </Button>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default ChangeStatusBA;

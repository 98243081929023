import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export default function SchemePicker({ schemes, changeEvent }) {
  return (
    <Autocomplete
      disableClearable
      freeSolo
      options={schemes}
      getOptionLabel={(scheme) => scheme.name}
      onChange={changeEvent}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" placeholder="Pilih Skema" />
      )}
    />
  );
}

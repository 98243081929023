/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import ReactDatetime from "react-datetime";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components
import CardsHeader from "components/Headers/MainCardsHeader.jsx";

import {
  chartOptions,
  parseOptions,
  chartExample7,
  chartExample8,
} from "variables/charts.jsx";
import { Link } from "react-router-dom";

import Cookies from "universal-cookie";
import { CSVLink } from "react-csv";
import Axios from "api/axios";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "../../../components/switch";
import { find, isEmpty } from "lodash";
import AWS from "aws-sdk";


const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const S3_REGION = process.env.REACT_APP_S3_REGION;
const S3_ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY;
const S3_SECRET_KEY = process.env.REACT_APP_S3_SECRET_KEY;
const REGION = S3_REGION;

AWS.config.update({
  accessKeyId: S3_ACCESS_KEY,
  secretAccessKey: S3_SECRET_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});


const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class Dashboard extends React.Component {
  state = {
    activeNav: 1,
    stockOpnameResult: [],
    chartExample1Data: "data1",
    columnsPresence: [],
    allPresence: [],
    allPresenceRaw: [],
    allDataPartner: [],
    thisMonth: null,
    printedMonthSummarySalesByDC: null,
    printedYearSummarySalesByDC: null,
    printedMonthSummarySales: null,
    printedYearSummarySales: null,
    printedMonthTableSummarySales: null,
    printedYearTableSummarySales: null,
    printedMonthPresence: null,
    printedYearPresence: null,
    printedDatePresenceByDC: null,
    printedMonthPresenceByDC: null,
    printedYearPresenceByDC: null,
    printedMonthSO: null,
    printedYearSO: null,
    printedMonthVisitorBuyer: null,
    printedYearVisitorBuyer: null,
    printedMonthNotParticipatedSales: null,
    printedYearNotParticipatedSales: null,
    printedMonthNotParticipatedVisitorBuyer: null,
    printedYearNotParticipatedVisitorBuyer: null,
    selectedMonthSummarySalesByDC: null,
    selectedYearSummarySalesByDC: null,
    selectedMonthSummarySales: null,
    selectedYearSummarySales: null,
    selectedMonthTableSummarySales: null,
    selectedYearTableSummarySales: null,
    selectedMonthPresence: null,
    selectedYearPresence: null,
    selectedDatePresenceByDC: null,
    selectedMonthPresenceByDC: null,
    selectedYearPresenceByDC: null,
    selectedDatePresence: null,
    selectedMonthSO: null,
    selectedYearSO: null,
    selectedDateSO: null,
    selectedMonthVisitorBuyer: null,
    selectedYearVisitorBuyer: null,
    selectedMonthNotParticipatedSales: null,
    selectedYearNotParticipatedSales: null,
    selectedMonthNotParticipatedVisitorBuyer: null,
    selectedYearNotParticipatedVisitorBuyer: null,
    noNext: true,
    noNextPresenceByDC: true,
    noNextSO: true,
    noNextSummarySales: true,
    noNextSummarySalesByDC: true,
    noNextTableSummarySales: true,
    noNextTableVisitorBuyer: true,
    noNextTableNotParticipatedSales: true,
    noNextTableNotParticipatedVisitorBuyer: true,
    tabActive: "DC",
    presenceChartData: {},
    presenceChartDataByDC: {},
    colors: {
      gray: {
        100: "#f6f9fc",
        200: "#e9ecef",
        300: "#dee2e6",
        400: "#ced4da",
        500: "#adb5bd",
        600: "#8898aa",
        700: "#525f7f",
        800: "#32325d",
        900: "#212529",
      },
      theme: {
        default: "#172b4d",
        primary: "#5e72e4",
        secondary: "#f4f5f7",
        info: "#11cdef",
        success: "#2dce89",
        danger: "#f5365c",
        warning: "#fb6340",
      },
      black: "#12263F",
      white: "#FFFFFF",
      transparent: "transparent",
    },
    listTanggal: [],
    listTanggalSummaryChart: [],
    salesChartData: {},
    summaryChartData: {},
    summaryChartDataMonthly: {},
    summaryChartDataYearly: {},
    summaryChartDataMonthlyByDC: {},
    summaryChartDataDailyByDC: {},
    isSummaryChartDaily: true,

    listDC: [],
    listOnlyDC: [],
    selectedDC: "DC ACEH",
    listSelectedDC: [],
    openPhotoModal: false,
    selectedPhotoBA: {},
    listTotalPresensiByDC: [],
    listTotalOffByDC: [],
    listTotalNotPresensiByDC: [],
    selectedDatePresenceByDcPicker: null,

    columnsSalesTable: [],
    salesTableData: [],
    rawSalesTableData: [],
    visitorBuyerData: [],
    rawVisitorBuyerData: [],
    columnsVisitorBuyerTable: [],
    totalPresencedByDC: [],
    totalBA: null,
    currentMonth: null,
    currentYear: null,

    listNotParticipatedSalesPartner: [],
    listNotParticipatedVisitorBuyerPartner: [],
    columnTableNotParticipated: [],

    isCustomerSalesRecapLoading: false,
    isCustomerVisitorRecapLoading: false,
    isBAPresenceRecapLoading: false,
    isTotalSalesDailyLoading: false,
    isNotReportingSalesLoading: false,
    isNotReportingVisitorBuyerLoading: false,

    viewCustomerSalesRecap: true,
    viewNotReportingSales: true,
    viewCustomerVisitorRecap: true,
    viewNotReportingVisitor: true,

    scoreboard: [],
    byCampaign: [],
    byDC: [],
    scoreboardFilter: true,
    campaignFilter: false,
    dcFilter: false,
    filterBy: 1,
    alert: null,
    listDCRegionSatu: [
      "DC ACEH",
      "DC BANGKA",
      "DC BANTEN",
      "DC BATAM",
      "DC BENGKULU",
      "DC BUKITTINGGI",
      "DC JAMBI",
      "DC JAKARTA",
      "DC LAMPUNG",
      "DC MEDAN",
      "DC PADANG",
      "DC PALEMBANG",
      "DC PEKANBARU",
      "DC RANTAU PRAPAT",
    ],
    listDCRegionDua: [
      "DC BANDUNG",
      "DC BANJARMASIN",
      "DC BEKASI",
      "DC BOGOR",
      "DC CIREBON",
      "DC PALANGKARAYA",
      "DC PONTIANAK",
      "DC PURWOKERTO",
      "DC SAMARINDA",
      "DC SUKABUMI",
      "DC TASIKMALAYA",
      "DC TEGAL",
    ],
    listDCRegionTiga: [
      "DC AMBON",
      "DC BALI",
      "DC JEMBER",
      "DC KEDIRI",
      "DC KENDARI",
      "DC MAKASSAR",
      "DC MALANG",
      "DC MANADO",
      "DC MATARAM",
      "DC SEMARANG",
      "DC SOLO",
      "DC SURABAYA",
      "DC TERNATE",
      "DC YOGYAKARTA",
    ],
    selectedRegion: "",
    listDCRegionSatuText: "Region 1",
    listDCRegionDuaText: "Region 2",
    listDCRegionTigaText: "Region 3",
    listRegion: [],
    listSalesArea: [],
    regionSelected: [],
    areaSelected: [],
    listAreaByRegion: [],
  };

  constructor() {
    super();
    this.reloadPresence = this.reloadPresence.bind(this);
    this.reloadPresenceByDC = this.reloadPresenceByDC.bind(this);
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.GetBAActionFormat = this.GetBAActionFormat.bind(this);
    this.GetPhotoActionFormat = this.GetPhotoActionFormat.bind(this);
    this.onDCSelectedChanged = this.onDCSelectedChanged.bind(this);
  }

  toggleNavs = (event) => {
    event.preventDefault();
    //var tempMonth = this.state.chosenMonth;
    this.setState({
      activeNav: event.target.value,
      filterBy: event.target.value,
    });
  };

  getScoreboardData = () => {
    // axios.get('https://training-api.pti-cosmetics.com/v_buffer_user_counter_global?sort=username.asc')
    // .then(response=> {
    //   this.setState({ scoreboard: response.data });
    // })
    // .catch(function(error){
    //   console.log(error);
    // })
    //  axios.get('https://training-api.pti-cosmetics.com/v_buffer_user_counter_details?sort=campaign.asc')
    // .then(response=> {
    //   this.setState({ byCampaign: response.data });
    // })
    // .catch(function(error){
    //   console.log(error);
    // })
    // axios.get('https://training-api.pti-cosmetics.com/v_buffer_user_counter_details?sort=dc.asc')
    // .then(response=> {
    //   this.setState({ byDC: response.data });
    // })
    // .catch(function(error){
    //   console.log(error);
    // })
  };

  // toggleNavs = (e, index) => {
  //   e.preventDefault();
  //   this.setState({
  //     activeNav: index,
  //     chartExample1Data:
  //       this.state.chartExample1Data === "data1" ? "data2" : "data1"
  //   });
  // };

  getSelectedDC = () => {
    const cookies = new Cookies();
    var selectedDC;
    if (cookies.get("selectedDC") === undefined) {
      selectedDC = "DC ACEH";
      this.setState({
        selectedDC: "DC ACEH",
      });
    } else {
      selectedDC = cookies.get("selectedDC");
      this.setState({
        selectedDC: cookies.get("selectedDC"),
      });
    }

    return selectedDC;
  };

  getListSelectedDC = () => {
    const cookies = new Cookies();
    var listSelectedDC = ["DC ACEH"];
    if (
      cookies.get("listSelectedDC") === undefined ||
      cookies.get("listSelectedDC").length === 0
    ) {
      this.setState({
        listSelectedDC: ["DC ACEH"],
      });
    } else {
      listSelectedDC = cookies.get("listSelectedDC");
      this.setState({
        listSelectedDC: cookies.get("listSelectedDC"),
      });
    }

    return listSelectedDC;
  };

  getTotalPresencedByDC() {
    var today = new Date();
    var currentMonth = today.getMonth() + 1;
    var currentYear = today.getFullYear();
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: "GET",
      url: `/dashboard/v-active-ba-by-dc/list?sort=dc.asc&month=${currentMonth}&year=${currentYear}`,
    };
    Axios(
      config
    )
      .then((res) => {
        let presenced = res.data
        var tempTotalBA = 0;
        presenced.data.data.forEach((ba) => {
          tempTotalBA = ba.total_ba + tempTotalBA;
        });

        this.setState({
          totalPresencedByDC: presenced.data.data,
          totalBA: tempTotalBA,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  initiateSalesChartData = () => {
    var tempSalesChartData = {
      data: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Wardah",
            backgroundColor: "#49B9C6",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Emina",
            backgroundColor: "#222222",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Make Over",
            backgroundColor: "#FF8DA1",
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
      },
    };

    this.setState({
      salesChartData: tempSalesChartData,
    });
  };

  initiateColumnPresence = () => {
    var tempColumn = [];

    var itemsID = {
      dataField: "ba_id",
      text: "ID",
      sort: true,
    };

    var itemsBAName = {
      dataField: "ba_name",
      text: "BA Name",
      sort: true,
    };

    var itemsBABrand = {
      dataField: "ba_brand",
      text: "BA Brand",
      sort: true,
    };

    var itemsBADC = {
      dataField: "dc",
      text: "DC",
      sort: true,
    };

    var itemsBAStore = {
      dataField: "ba_store",
      text: "BA Store",
      sort: true,
    };

    var itemsBANIP = {
      dataField: "nip",
      text: "NIP",
      sort: true,
    };

    var itemsISCs = {
      dataField: "is_cs",
      text: "CS/BA",
      sort: true,
    };

    var itemsPercentCompleteness = {
      dataField: "percent_completeness",
      text: "Kelengkapan Presensi (%)",
      sort: true,
    };

    var itemsActionButton = {
      text: "View Details",
      dataField: "",
      formatter: this.GetBAActionFormat,
    };

    var itemsViewPhoto = {
      text: "View Photo",
      dataField: "",
      formatter: this.GetPhotoActionFormat,
    };

    tempColumn.push(itemsActionButton);
    tempColumn.push(itemsViewPhoto);
    tempColumn.push(itemsID);
    tempColumn.push(itemsBAName);
    tempColumn.push(itemsBABrand);
    tempColumn.push(itemsBADC);
    tempColumn.push(itemsBAStore);
    tempColumn.push(itemsBANIP);
    tempColumn.push(itemsISCs);
    tempColumn.push(itemsPercentCompleteness);

    return tempColumn;
  };

  initiateColumnNotParticipated = () => {
    var tempColumn = [];

    var itemsID = {
      dataField: "partner_id",
      text: "ID",
      sort: true,
    };

    var itemsPartnerName = {
      dataField: "partner_name",
      text: "Nama Toko",
      sort: true,
    };

    var itemsPartnerDC = {
      dataField: "dc",
      text: "DC",
      sort: true,
    };

    var itemsPartnerRef = {
      dataField: "ref",
      text: "Cust ID",
      sort: true,
    };

    var itemsPartnerChannel = {
      dataField: "channel",
      text: "Channel",
      sort: true,
    };

    var itemsPartnerBrand = {
      dataField: "brand",
      text: "Brand",
      sort: true,
    };

    var itemKonsiPutus = {
      dataField: "k_p",
      text: "K/P",
      sort: true,
    };

    tempColumn.push(itemsID);
    tempColumn.push(itemsPartnerName);
    tempColumn.push(itemsPartnerRef);
    tempColumn.push(itemsPartnerDC);
    tempColumn.push(itemsPartnerChannel);
    tempColumn.push(itemsPartnerBrand);
    tempColumn.push(itemKonsiPutus);

    this.setState({
      columnTableNotParticipated: tempColumn,
    });
  };

  initiateColumnSales = () => {
    var tempSalesColumn = [];

    var itemsPartnerViewButton = {
      text: "Action",
      dataField: "",
      formatter: this.GetActionFormat,
    };

    var itemsPartnerName = {
      dataField: "partner_name",
      text: "Nama Toko",
      sort: true,
    };

    var itemsPartnerDC = {
      dataField: "dc",
      text: "DC",
      sort: true,
    };

    var itemsPartnerRef = {
      dataField: "partner_ref",
      text: "Customer ID",
      sort: true,
    };

    var itemsChannel = {
      dataField: "store_type_code",
      text: "Channel",
      sort: true,
    };

    var itemsPartnerBrand = {
      dataField: "brand",
      text: "Brand",
      sort: true,
    };

    var itemsPartnerTotal = {
      dataField: "total_sales",
      text: "Total",
      sort: true,
    };

    var itemsPartnerPercentCompleteness = {
      dataField: "percent_completeness",
      text: "Kelengkapan Laporan (%)",
      sort: true,
    };

    var itemsPartnerConsignment = {
      dataField: "konsi_putus",
      text: "K/P",
      sort: true,
    };

    tempSalesColumn.push(itemsPartnerViewButton);
    tempSalesColumn.push(itemsPartnerName);
    tempSalesColumn.push(itemsPartnerRef);
    tempSalesColumn.push(itemsPartnerDC);
    tempSalesColumn.push(itemsChannel);
    tempSalesColumn.push(itemsPartnerBrand);
    tempSalesColumn.push(itemsPartnerTotal);
    tempSalesColumn.push(itemsPartnerPercentCompleteness);
    tempSalesColumn.push(itemsPartnerConsignment);

    return tempSalesColumn;
  };

  initiateColumnVisitor = () => {
    var tempVisBuyColumn = [];

    var itemsPartnerName = {
      dataField: "partner_name",
      text: "Nama Toko",
      sort: true,
    };

    var itemsPartnerRef = {
      dataField: "partner_ref",
      text: "Customer ID",
      sort: true,
    };

    var itemsPartnerDC = {
      dataField: "dc",
      text: "DC",
      sort: true,
    };

    var itemsChannel = {
      dataField: "channel",
      text: "Channel",
      sort: true,
    };

    var itemsPartnerBrand = {
      dataField: "brand",
      text: "Brand",
      sort: true,
    };

    var itemsPartnerConsignment = {
      dataField: "konsi_putus",
      text: "K/P",
      sort: true,
    };

    var itemsPartnerTotalVisitor = {
      dataField: "total_visitor",
      text: "Total Visitor",
      sort: true,
    };

    var itemsPartnerTotalBuyer = {
      dataField: "total_buyer",
      text: "Total Shopper",
      sort: true,
    };

    var itemsPartnerConversionRate = {
      dataField: "conversion_rate",
      text: "Tingkat Konversi (%)",
      sort: true,
    };

    var itemsPartnerPercentCompleteness = {
      dataField: "percent_completeness",
      text: "Kelangkapan Laporan (%)",
      sort: true,
    };

    tempVisBuyColumn.push(itemsPartnerName);
    tempVisBuyColumn.push(itemsPartnerRef);
    tempVisBuyColumn.push(itemsPartnerDC);
    tempVisBuyColumn.push(itemsChannel);
    tempVisBuyColumn.push(itemsPartnerBrand);
    tempVisBuyColumn.push(itemsPartnerConsignment);
    tempVisBuyColumn.push(itemsPartnerTotalVisitor);
    tempVisBuyColumn.push(itemsPartnerTotalBuyer);
    tempVisBuyColumn.push(itemsPartnerConversionRate);
    tempVisBuyColumn.push(itemsPartnerPercentCompleteness);

    return tempVisBuyColumn;
  };

  initiateSummaryChart = (tempTgl) => {
    var tempSummaryChartData = {
      data: {
        labels: tempTgl,
        datasets: [
          {
            label: "Wardah",
            backgroundColor: "#49B9C6",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Emina",
            backgroundColor: "#222222",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Make Over",
            backgroundColor: "#FF8DA1",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Crystalure",
            backgroundColor: "rgb(153, 118, 17)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Instaperfect",
            backgroundColor: "rgb(197, 132, 144)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Labore",
            backgroundColor: "rgb(255, 0, 110)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Biodef",
            backgroundColor: "rgb(47, 53, 189)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Kahf",
            backgroundColor: "rgb(113, 118, 97)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Tavi",
            backgroundColor: "rgb(39, 44, 165)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "OMG",
            backgroundColor: "rgba(0,0,0,0.5)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Putri",
            backgroundColor: "rgba(	187, 190, 44)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Wonderly",
            backgroundColor: "rgba(222, 190, 218)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Beyondly",
            backgroundColor: "rgba(1,83,82)",
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
      },
    };

    var tempSummaryYearChartData = {
      data: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Wardah",
            backgroundColor: "#49B9C6",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Emina",
            backgroundColor: "#222222",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Make Over",
            backgroundColor: "#FF8DA1",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Crystalure",
            backgroundColor: "rgb(153, 118, 17)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Instaperfect",
            backgroundColor: "rgb(197, 132, 144)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Labore",
            backgroundColor: "rgb(255, 0, 110)",
            data: [0, 0, 0, 0, 0, 0],
          },

          {
            label: "Biodef",
            backgroundColor: "rgb(47, 53, 189)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Kahf",
            backgroundColor: "rgb(113, 118, 97)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Tavi",
            backgroundColor: "rgb(39, 44, 165)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "OMG",
            backgroundColor: "rgba(0,0,0,0.5)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Putri",
            backgroundColor: "rgba(	187, 190, 44)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Wonderly",
            backgroundColor: "rgba(222, 190, 218)",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            label: "Beyondly",
            backgroundColor: "rgba(1,83,82)",
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
      },
    };

    this.setState({
      summaryChartData: tempSummaryChartData,
      summaryChartDataMonthly: tempSummaryYearChartData,
    });
  };

  initiateByDC = (currentDate, currentMonth, currentYear) => {
    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: `master-dc/list?select=partner_name,partner_id&sort=partner_name.asc&partner_name=neq.HEAD%20OFFICE`,
    }
    Axios(
      config
    )
      .then((res) => {
        let response = res.data
        var tempListDC = [];
        var tempListOnlyDC = [];
        var tempListPresensiDC = [];
        var tempListOffDC = [];
        var tempListNotPresensiDC = [];
        var dcItem = {
          id: "NASIONAL",
          text: "NASIONAL",
        };

        tempListDC.push(dcItem);
        response.data.data.forEach((dc) => {
          var dcItem = {
            id: dc.partner_name,
            text: dc.partner_name,
          };

          tempListPresensiDC.push(0);
          tempListNotPresensiDC.push(0);
          tempListOffDC.push(0);
          tempListOnlyDC.push(dc.partner_name);
          tempListDC.push(dcItem);
        });



        var tempSalesChartDataByDC = {
          data: {
            labels: tempListOnlyDC,
            datasets: [
              {
                label: "Wardah",
                backgroundColor: "#49B9C6",
                data: [
                  5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,
                  5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,
                ],
              },
              {
                label: "Emina",
                backgroundColor: "#222222",
                data: [
                  5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,
                  5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,
                ],
              },
              {
                label: "Make Over",
                backgroundColor: "#FF8DA1",
                data: [
                  5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,
                  5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,
                ],
              },
            ],
          },
        };

        this.reloadAllSummaryChartbyDC(currentMonth, currentYear);

        this.setState({
          listDC: tempListDC,
          listOnlyDC: tempListOnlyDC,
          summaryChartDataMonthlyByDC: tempSalesChartDataByDC,
          summaryChartDataDailyByDC: tempSalesChartDataByDC,
          listTotalPresensiByDC: tempListPresensiDC,
          listTotalOffByDC: tempListOffDC,
          listTotalNotPresensiByDC: tempListNotPresensiDC,
        });

        this.reloadPresenceByDC(currentDate, currentMonth, currentYear);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getImageUrl = async (key) => {
    if (key && key.includes("aws")) {
      const getParams = {
        Bucket: S3_BUCKET,
        Key: key,
      };

      return await new Promise((resolve, reject) => {
        myBucket.getSignedUrl("getObject", getParams, (err, _url) => {
          if (err) return reject(err);
          return resolve(_url);
        });
      });
    }
    return key;
  };

  dataFormattedHandler = async ({ data }) => {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      arr.push({
        ...element,
        image: await this.getImageUrl(element.image),
      });
    }

    return arr;
  };



  componentDidMount() {
    // this.getScoreboardData()
    var dateFormat = require("dateformat");
    var today = new Date();
    var currentMonth = today.getMonth() + 1;
    var currentYear = today.getFullYear();
    var currentDate = today.getDate();
    var tempThisMonth = dateFormat(currentMonth, "mmmm");

    var lastDate = 31;
    var listSelectedDC = this.getListSelectedDC();
    this.getTotalPresencedByDC();

    var tempColumn = this.initiateColumnPresence();
    var tempTgl = [];
    var tempSalesColumn = this.initiateColumnSales();
    var tempVisBuyColumn = this.initiateColumnVisitor();
    var retempVisBuyColumn = [];

    for (var i = 1; i < lastDate + 1; i++) {
      var items = {
        dataField: "tgl" + i,
        text: i + "",
        sort: true,
      };
      var salesItems = {
        dataField: "sales" + i,
        text: i + "",
        sort: true,
      };
      var visItem = {
        dataField: "v" + i,
        text: "V " + i,
        sort: true,
      };
      var buyItem = {
        dataField: "b" + i,
        text: "S " + i,
        sort: true,
      };
      tempColumn.push(items);
      tempSalesColumn.push(salesItems);
      tempVisBuyColumn.push(visItem);
      retempVisBuyColumn.push(buyItem);
      if (i <= today.getDate()) {
        tempTgl.push(items.text);
      }
    }

    for (var idx = 0; idx < retempVisBuyColumn.length; idx += 1) {
      tempVisBuyColumn.push(retempVisBuyColumn[idx]);
    }

    this.setState({
      columnsSalesTable: tempSalesColumn,
      columnsPresence: tempColumn,
      columnsVisitorBuyerTable: tempVisBuyColumn,
      thisMonth: tempThisMonth,
      printedMonthSummarySalesByDC: dateFormat(
        new Date(today.getFullYear(), today.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearSummarySalesByDC: currentYear,
      printedMonthSummarySales: dateFormat(
        new Date(today.getFullYear(), today.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearSummarySales: currentYear,
      printedMonthTableSummarySales: dateFormat(
        new Date(today.getFullYear(), today.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearTableSummarySales: currentYear,
      printedMonthPresence: dateFormat(
        new Date(today.getFullYear(), today.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearPresence: currentYear,
      printedDatePresenceByDC: today.getDate(),
      printedMonthPresenceByDC: dateFormat(
        new Date(today.getFullYear(), today.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearPresenceByDC: currentYear,
      printedMonthSO: dateFormat(
        new Date(today.getFullYear(), today.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearSO: currentYear,
      printedMonthVisitorBuyer: dateFormat(
        new Date(today.getFullYear(), today.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearVisitorBuyer: currentYear,
      printedMonthNotParticipatedSales: dateFormat(
        new Date(today.getFullYear(), today.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearNotParticipatedSales: currentYear,
      printedMonthNotParticipatedVisitorBuyer: dateFormat(
        new Date(today.getFullYear(), today.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearNotParticipatedVisitorBuyer: currentYear,
      thisMonthInt: currentMonth,
      thisMonthDate: currentDate,
      selectedMonthSummarySalesByDC: currentMonth,
      selectedYearSummarySalesByDC: currentYear,
      selectedMonthSummarySales: currentMonth,
      selectedYearSummarySales: currentYear,
      selectedMonthTableSummarySales: currentMonth,
      selectedYearTableSummarySales: currentYear,
      selectedMonthPresence: currentMonth,
      selectedYearPresence: currentYear,
      selectedDatePresenceByDC: today.getDate(),
      selectedDatePresenceByDcPicker: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),
      selectedMonthPresenceByDC: currentMonth,
      selectedYearPresenceByDC: currentYear,
      selectedDatePresence: currentDate,
      selectedMonthSO: currentMonth,
      selectedYearSO: currentYear,
      selectedMonthVisitorBuyer: currentMonth,
      selectedYearVisitorBuyer: currentYear,
      selectedMonthNotParticipatedSales: currentMonth,
      selectedYearNotParticipatedSales: currentYear,
      selectedMonthNotParticipatedVisitorBuyer: currentMonth,
      selectedYearNotParticipatedVisitorBuyer: currentYear,
      listTanggal: tempTgl,
      listTanggalSummaryChart: tempTgl,
      currentMonth: currentMonth,
      currentYear: currentYear,
    });

    this.reloadPresence(currentDate, currentMonth, currentYear, listSelectedDC);
    this.reloadSO(currentMonth, currentYear);
    this.reloadTablePartner(currentMonth, today.getFullYear(), listSelectedDC);
    this.reloadVisitorBuyer(currentMonth, currentYear, listSelectedDC);
    this.initiateByDC(currentDate, currentMonth, currentYear);
    this.initiateColumnNotParticipated();
    this.getRegionList();
    this.getSelectedAreaList();
    this.getPartnerNotParticipatedSales(
      currentMonth,
      today.getFullYear(),
      listSelectedDC
    );
    this.getPartnerNotParticipatedVisitorBuyer(
      currentMonth,
      today.getFullYear(),
      listSelectedDC
    );
    this.initiateSummaryChart(tempTgl);
    this.reloadAllSummaryChart(
      currentMonth,
      today.getFullYear(),
      listSelectedDC
    );
  }

  getRegionList = async () => {
    try {
      const cookies = new Cookies()
      const userToken = cookies.get('userToken')
      const config = {
        headers: { Authorization: `Bearer ${userToken}` },
        method: 'GET',
        url: '/dashboard/v_master_salesregion/list',
      }
      const res = await Axios(config);
      const getRegion = res.data
      this.setState({
        listRegion: getRegion.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSelectedAreaList = async () => {
    try {
      const cookies = new Cookies()
      const userToken = cookies.get('userToken')
      const config = {
        headers: { Authorization: `Bearer ${userToken}` },
        method: 'GET',
        url: '/dashboard/v-sales-region-sales-area/list',
      }
      const res = await Axios(config);
      const getSelectedArea = res.data
      this.setState({
        listAreaByRegion: getSelectedArea.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onRegionSelected = (arr) => {
    const { listAreaByRegion } = this.state;
    let result = [];
    arr.forEach((item) => {
      const temp = listAreaByRegion.filter(
        (area) => area.sales_region_name === item.value
      );
      result = [...result, ...temp];
    });

    if (arr.length) {
      this.setState({
        listSalesArea: result,
      });
    } else {
      this.setState({
        listSalesArea: [],
      });
    }
  };

  getPartnerNotParticipatedSales = async (month, year, arr, filterType) => {
    this.setState({
      isNotReportingSalesLoading: true,
      listNotParticipatedSalesPartner: [],
    });
    const isSalesArea = filterType === "Region";

    var listParticipated = await this.getParticipatedSalesPartner(
      month,
      year,
      arr,
      isSalesArea
    );
    var listNotParticipated = [];

    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: ``,
    }
    if (isSalesArea) {
      const configUrl = `select=partner_id,ref,partner_name,brand,areasales_name,k_p,store_type_code,brand_id&sort=areasales_name.asc,partner_name.asc,brand_id.asc,ref.desc&areasales_name=[${arr}]`.replace(/ /g, '%20')
      config.url = `/dashboard/v_partner_bivi_active_allbrand_by_salesarea/list?${configUrl}`

    } else {
      const configUrl = `select=partner_id,ref,partner_name,brand,dc,k_p,store_type_code,brand_id&sort=dc.asc,partner_name.asc,brand_id.asc,ref.desc&dc=[${arr}]`.replace(/ /g, '%20')
      config.url = `/dashboard/v_partner_bivi_active_allbrand/list?${configUrl}`

    }
    if (arr.length > 0) {
      await Axios(config).then((res) => {
        let result = res.data
        var tempData = [...result.data.data];
        listParticipated.forEach((par) => {
          var found = 0;
          var idx = 0;
          while (idx < tempData.length && !found) {
            if (tempData[idx].brand_id === par) {
              found = true;
            } else {
              idx++;
            }
          }

          if (found) {
            tempData.splice(idx, 1);
          }
        });

        if (tempData.length > 0) {
          tempData.forEach((item) => {
            item.channel = "N/A";
            if (item.store_type_code) {
              item.channel = item.store_type_code.substr(
                0,
                item.store_type_code.indexOf("-")
              );
            }
          });
          listNotParticipated = tempData;
        } else {
          listNotParticipated = [];
        }
      });
    }

    this.setState({
      listNotParticipatedSalesPartner: listNotParticipated,
      isNotReportingSalesLoading: false,
    });
  };

  getPartnerNotParticipatedVisitorBuyer = async (
    month,
    year,
    arr,
    filterType
  ) => {
    this.setState({
      isNotReportingVisitorBuyerLoading: true,
      listNotParticipatedVisitorBuyerPartner: [],
    });
    const isSalesArea = filterType === "Region";
    var listParticipated = await this.getParticipatedVisitorBuyerPartner(
      month,
      year,
      arr,
      isSalesArea
    );
    var listNotParticipated = [];
    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: ``,
    }


    if (isSalesArea) {
      const configUrl = `select=partner_id,ref,partner_name,brand,areasales_name,k_p,store_type_code,brand_id&sort=areasales_name.asc,partner_name.asc,brand_id.asc,ref.desc&areasales_name=[${arr}]`.replace(/ /g, '%20')
      config.url = `/dashboard/v_partner_bivi_active_allbrand_by_salesarea/list?${configUrl}`
    } else {
      const configUrl = `select=partner_id,ref,partner_name,brand,dc,k_p,store_type_code,brand_id&sort=dc.asc,partner_name.asc,brand_id.asc,ref.desc&dc=[${arr}]`.replace(/ /g, '%20')
      config.url = `/dashboard/v_partner_bivi_active_allbrand/list?${configUrl}`
    }

    if (arr.length > 0) {
      await Axios(config).then((res) => {
        let result = res.data
        var tempData = [...result.data.data];
        listParticipated.forEach((par) => {
          var found = 0;
          var idx = 0;
          while (idx < tempData.length && !found) {
            if (tempData[idx].brand_id === par) {
              found = true;
            } else {
              idx++;
            }
          }

          if (found) {
            tempData.splice(idx, 1);
          }
        });

        if (tempData.length > 0) {
          tempData.forEach((item) => {
            item.channel = "N/A";
            if (item.store_type_code) {
              item.channel = item.store_type_code.substr(
                0,
                item.store_type_code.indexOf("-")
              );
            }
          });
          listNotParticipated = tempData;
        } else {
          listNotParticipated = [];
        }
      });
    }

    this.setState({
      listNotParticipatedVisitorBuyerPartner: listNotParticipated,
      isNotReportingVisitorBuyerLoading: false,
    });
  };

  getParticipatedVisitorBuyerPartner = async (
    month,
    year,
    arr,
    isSalesArea
  ) => {
    var listPartnerIdBrand = [];
    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: `/dashboard/v_monthly_participated_visitor_toko${isSalesArea ? "_by_salesarea" : ""}/list?${isSalesArea ? "sort=salesarea_name.asc" : "sort=dc.asc"},partner_name.asc,brand_id.asc,partner_ref.desc&select=brand_id&month=${month}&year=${year}&${isSalesArea ? "salesarea_name" : "dc"}=[${arr}]`,
      // query: `${isSalesArea ? "sort=salesarea_name" : "sort=dc"},partner_name,brand_id.asc,partner_ref.desc&select=brand_id&month=eq.${month}&year=eq.${year}&${isSalesArea ? "salesarea_name" : "dc"}=in.(${arr})`.replace(/ /g, '%20')
    }
    if (arr.length > 0) {
      await Axios(
        config
      )
        .then((res) => {
          const result = res.data
          result.data.data.forEach((partner) => {
            listPartnerIdBrand.push(partner.brand_id);
          });
        });
    }

    return listPartnerIdBrand;
  };

  getParticipatedSalesPartner = async (month, year, arr, isSalesArea) => {
    var listPartnerIdBrand = [];
    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: `/dashboard/v_monthly_participated_toko${isSalesArea ? "2" : ""}/list?${isSalesArea ? "sort=salesarea_name.asc" : "sort=dc.asc"},partner_name.asc,brand_id.asc,partner_ref.desc&select=brand_id&month=${month}&year=${year}&${isSalesArea ? "salesarea_name" : "dc"}=[${arr}]`,
    }
    config.url = config.url.replace(/ /g, '%20')
    if (arr.length > 0) {
      await Axios(
        config
      )
        .then((res) => {
          const result = res.data
          result.data.data.forEach((partner) => {
            listPartnerIdBrand.push(partner.brand_id);
          });
        });
    }

    return listPartnerIdBrand;
  };

  prevMonthSummarySales = () => {
    var changedMonth = this.state.selectedMonthSummarySales - 1;

    this.setState({
      noNextSummarySales: false,
    });

    var dateFormat = require("dateformat");
    var date = new Date(this.state.selectedYearSummarySales, changedMonth, 0);

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    var tempTgl = [];

    for (var i = 1; i < date.getDate() + 1; i++) {
      var tgl = i + "";
      tempTgl.push(tgl);
    }

    this.setState({
      listTanggalSummaryChart: tempTgl,
    });

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    let arrListOfScope = [];
    let filterType = "DC";
    if (this.state.tabActive === "Region") {
      arrListOfScope = this.state.areaSelected;
      filterType = "Region";
    } else {
      arrListOfScope = this.state.listSelectedDC;

      if (this.state.areaSelected.length > 0) {
        arrListOfScope = this.state.areaSelected;
      }
    }

    this.reloadAllSummaryChart(
      compiledMonth,
      compiledYear,
      arrListOfScope,
      filterType
    );

    this.setState({
      selectedMonthSummarySales: compiledMonth,
      selectedYearSummarySales: compiledYear,
      printedMonthSummarySales: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearSummarySales: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  prevMonthTableNotReportingVisitorBuyer = () => {
    var changedMonth = this.state.selectedMonthNotParticipatedVisitorBuyer - 1;

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearNotParticipatedVisitorBuyer,
      changedMonth,
      0
    );

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    let arrListOfScope = [];
    let scopeType = "DC";
    if (this.state.tabActive === "Region") {
      arrListOfScope = this.state.areaSelected;
      scopeType = "Region";
    } else {
      arrListOfScope = this.state.listSelectedDC;

      if (this.state.areaSelected.length > 0) {
        arrListOfScope = this.state.areaSelected;
      }
    }

    this.getPartnerNotParticipatedVisitorBuyer(
      compiledMonth,
      compiledYear,
      arrListOfScope,
      scopeType
    );

    this.setState({
      selectedMonthNotParticipatedVisitorBuyer: compiledMonth,
      selectedYearNotParticipatedVisitorBuyer: compiledYear,
      printedMonthNotParticipatedVisitorBuyer: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearNotParticipatedVisitorBuyer: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  prevMonthTableNotReportingSales = () => {
    var changedMonth = this.state.selectedMonthNotParticipatedSales - 1;

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearNotParticipatedSales,
      changedMonth,
      0
    );

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    let arrListOfScope = [];
    let scopeType = "DC";
    if (this.state.tabActive === "Region") {
      arrListOfScope = this.state.areaSelected;
      scopeType = "Region";
    } else {
      arrListOfScope = this.state.listSelectedDC;

      if (this.state.areaSelected.length > 0) {
        arrListOfScope = this.state.areaSelected;
      }
    }

    this.getPartnerNotParticipatedSales(
      compiledMonth,
      compiledYear,
      arrListOfScope,
      scopeType
    );

    this.setState({
      selectedMonthNotParticipatedSales: compiledMonth,
      selectedYearNotParticipatedSales: compiledYear,
      printedMonthNotParticipatedSales: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearNotParticipatedSales: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  prevMonthTableSummarySales = () => {
    let filterType = "";
    var changedMonth = this.state.selectedMonthTableSummarySales - 1;

    this.setState({
      noNextTableSummarySales: false,
    });

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearTableSummarySales,
      changedMonth,
      0
    );

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    var usedListDC = this.state.listSelectedDC;
    if (this.state.areaSelected.length > 0) {
      usedListDC = this.state.areaSelected;
      filterType = 'Region';
    }
    console.log('[debug] prev:', usedListDC)
    this.reloadTablePartner(compiledMonth, compiledYear, usedListDC, filterType);

    this.setState({
      selectedMonthTableSummarySales: compiledMonth,
      selectedYearTableSummarySales: compiledYear,
      printedMonthTableSummarySales: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearTableSummarySales: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  prevMonthSummarySalesByDC = () => {
    var changedMonth = this.state.selectedMonthSummarySalesByDC - 1;

    this.setState({
      noNextSummarySalesByDC: false,
    });

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearSummarySalesByDC,
      changedMonth,
      0
    );

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    this.reloadAllSummaryChartbyDC(compiledMonth, compiledYear);

    this.setState({
      selectedMonthSummarySalesByDC: compiledMonth,
      selectedYearSummarySalesByDC: compiledYear,
      printedMonthSummarySalesByDC: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearSummarySalesByDC: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  prevMonthVisitorBuyer = () => {
    var changedMonth = this.state.selectedMonthVisitorBuyer - 1;

    var dateFormat = require("dateformat");
    var date = new Date(this.state.selectedYearVisitorBuyer, changedMonth, 0);

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    let arrListOfScope = [];
    let scopeType = "DC";
    if (this.state.tabActive === "Region") {
      arrListOfScope = this.state.areaSelected;
      scopeType = "Region";
    } else {
      arrListOfScope = this.state.listSelectedDC;

      if (this.state.areaSelected.length > 0) {
        arrListOfScope = this.state.areaSelected;
      }
    }

    this.reloadVisitorBuyer(
      compiledMonth,
      compiledYear,
      arrListOfScope,
      scopeType
    );

    this.setState({
      selectedMonthVisitorBuyer: compiledMonth,
      selectedYearVisitorBuyer: compiledYear,
      printedMonthVisitorBuyer: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearVisitorBuyer: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  prevMonth = () => {
    var changedMonth = this.state.selectedMonthPresence - 1;
    var changedDate;

    this.setState({
      noNext: false,
    });

    if (changedMonth === this.state.thisMonthInt) {
      changedDate = this.state.thisMonthDate;
    } else {
      changedDate = parseInt(
        new Date(this.state.selectedYearPresence, changedMonth, 0).getDate()
      );
    }

    var dateFormat = require("dateformat");
    var date = new Date(this.state.selectedYearPresence, changedMonth, 0);

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();
    var compiledDate = date.getDate();

    var tempTgl = [];

    for (var i = 1; i < date.getDate() + 1; i++) {
      var tgl = i + "";
      tempTgl.push(tgl);
    }

    this.setState({
      listTanggal: tempTgl,
    });

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    let arrListOfScope = [];
    let scopeType = "DC";
    if (this.state.tabActive === "Region") {
      arrListOfScope = this.state.areaSelected;
      scopeType = "Region";
    } else {
      arrListOfScope = this.state.listSelectedDC;

      if (this.state.areaSelected.length > 0) {
        arrListOfScope = this.state.areaSelected;
      }
    }

    this.reloadPresence(
      changedDate,
      compiledMonth,
      compiledYear,
      arrListOfScope,
      scopeType
    );

    this.setState({
      selectedDatePresence: compiledDate,
      selectedMonthPresence: compiledMonth,
      selectedYearPresence: compiledYear,
      printedMonthPresence: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearPresence: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  prevDayPresenceByDC = () => {
    var changedDate = this.state.selectedDatePresenceByDC - 1;

    this.setState({
      noNextPresenceByDC: false,
    });

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearPresenceByDC,
      this.state.selectedMonthPresenceByDC - 1,
      changedDate
    );

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();
    var compiledDate = date.getDate();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    this.reloadPresenceByDC(compiledDate, compiledMonth, compiledYear);

    this.setState({
      selectedDatePresenceByDC: compiledDate,
      selectedMonthPresenceByDC: compiledMonth,
      selectedYearPresenceByDC: compiledYear,
      printedDatePresenceByDC: compiledDate,
      printedMonthPresenceByDC: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearPresenceByDC: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  prevMonthSO = () => {
    var changedMonth = this.state.selectedMonthSO - 1;

    this.setState({
      noNextSO: false,
    });

    var dateFormat = require("dateformat");
    var date = new Date(this.state.selectedYearSO, changedMonth, 0);

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    this.reloadSO(compiledMonth, compiledYear);

    this.setState({
      selectedMonthSO: compiledMonth,
      selectedYearSO: compiledYear,
      printedMonthSO: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearSO: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  nextMonthTableNotReportingVisitorBuyer = () => {
    var changedMonth = this.state.selectedMonthNotParticipatedVisitorBuyer + 1;

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearNotParticipatedVisitorBuyer,
      changedMonth,
      0
    );

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    let arrListOfScope = [];
    let scopeType = "DC";
    if (this.state.tabActive === "Region") {
      arrListOfScope = this.state.areaSelected;
      scopeType = "Region";
    } else {
      arrListOfScope = this.state.listSelectedDC;

      if (this.state.areaSelected.length > 0) {
        arrListOfScope = this.state.areaSelected;
      }
    }

    this.getPartnerNotParticipatedVisitorBuyer(
      compiledMonth,
      compiledYear,
      arrListOfScope,
      scopeType
    );

    this.setState({
      selectedMonthNotParticipatedVisitorBuyer: compiledMonth,
      selectedYearNotParticipatedVisitorBuyer: compiledYear,
      printedMonthNotParticipatedVisitorBuyer: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearNotParticipatedVisitorBuyer: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  nextMonthTableNotReportingSales = () => {
    var changedMonth = this.state.selectedMonthNotParticipatedSales + 1;

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearNotParticipatedSales,
      changedMonth,
      0
    );

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    let arrListOfScope = [];
    let scopeType = "DC";
    if (this.state.tabActive === "Region") {
      arrListOfScope = this.state.areaSelected;
      scopeType = "Region";
    } else {
      arrListOfScope = this.state.listSelectedDC;

      if (this.state.areaSelected.length > 0) {
        arrListOfScope = this.state.areaSelected;
      }
    }

    this.getPartnerNotParticipatedSales(
      compiledMonth,
      compiledYear,
      arrListOfScope,
      scopeType
    );

    this.setState({
      selectedMonthNotParticipatedSales: compiledMonth,
      selectedYearNotParticipatedSales: compiledYear,
      printedMonthNotParticipatedSales: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearNotParticipatedSales: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  nextMonthSummarySalesByDC = () => {
    var changedMonth = this.state.selectedMonthSummarySalesByDC + 1;

    if (changedMonth === this.state.thisMonthInt) {
      this.setState({
        noNextSummarySalesByDC: true,
      });
    }
    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearSummarySalesByDC,
      changedMonth,
      0
    );

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    this.reloadAllSummaryChartbyDC(compiledMonth, compiledYear);

    this.setState({
      selectedMonthSummarySalesByDC: compiledMonth,
      selectedYearSummarySalesByDC: compiledYear,
      printedMonthSummarySalesByDC: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearSummarySalesByDC: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  nextMonthSummarySales = () => {
    var changedMonth = this.state.selectedMonthSummarySales + 1;

    var changedDate;

    if (changedMonth === this.state.thisMonthInt) {
      changedDate = this.state.thisMonthDate;
      this.setState({
        noNextSummarySales: true,
      });
    } else {
      changedDate = parseInt(
        new Date(this.state.selectedYearSummarySales, changedMonth, 0).getDate()
      );
    }

    var dateFormat = require("dateformat");
    var date = new Date(this.state.selectedYearSummarySales, changedMonth, 0);

    var tempTgl = [];
    for (var i = 1; i < changedDate + 1; i++) {
      var tgl = i + "";
      tempTgl.push(tgl);
    }

    this.setState({
      listTanggalSummaryChart: tempTgl,
    });

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    let arrListOfScope = [];
    let filterType = "DC";

    if (this.state.tabActive === "Region") {
      arrListOfScope = this.state.areaSelected;
      filterType = "Region";
    } else {
      arrListOfScope = this.state.listSelectedDC;

      if (this.state.areaSelected.length > 0) {
        arrListOfScope = this.state.areaSelected;
      }
    }

    this.reloadAllSummaryChart(
      compiledMonth,
      compiledYear,
      arrListOfScope,
      filterType
    );

    this.setState({
      selectedMonthSummarySales: compiledMonth,
      selectedYearSummarySales: compiledYear,
      printedMonthSummarySales: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearSummarySales: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  nextMonthTableSummarySales = () => {
    let filterType = ""
    var changedMonth = this.state.selectedMonthTableSummarySales + 1;

    if (changedMonth === this.state.thisMonthInt) {
      this.setState({
        noNextTableSummarySales: true,
      });
    }

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearTableSummarySales,
      changedMonth,
      0
    );

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    var usedListDC = this.state.listSelectedDC;
    if (this.state.areaSelected.length > 0) {
      usedListDC = this.state.areaSelected;
      filterType = 'Region';
    }
    console.log('[debug] prev:', usedListDC)
    this.reloadTablePartner(compiledMonth, compiledYear, usedListDC, filterType);

    this.setState({
      selectedMonthTableSummarySales: compiledMonth,
      selectedYearTableSummarySales: compiledYear,
      printedMonthTableSummarySales: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearTableSummarySales: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  nextMonthVisitorBuyer = () => {
    var changedMonth = this.state.selectedMonthVisitorBuyer + 1;

    var dateFormat = require("dateformat");
    var date = new Date(this.state.selectedYearVisitorBuyer, changedMonth, 0);

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    let arrListOfScope = [];
    let scopeType = "DC";
    if (this.state.tabActive === "Region") {
      arrListOfScope = this.state.areaSelected;
      scopeType = "Region";
    } else {
      arrListOfScope = this.state.listSelectedDC;

      if (this.state.areaSelected.length > 0) {
        arrListOfScope = this.state.areaSelected;
      }
    }

    this.reloadVisitorBuyer(
      compiledMonth,
      compiledYear,
      arrListOfScope,
      scopeType
    );

    this.setState({
      selectedMonthVisitorBuyer: compiledMonth,
      selectedYearVisitorBuyer: compiledYear,
      printedMonthVisitorBuyer: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearVisitorBuyer: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  nextMonth = () => {
    var changedMonth = this.state.selectedMonthPresence + 1;

    var changedDate;

    if (changedMonth === this.state.thisMonthInt) {
      changedDate = this.state.thisMonthDate;
      this.setState({
        noNext: true,
      });
    } else {
      changedDate = parseInt(
        new Date(this.state.selectedYearPresence, changedMonth, 0).getDate()
      );
    }

    var dateFormat = require("dateformat");
    var date = new Date(this.state.selectedYearPresence, changedMonth, 0);

    var tempTgl = [];
    for (var i = 1; i < changedDate + 1; i++) {
      var tgl = i + "";
      tempTgl.push(tgl);
    }

    this.setState({
      listTanggal: tempTgl,
    });

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();
    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    let arrListOfScope = [];
    let scopeType = "DC";
    if (this.state.tabActive === "Region") {
      arrListOfScope = this.state.areaSelected;
      scopeType = "Region";
    } else {
      arrListOfScope = this.state.listSelectedDC;

      if (this.state.areaSelected.length > 0) {
        arrListOfScope = this.state.areaSelected;
      }
    }

    this.reloadPresence(
      changedDate,
      compiledMonth,
      compiledYear,
      arrListOfScope,
      scopeType
    );

    this.setState({
      selectedDatePresence: changedDate,
      selectedMonthPresence: compiledMonth,
      selectedYearPresence: compiledYear,
      printedMonthPresence: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearPresence: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  nextDayPresenceByDC = () => {
    var changedDate = this.state.selectedDatePresenceByDC + 1;
    var today = new Date();
    if (changedDate === today.getDate()) {
      this.setState({
        noNextPresenceByDC: true,
      });
    }

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearPresenceByDC,
      this.state.selectedMonthPresenceByDC - 1,
      changedDate
    );

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();
    var compiledDate = date.getDate();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    this.reloadPresenceByDC(compiledDate, compiledMonth, compiledYear);

    this.setState({
      selectedDatePresenceByDC: compiledDate,
      selectedMonthPresenceByDC: compiledMonth,
      selectedYearPresenceByDC: compiledYear,
      printedDatePresenceByDC: compiledDate,
      printedMonthPresenceByDC: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearPresenceByDC: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  nextMonthSO = () => {
    var changedMonth = this.state.selectedMonthSO + 1;

    if (changedMonth === this.state.thisMonthInt) {
      this.setState({
        noNextSO: true,
      });
    }

    var dateFormat = require("dateformat");
    var date = new Date(this.state.selectedYearSO, changedMonth, 0);

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    this.reloadSO(compiledMonth, compiledYear);

    this.setState({
      selectedMonthSO: compiledMonth,
      selectedYearSO: compiledYear,
      printedMonthSO: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearSO: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  reloadAllSummaryChartbyDC = (month, year) => {
    //SUMMARY BY DC : WARDAH
    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const configWardah = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: `/dashboard/v_monthly_dc_summary_report/list?sort=dc.asc&brand=brand:Wardah&month=${month}&year=${year}`,
    }
    Axios(configWardah
    )
      .then((res) => {
        const summaryDC = res.data
        var tempListByDCWardah = [];
        this.state.listOnlyDC.forEach((dcname, index) => {
          var idx = summaryDC.data.data.findIndex((item) => item.dc === dcname);
          if (idx !== -1) {
            tempListByDCWardah.push(summaryDC.data.data[idx].total_sales / 1000000);
          } else {
            tempListByDCWardah.push(0);
          }
        });

        this.reloadSummaryChartByDC(tempListByDCWardah, "Wardah");
      })
      .catch((error) => {
        console.log(error);
        var tempListByDCWardah = [];
        this.state.listOnlyDC.forEach((dcname, index) => {
          tempListByDCWardah.push(0);
        });

        this.reloadSummaryChartByDC(tempListByDCWardah, "Wardah");
      });

    //SUMMARY BY DC : MAKE OVER
    const configMO = {
      headers: { Authorization: `Bearer ${userToken}` },

      method: 'GET',
      url: `/dashboard/v_monthly_dc_summary_report/list?sort=dc.asc&brand=brand:Make Over&month=${month}&year=${year}`,
    }
    Axios(
      configMO
    )
      .then((res) => {
        const summaryDC = res.data
        var tempListByDCMakeOver = [];
        this.state.listOnlyDC.forEach((dcname, index) => {
          var idx = summaryDC.data.data.findIndex((item) => item.dc === dcname);
          if (idx !== -1) {
            tempListByDCMakeOver.push(
              summaryDC.data.data[idx].total_sales / 1000000
            );
          } else {
            tempListByDCMakeOver.push(0);
          }
        });

        this.reloadSummaryChartByDC(tempListByDCMakeOver, "Make Over");
      })
      .catch((error) => {
        console.log(error);
        var tempListByDCMakeOver = [];
        this.state.listOnlyDC.forEach((dcname, index) => {
          tempListByDCMakeOver.push(0);
        });

        this.reloadSummaryChartByDC(tempListByDCMakeOver, "Make Over");
      });

    //SUMMARY BY DC : EMINA
    const configEmina = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: `/dashboard/v_monthly_dc_summary_report/list?sort=dc.asc&brand=brand:Emina&month=${month}&year=${year}`,

    }
    Axios(
      configEmina
    )
      .then((res) => {
        const summaryDC = res.data

        var tempListByDCEmina = [];
        this.state.listOnlyDC.forEach((dcname, index) => {
          var idx = summaryDC.data.data.findIndex((item) => item.dc === dcname);
          if (idx !== -1) {
            tempListByDCEmina.push(summaryDC.data.data[idx].total_sales / 1000000);
          } else {
            tempListByDCEmina.push(0);
          }
        });

        this.reloadSummaryChartByDC(tempListByDCEmina, "Emina");
      })
      .catch((error) => {
        console.log(error);
        var tempListByDCEmina = [];
        this.state.listOnlyDC.forEach((dcname, index) => {
          tempListByDCEmina.push(0);
        });
        this.reloadSummaryChartByDC(tempListByDCEmina, "Emina");
      });
  };

  reloadAllSummaryChart = (month, year, list, filterType) => {
    this.setState({
      isTotalSalesDailyLoading: true,
    });

    let baseEndpoint = "";
    let baseEndpointMonthly = "";

    let dailySalesQuery = "";
    let monthlySalesQuery = "";

    const isSalesArea = filterType === "Region";
    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const configDaily = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: ``,
    }
    const configMonthly = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: ``,
    }
    if (isSalesArea) {
      const queryDaily = `input_year=${year}&input_salesarea={${list}}`.replace(/ /g, '%20')
      const queryMonthly = `input_year=${year}&input_salesarea={${list}}`.replace(/ /g, '%20')
      configDaily.url = `/sp-daily-sales-summary-by-salesarea-month/list?${queryDaily}`;
      configMonthly.url = `/sp-daily-sales-summary-by-salesarea/list?${queryMonthly}`;
      // configDaily.table = `rpc/sp_daily_sales_summary_by_salesarea_month`;
      // configDaily.query = `input_year=${year}&input_salesarea={${list}}`.replace(/ /g, '%20')
      // configMonthly.table = `rpc/sp_daily_sales_summary_by_salesarea`;
      // configMonthly.query = `input_year=${year}&input_salesarea={${list}}`.replace(/ /g, '%20')
    } else {
      const queryDaily = `input_year=${year}&input_dc={${list}}`.replace(/ /g, '%20')
      const queryMonthly = `input_year=${year}&input_dc={${list}}`.replace(/ /g, '%20')
      configDaily.url = `/sp-daily-sales-summary-by-dc-month/list?${queryDaily}`;
      configMonthly.url = `/sp-daily-sales-summary-by-dc/list?${queryMonthly}`;
    }

    const isNotNational = list.indexOf("NASIONAL") === -1;
    if (list.length > 0) {
      if (isNotNational) {
        configDaily.url = `${configDaily.url}&input_month=${month}`;
      } else {
        configDaily.url = `/dashboard/sp_daily_sales_summary_by_dc_nasional?input_year=${year}&input_month=${month}`;
      }

      Axios(configDaily)
        .then((res) => {
          let brands = {};
          let summaries = res.data
          summaries.data.data.forEach((summary) => {
            if (brands[summary.brand]) {
              brands[summary.brand] = [...brands[summary.brand], summary];
            } else {
              brands[summary.brand] = [summary];
            }
          });

          //looping every brand
          for (const key in brands) {
            let idx = 0;

            const summary = brands[key];
            const tempListSummary = [];

            this.state.listTanggalSummaryChart.forEach((tanggal) => {
              var nominal = 0;
              if (idx < summary.length) {
                if (tanggal === summary[idx].day + "") {
                  nominal = summary[idx].total_sales;

                  idx++;
                }
              }
              tempListSummary.push((nominal / 1000000).toFixed(2));
            });
            this.reloadSummaryChart(tempListSummary, key);
          }

          if (isNotNational) {
            // get monthly sales
            Axios(configMonthly).then((res) => {
              let brands = {};
              let summariesByMonthly = res.data
              summariesByMonthly.data.data.forEach((summary) => {
                if (brands[summary.brand]) {
                  brands[summary.brand] = [...brands[summary.brand], summary];
                } else {
                  brands[summary.brand] = [summary];
                }
              });
              //looping every brand
              for (const key in brands) {
                let idx = 0;
                const summary = brands[key];
                let dataByMonth = [];

                for (let j = 1; j <= 12; j++) {
                  let tempTotalSales = 0;

                  summary.forEach((item) => {
                    if (j === item.month) {
                      tempTotalSales += item.total_sales;
                    }
                  });
                  dataByMonth.push({
                    month: j,
                    total_sales: tempTotalSales,
                  });
                }

                var tempListSummaryMonthly = [];
                for (var i = 1; i < 13; i++) {
                  var nominal = 0;
                  if (idx < dataByMonth.length) {
                    if (i === dataByMonth[idx].month) {
                      nominal = dataByMonth[idx].total_sales;
                      idx++;
                    }
                  }
                  tempListSummaryMonthly.push(nominal / 1000000);
                }

                this.reloadSummaryMonthlyChart(tempListSummaryMonthly, key);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        isTotalSalesDailyLoading: false,
      });
    }
  };

  reloadSummaryChartByDC = (summary, brand) => {
    var tempSummaryChartData = {};

    if (brand === "Wardah") {
      tempSummaryChartData = {
        data: {
          labels: this.state.listOnlyDC,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: summary,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartDataMonthlyByDC.data.datasets
                ? this.state.summaryChartDataMonthlyByDC.data.datasets[1].data
                : [],
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartDataMonthlyByDC.data.datasets
                ? this.state.summaryChartDataMonthlyByDC.data.datasets[2].data
                : [],
            },
          ],
        },
      };
    } else if (brand === "Emina") {
      tempSummaryChartData = {
        data: {
          labels: this.state.listOnlyDC,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartDataMonthlyByDC.data.datasets
                ? this.state.summaryChartDataMonthlyByDC.data.datasets[0].data
                : [],
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: summary,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartDataMonthlyByDC.data.datasets
                ? this.state.summaryChartDataMonthlyByDC.data.datasets[2].data
                : [],
            },
          ],
        },
      };
    } else if (brand === "Make Over") {
      tempSummaryChartData = {
        data: {
          labels: this.state.listOnlyDC,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartDataMonthlyByDC.data.datasets
                ? this.state.summaryChartDataMonthlyByDC.data.datasets[0].data
                : [],
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartDataMonthlyByDC.data.datasets
                ? this.state.summaryChartDataMonthlyByDC.data.datasets[1].data
                : [],
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: summary,
            },
          ],
        },
      };
    }

    this.setState({
      summaryChartDataMonthlyByDC: tempSummaryChartData,
    });
  };

  reloadSummaryMonthlyChart = (summary, brand) => {
    let tempSummaryChartData = {};
    if (brand.includes("Wardah")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: summary,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartDataMonthly.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartDataMonthly.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartDataMonthly.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartDataMonthly.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartDataMonthly.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartDataMonthly.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Emina")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartDataMonthly.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: summary,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartDataMonthly.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartDataMonthly.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartDataMonthly.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartDataMonthly.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartDataMonthly.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Make Over")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartDataMonthly.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartDataMonthly.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: summary,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartDataMonthly.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartDataMonthly.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartDataMonthly.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartDataMonthly.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Crystallure")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartDataMonthly.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartDataMonthly.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartDataMonthly.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: summary,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartDataMonthly.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartDataMonthly.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartDataMonthly.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Instaperfect")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartDataMonthly.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartDataMonthly.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartDataMonthly.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartDataMonthly.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: summary,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartDataMonthly.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartDataMonthly.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Labor")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartDataMonthly.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartDataMonthly.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartDataMonthly.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartDataMonthly.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartDataMonthly.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: summary,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartDataMonthly.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Biodef")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartDataMonthly.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartDataMonthly.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartDataMonthly.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartDataMonthly.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartDataMonthly.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartDataMonthly.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: summary,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Kahf")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartDataMonthly.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartDataMonthly.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartDataMonthly.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartDataMonthly.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartDataMonthly.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartDataMonthly.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartDataMonthly.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: summary,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Tavi")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: summary,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("OMG")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: summary,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Putri")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: summary,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Wonderly")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: summary,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Beyondly")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: summary,
            },
          ],
        },
      };
    }

    this.setState({
      summaryChartDataMonthly: tempSummaryChartData,
    });
  };

  reloadSummaryChart = (summary, brand) => {
    var tempSummaryChartData = {};

    if (brand.includes("Wardah")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: summary,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartData.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Emina")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: summary,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartData.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Make Over")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: summary,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartData.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Crystallure")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: summary,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartData.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Instaperfect")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: summary,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartData.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Labor")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: summary,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartData.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Biodef")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: summary,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartData.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Kahf")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: summary,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Tavi")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: summary,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("OMG")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: summary,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Putri")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: summary,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Wonderly")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: summary,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: this.state.summaryChartDataMonthly.data.datasets[12].data,
            },
          ],
        },
      };
    } else if (brand.includes("Beyondly")) {
      tempSummaryChartData = {
        data: {
          labels: this.state.listTanggalSummaryChart,
          datasets: [
            {
              label: "Wardah",
              backgroundColor: "#49B9C6",
              data: this.state.summaryChartData.data.datasets[0].data,
            },
            {
              label: "Emina",
              backgroundColor: "#FF8DA1",
              data: this.state.summaryChartData.data.datasets[1].data,
            },
            {
              label: "Make Over",
              backgroundColor: "#222222",
              data: this.state.summaryChartData.data.datasets[2].data,
            },
            {
              label: "Crystallure",
              backgroundColor: "rgb(153, 118, 17)",
              data: this.state.summaryChartData.data.datasets[3].data,
            },
            {
              label: "Instaperfect",
              backgroundColor: "rgb(197, 132, 144)",
              data: this.state.summaryChartData.data.datasets[4].data,
            },
            {
              label: "Labore",
              backgroundColor: "rgb(255, 0, 110)",
              data: this.state.summaryChartData.data.datasets[5].data,
            },

            {
              label: "Biodef",
              backgroundColor: "rgb(47, 53, 189)",
              data: this.state.summaryChartData.data.datasets[6].data,
            },
            {
              label: "Kahf",
              backgroundColor: "rgb(113, 118, 97)",
              data: this.state.summaryChartDataMonthly.data.datasets[7].data,
            },
            {
              label: "Tavi",
              backgroundColor: "rgb(39, 44, 165)",
              data: this.state.summaryChartDataMonthly.data.datasets[8].data,
            },
            {
              label: "OMG",
              backgroundColor: "rgba(0,0,0,0.5)",
              data: this.state.summaryChartDataMonthly.data.datasets[9].data,
            },
            {
              label: "Putri",
              backgroundColor: "rgba(	187, 190, 44)",
              data: this.state.summaryChartDataMonthly.data.datasets[10].data,
            },
            {
              label: "Wonderly",
              backgroundColor: "rgba(222, 190, 218)",
              data: this.state.summaryChartDataMonthly.data.datasets[11].data,
            },
            {
              label: "Beyondly",
              backgroundColor: "rgba(1,83,82)",
              data: summary,
            },
          ],
        },
      };
    }

    if (tempSummaryChartData.data) {
      this.setState({
        summaryChartData: tempSummaryChartData,
        isTotalSalesDailyLoading: false,
      });
    }
  };

  reloadSO = (month, year) => {
    var items;
    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: `/dashboard/v_is_so_store/list?sort=partner_name.desc&month=${month}&year=${year}`,
    }
    Axios(
      config
    )
      .then((res) => {
        var temp = [];
        let listStore = res.data
        listStore.data.data.forEach((storeSO) => {
          if (storeSO.sum > 0) {
            items = {
              partner_id: storeSO.partner_id,
              partner_name: storeSO.partner_name,
              is_stock_opname: "SUDAH",
            };
          } else {
            items = {
              partner_id: storeSO.partner_id,
              partner_name: storeSO.partner_name,
              is_stock_opname: "BELUM",
            };
          }

          temp.push(items);
        });

        this.setState({
          allDataPartner: temp,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  reloadVisitorBuyer = (month, year, arr, filterType) => {
    this.setState({
      isCustomerVisitorRecapLoading: true,
      visitorBuyerData: [],
      rawVisitorBuyerData: [],
    });
    let query = "";
    const isSalesArea = filterType === "Region";
    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: ``,
    }

    if (isSalesArea) {
      let configQuery =
        "sort=salesarea_name.asc,partner_name.asc,brand.asc&month=" +
        month +
        "&year=" +
        year +
        "&salesarea_name=[" +
        arr +
        "]";
      configQuery = configQuery.replace(/ /g, '%20')

      config.url = `/dashboard/v_daily_visitor_buyer_by_salesarea/list?${configQuery}`

    } else {
      let configQuery =
        "sort=dc.asc,partner_name.asc,brand.asc&month=" +
        month +
        "&year=" +
        year +
        "&dc=[" +
        arr +
        "]";
      configQuery = configQuery.replace(/ /g, '%20')

      config.url = `/dashboard/v_daily_visitor_buyer/list?${configQuery}`

    }

    if (arr.length > 0) {
      var totalDay = this.getTotalDayTableVisitorRecap(month, year);
      Axios(config)
        .then((res) => {
          let response = res.data
          var processedVisitorBuyer = [];
          var rawProcessedVisitorBuyer = [];
          var percent_raw = 0;

          response.data.data.forEach((item) => {
            item.total_visitor = 0;
            item.total_buyer = 0;
            item.total_laporan = 0;
            item.percent_completeness = 0;
            item.channel = "N/A";
            if (item.store_type_code !== null) {
              item.channel = item.store_type_code.substr(
                0,
                item.store_type_code.indexOf("-")
              );
            }

            item.total_visitor =
              Number(item.v1) +
              Number(item.v2) +
              Number(item.v3) +
              Number(item.v4) +
              Number(item.v5) +
              Number(item.v6) +
              Number(item.v7) +
              Number(item.v8) +
              Number(item.v9) +
              Number(item.v10);
            item.total_visitor =
              item.total_visitor +
              Number(item.v11) +
              Number(item.v12) +
              Number(item.v13) +
              Number(item.v14) +
              Number(item.v15) +
              Number(item.v16) +
              Number(item.v17) +
              Number(item.v18) +
              Number(item.v19) +
              Number(item.v20);
            item.total_visitor =
              item.total_visitor +
              Number(item.v21) +
              Number(item.v22) +
              Number(item.v23) +
              Number(item.v24) +
              Number(item.v25) +
              Number(item.v26) +
              Number(item.v27) +
              Number(item.v28) +
              Number(item.v29) +
              Number(item.v30) +
              Number(item.v31);

            item.total_buyer =
              Number(item.b1) +
              Number(item.b2) +
              Number(item.b3) +
              Number(item.b4) +
              Number(item.b5) +
              Number(item.b6) +
              Number(item.b7) +
              Number(item.b8) +
              Number(item.b9) +
              Number(item.b10);
            item.total_buyer =
              item.total_buyer +
              Number(item.b11) +
              Number(item.b12) +
              Number(item.b13) +
              Number(item.b14) +
              Number(item.b15) +
              Number(item.b16) +
              Number(item.b17) +
              Number(item.b18) +
              Number(item.b19) +
              Number(item.b20);
            item.total_buyer =
              item.total_buyer +
              Number(item.b21) +
              Number(item.b22) +
              Number(item.b23) +
              Number(item.b24) +
              Number(item.b25) +
              Number(item.b26) +
              Number(item.b27) +
              Number(item.b28) +
              Number(item.b29) +
              Number(item.b30) +
              Number(item.b31);

            for (var i = 1; i <= totalDay; i++) {
              var index = "v" + i;
              if (item[index] !== null) {
                item.total_laporan += 1;
              }
            }

            percent_raw = parseInt(
              ((item.total_laporan / totalDay) * 100).toFixed()
            );
            if (percent_raw > 100) {
              percent_raw = 100;
            }

            item.percent_completeness = percent_raw;

            if (item.total_buyer === 0) {
              item.conversion_rate = 0;
            } else {
              item.conversion_rate = parseFloat(
                (item.total_buyer / item.total_visitor) * 100
              ).toFixed(2);
            }

            var processedItem = {
              DC: item.dc,
              "PARTNER NAME": item.partner_name,
              "CUST ID": item.partner_ref,
              CHANNEL: item.channel,
              BRAND: item.brand,
              "K/P": item.konsi_putus,
              "JUMLAH VISITOR": item.total_visitor,
              "JUMLAH SHOPPER": item.total_buyer,
              "TINGKAT KONVERSI (%)": item.conversion_rate,
              "KELENGKAPAN LAPORAN (%)": percent_raw,
              "VIS 1": item.v1,
              "VIS 2": item.v2,
              "VIS 3": item.v3,
              "VIS 4": item.v4,
              "VIS 5": item.v5,
              "VIS 6": item.v6,
              "VIS 7": item.v7,
              "VIS 8": item.v8,
              "VIS 9": item.v9,
              "VIS 10": item.v10,
              "VIS 11": item.v11,
              "VIS 12": item.v12,
              "VIS 13": item.v13,
              "VIS 14": item.v14,
              "VIS 15": item.v15,
              "VIS 16": item.v16,
              "VIS 17": item.v17,
              "VIS 18": item.v18,
              "VIS 19": item.v19,
              "VIS 20": item.v20,
              "VIS 21": item.v21,
              "VIS 22": item.v22,
              "VIS 23": item.v23,
              "VIS 24": item.v24,
              "VIS 25": item.v25,
              "VIS 26": item.v26,
              "VIS 27": item.v27,
              "VIS 28": item.v28,
              "VIS 29": item.v29,
              "VIS 30": item.v30,
              "VIS 31": Number(item.v31),
              "SHOP 1": item.b1,
              "SHOP 2": item.b2,
              "SHOP 3": item.b3,
              "SHOP 4": item.b4,
              "SHOP 5": item.b5,
              "SHOP 6": item.b6,
              "SHOP 7": item.b7,
              "SHOP 8": item.b8,
              "SHOP 9": item.b9,
              "SHOP 10": item.b10,
              "SHOP 11": item.b11,
              "SHOP 12": item.b12,
              "SHOP 13": item.b13,
              "SHOP 14": item.b14,
              "SHOP 15": item.b15,
              "SHOP 16": item.b16,
              "SHOP 17": item.b17,
              "SHOP 18": item.b18,
              "SHOP 19": item.b19,
              "SHOP 20": item.b20,
              "SHOP 21": item.b21,
              "SHOP 22": item.b22,
              "SHOP 23": item.b23,
              "SHOP 24": item.b24,
              "SHOP 25": item.b25,
              "SHOP 26": item.b26,
              "SHOP 27": item.b27,
              "SHOP 28": item.b28,
              "SHOP 29": item.b29,
              "SHOP 30": item.b30,
              "SHOP 31": Number(item.b31),
            };



            processedVisitorBuyer.push(item);
            rawProcessedVisitorBuyer.push(processedItem);
          });

          this.setState({
            visitorBuyerData: processedVisitorBuyer,
            rawVisitorBuyerData: rawProcessedVisitorBuyer,
            isCustomerVisitorRecapLoading: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        isCustomerVisitorRecapLoading: false,
      });
    }
  };

  reloadTablePartner = async (month, year, listDc, filterType) => {
    this.setState({
      isCustomerSalesRecapLoading: true,
      salesTableData: [],
      rawSalesTableData: [],
    });

    var tableRow = [];
    var rawTableRow = [];

    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: "",
    }

    if (listDc.length > 0) {
      let tablePartner = [];
      var totalDay = this.getTotalDayTableSalesRecap(month, year);
      console.log('[debug] filter:', filterType, listDc)

      if (filterType === "Region") {
        for (const areasales_item of listDc) {
          console.log('[debug]: ', areasales_item)
          const findListSalesAreaId = find(this.state.listAreaByRegion, {
            sales_area_name: areasales_item,
          });
          console.log('[debug]: ', findListSalesAreaId)

          if (findListSalesAreaId) {
            config.url = `/sp-fullrecap-total-daily-sales-by-salesarea/list?sort=salesarea_name.asc,partner_name.asc,ref.asc,brand.asc,day.asc&input_month=${month}&input_year=${year}&input_salesarea=${findListSalesAreaId.sales_area_id}`
            const res = await Axios(config);
            const data = res.data.data
            tablePartner = [...tablePartner, ...data.data];
          }
        }
      } else {
        const configDC = {
          headers: { Authorization: `Bearer ${userToken}` },
          method: 'GET',
          url: `/master-dc/list?partner_name=[${listDc}]`,
        }
        configDC.url = configDC.url.replace(/ /g, '%20')
        const res = await Axios(configDC
        );
        const masterDC = res.data
        var listDcId = masterDC.data.data.map((item) => item.partner_id);
        for (const dcId of listDcId) {
          config.url = `/sp-fullrecap-total-daily-sales-by-dc/list?sort=dc.asc,partner_name.asc,ref.asc,brand.asc,day.asc&input_month=${month}&input_year=${year}&input_dc=${dcId}`
          // config.query = `sort=dc,partner_name,ref,brand,day.asc&input_month=${month}&input_year=${year}&input_dc=${dcId}`;
          const res = await Axios(config);
          const data = res.data.data
          tablePartner = [...tablePartner, ...data.data];
        }
      }

      var rawItems = {
        dc: null,
        konsi_putus: null,
        partner_name: null,
        cust_id: null,
        store_type_code: null,
        brand: null,
        total_sales: 0,
        total_isi: 0,
        percent_completeness: 0,
        sales1: null,
        sales2: null,
        sales3: null,
        sales4: null,
        sales5: null,
        sales6: null,
        sales7: null,
        sales8: null,
        sales9: null,
        sales10: null,
        sales11: null,
        sales12: null,
        sales13: null,
        sales14: null,
        sales15: null,
        sales16: null,
        sales17: null,
        sales18: null,
        sales19: null,
        sales20: null,
        sales21: null,
        sales22: null,
        sales23: null,
        sales24: null,
        sales25: null,
        sales26: null,
        sales27: null,
        sales28: null,
        sales29: null,
        sales30: null,
        sales31: null,
      };

      var items = {
        partner_id: null,
        dc: null,
        konsi_putus: null,
        partner_name: null,
        partner_ref: null,
        store_type_code: null,
        brand: null,
        total_sales: 0,
        total_isi: 0,
        percent_completeness: 0,
        sales1: null,
        sales2: null,
        sales3: null,
        sales4: null,
        sales5: null,
        sales6: null,
        sales7: null,
        sales8: null,
        sales9: null,
        sales10: null,
        sales11: null,
        sales12: null,
        sales13: null,
        sales14: null,
        sales15: null,
        sales16: null,
        sales17: null,
        sales18: null,
        sales19: null,
        sales20: null,
        sales21: null,
        sales22: null,
        sales23: null,
        sales24: null,
        sales25: null,
        sales26: null,
        sales27: null,
        sales28: null,
        sales29: null,
        sales30: null,
        sales31: null,
      };

      var selectedPartnerIterate = null;
      var selectedBrandIterate = null;
      var selectedPartnerRef;
      var storeTypeCode;

      tablePartner.forEach((partner, index) => {
        if (selectedPartnerIterate === null) {
          //
          selectedPartnerIterate = partner.partner_name;
          selectedBrandIterate = partner.brand;
          selectedPartnerRef = partner.ref;

          storeTypeCode = "N/A";
          if (partner.store_type_code) {
            storeTypeCode = partner.store_type_code.substr(
              0,
              partner.store_type_code.indexOf("-")
            );
          }
          items.partner_name = partner.partner_name;
          items.partner_ref = partner.ref;
          items.store_type_code = storeTypeCode;
          items.brand = partner.brand;
          items.partner_id = partner.partner_id;
          items.dc = partner.dc;
          items.konsi_putus = partner.konsi_putus;

          rawItems.partner_name = partner.partner_name;
          rawItems.cust_id = partner.ref;
          rawItems.store_type_code = storeTypeCode;
          rawItems.brand = partner.brand;
          rawItems.dc = partner.dc;
          rawItems.konsi_putus = partner.konsi_putus;

          items.total_sales = items.total_sales + partner.total_sales;
          rawItems.total_sales = rawItems.total_sales + partner.total_sales;

          switch (partner.day) {
            case 1:
              if (partner.total_sales !== null) {
                items.sales1 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales1 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 2:
              if (partner.total_sales !== null) {
                items.sales2 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales2 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 3:
              if (partner.total_sales !== null) {
                items.sales3 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales3 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 4:
              if (partner.total_sales !== null) {
                items.sales4 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales4 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 5:
              if (partner.total_sales !== null) {
                items.sales5 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales5 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 6:
              if (partner.total_sales !== null) {
                items.sales6 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales6 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 7:
              if (partner.total_sales !== null) {
                items.sales7 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales7 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 8:
              if (partner.total_sales !== null) {
                items.sales8 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales8 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 9:
              if (partner.total_sales !== null) {
                items.sales9 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales9 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 10:
              if (partner.total_sales !== null) {
                items.sales10 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales10 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 11:
              if (partner.total_sales !== null) {
                items.sales11 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales11 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 12:
              if (partner.total_sales !== null) {
                items.sales12 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales12 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 13:
              if (partner.total_sales !== null) {
                items.sales13 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales13 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 14:
              if (partner.total_sales !== null) {
                items.sales14 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales14 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 15:
              if (partner.total_sales !== null) {
                items.sales15 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales15 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 16:
              if (partner.total_sales !== null) {
                items.sales16 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales16 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 17:
              if (partner.total_sales !== null) {
                items.sales17 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales17 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 18:
              if (partner.total_sales !== null) {
                items.sales18 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales18 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 19:
              if (partner.total_sales !== null) {
                items.sales19 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales19 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 20:
              if (partner.total_sales !== null) {
                items.sales20 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales20 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 21:
              if (partner.total_sales !== null) {
                items.sales21 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales21 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 22:
              if (partner.total_sales !== null) {
                items.sales22 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales22 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 23:
              if (partner.total_sales !== null) {
                items.sales23 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales23 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 24:
              if (partner.total_sales !== null) {
                items.sales24 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales24 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 25:
              if (partner.total_sales !== null) {
                items.sales25 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales25 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 26:
              if (partner.total_sales !== null) {
                items.sales26 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales26 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 27:
              if (partner.total_sales !== null) {
                items.sales27 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales27 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 28:
              if (partner.total_sales !== null) {
                items.sales28 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales28 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 29:
              if (partner.total_sales !== null) {
                items.sales29 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales29 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 30:
              if (partner.total_sales !== null) {
                items.sales30 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales30 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 31:
              if (partner.total_sales !== null) {
                items.sales31 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales31 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            default:
              break;
          }
        } else if (
          selectedPartnerIterate === partner.partner_name &&
          selectedBrandIterate === partner.brand &&
          selectedPartnerRef === partner.ref
        ) {
          items.total_sales = items.total_sales + partner.total_sales;
          rawItems.total_sales = rawItems.total_sales + partner.total_sales;
          switch (partner.day) {
            case 1:
              if (partner.total_sales !== null) {
                items.sales1 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales1 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 2:
              if (partner.total_sales !== null) {
                items.sales2 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales2 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 3:
              if (partner.total_sales !== null) {
                items.sales3 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales3 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 4:
              if (partner.total_sales !== null) {
                items.sales4 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales4 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 5:
              if (partner.total_sales !== null) {
                items.sales5 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales5 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 6:
              if (partner.total_sales !== null) {
                items.sales6 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales6 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 7:
              if (partner.total_sales !== null) {
                items.sales7 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales7 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 8:
              if (partner.total_sales !== null) {
                items.sales8 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales8 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 9:
              if (partner.total_sales !== null) {
                items.sales9 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales9 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 10:
              if (partner.total_sales !== null) {
                items.sales10 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales10 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 11:
              if (partner.total_sales !== null) {
                items.sales11 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales11 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 12:
              if (partner.total_sales !== null) {
                items.sales12 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales12 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 13:
              if (partner.total_sales !== null) {
                items.sales13 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales13 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 14:
              if (partner.total_sales !== null) {
                items.sales14 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales14 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 15:
              if (partner.total_sales !== null) {
                items.sales15 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales15 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 16:
              if (partner.total_sales !== null) {
                items.sales16 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales16 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 17:
              if (partner.total_sales !== null) {
                items.sales17 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales17 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 18:
              if (partner.total_sales !== null) {
                items.sales18 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales18 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 19:
              if (partner.total_sales !== null) {
                items.sales19 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales19 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 20:
              if (partner.total_sales !== null) {
                items.sales20 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales20 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 21:
              if (partner.total_sales !== null) {
                items.sales21 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales21 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 22:
              if (partner.total_sales !== null) {
                items.sales22 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales22 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 23:
              if (partner.total_sales !== null) {
                items.sales23 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales23 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 24:
              if (partner.total_sales !== null) {
                items.sales24 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales24 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 25:
              if (partner.total_sales !== null) {
                items.sales25 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales25 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 26:
              if (partner.total_sales !== null) {
                items.sales26 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales26 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 27:
              if (partner.total_sales !== null) {
                items.sales27 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales27 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 28:
              if (partner.total_sales !== null) {
                items.sales28 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales28 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 29:
              if (partner.total_sales !== null) {
                items.sales29 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales29 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 30:
              if (partner.total_sales !== null) {
                items.sales30 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales30 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 31:
              if (partner.total_sales !== null) {
                items.sales31 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales31 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            default:
              break;
          }
        } else {
          items.total_sales =
            "Rp." +
            items.total_sales.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            });
          items.percent_completeness = parseInt(
            ((items.total_isi / totalDay) * 100).toFixed()
          );
          rawItems.percent_completeness = parseInt(
            ((rawItems.total_isi / totalDay) * 100).toFixed()
          );
          tableRow.push(items);
          rawTableRow.push(rawItems);

          rawItems = {
            dc: null,
            partner_name: null,
            cust_id: null,
            store_type_code: null,
            brand: null,
            konsi_putus: null,
            total_sales: 0,
            total_isi: 0,
            sales1: null,
            sales2: null,
            sales3: null,
            sales4: null,
            sales5: null,
            sales6: null,
            sales7: null,
            sales8: null,
            sales9: null,
            sales10: null,
            sales11: null,
            sales12: null,
            sales13: null,
            sales14: null,
            sales15: null,
            sales16: null,
            sales17: null,
            sales18: null,
            sales19: null,
            sales20: null,
            sales21: null,
            sales22: null,
            sales23: null,
            sales24: null,
            sales25: null,
            sales26: null,
            sales27: null,
            sales28: null,
            sales29: null,
            sales30: null,
            sales31: null,
          };

          items = {
            partner_id: null,
            dc: null,
            partner_name: null,
            partner_ref: null,
            store_type_code: null,
            brand: null,
            konsi_putus: null,
            total_sales: 0,
            total_isi: 0,
            sales1: null,
            sales2: null,
            sales3: null,
            sales4: null,
            sales5: null,
            sales6: null,
            sales7: null,
            sales8: null,
            sales9: null,
            sales10: null,
            sales11: null,
            sales12: null,
            sales13: null,
            sales14: null,
            sales15: null,
            sales16: null,
            sales17: null,
            sales18: null,
            sales19: null,
            sales20: null,
            sales21: null,
            sales22: null,
            sales23: null,
            sales24: null,
            sales25: null,
            sales26: null,
            sales27: null,
            sales28: null,
            sales29: null,
            sales30: null,
            sales31: null,
          };

          selectedPartnerIterate = partner.partner_name;
          selectedBrandIterate = partner.brand;
          selectedPartnerRef = partner.ref;

          storeTypeCode = "N/A";
          if (partner.store_type_code) {
            storeTypeCode = partner.store_type_code.substr(
              0,
              partner.store_type_code.indexOf("-")
            );
          }

          items.partner_name = partner.partner_name;
          items.partner_ref = partner.ref;
          items.store_type_code = storeTypeCode;
          items.brand = partner.brand;
          items.partner_id = partner.partner_id;
          items.dc = partner.dc;
          items.konsi_putus = partner.konsi_putus;

          rawItems.partner_name = partner.partner_name;
          rawItems.cust_id = partner.ref;
          rawItems.store_type_code = storeTypeCode;
          rawItems.brand = partner.brand;
          rawItems.dc = partner.dc;
          rawItems.konsi_putus = partner.konsi_putus;

          items.total_sales = items.total_sales + partner.total_sales;
          rawItems.total_sales = rawItems.total_sales + partner.total_sales;

          switch (partner.day) {
            case 1:
              if (partner.total_sales !== null) {
                items.sales1 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales1 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 2:
              if (partner.total_sales !== null) {
                items.sales2 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales2 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 3:
              if (partner.total_sales !== null) {
                items.sales3 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales3 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 4:
              if (partner.total_sales !== null) {
                items.sales4 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales4 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 5:
              if (partner.total_sales !== null) {
                items.sales5 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales5 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 6:
              if (partner.total_sales !== null) {
                items.sales6 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales6 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 7:
              if (partner.total_sales !== null) {
                items.sales7 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales7 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 8:
              if (partner.total_sales !== null) {
                items.sales8 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales8 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 9:
              if (partner.total_sales !== null) {
                items.sales9 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales9 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 10:
              if (partner.total_sales !== null) {
                items.sales10 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales10 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 11:
              if (partner.total_sales !== null) {
                items.sales11 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales11 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 12:
              if (partner.total_sales !== null) {
                items.sales12 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales12 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 13:
              if (partner.total_sales !== null) {
                items.sales13 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales13 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 14:
              if (partner.total_sales !== null) {
                items.sales14 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales14 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 15:
              if (partner.total_sales !== null) {
                items.sales15 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales15 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 16:
              if (partner.total_sales !== null) {
                items.sales16 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales16 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 17:
              if (partner.total_sales !== null) {
                items.sales17 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales17 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 18:
              if (partner.total_sales !== null) {
                items.sales18 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales18 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 19:
              if (partner.total_sales !== null) {
                items.sales19 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales19 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 20:
              if (partner.total_sales !== null) {
                items.sales20 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales20 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 21:
              if (partner.total_sales !== null) {
                items.sales21 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales21 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 22:
              if (partner.total_sales !== null) {
                items.sales22 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales22 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 23:
              if (partner.total_sales !== null) {
                items.sales23 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales23 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 24:
              if (partner.total_sales !== null) {
                items.sales24 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales24 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 25:
              if (partner.total_sales !== null) {
                items.sales25 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales25 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 26:
              if (partner.total_sales !== null) {
                items.sales26 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales26 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 27:
              if (partner.total_sales !== null) {
                items.sales27 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales27 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 28:
              if (partner.total_sales !== null) {
                items.sales28 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales28 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 29:
              if (partner.total_sales !== null) {
                items.sales29 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales29 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 30:
              if (partner.total_sales !== null) {
                items.sales30 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales30 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            case 31:
              if (partner.total_sales !== null) {
                items.sales31 =
                  "Rp." +
                  partner.total_sales.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  });
                rawItems.sales31 = partner.total_sales;
                items.total_isi += 1;
                rawItems.total_isi += 1;
              }
              break;
            default:
              break;
          }
        }
        if (filterType === "Region") {
          items.salesarea_name = partner.salesarea_name;
          rawItems.salesarea_name = partner.salesarea_name;
        }
      });

      items.total_sales =
        "Rp." +
        items.total_sales.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
      items.percent_completeness = parseInt(
        ((items.total_isi / totalDay) * 100).toFixed()
      );
      if (parseFloat((items.total_isi / totalDay) * 100) > 100) {
        items.percent_completeness = 100;
      }
      rawItems.percent_completeness = parseInt(
        ((rawItems.total_isi / totalDay) * 100).toFixed()
      );
      if (parseFloat((rawItems.total_isi / totalDay) * 100) > 100) {
        rawItems.percent_completeness = 100;
      }

      rawTableRow.push(rawItems);
      tableRow.push(items);

      // })
      // .catch(error => {

      //   console.log(error)
      // })
    }

    this.setState({
      salesTableData: tableRow,
      rawSalesTableData: rawTableRow,
      isCustomerSalesRecapLoading: false,
    });
  };

  getTotalDayTableSalesRecap = (month, year) => {
    var today = new Date();
    var currentMonth = today.getMonth() + 1;
    if (month === currentMonth) {
      return new Date().getDate();
    } else {
      return new Date(year, month, 0).getDate();
    }
  };

  getTotalDayTableVisitorRecap = (month, year) => {
    var today = new Date();
    var currentMonth = today.getMonth() + 1;
    if (month === currentMonth) {
      return new Date().getDate();
    } else {
      return new Date(year, month, 0).getDate();
    }
  };

  reloadPresence = (date, month, year, listScope, tabActive) => {
    var isNasional = listScope.indexOf("NASIONAL") !== -1;
    this.setState({
      isBAPresenceRecapLoading: true,
      allPresence: [],
      allPresenceRaw: [],
    });
    var listAllPresence = [];
    var listALlPresenceRaw = [];

    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: "",
    }
    if (listScope.length > 0) {
      if (tabActive === "Region") {
        let configQuery = "presence=neq.0&sort=salesarea_name.desc,ba_id.desc,day.desc&month=" +
          month +
          "&year=" +
          year +
          "&salesarea_name=[" +
          listScope +
          "]";
        config.url = `/dashboard/v_daily_ba_presence_by_salesarea/list?${configQuery}`


      } else {
        let configQuery =
          "presence=neq.0&sort=dc.desc,ba_id.desc,day.desc&month=" +
          month +
          "&year=" +
          year;

        if (!isNasional) {
          configQuery = `${configQuery}&dc=[${listScope}]`;
        }
        configQuery = configQuery.replace(/ /g, '%20')

        config.url = `/dashboard/v_daily_ba_presence/list?${configQuery}`

      }
      Axios(config)
        .then((res) => {
          let allpresence = res.data
          var pastID = null;

          var itemsRaw = {
            dc: null,
            ba_id: null,
            ba_name: null,
            ba_nip: null,
            ba_brand: null,
            ba_store: null,
            nip: null,
            is_cs: null,
            percent_completeness: null,
            total_presence: null,
            total_not_presence: null,
            tgl1: null,
            tgl2: null,
            tgl3: null,
            tgl4: null,
            tgl5: null,
            tgl6: null,
            tgl7: null,
            tgl8: null,
            tgl9: null,
            tgl10: null,
            tgl11: null,
            tgl12: null,
            tgl13: null,
            tgl14: null,
            tgl15: null,
            tgl16: null,
            tgl17: null,
            tgl18: null,
            tgl19: null,
            tgl20: null,
            tgl21: null,
            tgl22: null,
            tgl23: null,
            tgl24: null,
            tgl25: null,
            tgl26: null,
            tgl27: null,
            tgl28: null,
            tgl29: null,
            tgl30: null,
            tgl31: null,
          };

          var items = {
            dc: null,
            ba_id: null,
            ba_name: null,
            ba_brand: null,
            ba_store: null,
            nip: null,
            is_cs: null,
            percent_completeness: null,
            total_presence: null,
            total_not_presence: null,
            tgl1: null,
            tgl2: null,
            tgl3: null,
            tgl4: null,
            tgl5: null,
            tgl6: null,
            tgl7: null,
            tgl8: null,
            tgl9: null,
            tgl10: null,
            tgl11: null,
            tgl12: null,
            tgl13: null,
            tgl14: null,
            tgl15: null,
            tgl16: null,
            tgl17: null,
            tgl18: null,
            tgl19: null,
            tgl20: null,
            tgl21: null,
            tgl22: null,
            tgl23: null,
            tgl24: null,
            tgl25: null,
            tgl26: null,
            tgl27: null,
            tgl28: null,
            tgl29: null,
            tgl30: null,
            tgl31: null,
          };

          var tgl1 = 0;
          var tgl1off = 0;
          var tgl2 = 0;
          var tgl2off = 0;
          var tgl3 = 0;
          var tgl3off = 0;
          var tgl4 = 0;
          var tgl4off = 0;
          var tgl5 = 0;
          var tgl5off = 0;
          var tgl6 = 0;
          var tgl6off = 0;
          var tgl7 = 0;
          var tgl7off = 0;
          var tgl8 = 0;
          var tgl8off = 0;
          var tgl9 = 0;
          var tgl9off = 0;
          var tgl10 = 0;
          var tgl10off = 0;
          var tgl11 = 0;
          var tgl11off = 0;
          var tgl12 = 0;
          var tgl12off = 0;
          var tgl13 = 0;
          var tgl13off = 0;
          var tgl14 = 0;
          var tgl14off = 0;
          var tgl15 = 0;
          var tgl15off = 0;
          var tgl16 = 0;
          var tgl16off = 0;
          var tgl17 = 0;
          var tgl17off = 0;
          var tgl18 = 0;
          var tgl18off = 0;
          var tgl19 = 0;
          var tgl19off = 0;
          var tgl20 = 0;
          var tgl20off = 0;
          var tgl21 = 0;
          var tgl21off = 0;
          var tgl22 = 0;
          var tgl22off = 0;
          var tgl23 = 0;
          var tgl23off = 0;
          var tgl24 = 0;
          var tgl24off = 0;
          var tgl25 = 0;
          var tgl25off = 0;
          var tgl26 = 0;
          var tgl26off = 0;
          var tgl27 = 0;
          var tgl27off = 0;
          var tgl28 = 0;
          var tgl28off = 0;
          var tgl29 = 0;
          var tgl29off = 0;
          var tgl30 = 0;
          var tgl30off = 0;
          var tgl31 = 0;
          var tgl31off = 0;

          var countPresence = 0;

          allpresence.data.data.forEach((presence, index) => {
            if (pastID === null) {
              pastID = presence.ba_id;

              items.ba_id = presence.ba_id;
              items.ba_name = presence.name;
              items.ba_brand = presence.ba_brand.slice(6);
              items.ba_store = presence.partner_name;
              items.is_cs = presence.is_cs ? "CS" : "BA";
              items.nip = presence.nip;
              items.dc = presence.dc;
              itemsRaw.dc = presence.dc;
              itemsRaw.ba_id = presence.ba_id;
              itemsRaw.ba_name = presence.name;
              itemsRaw.ba_nip = presence.nip;
              itemsRaw.ba_brand = presence.ba_brand.slice(6);
              itemsRaw.ba_store = presence.partner_name;
              itemsRaw.is_cs = presence.is_cs ? "CS" : "BA";
              itemsRaw.nip = presence.nip;

              switch (presence.day) {
                case 1:
                  if (!items.tgl1) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl1 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl1 = "IZIN";
                      tgl1off++;
                    } else if (presence.presence <= -1) {
                      items.tgl1 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl1 = "OFF";
                      tgl1off++;
                    } else {
                      items.tgl1 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl1 = "ON";
                      tgl1++;
                    }
                  }
                  break;
                case 2:
                  if (!items.tgl2) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl2 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl2 = "IZIN";
                      tgl2off++;
                    } else if (presence.presence <= -1) {
                      items.tgl2 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl2 = "OFF";
                      tgl2off++;
                    } else {
                      items.tgl2 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl2 = "ON";
                      tgl2++;
                    }
                  }
                  break;
                case 3:
                  if (!items.tgl3) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl3 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl3 = "IZIN";
                      tgl3off++;
                    } else if (presence.presence <= -1) {
                      items.tgl3 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl3 = "OFF";
                      tgl3off++;
                    } else {
                      items.tgl3 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl3 = "ON";
                      tgl3++;
                    }
                  }
                  break;
                case 4:
                  if (!items.tgl4) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl4 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl4 = "IZIN";
                      tgl4off++;
                    } else if (presence.presence <= -1) {
                      items.tgl4 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl4 = "OFF";
                      tgl4off++;
                    } else {
                      items.tgl4 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl4 = "ON";
                      tgl4++;
                    }
                  }
                  break;
                case 5:
                  if (!items.tgl5) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl5 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl5 = "IZIN";
                      tgl5off++;
                    } else if (presence.presence <= -1) {
                      items.tgl5 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl5 = "OFF";
                      tgl5off++;
                    } else {
                      items.tgl5 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl5 = "ON";
                      tgl5++;
                    }
                  }
                  break;
                case 6:
                  if (!items.tgl6) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl6 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl6 = "IZIN";
                      tgl6off++;
                    } else if (presence.presence <= -1) {
                      items.tgl6 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl6 = "OFF";
                      tgl6off++;
                    } else {
                      items.tgl6 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl6 = "ON";
                      tgl6++;
                    }
                  }
                  break;
                case 7:
                  if (!items.tgl7) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl7 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl7 = "IZIN";
                      tgl7off++;
                    } else if (presence.presence <= -1) {
                      items.tgl7 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl7 = "OFF";
                      tgl7off++;
                    } else {
                      items.tgl7 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl7 = "ON";
                      tgl7++;
                    }
                  }
                  break;
                case 8:
                  if (!items.tgl8) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl8 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl8 = "IZIN";
                      tgl8off++;
                    } else if (presence.presence <= -1) {
                      items.tgl8 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl8 = "OFF";
                      tgl8off++;
                    } else {
                      items.tgl8 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl8 = "ON";
                      tgl8++;
                    }
                  }
                  break;
                case 9:
                  if (!items.tgl9) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl9 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl9 = "IZIN";
                      tgl9off++;
                    } else if (presence.presence <= -1) {
                      items.tgl9 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl9 = "OFF";
                      tgl9off++;
                    } else {
                      items.tgl9 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl9 = "ON";
                      tgl9++;
                    }
                  }
                  break;
                case 10:
                  if (!items.tgl10) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl10 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl10 = "IZIN";
                      tgl10off++;
                    } else if (presence.presence <= -1) {
                      items.tgl10 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl10 = "OFF";
                      tgl10off++;
                    } else {
                      items.tgl10 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl10 = "ON";
                      tgl10++;
                    }
                  }
                  break;
                case 11:
                  if (!items.tgl11) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl11 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl11 = "IZIN";
                      tgl11off++;
                    } else if (presence.presence <= -1) {
                      items.tgl11 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl11 = "OFF";
                      tgl11off++;
                    } else {
                      items.tgl11 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl11 = "ON";
                      tgl11++;
                    }
                  }
                  break;
                case 12:
                  if (!items.tgl12) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl12 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl12 = "IZIN";
                      tgl12off++;
                    } else if (presence.presence <= -1) {
                      items.tgl12 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl12 = "OFF";
                      tgl12off++;
                    } else {
                      items.tgl12 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl12 = "ON";
                      tgl12++;
                    }
                  }
                  break;
                case 13:
                  if (!items.tgl13) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl13 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl13 = "IZIN";
                      tgl13off++;
                    } else if (presence.presence <= -1) {
                      items.tgl13 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl13 = "OFF";
                      tgl13off++;
                    } else {
                      items.tgl13 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl13 = "ON";
                      tgl13++;
                    }
                  }
                  break;
                case 14:
                  if (!items.tgl14) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl14 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl14 = "IZIN";
                      tgl14off++;
                    } else if (presence.presence <= -1) {
                      items.tgl14 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl14 = "OFF";
                      tgl14off++;
                    } else {
                      items.tgl14 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl14 = "ON";
                      tgl14++;
                    }
                  }
                  break;
                case 15:
                  if (!items.tgl15) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl15 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl15 = "IZIN";
                      tgl15off++;
                    } else if (presence.presence <= -1) {
                      items.tgl15 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl15 = "OFF";
                      tgl15off++;
                    } else {
                      items.tgl15 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl15 = "ON";
                      tgl15++;
                    }
                  }
                  break;
                case 16:
                  if (!items.tgl16) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl16 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl16 = "IZIN";
                      tgl16off++;
                    } else if (presence.presence <= -1) {
                      items.tgl16 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl16 = "OFF";
                      tgl16off++;
                    } else {
                      items.tgl16 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl16 = "ON";
                      tgl16++;
                    }
                  }
                  break;
                case 17:
                  if (!items.tgl17) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl17 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl17 = "IZIN";
                      tgl17off++;
                    } else if (presence.presence <= -1) {
                      items.tgl17 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl17 = "OFF";
                      tgl17off++;
                    } else {
                      items.tgl17 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl17 = "ON";
                      tgl17++;
                    }
                  }
                  break;
                case 18:
                  if (!items.tgl18) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl18 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl18 = "IZIN";
                      tgl18off++;
                    } else if (presence.presence <= -1) {
                      items.tgl18 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl18 = "OFF";
                      tgl18off++;
                    } else {
                      items.tgl18 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl18 = "ON";
                      tgl18++;
                    }
                  }
                  break;
                case 19:
                  if (!items.tgl19) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl19 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl19 = "IZIN";
                      tgl19off++;
                    } else if (presence.presence <= -1) {
                      items.tgl19 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl19 = "OFF";
                      tgl19off++;
                    } else {
                      items.tgl19 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl19 = "ON";
                      tgl19++;
                    }
                  }
                  break;
                case 20:
                  if (!items.tgl20) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl20 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl20 = "IZIN";
                      tgl20off++;
                    } else if (presence.presence <= -1) {
                      items.tgl20 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl20 = "OFF";
                      tgl20off++;
                    } else {
                      items.tgl20 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl20 = "ON";
                      tgl20++;
                    }
                  }
                  break;
                case 21:
                  if (!items.tgl21) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl21 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl21 = "IZIN";
                      tgl21off++;
                    } else if (presence.presence <= -1) {
                      items.tgl21 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl21 = "OFF";
                      tgl21off++;
                    } else {
                      items.tgl21 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl21 = "ON";
                      tgl21++;
                    }
                  }
                  break;
                case 22:
                  if (!items.tgl22) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl22 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl22 = "IZIN";
                      tgl22off++;
                    } else if (presence.presence <= -1) {
                      items.tgl22 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl22 = "OFF";
                      tgl22off++;
                    } else {
                      items.tgl22 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl22 = "ON";
                      tgl22++;
                    }
                  }
                  break;
                case 23:
                  if (!items.tgl23) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl23 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl23 = "IZIN";
                      tgl23off++;
                    } else if (presence.presence <= -1) {
                      items.tgl23 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl23 = "OFF";
                      tgl23off++;
                    } else {
                      items.tgl23 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl23 = "ON";
                      tgl23++;
                    }
                  }
                  break;
                case 24:
                  if (!items.tgl24) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl24 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl24 = "IZIN";
                      tgl24off++;
                    } else if (presence.presence <= -1) {
                      items.tgl24 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl24 = "OFF";
                      tgl24off++;
                    } else {
                      items.tgl24 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl24 = "ON";
                      tgl24++;
                    }
                  }
                  break;
                case 25:
                  if (!items.tgl25) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl25 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl25 = "IZIN";
                      tgl25off++;
                    } else if (presence.presence <= -1) {
                      items.tgl25 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl25 = "OFF";
                      tgl25off++;
                    } else {
                      items.tgl25 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl25 = "ON";
                      tgl25++;
                    }
                  }
                  break;
                case 26:
                  if (!items.tgl26) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl26 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl26 = "IZIN";
                      tgl26off++;
                    } else if (presence.presence <= -1) {
                      items.tgl26 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl26 = "OFF";
                      tgl26off++;
                    } else {
                      items.tgl26 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl26 = "ON";
                      tgl26++;
                    }
                  }
                  break;
                case 27:
                  if (!items.tgl27) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl27 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl27 = "IZIN";
                      tgl27off++;
                    } else if (presence.presence <= -1) {
                      items.tgl27 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl27 = "OFF";
                      tgl27off++;
                    } else {
                      items.tgl27 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl27 = "ON";
                      tgl27++;
                    }
                  }
                  break;
                case 28:
                  if (!items.tgl28) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl28 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl28 = "IZIN";
                      tgl28off++;
                    } else if (presence.presence <= -1) {
                      items.tgl28 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl28 = "OFF";
                      tgl28off++;
                    } else {
                      items.tgl28 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl28 = "ON";
                      tgl28++;
                    }
                  }
                  break;
                case 29:
                  if (!items.tgl29) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl29 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl29 = "IZIN";
                      tgl29off++;
                    } else if (presence.presence <= -1) {
                      items.tgl29 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl29 = "OFF";
                      tgl29off++;
                    } else {
                      items.tgl29 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl29 = "ON";
                      tgl29++;
                    }
                  }
                  break;
                case 30:
                  if (!items.tgl30) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl30 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl30 = "IZIN";
                      tgl30off++;
                    } else if (presence.presence <= -1) {
                      items.tgl30 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl30 = "OFF";
                      tgl30off++;
                    } else {
                      items.tgl30 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl30 = "ON";
                      tgl30++;
                    }
                  }
                  break;
                case 31:
                  if (!items.tgl31) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl31 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl31 = "IZIN";
                      tgl31off++;
                    } else if (presence.presence <= -1) {
                      items.tgl31 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl31 = "OFF";
                      tgl31off++;
                    } else {
                      items.tgl31 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl31 = "ON";
                      tgl31++;
                    }
                  }
                  break;
                default:
                  break;
              }
            } else if (pastID === presence.ba_id) {
              switch (presence.day) {
                case 1:
                  if (!items.tgl1) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl1 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl1 = "IZIN";
                      tgl1off++;
                    } else if (presence.presence <= -1) {
                      items.tgl1 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl1 = "OFF";
                      tgl1off++;
                    } else {
                      items.tgl1 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl1 = "ON";
                      tgl1++;
                    }
                  }
                  break;
                case 2:
                  if (!items.tgl2) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl2 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl2 = "IZIN";
                      tgl2off++;
                    } else if (presence.presence <= -1) {
                      items.tgl2 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl2 = "OFF";
                      tgl2off++;
                    } else {
                      items.tgl2 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl2 = "ON";
                      tgl2++;
                    }
                  }
                  break;
                case 3:
                  if (!items.tgl3) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl3 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl3 = "IZIN";
                      tgl3off++;
                    } else if (presence.presence <= -1) {
                      items.tgl3 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl3 = "OFF";
                      tgl3off++;
                    } else {
                      items.tgl3 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl3 = "ON";
                      tgl3++;
                    }
                  }
                  break;
                case 4:
                  if (!items.tgl4) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl4 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl4 = "IZIN";
                      tgl4off++;
                    } else if (presence.presence <= -1) {
                      items.tgl4 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl4 = "OFF";
                      tgl4off++;
                    } else {
                      items.tgl4 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl4 = "ON";
                      tgl4++;
                    }
                  }
                  break;
                case 5:
                  if (!items.tgl5) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl5 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl5 = "IZIN";
                      tgl5off++;
                    } else if (presence.presence <= -1) {
                      items.tgl5 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl5 = "OFF";
                      tgl5off++;
                    } else {
                      items.tgl5 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl5 = "ON";
                      tgl5++;
                    }
                  }
                  break;
                case 6:
                  if (!items.tgl6) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl6 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl6 = "IZIN";
                      tgl6off++;
                    } else if (presence.presence <= -1) {
                      items.tgl6 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl6 = "OFF";
                      tgl6off++;
                    } else {
                      items.tgl6 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl6 = "ON";
                      tgl6++;
                    }
                  }
                  break;
                case 7:
                  if (!items.tgl7) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl7 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl7 = "IZIN";
                      tgl7off++;
                    } else if (presence.presence <= -1) {
                      items.tgl7 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl7 = "OFF";
                      tgl7off++;
                    } else {
                      items.tgl7 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl7 = "ON";
                      tgl7++;
                    }
                  }
                  break;
                case 8:
                  if (!items.tgl8) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl8 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl8 = "IZIN";
                      tgl8off++;
                    } else if (presence.presence <= -1) {
                      items.tgl8 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl8 = "OFF";
                      tgl8off++;
                    } else {
                      items.tgl8 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl8 = "ON";
                      tgl8++;
                    }
                  }
                  break;
                case 9:
                  if (!items.tgl9) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl9 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl9 = "IZIN";
                      tgl9off++;
                    } else if (presence.presence <= -1) {
                      items.tgl9 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl9 = "OFF";
                      tgl9off++;
                    } else {
                      items.tgl9 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl9 = "ON";
                      tgl9++;
                    }
                  }
                  break;
                case 10:
                  if (!items.tgl10) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl10 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl10 = "IZIN";
                      tgl10off++;
                    } else if (presence.presence <= -1) {
                      items.tgl10 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl10 = "OFF";
                      tgl10off++;
                    } else {
                      items.tgl10 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl10 = "ON";
                      tgl10++;
                    }
                  }
                  break;
                case 11:
                  if (!items.tgl11) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl11 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl11 = "IZIN";
                      tgl11off++;
                    } else if (presence.presence <= -1) {
                      items.tgl11 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl11 = "OFF";
                      tgl11off++;
                    } else {
                      items.tgl11 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl11 = "ON";
                      tgl11++;
                    }
                  }
                  break;
                case 12:
                  if (!items.tgl12) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl12 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl12 = "IZIN";
                      tgl12off++;
                    } else if (presence.presence <= -1) {
                      items.tgl12 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl12 = "OFF";
                      tgl12off++;
                    } else {
                      items.tgl12 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl12 = "ON";
                      tgl12++;
                    }
                  }
                  break;
                case 13:
                  if (!items.tgl13) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl13 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl13 = "IZIN";
                      tgl13off++;
                    } else if (presence.presence <= -1) {
                      items.tgl13 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl13 = "OFF";
                      tgl13off++;
                    } else {
                      items.tgl13 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl13 = "ON";
                      tgl13++;
                    }
                  }
                  break;
                case 14:
                  if (!items.tgl14) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl14 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl14 = "IZIN";
                      tgl14off++;
                    } else if (presence.presence <= -1) {
                      items.tgl14 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl14 = "OFF";
                      tgl14off++;
                    } else {
                      items.tgl14 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl14 = "ON";
                      tgl14++;
                    }
                  }
                  break;
                case 15:
                  if (!items.tgl15) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl15 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl15 = "IZIN";
                      tgl15off++;
                    } else if (presence.presence <= -1) {
                      items.tgl15 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl15 = "OFF";
                      tgl15off++;
                    } else {
                      items.tgl15 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl15 = "ON";
                      tgl15++;
                    }
                  }
                  break;
                case 16:
                  if (!items.tgl16) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl16 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl16 = "IZIN";
                      tgl16off++;
                    } else if (presence.presence <= -1) {
                      items.tgl16 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl16 = "OFF";
                      tgl16off++;
                    } else {
                      items.tgl16 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl16 = "ON";
                      tgl16++;
                    }
                  }
                  break;
                case 17:
                  if (!items.tgl17) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl17 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl17 = "IZIN";
                      tgl17off++;
                    } else if (presence.presence <= -1) {
                      items.tgl17 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl17 = "OFF";
                      tgl17off++;
                    } else {
                      items.tgl17 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl17 = "ON";
                      tgl17++;
                    }
                  }
                  break;
                case 18:
                  if (!items.tgl18) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl18 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl18 = "IZIN";
                      tgl18off++;
                    } else if (presence.presence <= -1) {
                      items.tgl18 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl18 = "OFF";
                      tgl18off++;
                    } else {
                      items.tgl18 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl18 = "ON";
                      tgl18++;
                    }
                  }
                  break;
                case 19:
                  if (!items.tgl19) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl19 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl19 = "IZIN";
                      tgl19off++;
                    } else if (presence.presence <= -1) {
                      items.tgl19 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl19 = "OFF";
                      tgl19off++;
                    } else {
                      items.tgl19 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl19 = "ON";
                      tgl19++;
                    }
                  }
                  break;
                case 20:
                  if (!items.tgl20) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl20 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl20 = "IZIN";
                      tgl20off++;
                    } else if (presence.presence <= -1) {
                      items.tgl20 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl20 = "OFF";
                      tgl20off++;
                    } else {
                      items.tgl20 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl20 = "ON";
                      tgl20++;
                    }
                  }
                  break;
                case 21:
                  if (!items.tgl21) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl21 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl21 = "IZIN";
                      tgl21off++;
                    } else if (presence.presence <= -1) {
                      items.tgl21 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl21 = "OFF";
                      tgl21off++;
                    } else {
                      items.tgl21 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl21 = "ON";
                      tgl21++;
                    }
                  }
                  break;
                case 22:
                  if (!items.tgl22) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl22 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl22 = "IZIN";
                      tgl22off++;
                    } else if (presence.presence <= -1) {
                      items.tgl22 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl22 = "OFF";
                      tgl22off++;
                    } else {
                      items.tgl22 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl22 = "ON";
                      tgl22++;
                    }
                  }
                  break;
                case 23:
                  if (!items.tgl23) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl23 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl23 = "IZIN";
                      tgl23off++;
                    } else if (presence.presence <= -1) {
                      items.tgl23 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl23 = "OFF";
                      tgl23off++;
                    } else {
                      items.tgl23 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl23 = "ON";
                      tgl23++;
                    }
                  }
                  break;
                case 24:
                  if (!items.tgl24) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl24 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl24 = "IZIN";
                      tgl24off++;
                    } else if (presence.presence <= -1) {
                      items.tgl24 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl24 = "OFF";
                      tgl24off++;
                    } else {
                      items.tgl24 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl24 = "ON";
                      tgl24++;
                    }
                  }
                  break;
                case 25:
                  if (!items.tgl25) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl25 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl25 = "IZIN";
                      tgl25off++;
                    } else if (presence.presence <= -1) {
                      items.tgl25 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl25 = "OFF";
                      tgl25off++;
                    } else {
                      items.tgl25 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl25 = "ON";
                      tgl25++;
                    }
                  }
                  break;
                case 26:
                  if (!items.tgl26) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl26 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl26 = "IZIN";
                      tgl26off++;
                    } else if (presence.presence <= -1) {
                      items.tgl26 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl26 = "OFF";
                      tgl26off++;
                    } else {
                      items.tgl26 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl26 = "ON";
                      tgl26++;
                    }
                  }
                  break;
                case 27:
                  if (!items.tgl27) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl27 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl27 = "IZIN";
                      tgl27off++;
                    } else if (presence.presence <= -1) {
                      items.tgl27 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl27 = "OFF";
                      tgl27off++;
                    } else {
                      items.tgl27 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl27 = "ON";
                      tgl27++;
                    }
                  }
                  break;
                case 28:
                  if (!items.tgl28) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl28 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl28 = "IZIN";
                      tgl28off++;
                    } else if (presence.presence <= -1) {
                      items.tgl28 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl28 = "OFF";
                      tgl28off++;
                    } else {
                      items.tgl28 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl28 = "ON";
                      tgl28++;
                    }
                  }
                  break;
                case 29:
                  if (!items.tgl29) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl29 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl29 = "IZIN";
                      tgl29off++;
                    } else if (presence.presence <= -1) {
                      items.tgl29 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl29 = "OFF";
                      tgl29off++;
                    } else {
                      items.tgl29 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl29 = "ON";
                      tgl29++;
                    }
                  }
                  break;
                case 30:
                  if (!items.tgl30) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl30 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl30 = "IZIN";
                      tgl30off++;
                    } else if (presence.presence <= -1) {
                      items.tgl30 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl30 = "OFF";
                      tgl30off++;
                    } else {
                      items.tgl30 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl30 = "ON";
                      tgl30++;
                    }
                  }
                  break;
                case 31:
                  if (!items.tgl31) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl31 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl31 = "IZIN";
                      tgl31off++;
                    } else if (presence.presence <= -1) {
                      items.tgl31 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl31 = "OFF";
                      tgl31off++;
                    } else {
                      items.tgl31 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl31 = "ON";
                      tgl31++;
                    }
                  }
                  break;
                default:
                  break;
              }
            } else {
              items.total_presence = countPresence;

              items.total_not_presence = date - countPresence;
              items.percent_completeness = parseInt(
                ((items.total_presence / date) * 100).toFixed()
              );

              itemsRaw.total_presence = countPresence;
              itemsRaw.total_not_presence = date - countPresence;
              itemsRaw.percent_completeness = parseInt(
                ((items.total_presence / date) * 100).toFixed()
              );

              listAllPresence.push(items);
              listALlPresenceRaw.push(itemsRaw);

              countPresence = 0;

              items = {
                ba_id: null,
                ba_name: null,
                ba_brand: null,
                ba_store: null,
                is_cs: null,
                tgl1: null,
                tgl2: null,
                tgl3: null,
                tgl4: null,
                tgl5: null,
                tgl6: null,
                tgl7: null,
                tgl8: null,
                tgl9: null,
                tgl10: null,
                tgl11: null,
                tgl12: null,
                tgl13: null,
                tgl14: null,
                tgl15: null,
                tgl16: null,
                tgl17: null,
                tgl18: null,
                tgl19: null,
                tgl20: null,
                tgl21: null,
                tgl22: null,
                tgl23: null,
                tgl24: null,
                tgl25: null,
                tgl26: null,
                tgl27: null,
                tgl28: null,
                tgl29: null,
                tgl30: null,
                tgl31: null,
              };

              itemsRaw = {
                ba_id: null,
                ba_name: null,
                ba_brand: null,
                ba_store: null,
                is_cs: null,
                percent_completeness: null,
                total_presence: null,
                total_not_presence: null,
                tgl1: null,
                tgl2: null,
                tgl3: null,
                tgl4: null,
                tgl5: null,
                tgl6: null,
                tgl7: null,
                tgl8: null,
                tgl9: null,
                tgl10: null,
                tgl11: null,
                tgl12: null,
                tgl13: null,
                tgl14: null,
                tgl15: null,
                tgl16: null,
                tgl17: null,
                tgl18: null,
                tgl19: null,
                tgl20: null,
                tgl21: null,
                tgl22: null,
                tgl23: null,
                tgl24: null,
                tgl25: null,
                tgl26: null,
                tgl27: null,
                tgl28: null,
                tgl29: null,
                tgl30: null,
                tgl31: null,
              };

              pastID = presence.ba_id;

              items.ba_id = presence.ba_id;
              items.ba_name = presence.name;
              items.ba_brand = presence.ba_brand
                ? presence.ba_brand.slice(6)
                : "Wardah";
              items.ba_store = presence.partner_name;
              items.is_cs = presence.is_cs ? "CS" : "BA";
              items.nip = presence.nip;
              items.dc = presence.dc;

              itemsRaw.dc = presence.dc;
              itemsRaw.ba_id = presence.ba_id;
              itemsRaw.ba_name = presence.name;
              itemsRaw.ba_nip = presence.nip;
              itemsRaw.ba_brand = presence.ba_brand
                ? presence.ba_brand.slice(6)
                : "Wardah";
              itemsRaw.ba_store = presence.partner_name;
              itemsRaw.is_cs = presence.is_cs ? "CS" : "BA";
              itemsRaw.nip = presence.nip;

              switch (presence.day) {
                case 1:
                  if (!items.tgl1) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl1 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl1 = "IZIN";
                      tgl1off++;
                    } else if (presence.presence <= -1) {
                      items.tgl1 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl1 = "OFF";
                      tgl1off++;
                    } else {
                      items.tgl1 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl1 = "ON";
                      tgl1++;
                    }
                  }
                  break;
                case 2:
                  if (!items.tgl2) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl2 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl2 = "IZIN";
                      tgl2off++;
                    } else if (presence.presence <= -1) {
                      items.tgl2 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl2 = "OFF";
                      tgl2off++;
                    } else {
                      items.tgl2 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl2 = "ON";
                      tgl2++;
                    }
                  }
                  break;
                case 3:
                  if (!items.tgl3) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl3 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl3 = "IZIN";
                      tgl3off++;
                    } else if (presence.presence <= -1) {
                      items.tgl3 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl3 = "OFF";
                      tgl3off++;
                    } else {
                      items.tgl3 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl3 = "ON";
                      tgl3++;
                    }
                  }
                  break;
                case 4:
                  if (!items.tgl4) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl4 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl4 = "IZIN";
                      tgl4off++;
                    } else if (presence.presence <= -1) {
                      items.tgl4 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl4 = "OFF";
                      tgl4off++;
                    } else {
                      items.tgl4 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl4 = "ON";
                      tgl4++;
                    }
                  }
                  break;
                case 5:
                  if (!items.tgl5) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl5 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl5 = "IZIN";
                      tgl5off++;
                    } else if (presence.presence <= -1) {
                      items.tgl5 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl5 = "OFF";
                      tgl5off++;
                    } else {
                      items.tgl5 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl5 = "ON";
                      tgl5++;
                    }
                  }
                  break;
                case 6:
                  if (!items.tgl6) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl6 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl6 = "IZIN";
                      tgl6off++;
                    } else if (presence.presence <= -1) {
                      items.tgl6 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl6 = "OFF";
                      tgl6off++;
                    } else {
                      items.tgl6 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl6 = "ON";
                      tgl6++;
                    }
                  }
                  break;
                case 7:
                  if (!items.tgl7) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl7 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl7 = "IZIN";
                      tgl7off++;
                    } else if (presence.presence <= -1) {
                      items.tgl7 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl7 = "OFF";
                      tgl7off++;
                    } else {
                      items.tgl7 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl7 = "ON";
                      tgl7++;
                    }
                  }
                  break;
                case 8:
                  if (!items.tgl8) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl8 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl8 = "IZIN";
                      tgl8off++;
                    } else if (presence.presence <= -1) {
                      items.tgl8 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl8 = "OFF";
                      tgl8off++;
                    } else {
                      items.tgl8 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl8 = "ON";
                      tgl8++;
                    }
                  }
                  break;
                case 9:
                  if (!items.tgl9) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl9 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl9 = "IZIN";
                      tgl9off++;
                    } else if (presence.presence <= -1) {
                      items.tgl9 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl9 = "OFF";
                      tgl9off++;
                    } else {
                      items.tgl9 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl9 = "ON";
                      tgl9++;
                    }
                  }
                  break;
                case 10:
                  if (!items.tgl10) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl10 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl10 = "IZIN";
                      tgl10off++;
                    } else if (presence.presence <= -1) {
                      items.tgl10 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl10 = "OFF";
                      tgl10off++;
                    } else {
                      items.tgl10 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl10 = "ON";
                      tgl10++;
                    }
                  }
                  break;
                case 11:
                  if (!items.tgl11) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl11 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl11 = "IZIN";
                      tgl11off++;
                    } else if (presence.presence <= -1) {
                      items.tgl11 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl11 = "OFF";
                      tgl11off++;
                    } else {
                      items.tgl11 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl11 = "ON";
                      tgl11++;
                    }
                  }
                  break;
                case 12:
                  if (!items.tgl12) {
                    countPresence++;

                    if (presence.presence <= -40) {
                      items.tgl12 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl12 = "IZIN";
                      tgl12off++;
                    } else if (presence.presence <= -1) {
                      items.tgl12 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl12 = "OFF";
                      tgl12off++;
                    } else {
                      items.tgl12 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl12 = "ON";
                      tgl12++;
                    }
                  }
                  break;
                case 13:
                  if (!items.tgl13) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl13 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl13 = "IZIN";
                      tgl13off++;
                    } else if (presence.presence <= -1) {
                      items.tgl13 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl13 = "OFF";
                      tgl13off++;
                    } else {
                      items.tgl13 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl13 = "ON";
                      tgl13++;
                    }
                  }
                  break;
                case 14:
                  if (!items.tgl14) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl14 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl14 = "IZIN";
                      tgl14off++;
                    } else if (presence.presence <= -1) {
                      items.tgl14 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl14 = "OFF";
                      tgl14off++;
                    } else {
                      items.tgl14 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl14 = "ON";
                      tgl14++;
                    }
                  }
                  break;
                case 15:
                  if (!items.tgl15) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl15 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl15 = "IZIN";
                      tgl15off++;
                    } else if (presence.presence <= -1) {
                      items.tgl15 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl15 = "OFF";
                      tgl15off++;
                    } else {
                      items.tgl15 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl15 = "ON";
                      tgl15++;
                    }
                  }
                  break;
                case 16:
                  if (!items.tgl16) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl16 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl16 = "IZIN";
                      tgl16off++;
                    } else if (presence.presence <= -1) {
                      items.tgl16 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl16 = "OFF";
                      tgl16off++;
                    } else {
                      items.tgl16 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl16 = "ON";
                      tgl16++;
                    }
                  }
                  break;
                case 17:
                  if (!items.tgl17) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl17 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl17 = "IZIN";
                      tgl17off++;
                    } else if (presence.presence <= -1) {
                      items.tgl17 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl17 = "OFF";
                      tgl17off++;
                    } else {
                      items.tgl17 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl17 = "ON";
                      tgl17++;
                    }
                  }
                  break;
                case 18:
                  if (!items.tgl18) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl18 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl18 = "IZIN";
                      tgl18off++;
                    } else if (presence.presence <= -1) {
                      items.tgl18 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl18 = "OFF";
                      tgl18off++;
                    } else {
                      items.tgl18 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl18 = "ON";
                      tgl18++;
                    }
                  }
                  break;
                case 19:
                  if (!items.tgl19) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl19 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl19 = "IZIN";
                      tgl19off++;
                    } else if (presence.presence <= -1) {
                      items.tgl19 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl19 = "OFF";
                      tgl19off++;
                    } else {
                      items.tgl19 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl19 = "ON";
                      tgl19++;
                    }
                  }
                  break;
                case 20:
                  if (!items.tgl20) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl20 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl20 = "IZIN";
                      tgl20off++;
                    } else if (presence.presence <= -1) {
                      items.tgl20 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl20 = "OFF";
                      tgl20off++;
                    } else {
                      items.tgl20 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl20 = "ON";
                      tgl20++;
                    }
                  }
                  break;
                case 21:
                  if (!items.tgl21) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl21 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl21 = "IZIN";
                      tgl21off++;
                    } else if (presence.presence <= -1) {
                      items.tgl21 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl21 = "OFF";
                      tgl21off++;
                    } else {
                      items.tgl21 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl21 = "ON";
                      tgl21++;
                    }
                  }
                  break;
                case 22:
                  if (!items.tgl22) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl22 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl22 = "IZIN";
                      tgl22off++;
                    } else if (presence.presence <= -1) {
                      items.tgl22 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl22 = "OFF";
                      tgl22off++;
                    } else {
                      items.tgl22 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl22 = "ON";
                      tgl22++;
                    }
                  }
                  break;
                case 23:
                  if (!items.tgl23) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl23 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl23 = "IZIN";
                      tgl23off++;
                    } else if (presence.presence <= -1) {
                      items.tgl23 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl23 = "OFF";
                      tgl23off++;
                    } else {
                      items.tgl23 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl23 = "ON";
                      tgl23++;
                    }
                  }
                  break;
                case 24:
                  if (!items.tgl24) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl24 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl24 = "IZIN";
                      tgl24off++;
                    } else if (presence.presence <= -1) {
                      items.tgl24 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl24 = "OFF";
                      tgl24off++;
                    } else {
                      items.tgl24 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl24 = "ON";
                      tgl24++;
                    }
                  }
                  break;
                case 25:
                  if (!items.tgl25) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl25 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl25 = "IZIN";
                      tgl25off++;
                    } else if (presence.presence <= -1) {
                      items.tgl25 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl25 = "OFF";
                      tgl25off++;
                    } else {
                      items.tgl25 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl25 = "ON";
                      tgl25++;
                    }
                  }
                  break;
                case 26:
                  if (!items.tgl26) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl26 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl26 = "IZIN";
                      tgl26off++;
                    } else if (presence.presence <= -1) {
                      items.tgl26 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl26 = "OFF";
                      tgl26off++;
                    } else {
                      items.tgl26 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl26 = "ON";
                      tgl26++;
                    }
                  }
                  break;
                case 27:
                  if (!items.tgl27) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl27 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl27 = "IZIN";
                      tgl27off++;
                    } else if (presence.presence <= -1) {
                      items.tgl27 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl27 = "OFF";
                      tgl27off++;
                    } else {
                      items.tgl27 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl27 = "ON";
                      tgl27++;
                    }
                  }
                  break;
                case 28:
                  if (!items.tgl28) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl28 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl28 = "IZIN";
                      tgl28off++;
                    } else if (presence.presence <= -1) {
                      items.tgl28 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl28 = "OFF";
                      tgl28off++;
                    } else {
                      items.tgl28 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl28 = "ON";
                      tgl28++;
                    }
                  }
                  break;
                case 29:
                  if (!items.tgl29) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl29 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl29 = "IZIN";
                      tgl29off++;
                    } else if (presence.presence <= -1) {
                      items.tgl29 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl29 = "OFF";
                      tgl29off++;
                    } else {
                      items.tgl29 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl29 = "ON";
                      tgl29++;
                    }
                  }
                  break;
                case 30:
                  if (!items.tgl30) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl30 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl30 = "IZIN";
                      tgl30off++;
                    } else if (presence.presence <= -1) {
                      items.tgl30 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl30 = "OFF";
                      tgl30off++;
                    } else {
                      items.tgl30 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl30 = "ON";
                      tgl30++;
                    }
                  }
                  break;
                case 31:
                  if (!items.tgl31) {
                    countPresence++;
                    if (presence.presence <= -40) {
                      items.tgl31 = <p style={{ color: "#FBAF18" }}>⬤</p>;
                      itemsRaw.tgl31 = "IZIN";
                      tgl31off++;
                    } else if (presence.presence <= -1) {
                      items.tgl31 = <p style={{ color: "#fb6340" }}>⬤</p>;
                      itemsRaw.tgl31 = "OFF";
                      tgl31off++;
                    } else {
                      items.tgl31 = <p style={{ color: "#2dce89" }}>⬤</p>;
                      itemsRaw.tgl31 = "ON";
                      tgl31++;
                    }
                  }
                  break;
                default:
                  break;
              }
            }

            if (tabActive === "Region") {
              items.salesarea_name = presence.salesarea_name;
            }
          });

          items.total_presence = countPresence;
          items.total_not_presence = date - countPresence;
          items.percent_completeness = parseInt(
            ((items.total_presence / date) * 100).toFixed()
          );

          itemsRaw.total_presence = countPresence;
          itemsRaw.total_not_presence = date - countPresence;
          itemsRaw.percent_completeness = parseInt(
            ((items.total_presence / date) * 100).toFixed()
          );

          listAllPresence.push(items);
          listALlPresenceRaw.push(itemsRaw);
          // const config = {
          //   headers: { Authorization: `Bearer ${userToken}` },
          //   method: "get",
          //   url: `/v-active-ba-by-dc/list?sort=dc.asc&month=${currentMonth}&year=${currentYear}`,
          // };
          const cookies = new Cookies()
          const userToken = cookies.get('userToken')
          const config = {
            headers: { Authorization: `Bearer ${userToken}` },
            method: 'get',
            url: ``
          }

          if (tabActive === "Region") {
            config.url = `/dashboard/v_active_ba_by_salesarea/list?sort=salesarea_name.asc&month=${month}&year=${year}`
          } else {
            config.url = `/dashboard/v-active-ba-by-dc/list?sort=dc.asc&month=${month}&year=${year}`
          }

          Axios(config)
            .then((res) => {
              let presenced = res.data
              var tempTotalBA = 0;
              presenced.data.data.forEach((ba) => {
                tempTotalBA = ba.total_ba + tempTotalBA;
              });

              var dataNotPresence = [];

              if (isNasional) {
                dataNotPresence = [
                  tempTotalBA - tgl1 - tgl1off,
                  tempTotalBA - tgl2 - tgl2off,
                  tempTotalBA - tgl3 - tgl3off,
                  tempTotalBA - tgl4 - tgl4off,
                  tempTotalBA - tgl5 - tgl5off,
                  tempTotalBA - tgl6 - tgl6off,
                  tempTotalBA - tgl7 - tgl7off,
                  tempTotalBA - tgl8 - tgl8off,
                  tempTotalBA - tgl9 - tgl9off,
                  tempTotalBA - tgl10 - tgl10off,
                  tempTotalBA - tgl11 - tgl11off,
                  tempTotalBA - tgl12 - tgl12off,
                  tempTotalBA - tgl13 - tgl13off,
                  tempTotalBA - tgl14 - tgl14off,
                  tempTotalBA - tgl15 - tgl15off,
                  tempTotalBA - tgl16 - tgl16off,
                  tempTotalBA - tgl17 - tgl17off,
                  tempTotalBA - tgl18 - tgl18off,
                  tempTotalBA - tgl19 - tgl19off,
                  tempTotalBA - tgl20 - tgl20off,
                  tempTotalBA - tgl21 - tgl21off,
                  tempTotalBA - tgl22 - tgl22off,
                  tempTotalBA - tgl23 - tgl23off,
                  tempTotalBA - tgl24 - tgl24off,
                  tempTotalBA - tgl25 - tgl25off,
                  tempTotalBA - tgl26 - tgl26off,
                  tempTotalBA - tgl27 - tgl27off,
                  tempTotalBA - tgl28 - tgl28off,
                  tempTotalBA - tgl29 - tgl29off,
                  tempTotalBA - tgl30 - tgl30off,
                  tempTotalBA - tgl31 - tgl31off,
                ];
              } else {
                tempTotalBA = this.getTotalBA(presenced.data.data, listScope);

                dataNotPresence = [
                  tempTotalBA - tgl1 - tgl1off,
                  tempTotalBA - tgl2 - tgl2off,
                  tempTotalBA - tgl3 - tgl3off,
                  tempTotalBA - tgl4 - tgl4off,
                  tempTotalBA - tgl5 - tgl5off,
                  tempTotalBA - tgl6 - tgl6off,
                  tempTotalBA - tgl7 - tgl7off,
                  tempTotalBA - tgl8 - tgl8off,
                  tempTotalBA - tgl9 - tgl9off,
                  tempTotalBA - tgl10 - tgl10off,
                  tempTotalBA - tgl11 - tgl11off,
                  tempTotalBA - tgl12 - tgl12off,
                  tempTotalBA - tgl13 - tgl13off,
                  tempTotalBA - tgl14 - tgl14off,
                  tempTotalBA - tgl15 - tgl15off,
                  tempTotalBA - tgl16 - tgl16off,
                  tempTotalBA - tgl17 - tgl17off,
                  tempTotalBA - tgl18 - tgl18off,
                  tempTotalBA - tgl19 - tgl19off,
                  tempTotalBA - tgl20 - tgl20off,
                  tempTotalBA - tgl21 - tgl21off,
                  tempTotalBA - tgl22 - tgl22off,
                  tempTotalBA - tgl23 - tgl23off,
                  tempTotalBA - tgl24 - tgl24off,
                  tempTotalBA - tgl25 - tgl25off,
                  tempTotalBA - tgl26 - tgl26off,
                  tempTotalBA - tgl27 - tgl27off,
                  tempTotalBA - tgl28 - tgl28off,
                  tempTotalBA - tgl29 - tgl29off,
                  tempTotalBA - tgl30 - tgl30off,
                  tempTotalBA - tgl31 - tgl31off,
                ];
              }

              dataNotPresence.forEach((item, index) => {
                if (item < 0) {
                  dataNotPresence[index] = 0;
                }
              });

              var presenceBarChartData = {
                data: {
                  labels: this.state.listTanggal,
                  datasets: [
                    {
                      label: "Total Presensi",
                      backgroundColor: this.state.colors.theme["success"],
                      data: [
                        tgl1,
                        tgl2,
                        tgl3,
                        tgl4,
                        tgl5,
                        tgl6,
                        tgl7,
                        tgl8,
                        tgl9,
                        tgl10,
                        tgl11,
                        tgl12,
                        tgl13,
                        tgl14,
                        tgl15,
                        tgl16,
                        tgl17,
                        tgl18,
                        tgl19,
                        tgl20,
                        tgl21,
                        tgl22,
                        tgl23,
                        tgl24,
                        tgl25,
                        tgl26,
                        tgl27,
                        tgl28,
                        tgl29,
                        tgl30,
                        tgl31,
                        tgl31off,
                      ],
                    },
                    {
                      label: "Total Off",
                      backgroundColor: this.state.colors.theme["primary"],
                      data: [
                        tgl1off,
                        tgl2off,
                        tgl3off,
                        tgl4off,
                        tgl5off,
                        tgl6off,
                        tgl7off,
                        tgl8off,
                        tgl9off,
                        tgl10off,
                        tgl11off,
                        tgl12off,
                        tgl13off,
                        tgl14off,
                        tgl15off,
                        tgl16off,
                        tgl17off,
                        tgl18off,
                        tgl19off,
                        tgl20off,
                        tgl21off,
                        tgl22off,
                        tgl23off,
                        tgl24off,
                        tgl25off,
                        tgl26off,
                        tgl27off,
                        tgl28off,
                        tgl29off,
                        tgl30off,
                        tgl31off,
                        tgl31off,
                      ],
                    },
                    {
                      label: "Total Tidak Presensi",
                      backgroundColor: this.state.colors.theme["danger"],
                      data: dataNotPresence,
                    },
                  ],
                },
              };

              this.setState({
                allPresence: listAllPresence,
                allPresenceRaw: listALlPresenceRaw,
                presenceChartData: presenceBarChartData,
                isBAPresenceRecapLoading: false,
              });
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                isBAPresenceRecapLoading: false,
              });
            });

          this.setState({
            isBAPresenceRecapLoading: false,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isBAPresenceRecapLoading: false,
          });
        });
    } else {
      this.setState({
        isBAPresenceRecapLoading: false,
      });
    }
  };

  getTotalBA = (presencedData, listScope) => {
    const { tabActive } = this.state;
    var totalBa = 0;
    var idx = 0;
    if (tabActive === "Region") {
      var listOnlyArea = [];

      presencedData.forEach((area) => {
        listOnlyArea.push(area.salesarea_name);
      });

      listScope.forEach((item) => {
        idx = listOnlyArea.indexOf(item);

        if (idx !== -1) {
          totalBa += presencedData[idx].total_ba;
        }
      });
    } else {
      var listOnlyDC = [];

      presencedData.forEach((dc) => {
        listOnlyDC.push(dc.dc);
      });

      listScope.forEach((item) => {
        idx = listOnlyDC.indexOf(item);

        if (idx !== -1) {
          totalBa += presencedData[idx].total_ba;
        }
      });
    }

    return totalBa;
  };

  reloadPresenceByDC = (date, month, year) => {
    var pickerDate = new Date(year, month - 1, date);
    var tempPresensi = [];
    var tempOff = [];
    var tempNotPresensi = [];
    this.state.listOnlyDC.forEach((dc) => {
      tempPresensi.push(0);
      tempOff.push(0);
      tempNotPresensi.push(0);
    });

    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const configPresence = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: `/dashboard/v_daily_ba_presence/list?presence=neq.0&sort=dc.desc,day.desc&day=${date}&month=${month}&year=${year}`,
    }

    Axios(configPresence)
      .then((res) => {
        let response = res.data
        var idxPresence = 0;
        var tempPresenced = [];
        var totalNotPresenced = [];
        var item = {};
        const configActiveBA = {
          headers: { Authorization: `Bearer ${userToken}` },
          method: 'GET',
          url: `/dashboard/v-active-ba-by-dc/list?sort=dc.asc&month=${month}&year=${year}`,
        }

        Axios(configActiveBA)
          .then((res) => {
            const presenced = res.data
            this.state.listOnlyDC.forEach((dc) => {
              if (presenced.data.data.length == 0) {
                item = {
                  total_ba: 0,
                  dc: dc,
                };
                tempPresenced.push(item);
              } else if (dc !== presenced.data.data[idxPresence].dc) {
                tempPresenced.push(presenced.data.data[idxPresence]);
                idxPresence++;
              } else {
                item = {
                  total_ba: 0,
                  dc: dc,
                };
                tempPresenced.push(item);
              }
            });

            response.data.data.forEach((presence) => {
              var idx = this.state.listOnlyDC.indexOf(presence.dc);
              if (idx > -1) {
                if (presence.presence <= -1) {
                  tempOff[idx]++;
                } else {
                  tempPresensi[idx]++;
                }
              }
            });

            tempPresensi.forEach((presensi, index) => {
              totalNotPresenced.push(
                tempPresenced[index].total_ba -
                tempOff[index] -
                tempPresensi[index]
              );
            });

            var presenceBarChartData = {
              data: {
                labels: this.state.listOnlyDC,
                datasets: [
                  {
                    label: "Total Presensi",
                    backgroundColor: this.state.colors.theme["success"],
                    data: tempPresensi,
                  },
                  {
                    label: "Total Off",
                    backgroundColor: this.state.colors.theme["primary"],
                    data: tempOff,
                  },
                  {
                    label: "Total Tidak Presensi",
                    backgroundColor: this.state.colors.theme["danger"],
                    data: totalNotPresenced,
                  },
                ],
              },
            };

            this.setState({
              presenceChartDataByDC: presenceBarChartData,
              selectedDatePresenceByDcPicker: pickerDate,
              totalPresencedByDC: tempPresenced,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  GetActionFormat(cell, row) {
    return (
      <div>
        <Link
          to={`/admin/dashboard-partner?partner_id=${row.partner_id}&brand=${row.brand}`}
        >
          <button
            type="button"
            className="btn btn-outline-primary btn-sm ts-buttom"
            size="sm"
          >
            View
          </button>
        </Link>
      </div>
    );
  }

  GetBAActionFormat(cell, row) {
    return (
      <div>
        <Link to={`/admin/dashboard-ba?ba_id=${row.ba_id}`}>
          <button
            type="button"
            className="btn btn-outline-primary btn-sm ts-buttom"
            size="sm"
          >
            View
          </button>
        </Link>
      </div>
    );
  }

  GetPhotoActionFormat(cell, row) {
    return (
      <div>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm ts-buttom"
          size="sm"
          onClick={this.onPhotoView.bind(this, cell, row)}
        >
          View
        </button>
      </div>
    );
  }

  onBAClick = (cell, ba, rowIndex) => {
    const cookies = new Cookies();
    var item = {
      ba_id: ba.ba_id,
      ba_name: ba.ba_name,
      ba_brand: ba.ba_brand,
      ba_store: ba.ba_store,
    };
    cookies.set("selectedBA", item, { path: "/" });
  };

  onPhotoView = (cell, ba, rowIndex) => {
    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: 'GET',
      url: `/presence?sort=id.desc&ba_id=${ba.ba_id}&select=image,write_date,ba_id&limit=1`,
    }
    Axios(config
    )
      .then(async (res) => {
        let response = res.data
        const dataFormatted = await this.dataFormattedHandler({
          data: response.data.data,
        });


        this.setState({
          openPhotoModal: true,
          selectedPhotoBA: dataFormatted[0],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleClose = () => {
    this.setState({
      openPhotoModal: false,
    });
  };

  onPartnerClick = (cell, partner, rowIndex) => {
    const cookies = new Cookies();
    cookies.set("selectedPartner", partner, { path: "/" });
  };

  changeSummaryChart = () => {
    this.setState({
      isSummaryChartDaily: !this.state.isSummaryChartDaily,
    });
  };

  handleStartDateChange = (date) => {
    var dateFormat = require("dateformat");
    var day = parseInt(dateFormat(date._d, "d"));
    var month = parseInt(dateFormat(date._d, "m"));
    var year = parseInt(dateFormat(date._d, "yyyy"));

    this.reloadPresenceByDC(day, month, year);

    this.setState({
      selectedDatePresenceByDC: day,
      selectedMonthPresenceByDC: month,
      selectedYearPresenceByDC: year,
      printedDatePresenceByDC: day,
      printedMonthPresenceByDC: dateFormat(new Date(year, month, 0), "mmmm"),
      printedYearPresenceByDC: dateFormat(new Date(year, month, 0), "yyyy"),
    });
  };

  onPartnerClick = (cell, partner, rowIndex) => {
    const cookies = new Cookies();
    cookies.set("selectedPartner", partner, { path: "/" });
  };

  onSalesAreaChangeHandler = (arr) => {
    const areaSales = arr.map((item) => item.sales_area_name);
    this.setState({
      areaSelected: areaSales,
    });

    this.reloadPresence(
      this.state.selectedDatePresence,
      this.state.selectedMonthPresence,
      this.state.selectedYearPresence,
      areaSales,
      "Region"
    );
    this.reloadAllSummaryChart(
      this.state.selectedMonthSummarySales,
      this.state.selectedYearSummarySales,
      areaSales,
      "Region"
    );

    this.reloadTablePartner(
      this.state.selectedMonthTableSummarySales,
      this.state.selectedYearTableSummarySales,
      areaSales,
      "Region"
    );

    this.getPartnerNotParticipatedSales(
      this.state.selectedMonthNotParticipatedSales,
      this.state.selectedYearNotParticipatedSales,
      areaSales,
      "Region"
    );

    this.reloadVisitorBuyer(
      this.state.selectedMonthVisitorBuyer,
      this.state.selectedYearVisitorBuyer,
      areaSales,
      "Region"
    );

    this.getPartnerNotParticipatedVisitorBuyer(
      this.state.selectedMonthNotParticipatedVisitorBuyer,
      this.state.selectedYearNotParticipatedVisitorBuyer,
      areaSales,
      "Region"
    );
  };

  onDCSelectedChanged = async (value, event) => {
    await this.initiateSummaryChart(this.state.listTanggalSummaryChart);
    var arrayDC = [];
    value.forEach((item) => {
      arrayDC.push(item.text);
    });
    const cookies = new Cookies();
    cookies.set("listSelectedDC", arrayDC, { path: "/" });
    this.setState({
      listSelectedDC: arrayDC,
    });

    this.reloadPresence(
      this.state.selectedDatePresence,
      this.state.selectedMonthPresence,
      this.state.selectedYearPresence,
      arrayDC
    );
    this.reloadAllSummaryChart(
      this.state.selectedMonthSummarySales,
      this.state.selectedYearSummarySales,
      arrayDC
    );
    this.reloadTablePartner(
      this.state.selectedMonthTableSummarySales,
      this.state.selectedYearTableSummarySales,
      arrayDC
    );
    this.reloadVisitorBuyer(
      this.state.selectedMonthVisitorBuyer,
      this.state.selectedYearVisitorBuyer,
      arrayDC
    );
    this.getPartnerNotParticipatedSales(
      this.state.selectedMonthNotParticipatedSales,
      this.state.selectedYearNotParticipatedSales,
      arrayDC
    );
    this.getPartnerNotParticipatedVisitorBuyer(
      this.state.selectedMonthNotParticipatedVisitorBuyer,
      this.state.selectedYearNotParticipatedVisitorBuyer,
      arrayDC
    );
  };

  render() {
    var columns = [];
    var itemsID;
    if (this.state.columnsPresence.length === 0) {
      itemsID = {
        dataField: "ba_id",
        text: "ID",
        sort: true,
      };
      columns.push(itemsID);
    } else {
      let temp = [...this.state.columnsPresence];
      if (this.state.tabActive === "Region") {
        temp[5] = {
          dataField: "salesarea_name",
          text: "Sales Area",
          sort: true,
        };
      } else {
        temp[5] = {
          dataField: "dc",
          text: "DC",
          sort: true,
        };
      }
      columns = temp;
    }
    var columnsSales = [];
    if (this.state.columnsSalesTable.length === 0) {
      itemsID = {
        dataField: "partner_name",
        text: "Nama Toko",
        sort: true,
      };
      columnsSales.push(itemsID);
    } else {
      let temp = [...this.state.columnsSalesTable];
      if (this.state.tabActive === "Region") {
        temp[3] = {
          dataField: "salesarea_name",
          text: "Sales Area",
          sort: true,
        };
      } else {
        temp[3] = {
          dataField: "dc",
          text: "DC",
          sort: true,
        };
      }
      columnsSales = temp;
    }

    var columnsVisitorBuyerTable = [];
    if (this.state.columnsVisitorBuyerTable.length === 0) {
      itemsID = {
        dataField: "partner_name",
        text: "Nama Toko",
        sort: true,
      };
      columnsVisitorBuyerTable.push(itemsID);
    } else {
      let temp = [...this.state.columnsVisitorBuyerTable];
      if (this.state.tabActive === "Region") {
        temp[2] = {
          dataField: "salesarea_name",
          text: "Sales Area",
          sort: true,
        };
      } else {
        temp[2] = {
          dataField: "dc",
          text: "DC",
          sort: true,
        };
      }
      columnsVisitorBuyerTable = temp;
    }

    var columnTableNotParticipated = [];
    if (this.state.columnTableNotParticipated.length === 0) {
      itemsID = {
        dataField: "partner_name",
        text: "Nama Toko",
        sort: true,
      };
      columnTableNotParticipated.push(itemsID);
    } else {
      let temp = [...this.state.columnTableNotParticipated];
      if (this.state.tabActive === "Region") {
        temp[3] = {
          dataField: "areasales_name",
          text: "Sales Area",
          sort: true,
        };
      } else {
        temp[3] = {
          dataField: "dc",
          text: "DC",
          sort: true,
        };
      }
      columnTableNotParticipated = temp;
    }

    const noNext = this.state.noNext;
    // const noNextSO = this.state.noNextSO
    const noNextPresenceByDC = this.state.noNextPresenceByDC;
    const nextMonth = this.nextMonth.bind(this);
    const nextDayPresenceByDC = this.nextDayPresenceByDC;
    // const nextMonthSO = this.nextMonthSO.bind(this)
    const noNextSummarySalesByDC = this.state.noNextSummarySalesByDC;
    const noNextSummarySales = this.state.noNextSummarySales;
    const noNextTableSummarySales = this.state.noNextTableSummarySales;
    const nextMonthSummarySales = this.nextMonthSummarySales.bind(this);
    const prevMonthSummarySales = this.prevMonthSummarySales.bind(this);
    const nextMonthSummarySalesByDC = this.nextMonthSummarySalesByDC.bind(this);
    const prevMonthSummarySalesByDC = this.prevMonthSummarySalesByDC.bind(this);
    const nextMonthTableSummarySales =
      this.nextMonthTableSummarySales.bind(this);
    const prevMonthTableSummarySales =
      this.prevMonthTableSummarySales.bind(this);
    const nextMonthTableNotReportingSales =
      this.nextMonthTableNotReportingSales.bind(this);
    const prevMonthTableNotReportingSales =
      this.prevMonthTableNotReportingSales.bind(this);
    const nextMonthTableNotReportingVisitorBuyer =
      this.nextMonthTableNotReportingVisitorBuyer.bind(this);
    const prevMonthTableNotReportingVisitorBuyer =
      this.prevMonthTableNotReportingVisitorBuyer.bind(this);

    const changeSummaryChart = this.changeSummaryChart.bind(this);
    const isSummaryChartDaily = this.state.isSummaryChartDaily;

    var summaryChartDataShown = {};
    if (this.state.isSummaryChartDaily) {
      summaryChartDataShown = this.state.summaryChartData;
    } else {
      summaryChartDataShown = this.state.summaryChartDataMonthly;
    }

    var isDownload = false;
    const allPresence = this.state.allPresenceRaw;
    if (this.state.allPresenceRaw.length !== 0) {
      isDownload = true;
    }

    const rawSalesTableData = this.state.rawSalesTableData;
    var isSalesDownload = false;
    if (this.state.rawSalesTableData.length !== 0) {
      isSalesDownload = true;
    }
    var listDCName = "";
    this.state.listSelectedDC.forEach((item, index) => {
      listDCName += item;
      if (index !== this.state.listSelectedDC.length - 1) {
        listDCName += " - ";
      }
    });

    var regionText = this.state.selectedRegion + "Text";
    var region = this.state[regionText];

    if (this.state.areaSelected.length > 0) {
      listDCName = region;
    }

    const allPresenceFileName =
      "BA Presence " +
      this.state.printedMonthPresence +
      " " +
      this.state.printedYearPresence +
      " - " +
      listDCName +
      ".csv";
    const salesFileName =
      "Sales Table " +
      listDCName +
      " " +
      this.state.printedMonthTableSummarySales +
      " " +
      this.state.printedYearTableSummarySales +
      ".csv";
    const notParticipatedSalesFileName =
      "Not Reporting Sales Table " +
      listDCName +
      " " +
      this.state.printedMonthNotParticipatedSales +
      " " +
      this.state.printedYearNotParticipatedSales +
      ".csv";
    const notParticipatedVisitorBuyerFileName =
      "Not Reporting Visitor Buyer Table " +
      listDCName +
      " " +
      this.state.printedMonthNotParticipatedVisitorBuyer +
      " " +
      this.state.printedYearNotParticipatedVisitorBuyer +
      ".csv";
    const visitorBuyerFileName =
      "Visitor Buyer Table " +
      listDCName +
      " " +
      this.state.printedMonthVisitorBuyer +
      " " +
      this.state.printedYearVisitorBuyer +
      ".csv";

    // const { scoreboard } = this.state;
    // const { byCampaign } = this.state;
    // const { byDC } = this.state;

    // const filterBy = this.state.filterBy;

    //   const headers_table = [
    //       { label: 'Username', key: 'username' },
    //       { label: 'Campaigns Shared', key: 'campaign_shared'},
    //       { label: 'Products Shared', key: 'product_shared'},
    //       { label: 'Partner Name', key: 'partner_name'},
    //       { label: 'Total Clicked', key: 'total_clicked' },
    //       { label: 'Media Facebook Clicked', key: 'facebook' },
    //       { label: 'Media Instagram Clicked', key: 'instagram' },,
    //       { label: 'Media Line Clicked', key: 'line' },
    //       { label: 'Media Twitter Clicked', key: 'twitter' },
    //       { label: 'Media Whatsapp Clicked', key: 'whatsapp' },

    //   ];

    //   const headers_campaign = [
    //       { label: 'Username', key: 'username' },
    //       { label: 'Campaign', key: 'campaign'},
    //       { label: 'Total Clicked', key: 'total_clicked' },
    //       { label: 'Media Facebook Clicked', key: 'facebook' },
    //       { label: 'Media Instagram Clicked', key: 'instagram' },
    //       { label: 'Media Line Clicked', key: 'line' },
    //       { label: 'Media Twitter Clicked', key: 'twitter' },
    //       { label: 'Media Whatsapp Clicked', key: 'whatsapp' },

    //   ];

    //   const headers_dc = [
    //       { label: 'Username', key: 'username' },
    //       { label: 'DC', key: 'dc'},
    //       { label: 'Partner Name', key: 'partner_name'},
    //       { label: 'Total Clicked', key: 'total_clicked' },
    //       { label: 'Media Facebook Clicked', key: 'facebook' },
    //       { label: 'Media Instagram Clicked', key: 'instagram' },
    //       { label: 'Media Line Clicked', key: 'line' },
    //       { label: 'Media Twitter Clicked', key: 'twitter' },
    //       { label: 'Media Whatsapp Clicked', key: 'whatsapp' },

    //   ];

    //   const columnElse = [
    //     {
    //       dataField: "username",
    //       text: "Username",
    //       sort: true,
    //     },
    //     {
    //       dataField: "partner_name",
    //       text: "Partner",
    //       sort: false,

    //     },
    //     {
    //       dataField: "campaign_shared",
    //       text: "Campaign Shared",
    //       sort: false,

    //     },
    //     {
    //       dataField: "product_shared",
    //       text: "Product Shared",
    //       sort: false,
    //       style: {
    //         width: '50px',
    //       }
    //     },
    //     {
    //       dataField: "facebook",
    //       text: "FB clicked",
    //       sort: false
    //     },

    //     {
    //       dataField: "instagram",
    //       text: "IG clicked",
    //       sort: false
    //     },
    //     {
    //       dataField: "line",
    //       text: "Line clicked",
    //       sort: false,
    //       style: {
    //         width: '50px',
    //       }
    //     },
    //     {
    //       dataField: "twitter",
    //       text: "Twitter clicked",
    //       sort: false
    //     },
    //     {
    //       dataField: "whatsapp",
    //       text: "WA clicked",
    //       sort: false
    //     },

    //     {
    //         dataField: "total_clicked",
    //         text: "Total Clicked",
    //         sort: false
    //     },
    //   ]

    //   const columnByCampaign = [
    //     {
    //     dataField: "username",
    //     text: "Username",
    //     sort: true,
    //   },
    //     {
    //     dataField: "campaign",
    //     text: "Campaign",
    //     sort: false,

    //   },

    //   {
    //     dataField: "facebook",
    //     text: "FB clicked",
    //     sort: false
    //   },

    //   {
    //     dataField: "instagram",
    //     text: "IG clicked",
    //     sort: false
    //   },

    //   {
    //     dataField: "line",
    //     text: "Line clicked",
    //     sort: false,
    //     style: {
    //       width: '50px',
    //     }
    //   },

    //     {
    //     dataField: "twitter",
    //     text: "Twitter clicked",
    //     sort: false
    //   },

    //   {
    //     dataField: "whatsapp",
    //     text: "WA clicked",
    //     sort: false
    //   },

    //   {
    //       dataField: "total_clicked",
    //       text: "Total Clicked",
    //       sort: false
    //   }
    // ]

    //   const columnByDC = [
    //     {
    //     dataField: "username",
    //     text: "Username",
    //     sort: true,
    //   },
    //   {
    //     dataField: "dc",
    //     text: "DC",
    //     sort: false,

    //   },
    //     {
    //     dataField: "partner_name",
    //     text: "Partner",
    //     sort: false,

    //   },

    //   {
    //     dataField: "facebook",
    //     text: "FB clicked",
    //     sort: false
    //   },

    //   {
    //     dataField: "instagram",
    //     text: "IG clicked",
    //     sort: false
    //   },

    //   {
    //     dataField: "line",
    //     text: "Line clicked",
    //     sort: false,
    //     style: {
    //       width: '50px',
    //     }
    //   },

    //     {
    //     dataField: "twitter",
    //     text: "Twitter clicked",
    //     sort: false
    //   },

    //   {
    //     dataField: "whatsapp",
    //     text: "WA clicked",
    //     sort: false
    //   },

    //   {
    //       dataField: "total_clicked",
    //       text: "Total Clicked",
    //       sort: false
    //   }
    // ]

    var defaultSelectedDC = [];
    if (this.state.listDC.length > 0 && this.state.listSelectedDC.length > 0) {
      this.state.listDC.forEach((item) => {
        var isFound = false;
        var idx = 0;
        while (idx < this.state.listSelectedDC.length && !isFound) {
          if (item.text === this.state.listSelectedDC[idx]) {
            defaultSelectedDC.push(item);
            isFound = true;
          } else {
            idx++;
          }
        }
      });
    }

    const isNotNational = this.state.listSelectedDC.indexOf("NASIONAL") === -1;
    return (
      <>
        <CardsHeader name="Main Dashboard" parentName="Dashboards" />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Filter</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <h5 className="mb-3">Type</h5>
                      <Switch
                        tabList={{ left: "DC", right: "Region" }}
                        onChange={(value) => {
                          //reset summary
                          this.initiateSummaryChart(
                            this.state.listTanggalSummaryChart
                          );
                          this.setState({
                            tabActive: value,
                            areaSelected: [],
                            listSelectedDC: [],
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  {this.state.tabActive === "Region" ? (
                    <Row className="mb-4">
                      {/* <Col xl="6">
                        {this.state.listRegion.length > 0 && (
                          <Autocomplete
                            multiple
                            id="list-region"
                            options={this.state.listRegion}
                            getOptionLabel={(option) => option.value}
                            freeSolo
                            onChange={(event, value) =>
                              this.onRegionSelected(value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Pilih Region"
                                placeholder="Pilih Region"
                              />
                            )}
                          />
                        )}
                      </Col> */}
                      <Col xl="6">
                        {this.state.listAreaByRegion.length > 0 && (
                          <Autocomplete
                            multiple
                            id="list-areasales"
                            options={this.state.listAreaByRegion}
                            disabled={isEmpty(this.state.listAreaByRegion)}
                            getOptionLabel={(option) => option.sales_area_name}
                            freeSolo
                            onChange={(event, value) =>
                              this.onSalesAreaChangeHandler(value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Pilih Area Sales"
                                placeholder="Pilih Area Sales"
                              />
                            )}
                          />
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xl="6">
                        <Form xl="6">
                          {this.state.listDC.length > 0 && (
                            <Autocomplete
                              multiple
                              id="list-dc"
                              options={this.state.listDC}
                              defaultValue={defaultSelectedDC}
                              getOptionLabel={(option) => option.text}
                              freeSolo
                              onChange={(event, value) =>
                                this.onDCSelectedChanged(value)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Pilih DC"
                                  placeholder="Pilih DC"
                                />
                              )}
                            />
                          )}
                        </Form>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.listSelectedDC.length > 0 ||
            this.state.areaSelected.length > 0 ? (
            <>
              <Row>
                <Col xl="12">
                  <Card>
                    <CardHeader>
                      <h6 className="surtitle">
                        Overview -{" "}
                        {this.state.tabActive === "Region"
                          ? this.state.areaSelected.toString()
                          : this.state.selectedRegion === ""
                            ? this.state.listSelectedDC.map((item, index) =>
                              index === this.state.listSelectedDC.length - 1
                                ? item
                                : item + ", "
                            )
                            : region}
                      </h6>
                      <h5 className="h3 mb-0">
                        BA Total Presence Recap -{" "}
                        {this.state.printedMonthPresence}{" "}
                        {this.state.printedYearPresence}
                      </h5>
                      <div style={{ marginLeft: "2%" }}>
                        <Row>
                          <Button
                            onClick={this.prevMonth.bind(this)}
                            color="primary"
                            size="sm"
                            type="button"
                          >
                            Prev
                          </Button>

                          {(function () {
                            if (!noNext) {
                              return (
                                <Button
                                  onClick={nextMonth}
                                  color="primary"
                                  size="sm"
                                  type="button"
                                >
                                  Next
                                </Button>
                              );
                            }
                          })()}
                        </Row>
                      </div>
                    </CardHeader>
                    <CardBody>
                      {this.state.isBAPresenceRecapLoading ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <CircularProgress />
                        </div>
                      ) : null}
                      <div className="chart">
                        <Bar
                          data={this.state.presenceChartData.data}
                          options={chartExample7.options}
                          className="chart-canvas"
                          id="chart-bar-stacked"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {this.state.listSelectedDC.indexOf("NASIONAL") !== -1 ? (
                <Row>
                  <Col xl="12">
                    <Card>
                      <CardHeader>
                        <h6 className="surtitle">Overview per DC</h6>
                        <h5 className="h3 mb-0">
                          BA Total Presence Recap -{" "}
                          {this.state.printedDatePresenceByDC}{" "}
                          {this.state.printedMonthPresenceByDC}{" "}
                          {this.state.printedYearPresenceByDC}
                        </h5>
                        <div style={{ marginLeft: "2%" }}>
                          <Row>
                            <Button
                              onClick={this.prevDayPresenceByDC.bind(this)}
                              color="primary"
                              size="sm"
                              type="button"
                            >
                              Prev
                            </Button>

                            {(function () {
                              if (!noNextPresenceByDC) {
                                return (
                                  <Button
                                    onClick={nextDayPresenceByDC}
                                    color="primary"
                                    size="sm"
                                    type="button"
                                  >
                                    Next
                                  </Button>
                                );
                              }
                            })()}

                            <ReactDatetime
                              value={this.state.selectedDatePresenceByDcPicker}
                              onChange={this.handleStartDateChange}
                              //name='start_date'
                              //dateFormat="dd/MM/yyyy"
                              //selectsStart
                              timeFormat={false}
                            />
                          </Row>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className="chart">
                          <Bar
                            data={this.state.presenceChartDataByDC.data}
                            options={chartExample7.options}
                            className="chart-canvas"
                            id="chart-bar-stacked"
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}
              <Row>
                {/*MODAL VIEW PHOTO*/}
                <Modal
                  isOpen={this.state.openPhotoModal}
                  toggle={this.handleClose}
                >
                  <ModalHeader toggle={this.handleClose}>
                    Presence Photo
                  </ModalHeader>
                  <ModalBody>
                    {this.state.selectedPhotoBA.write_date !== undefined ? (
                      <h3>
                        Tanggal :{" "}
                        {this.state.selectedPhotoBA.write_date.slice(0, 10)} |
                        Waktu :{" "}
                        {this.state.selectedPhotoBA.write_date.slice(11, 16)}
                      </h3>
                    ) : null}
                    <img src={this.state.selectedPhotoBA.image} alt="ba" />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.handleClose}>
                      Close
                    </Button>{" "}
                  </ModalFooter>
                </Modal>
                {this.state.listSelectedDC.indexOf("NASIONAL") === -1 ? (
                  <Col xl="12">
                    <Row>
                      <div className="col">
                        <Card>
                          <CardHeader>
                            <h6 className="surtitle">
                              Overview -{" "}
                              {this.state.selectedRegion === ""
                                ? this.state.listSelectedDC.map((item, index) =>
                                  index ===
                                    this.state.listSelectedDC.length - 1
                                    ? item
                                    : item + ", "
                                )
                                : region}
                            </h6>
                            <h3 className="mb-0">
                              BA Presence Recap -{" "}
                              {this.state.printedMonthPresence}{" "}
                              {this.state.printedYearPresence}
                            </h3>
                            <div style={{ marginLeft: "2%" }}>
                              <Row>
                                <Col xl="6">
                                  {(function () {
                                    if (isDownload) {
                                      return (
                                        <Button
                                          color="primary"
                                          size="sm"
                                          type="button"
                                        >
                                          <CSVLink
                                            data={allPresence}
                                            filename={allPresenceFileName}
                                            style={{ color: "#FFFFFF" }}
                                          >
                                            Download
                                          </CSVLink>
                                        </Button>
                                      );
                                    }
                                  })()}

                                  <Button
                                    onClick={this.prevMonth.bind(this)}
                                    color="primary"
                                    size="sm"
                                    type="button"
                                  >
                                    Prev
                                  </Button>

                                  {(function () {
                                    if (!noNext) {
                                      return (
                                        <Button
                                          onClick={nextMonth}
                                          color="primary"
                                          size="sm"
                                          type="button"
                                        >
                                          Next
                                        </Button>
                                      );
                                    }
                                  })()}
                                </Col>
                                <Col xl="6">
                                  <Row>
                                    <Col xl="6">
                                      <p
                                        style={{
                                          color: "#fb6340",
                                          display: "inline",
                                        }}
                                      >
                                        ⬤&nbsp;
                                      </p>{" "}
                                      : &nbsp;OFF
                                    </Col>
                                    <Col xl="6">
                                      <p
                                        style={{
                                          color: "#FBAF18",
                                          display: "inline",
                                        }}
                                      >
                                        ⬤&nbsp;
                                      </p>{" "}
                                      : &nbsp;IZIN
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="6">
                                      <p
                                        style={{
                                          color: "#2dce89",
                                          display: "inline",
                                        }}
                                      >
                                        ⬤&nbsp;
                                      </p>{" "}
                                      : &nbsp;PRESENSI
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </CardHeader>
                          {this.state.isBAPresenceRecapLoading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          ) : null}
                          <ToolkitProvider
                            data={this.state.allPresence}
                            keyField="name"
                            columns={columns}
                            search
                          >
                            {(props) => (
                              <div className="py-4 table-responsive">
                                <div
                                  id="datatable-basic_filter"
                                  className="dataTables_filter px-4 pb-1"
                                >
                                  <label>
                                    Search:
                                    <SearchBar
                                      className="form-control-sm"
                                      placeholder=""
                                      {...props.searchProps}
                                    />
                                  </label>
                                </div>
                                <BootstrapTable
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  pagination={pagination}
                                  bordered={false}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </Card>
                      </div>
                    </Row>
                  </Col>
                ) : null}
              </Row>

              <Row>
                <Col xl="12">
                  <Card>
                    <CardHeader>
                      <h6 className="surtitle">
                        Overview -{" "}
                        {this.state.selectedRegion === ""
                          ? this.state.listSelectedDC.map((item, index) =>
                            index === this.state.listSelectedDC.length - 1
                              ? item
                              : item + ", "
                          )
                          : region}
                      </h6>
                      <h5 className="h3 mb-0">
                        Total Sales Daily{" "}
                        {isSummaryChartDaily
                          ? this.state.printedMonthSummarySales +
                          " " +
                          this.state.printedYearSummarySales
                          : this.state.printedYearSummarySales}{" "}
                        - Summary Input
                      </h5>
                      <div style={{ marginLeft: "2%" }}>
                        <Row>
                          {(function () {
                            if (isSummaryChartDaily) {
                              return (
                                <>
                                  {isNotNational && (
                                    <Button
                                      onClick={changeSummaryChart}
                                      color="primary"
                                      size="sm"
                                      type="button"
                                    >
                                      Monthly
                                    </Button>
                                  )}
                                </>
                              );
                            } else {
                              return (
                                <Button
                                  onClick={changeSummaryChart}
                                  color="primary"
                                  size="sm"
                                  type="button"
                                >
                                  Daily
                                </Button>
                              );
                            }
                          })()}

                          {(function () {
                            if (isSummaryChartDaily) {
                              return (
                                <Button
                                  onClick={prevMonthSummarySales}
                                  color="primary"
                                  size="sm"
                                  type="button"
                                >
                                  Prev
                                </Button>
                              );
                            }
                          })()}

                          {(function () {
                            if (!noNextSummarySales && isSummaryChartDaily) {
                              return (
                                <Button
                                  onClick={nextMonthSummarySales}
                                  color="primary"
                                  size="sm"
                                  type="button"
                                >
                                  Next
                                </Button>
                              );
                            }
                          })()}
                        </Row>
                      </div>
                    </CardHeader>
                    <CardBody>
                      {this.state.isTotalSalesDailyLoading ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <CircularProgress />
                        </div>
                      ) : null}
                      <div className="chart">
                        <Bar
                          data={summaryChartDataShown.data}
                          options={chartExample8.options}
                          className="chart-canvas"
                          id="chart-bar-stacked"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {this.state.listSelectedDC.indexOf("NASIONAL") !== -1 ? (
                <Row>
                  <Col xl="12">
                    <Card>
                      <CardHeader>
                        <h6 className="surtitle">Overview Per DC</h6>
                        <h5 className="h3 mb-0">
                          Total Sales {this.state.printedMonthSummarySalesByDC}{" "}
                          - Summary Input
                        </h5>
                        <div style={{ marginLeft: "2%" }}>
                          <Row>
                            {/*(function(){
                          if(isSummaryChartDaily){
                            return(
                              <Button onClick={changeSummaryChart} color="primary" size="sm" type="button">
                                Monthly
                              </Button>
                            )
                          } else {
                            return(
                              <Button onClick={changeSummaryChart} color="primary" size="sm" type="button">
                                Daily
                              </Button>
                            )
                          }
                        })()*/}

                            {(function () {
                              if (isSummaryChartDaily) {
                                return (
                                  <Button
                                    onClick={prevMonthSummarySalesByDC}
                                    color="primary"
                                    size="sm"
                                    type="button"
                                  >
                                    Prev
                                  </Button>
                                );
                              }
                            })()}

                            {(function () {
                              if (
                                !noNextSummarySalesByDC &&
                                isSummaryChartDaily
                              ) {
                                return (
                                  <Button
                                    onClick={nextMonthSummarySalesByDC}
                                    color="primary"
                                    size="sm"
                                    type="button"
                                  >
                                    Next
                                  </Button>
                                );
                              }
                            })()}
                          </Row>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className="chart">
                          <Bar
                            data={this.state.summaryChartDataMonthlyByDC.data}
                            options={chartExample8.options}
                            className="chart-canvas"
                            id="chart-bar-stacked"
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}

              {this.state.listSelectedDC.indexOf("NASIONAL") === -1 ? (
                <Row>
                  <Col xl="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xl="10">
                            <h6 className="surtitle">
                              Overview -{" "}
                              {this.state.selectedRegion === ""
                                ? this.state.listSelectedDC.map((item, index) =>
                                  index ===
                                    this.state.listSelectedDC.length - 1
                                    ? item
                                    : item + ", "
                                )
                                : region}
                            </h6>
                            <h3 className="mb-0">
                              Customer Sales Recap -{" "}
                              {this.state.printedMonthTableSummarySales}{" "}
                              {this.state.printedYearTableSummarySales}
                            </h3>
                          </Col>
                          <Col xl="2">
                            <Button
                              color="primary"
                              style={{ float: "right" }}
                              onClick={() =>
                                this.setState({
                                  viewCustomerSalesRecap:
                                    !this.state.viewCustomerSalesRecap,
                                })
                              }
                            >
                              {this.state.viewCustomerSalesRecap ? (
                                <KeyboardArrowUpIcon
                                  style={{ color: "white" }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  style={{ color: "white" }}
                                />
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>

                      {this.state.viewCustomerSalesRecap ? (
                        <CardBody className="align-items-center">
                          <div style={{ marginLeft: "2%" }}>
                            <Row>
                              {(function () {
                                if (isSalesDownload) {
                                  return (
                                    <Button
                                      color="primary"
                                      size="sm"
                                      type="button"
                                    >
                                      <CSVLink
                                        data={rawSalesTableData}
                                        filename={salesFileName}
                                        style={{ color: "#FFFFFF" }}
                                      >
                                        Download
                                      </CSVLink>
                                    </Button>
                                  );
                                }
                              })()}
                              <Button
                                onClick={prevMonthTableSummarySales}
                                color="primary"
                                size="sm"
                                type="button"
                              >
                                Prev
                              </Button>

                              {(function () {
                                if (!noNextTableSummarySales) {
                                  return (
                                    <Button
                                      onClick={nextMonthTableSummarySales}
                                      color="primary"
                                      size="sm"
                                      type="button"
                                    >
                                      Next
                                    </Button>
                                  );
                                }
                              })()}
                            </Row>
                          </div>

                          {this.state.isCustomerSalesRecapLoading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          ) : null}
                          <ToolkitProvider
                            data={this.state.salesTableData}
                            keyField="name"
                            columns={columnsSales}
                            search
                          >
                            {(props) => (
                              <div className="py-4 table-responsive">
                                <div
                                  id="datatable-basic_filter"
                                  className="dataTables_filter px-4 pb-1"
                                >
                                  <label>
                                    Search:
                                    <SearchBar
                                      className="form-control-sm"
                                      placeholder=""
                                      {...props.searchProps}
                                    />
                                  </label>
                                </div>
                                <BootstrapTable
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  pagination={pagination}
                                  bordered={false}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      ) : null}
                    </Card>
                  </Col>

                  <Col xl="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xl="10">
                            <h6 className="surtitle">
                              Overview -{" "}
                              {this.state.selectedRegion === ""
                                ? this.state.listSelectedDC.map((item, index) =>
                                  index ===
                                    this.state.listSelectedDC.length - 1
                                    ? item
                                    : item + ", "
                                )
                                : region}
                            </h6>
                            <h3 className="mb-0">
                              Not Reporting Sell Out -{" "}
                              {this.state.printedMonthNotParticipatedSales}{" "}
                              {this.state.printedYearNotParticipatedSales}
                            </h3>
                          </Col>
                          <Col xl="2">
                            <Button
                              color="primary"
                              style={{ float: "right" }}
                              onClick={() =>
                                this.setState({
                                  viewNotReportingSales:
                                    !this.state.viewNotReportingSales,
                                })
                              }
                            >
                              {this.state.viewNotReportingSales ? (
                                <KeyboardArrowUpIcon
                                  style={{ color: "white" }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  style={{ color: "white" }}
                                />
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>

                      {this.state.viewNotReportingSales ? (
                        <CardBody>
                          <div style={{ marginLeft: "2%" }}>
                            <Row>
                              {this.state.listNotParticipatedSalesPartner
                                .length > 0 ? (
                                <Button color="primary" size="sm" type="button">
                                  <CSVLink
                                    data={
                                      this.state.listNotParticipatedSalesPartner
                                    }
                                    filename={notParticipatedSalesFileName}
                                    style={{ color: "#FFFFFF" }}
                                  >
                                    Download
                                  </CSVLink>
                                </Button>
                              ) : null}
                              <Button
                                onClick={prevMonthTableNotReportingSales}
                                color="primary"
                                size="sm"
                                type="button"
                              >
                                Prev
                              </Button>

                              {this.state.selectedMonthNotParticipatedSales !==
                                this.state.thisMonthInt ? (
                                <Button
                                  onClick={nextMonthTableNotReportingSales}
                                  color="primary"
                                  size="sm"
                                  type="button"
                                >
                                  Next
                                </Button>
                              ) : null}
                            </Row>
                          </div>
                          {this.state.isNotReportingSalesLoading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          ) : null}
                          <ToolkitProvider
                            data={this.state.listNotParticipatedSalesPartner}
                            keyField="id"
                            columns={columnTableNotParticipated}
                            search
                          >
                            {(props) => (
                              <div className="py-4 table-responsive">
                                <div
                                  id="datatable-basic_filter"
                                  className="dataTables_filter px-4 pb-1"
                                >
                                  <label>
                                    Search:
                                    <SearchBar
                                      className="form-control-sm"
                                      placeholder=""
                                      {...props.searchProps}
                                    />
                                  </label>
                                </div>
                                <BootstrapTable
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  pagination={pagination}
                                  bordered={false}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      ) : null}
                    </Card>
                  </Col>

                  <Col xl="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xl="10">
                            <h6 className="surtitle">
                              Overview -{" "}
                              {this.state.selectedRegion === ""
                                ? this.state.listSelectedDC.map((item, index) =>
                                  index ===
                                    this.state.listSelectedDC.length - 1
                                    ? item
                                    : item + ", "
                                )
                                : region}
                            </h6>
                            <h3 className="mb-0">
                              Customer Visitor Recap -{" "}
                              {this.state.printedMonthVisitorBuyer}{" "}
                              {this.state.printedYearVisitorBuyer}
                            </h3>
                          </Col>

                          <Col xl="2">
                            <Button
                              color="primary"
                              style={{ float: "right" }}
                              onClick={() =>
                                this.setState({
                                  viewCustomerVisitorRecap:
                                    !this.state.viewCustomerVisitorRecap,
                                })
                              }
                            >
                              {this.state.viewCustomerVisitorRecap ? (
                                <KeyboardArrowUpIcon
                                  style={{ color: "white" }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  style={{ color: "white" }}
                                />
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>

                      {this.state.viewCustomerVisitorRecap ? (
                        <CardBody>
                          <div style={{ marginLeft: "2%" }}>
                            <Row>
                              {this.state.rawVisitorBuyerData.length !== 0 ? (
                                <Button color="primary" size="sm" type="button">
                                  <CSVLink
                                    data={this.state.rawVisitorBuyerData}
                                    filename={visitorBuyerFileName}
                                    style={{ color: "#FFFFFF" }}
                                  >
                                    Download
                                  </CSVLink>
                                </Button>
                              ) : null}
                              <Button
                                onClick={this.prevMonthVisitorBuyer}
                                color="primary"
                                size="sm"
                                type="button"
                              >
                                Prev
                              </Button>

                              {this.state.selectedMonthVisitorBuyer !==
                                this.state.thisMonthInt ? (
                                <Button
                                  onClick={this.nextMonthVisitorBuyer}
                                  color="primary"
                                  size="sm"
                                  type="button"
                                >
                                  Next
                                </Button>
                              ) : null}
                            </Row>
                          </div>
                          {this.state.isCustomerVisitorRecapLoading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          ) : null}
                          <ToolkitProvider
                            data={this.state.visitorBuyerData}
                            keyField="name"
                            columns={columnsVisitorBuyerTable}
                            search
                          >
                            {(props) => (
                              <div className="py-4 table-responsive">
                                <div
                                  id="datatable-basic_filter"
                                  className="dataTables_filter px-4 pb-1"
                                >
                                  <label>
                                    Search:
                                    <SearchBar
                                      className="form-control-sm"
                                      placeholder=""
                                      {...props.searchProps}
                                    />
                                  </label>
                                </div>
                                <BootstrapTable
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  pagination={pagination}
                                  bordered={false}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      ) : null}
                    </Card>
                  </Col>

                  <Col xl="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xl="10">
                            <h6 className="surtitle">
                              Overview -{" "}
                              {this.state.selectedRegion === ""
                                ? this.state.listSelectedDC.map((item, index) =>
                                  index ===
                                    this.state.listSelectedDC.length - 1
                                    ? item
                                    : item + ", "
                                )
                                : region}
                            </h6>
                            <h3 className="mb-0">
                              Not Reporting Visitor Buyer -{" "}
                              {
                                this.state
                                  .printedMonthNotParticipatedVisitorBuyer
                              }{" "}
                              {
                                this.state
                                  .printedYearNotParticipatedVisitorBuyer
                              }
                            </h3>
                          </Col>
                          <Col xl="2">
                            <Button
                              color="primary"
                              style={{ float: "right" }}
                              onClick={() =>
                                this.setState({
                                  viewNotReportingVisitor:
                                    !this.state.viewNotReportingVisitor,
                                })
                              }
                            >
                              {this.state.viewNotReportingVisitor ? (
                                <KeyboardArrowUpIcon
                                  style={{ color: "white" }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  style={{ color: "white" }}
                                />
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>

                      {this.state.viewNotReportingVisitor ? (
                        <CardBody>
                          <div style={{ marginLeft: "2%" }}>
                            <Row>
                              {this.state.listNotParticipatedVisitorBuyerPartner
                                .length > 0 ? (
                                <Button color="primary" size="sm" type="button">
                                  <CSVLink
                                    data={
                                      this.state
                                        .listNotParticipatedVisitorBuyerPartner
                                    }
                                    filename={
                                      notParticipatedVisitorBuyerFileName
                                    }
                                    style={{ color: "#FFFFFF" }}
                                  >
                                    Download
                                  </CSVLink>
                                </Button>
                              ) : null}
                              <Button
                                onClick={prevMonthTableNotReportingVisitorBuyer}
                                color="primary"
                                size="sm"
                                type="button"
                              >
                                Prev
                              </Button>

                              {this.state
                                .selectedMonthNotParticipatedVisitorBuyer !==
                                this.state.thisMonthInt ? (
                                <Button
                                  onClick={
                                    nextMonthTableNotReportingVisitorBuyer
                                  }
                                  color="primary"
                                  size="sm"
                                  type="button"
                                >
                                  Next
                                </Button>
                              ) : null}
                            </Row>
                          </div>
                          {this.state.isNotReportingVisitorBuyerLoading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          ) : null}
                          <ToolkitProvider
                            data={
                              this.state.listNotParticipatedVisitorBuyerPartner
                            }
                            keyField="id"
                            columns={columnTableNotParticipated}
                            search
                          >
                            {(props) => (
                              <div className="py-4 table-responsive">
                                <div
                                  id="datatable-basic_filter"
                                  className="dataTables_filter px-4 pb-1"
                                >
                                  <label>
                                    Search:
                                    <SearchBar
                                      className="form-control-sm"
                                      placeholder=""
                                      {...props.searchProps}
                                    />
                                  </label>
                                </div>
                                <BootstrapTable
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  pagination={pagination}
                                  bordered={false}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      ) : null}
                    </Card>
                  </Col>
                </Row>
              ) : null}
            </>
          ) : (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="12" style={{ textAlign: "center" }}>
                        <h1>
                          Silahkan Pilih{" "}
                          {this.state.tabActive === "Region" ? "Area" : "DC"}{" "}
                          Terlebih Dahulu
                        </h1>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/*           
          <Row>
            <Col xl="12">
            {this.state.alert}
              <Row>
                <div className="col">
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0">BA SCOREBOARD {filterBy === '3' ? "filter by DC" : filterBy ===  '2' ? "filter by Campaign" : null}</h3>
                  <p className="text-sm mb-0">
                        This is an exmaple of data table using the well known
                        react-bootstrap-table2 plugin. This is a minimal setup in
                        order to get started fast.
                      </p>
                    </CardHeader>

                    

                    <ToolkitProvider
                      data={filterBy === '3' ? this.state.byDC : filterBy === '2' ? this.state.byCampaign : this.state.scoreboard}
                      keyField="name"
                      columns={filterBy === '3' ? columnByDC : filterBy === '2' ? columnByCampaign : columnElse}
                      search
                    >
                                        {props => (
                        <div className="py-4 table-responsive">
                          <Container fluid>
                            <Row>
                            <Col xs={12} sm={3}>
                                <div
                                  id="datatable-basic_filter"
                                  className="dataTables_filter pb-1"
                                >
                                  <label>
                                    Search:
                                    <SearchBar
                                      className="form-control-sm"
                                      placeholder=""
                                      {...props.searchProps}
                                    />
                                  </label>
                                </div>
                              </Col>

                              <Col xs={12} sm={6}>
                              <Form className="col-xl-4 col-md-6">
                                <Select2
                                  className="form-control-sm"
                                  defaultValue={this.state.filterBy}
                                  options={{
                                    placeholder: "Filter By"
                                  }}
                                  data={[
                                    {id:1, text:"BA"},
                                    {id:2, text:"Campaign"},
                                    {id:3, text:"DC"},
                                  ]}
                                  // update={value}
                                  onChange={this.toggleNavs}
                                />
                              </Form>
                              </Col>

                              <Col xs={12} sm={3}>
                              <div
                                  id="datatable-basic_filter"
                                  className="dataTables_filter px-4 pb-1 float-right"
                                >
                                <CSVLink 
                                      data = {filterBy === '3' ? this.state.byDC : filterBy === '2' ? this.state.byCampaign : this.state.scoreboard} 
                                      headers={headers_dc}
                                      filename = {'BA scoreboard filter by DC.csv'}>
                                                  <Button
                                                    style={{marginRight: "5px"}}
                                                    color="default"
                                                    size="sm"
                                                    className="buttons-copy buttons-html5"
                                                    id="print-tooltip"
                                                  >
                                                    Export to CSV
                                                  </Button>
                                      </CSVLink>
                                  <ButtonGroup>
                                  <Button
                                    className="buttons-copy buttons-html5"
                                    color="default"
                                    size="sm"
                                    id="copy-tooltip"
                                    onClick={() =>
                                      this.copyToClipboardAsTable(
                                        document.getElementById("react-bs-table")
                                      )
                                    }
                                  >
                                    <span>Copy</span>
                                  </Button>
                                </ButtonGroup>
                                <UncontrolledTooltip
                                  placement="top"
                                  target="print-tooltip"
                                >
                                  This will open a print page with the visible rows
                                  of the table.
                                </UncontrolledTooltip>
                                <UncontrolledTooltip
                                  placement="top"
                                  target="copy-tooltip"
                                >
                                  This will copy to your clipboard the visible rows
                                  of the table.
                                </UncontrolledTooltip>
                              </div>  
                              </Col>
                            </Row>
                          </Container>

                          <BootstrapTable
                            ref={el => (this.componentRef = el)}
                            {...props.baseProps}
                            bootstrap4={true}
                            pagination={pagination}
                            bordered={false}
                            id="react-bs-table"
                          />

                        </div>
                      )}
                    </ToolkitProvider>
                  </Card>
                </div>
              </Row>
            </Col>
          </Row> */}
        </Container>
      </>
    );
  }
}

export default Dashboard;

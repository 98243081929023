/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// react component used to create sweet alerts

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import LoadingProvider from "../../../components/ba-achievement/LoadingProvider";
import ReactWordcloud from "react-wordcloud";
import dateFormat from "dateformat";
import Axios from "api/axios";
import Cookies from "universal-cookie";

const OtherCompetitor = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [listCompetitor, setListCompetitor] = useState([]);
    const [listBestSeller, setListBestSeller] = useState([]);
    const [selectedMonthYear, setSelectedMonthYear] = useState({
      bestSeller: {},
      competitor: {}
    })
    const options = {
        colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
        enableTooltip: true,
        deterministic: false,
        fontFamily: "impact",
        fontSizes: [5, 60],
        fontStyle: "normal",
        fontWeight: "normal",
        padding: 1,
        rotations: 3,
        rotationAngles: [0,0],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 1000
    };

    const fetchCompetitor = async (year, month) => {
      try {
        setIsLoading(true);
        const cookies = new Cookies();
        const userToken = cookies.get("userToken");

        let { data } = await Axios({
          method: "GET",
          url: `/v_comp_competitor_other?year=${year}&month=${month}`,
          headers: { Authorization: `Bearer ${userToken}` },
        }).then((response) => {
          return response.data.data;
        });

        setListCompetitor(data);
        setIsLoading(false);
        const item = { ...selectedMonthYear };
        item.competitor.month = month;
        item.competitor.year = year;
        item.competitor.printedMonth = dateFormat(
          new Date(year, month - 1, 1),
          "mmmm"
        );

        setSelectedMonthYear(item);
      } catch (error) {
        setListCompetitor(listCompetitor);
        setIsLoading(false);
      }
    };

    const fetchBestSeller = async (year, month) => {
      try {
        setIsLoading(true);
        const cookies = new Cookies();
        const userToken = cookies.get("userToken");

        let { data } = await Axios({
          headers: { Authorization: `Bearer ${userToken}` },
          method: "GET",
          url: `v_comp_product_best_seller?year=${year}&month=${month}`,
        }).then((response) => {
          return response.data.data;
        });

        setListBestSeller(data);
        setIsLoading(false);
        const item = { ...selectedMonthYear };
        item.bestSeller.month = month;
        item.bestSeller.year = year;
        item.bestSeller.printedMonth = dateFormat(
          new Date(year, month - 1, 1),
          "mmmm"
        );

        setSelectedMonthYear(item);
      } catch (error) {
        setListBestSeller(listCompetitor);
        setIsLoading(false);
      }
    };

    const fetchAllData = async (year, month) => {
      await fetchCompetitor(year, month);
      await fetchBestSeller(year, month);

    }

    useEffect(() => {
        let currentDate = new Date()
        fetchAllData(currentDate.getFullYear(), currentDate.getMonth()+1)
    }, []);

    const prevMonth = (type) => {
      const temp = {...selectedMonthYear};
      let prevDate = new Date(temp[type].year, temp[type].month-2, 1)
      if (type === 'bestSeller') {
        fetchBestSeller(prevDate.getFullYear(), prevDate.getMonth()+1)
      } else {
        fetchCompetitor(prevDate.getFullYear(), prevDate.getMonth()+1)
      }
    }

    const nextMonth = (type) => {
      const temp = {...selectedMonthYear};
      let prevDate = new Date(temp[type].year, temp[type].month, 1)
      if (type === 'bestSeller') {
        fetchBestSeller(prevDate.getFullYear(), prevDate.getMonth()+1)
      } else {
        fetchCompetitor(prevDate.getFullYear(), prevDate.getMonth()+1)
      }
    }


    return (
      <>
        <SimpleHeader name="Wordcloud" parentName="Competitor Report" />
        <Container className="mt--6" fluid>
          <LoadingProvider isLoading={isLoading} />
          <Row>
            <Col xl={12}>
              <Card style={{ padding: "16px", marginBottom: "24px" }}>
                  <h1>Product Best Seller - {selectedMonthYear.bestSeller.printedMonth} {selectedMonthYear.bestSeller.year}</h1>
                  <Row>
                    <Col xl="4">
                      <Button onClick={() => prevMonth("bestSeller")} color="primary" size="sm" type="button" style={{width: "128px"}}>
                        Prev
                      </Button>
                      {new Date().getMonth() + 1 === selectedMonthYear.bestSeller.month ? null :
                        <Button onClick={() => nextMonth("bestSeller")} color="primary" size="sm" type="button" style={{width: "128px"}}>
                          Next
                        </Button> 
                      }
                    </Col>
                  </Row>                  
                  {listBestSeller.length > 0 ? (
                      <ReactWordcloud options={options} words={listBestSeller} />
                  ) : (
                      <div style={{ padding: "15vh", textAlign: "center"}}>
                          <h1>Belum ada data best seller</h1>
                      </div>
                  )}
              </Card>
              <Card style={{ padding: "16px" }}>
                  <h1>Other Competitor Brand - {selectedMonthYear.competitor.printedMonth} {selectedMonthYear.competitor.year}</h1>
                  <Row>
                    <Col xl="4">
                      <Button onClick={() => prevMonth("competitor")} color="primary" size="sm" type="button" style={{width: "128px"}}>
                        Prev
                      </Button>
                      {new Date().getMonth() + 1 === selectedMonthYear.competitor.month ? null :
                        <Button onClick={() => nextMonth("competitor")} color="primary" size="sm" type="button" style={{width: "128px"}}>
                          Next
                        </Button> 
                      }
                    </Col>
                  </Row>   
                  {listCompetitor.length > 0 ? (
                      <ReactWordcloud options={options} words={listCompetitor} />
                  ) : (
                      <div style={{ padding: "15vh", textAlign: "center"}}>
                          <h1>Belum ada data kompetitor</h1>
                      </div>
                  )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
}

export default OtherCompetitor;

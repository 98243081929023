import React, { useState } from "react";
import "./styles.scss";

const Switch = ({ tabList, onChange }) => {
  const [tabActive, setTabActive] = useState(tabList.left);

  const changeTabHandler = ({ type }) => {
    setTabActive(type);
    onChange(type);
  };

  return (
    <div className="switch-global__container">
      <div
        className={`${
          tabActive === tabList.left && "actived"
        } switch-item__container left`}
        onClick={() => changeTabHandler({ type: tabList.left })}
      >
        {tabList.left}
      </div>
      <div
        className={`${
          tabActive === tabList.right && "actived"
        } switch-item__container right`}
        onClick={() => changeTabHandler({ type: tabList.right })}
      >
        {tabList.right}
      </div>
    </div>
  );
};

export default Switch;

import React, { Component } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardHeader,
  CardBody,
  Badge,
} from "reactstrap";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Cookies from "universal-cookie";
import DatePicker from "react-multi-date-picker";

import FullrowCard from "../../../components/ba-achievement/FullrowCard";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import SnackbarAlert from "../../../components/ba-achievement/SnackbarAlert";
import InvalidYearMonthAlert from "../../../components/ba-achievement/InvalidYearMonthAlert";
import LoadingProvider from "../../../components/ba-achievement/LoadingProvider";

import fetchAllDCs from "../../../services/fetchAllDCs";
import fetchAllSalesArea from "../../../services/fetchAllAreaSales";
import fetchAllParameters from "../../../services/fetch/parameter/fetchAllParameters";
import fetchAllSchemes from "../../../services/fetchAllSchemes";
import fetchSchemeById from "../../../services/fetchSchemeById";
import fetchSchemeLineBySchemeId from "../../../services/fetchSchemeLineBySchemeId";

import toYearMonthString from "../../../helpers/toYearMonthString";
import { find } from "lodash";
import Axios from "services/axios";
import SalesAreaPicker from "../../../components/ba-achievement/SalesAreaPicker";
import PatchSchemeById from "../../../services/patch/patchSchemeById";

import postSchemeLinesUpdate from "../../../services/post/schemeLines/postSchemeLinesUpdate";


import DeleteSchemeLinesById from "../../../services/delete/deleteSchemeLinesById";


export default class SchemeEdit extends Component {
  state = {
    isLoading: false,
    fetchedDCs: null,
    fetchedParameters: null,
    fetchedSchemes: null,
    DCYearMonthsMap: null,
    invalidDCYearMonth: null,
    name: "",
    description: "",
    DCs: [],
    initialMonths: [],
    months: [],
    parameters: [],
    // initialParameters: [],
    alert: {
      isVisible: false,
      severity: "error",
      message: "",
    },
    user: null,
    schemaData: [],
    areasales: [],
    invalidAreaSalesYearMonth: null,
    areaSalesYearMonthsMap: [],
    salesareaAll: [],
  };

  getSelectedAreaList = async () => {
    try {
      let data = await fetchAllSalesArea()
      this.setState({
        salesareaAll: data
      })
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const cookies = new Cookies();
      await this.getSelectedAreaList();
      const fromCookie = cookies.get("userdashboard");
      this.setState({ user: fromCookie });

      const { id: schemeId } = this.props.match.params;

      const [fetchedDCs, fetchedParameters, fetchedSchemes, scheme] =
        await Promise.all([
          fetchAllDCs(),
          fetchAllParameters(),
          fetchAllSchemes(),
          fetchSchemeById(schemeId),
        ]);

      // populate dcs
      const { dc_id: dcIds, areasales_id } = scheme;
      let DCs = [];
      let areasalesTemp = [];
      if (!!dcIds) {
        DCs = fetchedDCs.filter((elem) => {
          return dcIds.indexOf(elem.partner_id) !== -1;
        });
      }

      //poplate areasales
      if (areasales_id) {
        areasales_id.forEach((item) => {
          const findAreaSales = find(this.state.salesareaAll, { id: item });
          if (findAreaSales) {
            areasalesTemp.push(findAreaSales);
          }
        });
      }
      // populate parameters
      const schemeLines = await fetchSchemeLineBySchemeId(schemeId);
      const parameterIds = schemeLines.map((elem) => elem.parameter_id);
      const initialParameters = fetchedParameters.filter((elem) => {
        return parameterIds.indexOf(elem.id) !== -1;
      });

      // populate months
      const initialMonths = scheme.year_month;

      this.setState({
        name: scheme.name,
        description: scheme.description,
        DCs,
        months: initialMonths,
        initialMonths,
        fetchedDCs,
        fetchedParameters,
        fetchedSchemes,
        parameters: initialParameters,
        isLoading: false,
        areasales: areasalesTemp,
      });

      if (!!areasalesTemp) {
        this.mapAreaSalesYearMonths();
      }

      if (!!dcIds) {
        this.mapDCYearMonths();
      }
    } catch (err) {
      console.log(err);
      this.fireAlert(
        "error",
        "Terjadi kesalahan, periksa kembali koneksi anda!"
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.DCs !== this.state.DCs ||
      prevState.months !== this.state.months ||
      prevState.areasales !== this.state.areasales
    ) {
      if (!this.state.areaSalesYearMonthsMap) return;

      if (this.state.areasales.length) {
        this.validateAreaSalesYearMonths();
        return;
      }
      if (!this.state.DCYearMonthsMap) return;

      if (!this.state.DCs || !this.state.months) {
        this.setState({ invalidDCYearMonth: null });
        return;
      }

      if (this.state.DCs.length) {
        this.validateDCYearMonths();
        return;
      }
    }
  }

  // map DCs and YearMonths to validate existing scheme for specific DC and yearMonth
  mapDCYearMonths = () => {
    const { fetchedSchemes: schemes } = this.state;
    if (schemes) {
      const map = schemes.reduce((result, scheme) => {
        const { dc_id: DCIds, year_month: yearMonths } = scheme;
        if (!!DCIds) {
          DCIds.forEach((id) => {
            if (!result[id]) result[id] = [];
            yearMonths.forEach((yearMonth) => {
              result[id] = [...result[id], yearMonth].sort();
            });
          });
        }

        return result;
      }, {});

      this.setState({ DCYearMonthsMap: map });
    }
  };

  // map areasales and YearMonths to validate existing scheme for specific areasales and yearMonth
  mapAreaSalesYearMonths = () => {
    const { fetchedSchemes: schemes } = this.state;
    if (schemes) {
      const map = schemes.reduce((result, scheme) => {
        const { areasales_id, year_month: yearMonths } = scheme;

        if (!!areasales_id && areasales_id.length) {
          areasales_id.forEach((id) => {
            if (!result[id]) result[id] = [];
            yearMonths.forEach((yearMonth) => {
              result[id] = [...result[id], yearMonth].sort();
            });
          });
        }

        return result;
      }, {});
      this.setState({ areaSalesYearMonthsMap: map });
    }
  };

  validateAreaSalesYearMonths() {
    let isValid = true;
    const invalidAreaSalesYearMonth = {};
    const { areasales, months, areaSalesYearMonthsMap, initialMonths } =
      this.state;
    // const areaSales = areaSales.map((item) => item);
    for (let i = 0; i < areasales.length; i++) {
      const id = areasales[i].id;
      const areaSalesYearMonths = areaSalesYearMonthsMap[id];
      if (areaSalesYearMonths) {
        for (const month of areaSalesYearMonths) {
          if (months.indexOf(month) !== -1) {
            isValid = false;

            invalidAreaSalesYearMonth[id]
              ? (invalidAreaSalesYearMonth[id] = [
                  ...invalidAreaSalesYearMonth[id],
                  month,
                ].sort())
              : (invalidAreaSalesYearMonth[id] = [month].sort());
          }
        }
      }
    }

    if (!isValid) {
      // bypass validasi jika bulan2 sebelumnya untuk areasales tertentu dipilih lagi
      for (const j in invalidAreaSalesYearMonth) {
        invalidAreaSalesYearMonth[j] = invalidAreaSalesYearMonth[j].filter(
          (elem) => {
            return initialMonths.indexOf(elem) === -1;
          }
        );
      }
      const result = {};
      const keys = Object.keys(invalidAreaSalesYearMonth);
      keys.forEach((key) => {
        if (invalidAreaSalesYearMonth[key].length) {
          result[key] = Array.from(new Set(invalidAreaSalesYearMonth[key]));
        }
      });

      this.setState({
        invalidAreaSalesYearMonth: Object.keys(result).length ? result : null,
      });
      return;
    }

    this.setState({ invalidAreaSalesYearMonth: null });
  }

  validateDCYearMonths() {
    let isValid = true;
    const invalidDCYearMonth = {};
    const { DCs, months, DCYearMonthsMap, initialMonths } = this.state;
    const dc_ids = DCs.map((DC) => DC.partner_id);

    for (let i = 0; i < dc_ids.length; i++) {
      const id = dc_ids[i];
      const dcYearMonths = DCYearMonthsMap[id];

      if (dcYearMonths) {
        for (const month of dcYearMonths) {
          if (months.indexOf(month) !== -1) {
            isValid = false;

            invalidDCYearMonth[id]
              ? (invalidDCYearMonth[id] = [
                  ...invalidDCYearMonth[id],
                  month,
                ].sort())
              : (invalidDCYearMonth[id] = [month].sort());
          }
        }
      }
    }

    if (!isValid) {
      // bypass validasi jika bulan2 sebelumnya untuk dc tertentu dipilih lagi
      for (const i in invalidDCYearMonth) {
        invalidDCYearMonth[i] = invalidDCYearMonth[i].filter((elem) => {
          return initialMonths.indexOf(elem) === -1;
        });
      }

      const result = {};
      const keys = Object.keys(invalidDCYearMonth);
      keys.forEach((key) => {
        if (invalidDCYearMonth[key].length) {
          result[key] = Array.from(new Set(invalidDCYearMonth[key]));
        }
      });

      this.setState({
        invalidDCYearMonth: Object.keys(result).length ? result : null,
      });

      return;
    }

    this.setState({ invalidDCYearMonth: null });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    // validate already created DC/yearMonth scheme combination
    if (this.state.invalidDCYearMonth && this.state.DCs.length) {
      this.fireAlert(
        "error",
        "Skema untuk DC dan bulan yang sama sudah pernah dibuat!"
      );
      return;
    }

    // validate already created areasales/yearMonth scheme combination
    if (this.state.invalidAreaSalesYearMonth && this.state.areasales.length) {
      this.fireAlert(
        "error",
        "Skema untuk Sales Area dan bulan yang sama sudah pernah dibuat!"
      );
      return;
    }

    const { id: schemeId } = this.props.match.params;
    const {
      name,
      description,
      DCs,
      parameters,
      months: year_month,
      user,
      areasales,
    } = this.state;

    // validation: empty input
    if (
      !name ||
      !description ||
      (!DCs && !areasales) ||
      (!DCs.length && !areasales.length) ||
      !parameters ||
      !parameters.length ||
      !year_month ||
      !year_month.length
    ) {
      this.fireAlert("error", "Pastikan semua field telah terisi!");
      return;
    }

    const user_id = user.ba_id ? user.ba_id : user.id;
    const date_created = new Date();
    const is_active = true;
    const is_submitted = true;

    try {
      this.setState({ isLoading: true });
      // PATCH scheme
      let payloadPatch = {
        user_id,
        date_created,
        name,
        description,
        dc_id: DCs.length ? DCs.map((dc) => dc.partner_id) : [],
        year_month,
        areasales_id: areasales.length
          ? areasales.map((item) => item.id)
          : [],
      }

      await PatchSchemeById(schemeId, payloadPatch)
  
      // DELETE PREVIOUS SCHEME LINE
      await DeleteSchemeLinesById(schemeId)

      // POST UPDATED SCHEME LINE
      let payloadPost = this.state.parameters.map((parameter) => {
        return {
          user_id,
          date_created,
          scheme_id: schemeId,
          parameter_id: parameter.id,
          is_active,
          is_submitted,
        };
      });
      await postSchemeLinesUpdate(payloadPost)

      this.fireAlert("success", "Skema berhasil di-update!");
      this.mapDCYearMonths();
    } catch (err) {
      console.log(err)
      this.fireAlert(
        "error",
        "Terjadi kesalahan, periksa kembali koneksi anda!"
      );
    } finally {
      this.setState({ isLoading: false });
    }
  };

  // open alert
  fireAlert = (severity, message) => {
    this.setState({
      alert: {
        isVisible: true,
        severity,
        message,
      },
    });
  };

  // close alert
  closeAlert = () => {
    this.setState({
      alert: {
        isVisible: false,
        severity: "error",
        message: "",
      },
    });
  };

  renderInvalidAreaSalesYearMonth() {
    const { invalidAreaSalesYearMonth, salesareaAll } = this.state;
    const invalidAreaSalesIds = Object.keys(invalidAreaSalesYearMonth).map(
      (key) => +key
    );

    const checkedArea = salesareaAll.filter(
      (area) => invalidAreaSalesIds.indexOf(area.id) !== -1
    );

    return checkedArea.map((item) => {
      const yearMonths = invalidAreaSalesYearMonth[item.id];

      return (
        <Col xs={2}>
          <h4 className="mb-3">{item.value}</h4>
          {yearMonths.map((month) => {
            return <div className="my-1">{toYearMonthString(month)}</div>;
          })}
        </Col>
      );
    });
  }
  renderInvalidDCYearMonth() {
    const { invalidDCYearMonth, fetchedDCs } = this.state;
    const invalidDCIds = Object.keys(invalidDCYearMonth).map((key) => +key);

    const invalidDCs = fetchedDCs.filter(
      (DC) => invalidDCIds.indexOf(DC.partner_id) !== -1
    );

    return invalidDCs.map((DC) => {
      const DC_NAME = DC.partner_name;
      const yearMonths = invalidDCYearMonth[DC.partner_id.toString()];

      return (
        <Col xs={2}>
          <h4 className="mb-3">{DC_NAME}</h4>
          {yearMonths.map((month) => {
            return <div className="my-1">{toYearMonthString(month)}</div>;
          })}
        </Col>
      );
    });
  }

  handleChangeDC = (event, value) => {
    if (!value.length) {
      this.setState({
        invalidDCYearMonth: null,
      });
    }

    this.setState({
      DCs: value.sort((a, b) => {
        return a.partner_name > b.partner_name ? 1 : -1;
      }),
    });
  };

  handleChangeAreaSales = (_, value) => {
    if (!value.length) {
      this.setState({
        invalidAreaSalesYearMonth: null,
      });
    }

    this.setState({
      areasales: value.sort((a, b) => {
        return a > b ? 1 : -1;
      }),
    });
  };

  handleChangeParameter = (event, value) => {
    this.setState({
      parameters: value,
    });
  };

  handleChangeMonth = (value) => {
    const valueString = value.toString();
    this.setState({
      months: !valueString ? [] : valueString.split(",").sort(),
    });
  };

  render() {
    return (
      <>
        <SimpleHeader name="Edit Schema" parentName="BA Achievement" />

        <SnackbarAlert
          isVisible={this.state.alert.isVisible}
          severity={this.state.alert.severity}
          message={this.state.alert.message}
          closeEvent={() => this.closeAlert()}
        />

        <Container className="mt--6" fluid>
          <LoadingProvider isLoading={this.state.isLoading}>
            <FullrowCard>
              <CardHeader>
                <h2>Edit Skema</h2>
              </CardHeader>
              <CardBody>
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label>
                      <h3>Nama Skema</h3>
                    </Label>
                    <Input
                      style={{ border: "0.1rem solid #c4c4c4" }}
                      type="text"
                      placeholder="Nama skema"
                      value={this.state.name}
                      onChange={(event) =>
                        this.setState({ name: event.target.value })
                      }
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>
                      <h3>Deskripsi Skema</h3>
                    </Label>
                    <Input
                      style={{ border: "0.1rem solid #c4c4c4" }}
                      type="textarea"
                      placeholder="Deskripsi skema"
                      value={this.state.description}
                      onChange={(event) =>
                        this.setState({ description: event.target.value })
                      }
                    />
                  </FormGroup>

                  {this.state.DCs.length > 0 && (
                    <FormGroup>
                      <Label>
                        <h3>DC Aktif</h3>
                      </Label>
                      <Autocomplete
                        multiple={true}
                        freeSolo
                        value={this.state.DCs}
                        options={this.state.fetchedDCs || []}
                        getOptionLabel={(option) => option.partner_name}
                        onChange={this.handleChangeDC}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Pilih DC"
                          />
                        )}
                      />
                    </FormGroup>
                  )}

                  {this.state.areasales && this.state.areasales.length > 0 && (
                    <FormGroup>
                      <Label>
                        <h3>Area Sales Aktif</h3>
                      </Label>
                      <Autocomplete
                        multiple={true}
                        freeSolo
                        value={this.state.areasales}
                        options={this.state.salesareaAll}
                        getOptionLabel={(option) => option.value}
                        onChange={this.handleChangeAreaSales}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Pilih Area Sales"
                          />
                        )}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label>
                      <h3>Bulan Aktif</h3>
                    </Label>
                    <Row>
                      <Col xs={2}>
                        <div>
                          <DatePicker
                            format="YYYY-MM"
                            multiple
                            onlyMonthPicker
                            onChange={this.handleChangeMonth}
                            render={(value, openCalendar) => {
                              return (
                                <Button
                                  type="button"
                                  color={
                                    value.toString() === ""
                                      ? "light"
                                      : "success"
                                  }
                                  onClick={() => openCalendar()}
                                >
                                  Pilih Bulan
                                </Button>
                              );
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={4}>
                        <h4>dari:</h4>
                        {this.state.initialMonths.map((month) => {
                          return (
                            <>
                              <h2 className="d-inline-block mx-1">
                                <Badge color="primary">
                                  {toYearMonthString(month)}
                                </Badge>
                              </h2>
                            </>
                          );
                        })}
                      </Col>
                      <Col xs={6}>
                        <h4>menjadi:</h4>
                        {this.state.months.map((month) => {
                          return (
                            <>
                              <h2 className="d-inline-block mx-1">
                                <Badge color="primary">
                                  {toYearMonthString(month)}
                                </Badge>
                              </h2>
                            </>
                          );
                        })}
                      </Col>
                    </Row>
                  </FormGroup>

                  {this.state.invalidDCYearMonth ? (
                    <div>
                      <InvalidYearMonthAlert>
                        <Row>{this.renderInvalidDCYearMonth()}</Row>
                      </InvalidYearMonthAlert>
                    </div>
                  ) : null}

                  {this.state.invalidAreaSalesYearMonth && (
                    <div>
                      <InvalidYearMonthAlert isSalesArea={true}>
                        <Row>{this.renderInvalidAreaSalesYearMonth()}</Row>
                      </InvalidYearMonthAlert>
                    </div>
                  )}

                  <FormGroup>
                    <Label>
                      <h3>Parameter Aktif</h3>
                    </Label>
                    <Autocomplete
                      multiple={true}
                      freeSolo
                      options={this.state.fetchedParameters}
                      getOptionLabel={(parameter) => parameter.name}
                      value={this.state.parameters}
                      onChange={this.handleChangeParameter}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Pilih Parameter"
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="w-100"
                      disabled={this.invalidDCYearMonth ? true : false}
                    >
                      Submit
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </FullrowCard>
          </LoadingProvider>
        </Container>
      </>
    );
  }
}

import React, { useState, useEffect } from 'react'
import { Container, CardHeader, CardBody, Button, FormGroup, Label, Input, Row, Col } from 'reactstrap'
import Alert from '@material-ui/lab/Alert'
import { Typography } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'

import SimpleHeader from '../../../components/Headers/SimpleHeader'
import FullrowCard from '../../../components/ba-achievement/FullrowCard'
import SnackbarAlert from '../../../components/ba-achievement/SnackbarAlert'
import LoadingProvider from '../../../components/ba-achievement/LoadingProvider'
import TargetTracker from '../../../components/ba-achievement/TargetTracker'
import AccordionAlert from '../../../components/ba-achievement/AccordionAlert'

import DisplayDetailRow from '../../../components/ba-achievement/upload-target/DisplayDetailRow'
import AvgBasketSizeInputCard from '../../../components/ba-achievement/upload-target/AvgBasketSizeInputCard'
import FormGroupUploadTarget from '../../../components/ba-achievement/upload-target/FormGroupUploadTarget'
import TotalVisitorInputCard from '../../../components/ba-achievement/upload-target/TotalVisitorInputCard'

import useUploadTargetInput from '../../../hooks/useUploadTargetInput'
import useParseXls from '../../../hooks/useParseXls'
import useParseTarget from '../../../hooks/useParseTarget'
import usePostTarget from '../../../hooks/usePostTarget'

import fetchTargetTracker from '../../../services/fetchTargetTracker'
import fetchTargetTrackerBySalesArea from '../../../services/fetchTargetTrackerBySalesArea'

import toYearMonthString from '../../../helpers/toYearMonthString'

import './style.scss'
import fetchAllBrand from '../../../services/fetchAllBrand'

export default function UploadTarget() {
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingUpload, setIsLoadingUpload] = useState(false)
	const [templateDetail, setTemplateDetail] = useState(null)
	const [displayDetail, setDisplayDetail] = useState(null)
	const [sheetData, setSheetData] = useState(null)
	const [sheetError, setSheetError] = useState(null)
	const [uploadedYearMonthDCs, setUploadedYearMonthDCs] = useState(null)

	const [alert, setAlert] = useState({
		isVisible: false,
		severity: 'error',
		message: '',
	})

	const resetState = () => {
		setDisplayDetail(null)
		setTemplateDetail(null)
		setSheetData(null)
		setSheetError(null)
	}

	const { parseTargetPersonal, parseTargetBrand, parseTargetGlobal } = useParseTarget()

	const { postTargetPersonal, postTargetBrand, postTargetGlobal } = usePostTarget()

	// }

	const {
		parameterInput,
		setParameterInput,
		configurableAttributes,
		setConfigurableAttributes,
		totalVisitor,
		setTotalVisitor,
		brands,
		conversionRate,
		setConversionRate,
		clearInput,
	} = useUploadTargetInput()

	// set display detail
	useEffect(() => {
		if (templateDetail) {
			const mounted = async () => {
				const { scheme, parameters, DCs, yearMonth, schemeSalesArea } = templateDetail

				let obj = {}

				if (schemeSalesArea.length) {
					obj = {
						yearMonth: toYearMonthString(yearMonth),
						salesarea: schemeSalesArea.map(DC => DC.value),
						parameters: parameters.map(parameter => parameter.name),
						scheme: {
							name: scheme.name,
							description: scheme.description,
						},
					}
				} else {
					obj = {
						yearMonth: toYearMonthString(yearMonth),
						DCs: DCs.map(DC => DC.partner_name),
						parameters: parameters.map(parameter => parameter.name),
						scheme: {
							name: scheme.name,
							description: scheme.description,
						},
					}
				}

				setDisplayDetail(obj)

				// TRACKER
				let targetTracker = ''

				if (schemeSalesArea && schemeSalesArea.length) {
					targetTracker = await fetchTargetTrackerBySalesArea({
						scheme,
					})
				} else {
					targetTracker = await fetchTargetTracker()
				}

				const reducedTargetTracker = targetTracker.reduce((result, target) => {
					const { year_month, dc_id, is_target_submitted, salesarea_id } = target
					if (is_target_submitted) {
						result = [...result, `${year_month}--${salesarea_id || salesarea_id >= 0 ? salesarea_id : dc_id}`]
					}

					return result
				}, [])
				setUploadedYearMonthDCs(reducedTargetTracker)
			}

			mounted()
		}
	}, [templateDetail])

	useEffect(() => {
		if (!brands.length) return
		const tempTotalVisitor = { ...totalVisitor }
		brands.forEach(brand => {
			const caConversionRate = configurableAttributes[brand]['caConversionRate']
			const caAvgBasketSize = configurableAttributes[brand]['caAvgBasketSize']
			if (caConversionRate && caAvgBasketSize) {
				tempTotalVisitor[brand] = +caConversionRate * +caAvgBasketSize * 0.01
			}
		})

		setTotalVisitor(tempTotalVisitor)
	}, [configurableAttributes])

	//handle input changes
	const { handleFileChange } = useParseXls({
		setTemplateDetail,
		setSheetData,
		setSheetError,
		resetState,
		setIsLoading,
	})

	const handleParameterInputChange = event => {
		const { name, value } = event.target
		setParameterInput(prev => {
			return {
				...prev,
				[name]: value,
			}
		})
	}

	const handleConfigurableAttributeChange = (event, type) => {
		const { name, value } = event.target
		const temp = { ...configurableAttributes }
		temp[name][type] = value
		setConfigurableAttributes(temp)
	}

	// handle upload
	const handleUpload = async () => {
		try {
			// handle error if there are sheet errors,
			// allow if cell value is null, prohib if cell value is invalid (not a number or <= 0)
			console.log(sheetError)
			if (sheetError) {
				let isValid = true

				if (sheetError.name === 'invalidRowValue') {
					for (let i = 0; i < sheetError.errors.length; i++) {
						const { errorMessage } = sheetError.errors[i]
						if (
							errorMessage === 'cell memiliki nilai tidak valid (bukan angka)' ||
							errorMessage === 'cell memiliki nilai yang tidak valid (angka nol atau negatif)'
						) {
							isValid = false
						}
					}
				}

				if (!isValid) {
					fireAlert('error', 'Pastikan data spreadsheet sudah benar! (Tidak boleh ada nilai selain angka atau angka nol dan negatif)')
					return
				}
			}
			setIsLoadingUpload(true)
			const { scheme, parameters, DCs, yearMonth, schemeSalesArea } = templateDetail
			const target = {
				personal: [],
				brand: [],
				global: [],
			}
			const fetchBrands = await fetchAllBrand()
			const brandNames = fetchBrands.filter(brand => brand.name !== 'All').map(brand => brand.name)

			parameters.forEach(parameter => {
				switch (parameter.name) {
					case 'Conversion Rate':
						target.global = [
							...target.global,
							...parseTargetGlobal(conversionRate, {
								parameter,
								DCs,
								yearMonth,
								schemeSalesArea,
							}),
						]
						break
					case 'Sell In':
						target.personal = [
							...target.personal,
							...parseTargetPersonal(sheetData, {
								parameter,
								DCs,
								yearMonth,
								schemeSalesArea,
							}),
						]
						break
					case 'Sell Out':
						target.personal = [
							...target.personal,
							...parseTargetPersonal(sheetData, {
								parameter,
								DCs,
								yearMonth,
								schemeSalesArea,
							}),
						]
						break
					case 'Average Basket Size':
						const targetAvgBasketSize = {}
						brands.forEach(brand => {
							targetAvgBasketSize[brand] = configurableAttributes[brand].caAvgBasketSize
						})

						target.brand = [
							...target.brand,
							...parseTargetBrand(
								targetAvgBasketSize,
								{
									parameter,
									DCs,
									yearMonth,
									schemeSalesArea,
								},
								brandNames
							),
						]
						break
					case 'Total Visitor':
						const targetTotalVisitor = {}
						brands.forEach(brand => {
							targetTotalVisitor[brand] = totalVisitor[brand]
						})

						target.brand = [
							...target.brand,
							...parseTargetBrand(
								targetTotalVisitor,
								{
									parameter,
									DCs,
									yearMonth,
									schemeSalesArea,
								},
								brandNames
							),
						]
						break
					default:
						return
				}
			})

			await Promise.all([postTargetPersonal(target.personal), postTargetBrand(target.brand), postTargetGlobal(target.global)])

			// re-fetch TRACKER
			let targetTracker = []

			if (schemeSalesArea && schemeSalesArea.length) {
				targetTracker = await fetchTargetTrackerBySalesArea({
					scheme,
				})
			} else {
				targetTracker = await fetchTargetTracker()
			}

			const reducedTargetTracker = targetTracker.reduce((result, target) => {
				const { year_month, dc_id, is_target_submitted, salesarea_id } = target

				if (is_target_submitted) {
					result = [...result, `${year_month}--${salesarea_id || salesarea_id >= 0 ? salesarea_id : dc_id}`]
				}

				return result
			}, [])

			setUploadedYearMonthDCs(reducedTargetTracker)

			resetState()
			clearInput()

			fireAlert('success', 'Upload target berhasil!')
		} catch (err) {
			console.log(err)
			const errorMessage = {
				emptyFields: 'Pastikan semua field telah terisi!',
				notANumber: 'Pastikan input semua field adalah angka valid!',
			}
			fireAlert('error', errorMessage[err.message])
		} finally {
			setIsLoadingUpload(false)
		}
	}

	// open alert
	const fireAlert = (severity, message) => {
		setAlert({
			isVisible: true,
			severity,
			message,
		})
	}

	// close alert
	const closeAlert = () => {
		setAlert({
			isVisible: false,
			severity: 'error',
			message: '',
		})
	}

	const parameterList = templateDetail ? templateDetail.parameters.map(elem => elem.name) : []

	return (
		<>
			<SimpleHeader name="Upload Target" parentName="BA Achievement" />

			<SnackbarAlert isVisible={alert.isVisible} severity={alert.severity} message={alert.message} closeEvent={() => closeAlert()} />

			<Container className="mt--6" fluid>
				<LoadingProvider isLoading={isLoadingUpload}>
					<FullrowCard>
						<CardHeader>
							<h2>Upload Target</h2>
						</CardHeader>
						<CardBody>
							<FormGroup>
								<Label>
									<h3>Silahkan Pilih File Template</h3>
								</Label>
								<Input type="file" onChange={handleFileChange} />
							</FormGroup>

							{/* file verification loading */}
							{isLoading && <CircularProgress color="inherit" />}

							{sheetError && (
								<Row>
									<Col xs={12}>
										<AccordionAlert sheetError={sheetError} />
									</Col>
								</Row>
							)}

							{displayDetail ? (
								<>
									<Row className="my-3">
										<Col xs={12}>
											<Alert>
												<Typography variant="body1">Spreadsheet telah berhasil tervalidasi!</Typography>
											</Alert>
										</Col>
									</Row>

									<Row>
										<Col xs={12} className="mb-3">
											<h3>Informasi File Template</h3>
										</Col>
									</Row>

									<DisplayDetailRow title="Nama Skema" value={displayDetail.scheme.name} />
									<DisplayDetailRow title="Deskripsi Skema" value={displayDetail.scheme.description} />
									<DisplayDetailRow title="Parameter" value={displayDetail.parameters.join(', ')} />
									<DisplayDetailRow
										title={displayDetail.salesarea && displayDetail.salesarea.length ? 'Area Sales' : 'DC'}
										value={
											displayDetail.salesarea && displayDetail.salesarea.length ? displayDetail.salesarea.join(', ') : displayDetail.DCs.join(', ')
										}
									/>
									<DisplayDetailRow title="Bulan" value={displayDetail.yearMonth} />
									{uploadedYearMonthDCs && (
										<Row>
											<Col xs={12}>
												<TargetTracker
													yearMonths={templateDetail.scheme.year_month}
													arr={templateDetail.schemeSalesArea.length ? templateDetail.schemeSalesArea : templateDetail.schemeDCs}
													uploadedYearMonthDCs={uploadedYearMonthDCs}
													isAreaSales={templateDetail.schemeSalesArea.length}
												/>
											</Col>
										</Row>
									)}
								</>
							) : null}
						</CardBody>
					</FullrowCard>

					{templateDetail && displayDetail ? (
						<>
							{parameterList.indexOf('Conversion Rate') !== -1 && (
								<FullrowCard>
									<CardHeader>
										<h2>Parameter: Conversion Rate</h2>
									</CardHeader>
									<CardBody>
										<Row>
											<Col xs={12} sm={6}>
												<FormGroupUploadTarget
													label="Conversion Rate (%)"
													placeholder="target conversion rate"
													name="conversionRate"
													value={conversionRate}
													handleChange={e => setConversionRate(e.target.value)}
												/>
											</Col>
											<Col
												xs={12}
												sm={6}
												style={{
													display: 'flex',
													alignItems: 'flex-end',
													paddingBottom: '1rem',
												}}
											>
												<h5>Conversion Rate: {conversionRate || 0} %</h5>
											</Col>
										</Row>
									</CardBody>
								</FullrowCard>
							)}

							{parameterList.indexOf('Average Basket Size') !== -1 && (
								<FullrowCard>
									<CardHeader>
										<h2>Parameter: Average Basket Size</h2>
									</CardHeader>
									<CardBody>
										{brands.map(brand => (
											<AvgBasketSizeInputCard
												key={brand}
												label={brand}
												name={brand}
												value={parameterInput[brand]}
												handleChange={handleParameterInputChange}
											/>
										))}
									</CardBody>
								</FullrowCard>
							)}

							{parameterList.indexOf('Total Visitor') !== -1 && (
								<FullrowCard>
									<CardHeader>
										<h2>Parameter: Pencapaian Total Visitor</h2>
									</CardHeader>
									<CardBody>
										{brands.map(brand => (
											<TotalVisitorInputCard
												key={brand}
												brandLabel={brand}
												value={configurableAttributes[brand]}
												handleConfigurableAttributeChange={handleConfigurableAttributeChange}
												totalVisitorValue={totalVisitor['brand']}
											/>
										))}
									</CardBody>
								</FullrowCard>
							)}

							<FullrowCard>
								<Button color="primary" onClick={handleUpload}>
									Upload Target
								</Button>
							</FullrowCard>
						</>
					) : null}
				</LoadingProvider>
			</Container>
		</>
	)
}

/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Container,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import CompetitorForms from "./component/CompetitorForms";

const RegisterCompetitor = () => {
    return (
      <>
        <AuthHeader
          title="Register Competitor"
        />
        <Container className="mt--8 pb-5">
          <CompetitorForms />
        </Container>
      </>
    );
}

export default RegisterCompetitor;

/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)

import ReactDatetime from "react-datetime";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import Cookies from "universal-cookie";
import HeatMap from "react-heatmap-grid";
import queryString from "querystring";
import moment from "moment";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import AWS from "aws-sdk";

// core components
import CardsHeaderBA from "components/Headers/CardsHeaderBA.jsx";
import BANotSelected from "../examples/BANotSelected.jsx";
import { CSVLink } from "react-csv";
import axios from "api/axios.js";
import { uniqWith } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { chartOptions, parseOptions } from "variables/charts.jsx";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const S3_REGION = process.env.REACT_APP_S3_REGION;
const S3_ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY;
const S3_SECRET_KEY = process.env.REACT_APP_S3_SECRET_KEY;
const REGION = S3_REGION;

AWS.config.update({
  accessKeyId: S3_ACCESS_KEY,
  secretAccessKey: S3_SECRET_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const paginationTanggal = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 31,
  sizePerPageRenderer: () => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>Show {31} entries.</label>
    </div>
  ),
});

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const sortedIndexDate = (arr) => {
  return arr.sort((a, b) => (a.write_date < b.write_date ? 1 : -1));
};

const sortedIndexBy = (arr) => {
  return arr.sort((a, b) => (a.id < b.id ? 1 : -1));
};

class DashboardBA extends React.Component {
  state = {
    activeNav: 1,
    chartBASales: "data1",
    selectedBA: {},
    presenceDetails: [],
    presenceEndDetails: [],
    listPresenceAwal: [],
    listMonthlySales: [],
    listDailySales: [],
    listMostSoldSKU: [],
    listMostSoldSKUQty: [],
    skipday: 0,
    listTanggal: [],
    listTanggalHeatmap: [],
    target: 0,
    storeTotalSales: 0,
    storeSales: 0,
    todayTarget: 0,
    currentMonth: null,
    currentMonthPresenceDetails: null,
    currentYearPresenceDetails: null,
    selectedMonthPresenceDetails: null,
    selectedYearPresenceDetails: null,
    selectedMonthPresenceHeatmap: null,
    selectedYearPresenceHeatmap: null,
    selectedMonthSalesDetails: null,
    selectedYearSalesDetails: null,
    selectedDaySalesDetails: null,
    printedMonthSalesDetails: null,
    printedYearSalesDetails: null,
    printedMonthPresenceDetails: null,
    printedYearPresenceDetails: null,
    printedMonthPresenceHeatmap: null,
    noNextPresenceDetails: true,
    noNextPresenceHeatmap: true,
    selectedPhotoBA: [{}, {}, {}],
    salesDetails: [],
    salesDetailsDaily: [],
    isMonthly: true,
    columnsDaily: [],
    columnsMonthly: [],
    salesColumn: [],
  };

  constructor() {
    super();
    this.reloadPresenceDetails = this.reloadPresenceDetails.bind(this);
    this.reloadPresenceHeatmap = this.reloadPresenceHeatmap.bind(this);
    this.reloadAllData = this.reloadAllData.bind(this);
  }

  componentDidMount() { }

  getImageUrl = async (key) => {
    if (key && key.includes("aws")) {
      const getParams = {
        Bucket: S3_BUCKET,
        Key: key,
      };

      return await new Promise((resolve, reject) => {
        myBucket.getSignedUrl("getObject", getParams, (err, _url) => {
          if (err) return reject(err);
          return resolve(_url);
        });
      });
    }
    return key;
  };

  dataFormattedHandler = async ({ data }) => {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      console.log(element);

      arr.push({
        ...element,
        image: await this.getImageUrl(element.image),
      });
    }

    return arr;
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }

    var dateFormat = require("dateformat");

    var today = new Date();
    var thisMonth = today.getMonth() + 1;
    var thisYear = today.getFullYear();
    var thisDay = today.getDate();

    var tempselectedBA;

    const { ba_id } = queryString.parse(
      window.location.search.replace("?", "")
    );

    const cookies = new Cookies()
    const userToken = cookies.get('userToken')

    if (ba_id !== undefined) {
      axios({
        method: "GET",
        url: `master-ba/list?id=${ba_id}`,
        headers: { Authorization: `Bearer ${userToken}` }

      })
        .then((res) => {
          const baSelected = res.data
          tempselectedBA = baSelected.data.data[0];
          axios({
            method: "GET",
            url: `presence?sort=id.desc&ba_id=${ba_id}&select=image,write_date,ba_id,phase&limit=3`,
            headers: { Authorization: `Bearer ${userToken}` }
          })
            .then(async (res) => {
              const response = res.data
              if (response.data.data.length < 3) {
                response.data.data.push({});
              }
              const dataFormatted = await this.dataFormattedHandler({
                data: response.data.data,
              });
              this.setState({
                selectedPhotoBA: dataFormatted,
                selectedBA: tempselectedBA,
                selectedDaySalesDetails: thisDay,
                selectedMonthSalesDetails: thisMonth,
                selectedYearSalesDetails: thisYear,
                selectedDateSalesDetails: today,
                printedMonthSalesDetails: dateFormat(today, "mmmm"),
                printedYearSalesDetails: dateFormat(today, "yyyy"),
              });
            })
            .catch((error) => {
              console.log(error);
            });
          this.reloadAllData(tempselectedBA);
          this.reloadSalesTable(thisMonth, thisYear);
          this.reloadSalesTableDaily(thisDay, thisMonth, thisYear);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  salesDetailsPrevMonth() {
    var dateFormat = require("dateformat");
    var day = this.state.selectedDaySalesDetails;
    var month = this.state.selectedMonthSalesDetails;
    var year = this.state.selectedYearSalesDetails;
    var fullDate = new Date(year, month - 2, day);

    this.reloadSalesTable(fullDate.getMonth() + 1, fullDate.getFullYear());
    this.reloadSalesTableDaily(
      day,
      fullDate.getMonth() + 1,
      fullDate.getFullYear()
    );

    this.setState({
      selectedMonthSalesDetails: fullDate.getMonth() + 1,
      selectedYearSalesDetails: fullDate.getFullYear(),
      printedMonthSalesDetails: dateFormat(
        new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearSalesDetails: dateFormat(
        new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 0),
        "yyyy"
      ),
    });
  }

  salesDetailsNextMonth() {
    var dateFormat = require("dateformat");
    var day = this.state.selectedDaySalesDetails;
    var month = this.state.selectedMonthSalesDetails;
    var year = this.state.selectedYearSalesDetails;
    var fullDate = new Date(year, month, day);
    this.reloadSalesTable(fullDate.getMonth() + 1, fullDate.getFullYear());
    this.reloadSalesTableDaily(
      day,
      fullDate.getMonth() + 1,
      fullDate.getFullYear()
    );

    this.setState({
      selectedMonthSalesDetails: fullDate.getMonth() + 1,
      selectedYearSalesDetails: fullDate.getFullYear(),
      printedMonthSalesDetails: dateFormat(
        new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearSalesDetails: dateFormat(
        new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 0),
        "yyyy"
      ),
    });
  }

  handleDailySalesDetailsChange = (date) => {
    var dateFormat = require("dateformat");
    var day = parseInt(dateFormat(date._d, "d"));
    var month = parseInt(dateFormat(date._d, "m"));
    var year = parseInt(dateFormat(date._d, "yyyy"));

    this.reloadSalesTableDaily(day, month, year);
    this.reloadSalesTable(month, year);

    this.setState({
      selectedDaySalesDetails: day,
      selectedMonthSalesDetails: month,
      selectedYearSalesDetails: year,
      selectedDateSalesDetails: new Date(year, month - 1, day),
      printedMonthSalesDetails: dateFormat(new Date(year, month, 0), "mmmm"),
      printedYearSalesDetails: dateFormat(new Date(year, month, 0), "yyyy"),
    });
  };

  reloadSalesTable(month, year) {
    const { ba_id } = queryString.parse(
      window.location.search.replace("?", "")
    );
    const cookies = new Cookies()
    const userToken = cookies.get('userToken')

    axios({
      method: "GET",
      url: `dashboard/v-monthly-ba-product-sold/list?select=product_name,total_sold&ba_id=${ba_id}&month=${month}&year=${year}`,
      headers: { Authorization: `Bearer ${userToken}` }

    })
      .then((res) => {
        const salesSKU = res.data
        this.setState({
          salesDetails: salesSKU.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  reloadSalesTableDaily(day, month, year) {
    const { ba_id } = queryString.parse(
      window.location.search.replace("?", "")
    );
    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    axios({
      method: "GET",
      url: `dashboard/v-monthly-ba-product-sold/list?select=product_name,total_sold&ba_id=${ba_id}&day=${day}&month=${month}&year=${year}`,
      headers: { Authorization: `Bearer ${userToken}` }

    })
      .then((res) => {
        const salesSKU = res.data
        this.setState({
          salesDetailsDaily: salesSKU.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  reloadAllData(ba) {
    var dateFormat = require("dateformat");

    var today = new Date();
    var firstday = dateFormat(
      new Date(today.getFullYear(), today.getMonth(), 1),
      "dddd"
    );
    var lastday = parseInt(
      dateFormat(new Date(today.getFullYear(), today.getMonth() + 1, 0), "dd")
    );

    var firstdate_thismonth = dateFormat(
      new Date(today.getFullYear(), today.getMonth(), 1),
      "yyyy-mm-dd"
    );
    var firstdate_nextmonth = dateFormat(
      new Date(today.getFullYear(), today.getMonth() + 1, 1),
      "yyyy-mm-dd"
    );
    var intmonth = today.getMonth() + 1;
    var intyear = today.getFullYear();

    var templistpresensi = [];

    this.setState({
      currentMonthPresenceDetails: today.getMonth() + 1,
      currentYearPresenceDetails: today.getFullYear(),
      selectedMonthPresenceDetails: today.getMonth() + 1,
      selectedYearPresenceDetails: today.getFullYear(),
      selectedMonthPresenceHeatmap: today.getMonth() + 1,
      selectedYearPresenceHeatmap: today.getFullYear(),
      printedMonthPresenceDetails: dateFormat(today, "mmmm"),
      printedYearPresenceDetails: dateFormat(today, "yyyy"),
      printedMonthPresenceHeatmap: dateFormat(today, "mmmm"),
      printedYearPresenceHeatmap: dateFormat(today, "yyyy"),
    });

    var skipday = 0;
    if (firstday === "Monday") {
      skipday = 0;
    } else if (firstday === "Tuesday") {
      skipday = 1;
    } else if (firstday === "Wednesday") {
      skipday = 2;
    } else if (firstday === "Thursday") {
      skipday = 3;
    } else if (firstday === "Friday") {
      skipday = 4;
    } else if (firstday === "Saturday") {
      skipday = 5;
    } else if (firstday === "Sunday") {
      skipday = 6;
    }

    var listtgl = [];
    for (var tgl = 1; tgl < lastday + 1; tgl++) {
      listtgl.push(tgl);
    }

    this.setState({
      skipday: skipday * -1,
      listTanggal: listtgl,
      listTanggalHeatmap: listtgl,
    });

    var tempselectedBA;
    var tempPresenceData = [];
    var tempPresenceAkhir = [];

    if (ba !== undefined) {
      tempselectedBA = ba;

      //GET PRESENSI THIS MONTH
      const cookies = new Cookies()
      const userToken = cookies.get('userToken')
      axios({
        method: "GET",
        url: `dashboard/v-presence-partner-details/list?phase=[Awal,Izin]&write_date=(${firstdate_thismonth},${firstdate_nextmonth})&sort=write_date.desc&select=id,ba_id,write_date,phase,keterangan,address,location,partner_name&ba_id=${tempselectedBA.id}`,
        headers: { Authorization: `Bearer ${userToken}` }

      })
        .then((res) => {
          const presensi = res.data
          let attendanceIn = [...presensi.data.data];
          attendanceIn = uniqWith(
            sortedIndexBy(attendanceIn),
            (prev, next) =>
              dateFormat(prev.write_date, "yyyy-mm-dd", true) ===
              dateFormat(next.write_date, "yyyy-mm-dd", true)
          );

          presensi.data.data = sortedIndexDate(attendanceIn);

          var idx = 0;
          var tgl = lastday;
          var intTodayDate = new Date().getDate();
          var tanggal = presensi.data.data;
          var bulan = presensi.data.data;
          var tahun = presensi.data.data;
          var pDate, pMonth, pYear;
          var items;

          for (tgl; tgl > 0; tgl--) {
            if (tanggal.length > 0) {
              pDate = parseInt(tanggal[idx].write_date.slice(8, 10));
              pMonth = parseInt(bulan[idx].write_date.slice(5, 7));
              pYear = parseInt(tahun[idx].write_date.slice(0, 4));
            } else {
              pDate = -1;
              pMonth = -1;
              pYear = -1;
            }

            if (pMonth === intmonth && pYear === intyear && pDate === tgl) {
              templistpresensi.push(100);
              if (tgl <= intTodayDate) {
                items = {
                  partner_name: presensi.data.data[idx].partner_name,
                  date: presensi.data.data[idx].write_date.slice(0, 10),
                  time: presensi.data.data[idx].write_date.slice(11, 16),
                  keterangan: presensi.data.data[idx].keterangan,
                  phase: presensi.data.data[idx].phase,
                  address: (
                    <a href={presensi.data.data[idx].location}>
                      {presensi.data.data[idx].location}
                    </a>
                  ),
                };

                tempPresenceData.push(items);
              }

              if (idx !== presensi.data.data.length - 1) {
                idx++;
                while (
                  idx !== presensi.data.data.length - 1 &&
                  pDate === parseInt(tanggal[idx].write_date.slice(8, 10))
                ) {
                  idx++;
                }
              }
            } else {
              if (tgl <= intTodayDate) {
                var tglPrint;
                if (tgl < 10) {
                  tglPrint = "0" + tgl;
                } else {
                  tglPrint = tgl;
                }
                items = {
                  date:
                    today.getFullYear() +
                    "-" +
                    dateFormat(today, "mm") +
                    "-" +
                    tglPrint,
                  time: "-",
                  keterangan: "Tidak Presensi",
                  address: "-",
                };

                tempPresenceData.push(items);
              }
              templistpresensi.push(20);
            }
          }
          axios({

            method: "GET",
            url: `dashboard/v-presence-partner-details/list?phase=Akhir&write_date=(${firstdate_thismonth},${firstdate_nextmonth})&sort=write_date.desc&select=id,ba_id,write_date,phase,keterangan,address,location,partner_name&ba_id=${tempselectedBA.id}`,
            headers: { Authorization: `Bearer ${userToken}` }


          })
            .then((res) => {
              const presensiAkhir = res.data
              var defaultPartner = presensi.data.data[0].partner_name;
              var idx = 0;
              var tgl = lastday;
              var intTodayDate = new Date().getDate();

              let attendanceOut = [...presensiAkhir.data.data];
              attendanceOut = uniqWith(
                sortedIndexBy(attendanceOut),
                (prev, next) =>
                  dateFormat(prev.write_date, "yyyy-mm-dd", true) ===
                  dateFormat(next.write_date, "yyyy-mm-dd", true)
              );

              presensiAkhir.data.data = sortedIndexDate(attendanceOut);

              var tanggal = presensiAkhir.data.data;
              var bulan = presensiAkhir.data.data;
              var tahun = presensiAkhir.data.data;
              var pDate, pMonth, pYear;
              var items;
              for (tgl; tgl > 0; tgl--) {
                if (tanggal.length > 0) {
                  pDate = parseInt(tanggal[idx].write_date.slice(8, 10));
                  pMonth = parseInt(bulan[idx].write_date.slice(5, 7));
                  pYear = parseInt(tahun[idx].write_date.slice(0, 4));
                } else {
                  pDate = -1;
                  pMonth = -1;
                  pYear = -1;
                }
                if (pMonth === intmonth && pYear === intyear && pDate === tgl) {
                  if (tgl <= intTodayDate) {
                    items = {
                      partner_name: presensi.data.data[idx].partner_name,
                      date: presensiAkhir.data.data[idx].write_date.slice(0, 10),
                      time: presensiAkhir.data.data[idx].write_date.slice(11, 16),
                      keterangan: presensiAkhir.data.data[idx].keterangan,
                      address: (
                        <a href={presensiAkhir.data.data[idx].location}>
                          {presensiAkhir.data.data[idx].location}
                        </a>
                      ),
                    };
                    tempPresenceAkhir.push(items);
                  }

                  if (idx !== presensiAkhir.data.data.length - 1) {
                    idx++;

                    while (
                      idx !== presensiAkhir.data.data.length - 1 &&
                      pDate === parseInt(tanggal[idx].write_date.slice(8, 10))
                    ) {
                      idx++;
                    }
                  }
                } else {
                  if (tgl <= intTodayDate) {
                    var tglPrint;
                    if (tgl < 10) {
                      tglPrint = "0" + tgl;
                    } else {
                      tglPrint = tgl;
                    }
                    items = {
                      partner: defaultPartner,
                      date:
                        today.getFullYear() +
                        "-" +
                        dateFormat(today, "mm") +
                        "-" +
                        tglPrint,
                      time: "-",
                      keterangan: "Tidak Presensi",
                      address: "-",
                    };

                    tempPresenceAkhir.push(items);
                  }
                }
              }

              var tempPresenceFull = [];
              tempPresenceData.forEach((awal, index) => {
                var itemsFull = {
                  partner_name: awal.partner_name
                    ? awal.partner_name
                    : defaultPartner,
                  date: awal.date,
                  timestart: awal.time,
                  timeend:
                    awal.phase === "Izin"
                      ? awal.time
                      : tempPresenceAkhir[index].time,
                  keteranganstart: awal.keterangan,
                  keteranganend:
                    awal.phase === "Izin"
                      ? awal.keterangan
                      : tempPresenceAkhir[index].keterangan,
                  addressStart: awal.address,
                  addressEnd:
                    awal.phase === "Izin"
                      ? awal.address
                      : tempPresenceAkhir[index].address,
                };

                tempPresenceFull.push(itemsFull);
              });

              const tempPresenceDetailsReversed = tempPresenceFull.reverse();
              this.setState({
                presenceDetails: tempPresenceDetailsReversed,
              });
            })
            .catch((error) => {
              console.log(error);
            });

          for (var i = 0; i < skipday; i += 1) {
            templistpresensi.push(0);
          }

          const tempreverselistpresensi = templistpresensi.reverse();
          const sisabulan = 42 - tempreverselistpresensi.length;
          for (var j = 0; j < sisabulan; j += 1) {
            tempreverselistpresensi.push(0);
          }

          var datapresensi = [];
          var count = 0;
          for (var k = 0; k < 6; k += 1) {
            var temp = [];
            for (var index = 0; index < 7; index++) {
              temp.push(tempreverselistpresensi[count]);
              count++;
            }
            datapresensi.push(temp);
          }

          this.setState({
            listPresensi: datapresensi,
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.setState({
        selectedBA: ba,
      });
    }
  }

  presenceDetailsPrevMonth = () => {
    var changedMonth = this.state.selectedMonthPresenceDetails - 1;

    this.setState({
      noNextPresenceDetails: false,
    });

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearPresenceDetails,
      changedMonth,
      0
    );
    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    this.reloadPresenceDetails(compiledMonth, compiledYear);

    this.setState({
      selectedMonthPresenceDetails: compiledMonth,
      selectedYearPresenceDetails: compiledYear,
      printedMonthPresenceDetails: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearPresenceDetails: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  presenceHeatmapPrevMonth = () => {
    var changedMonth = this.state.selectedMonthPresenceHeatmap - 1;

    this.setState({
      noNextPresenceHeatmap: false,
    });

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearPresenceHeatmap,
      changedMonth,
      0
    );
    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    this.reloadPresenceHeatmap(compiledMonth, compiledYear);

    this.setState({
      selectedMonthPresenceHeatmap: compiledMonth,
      selectedYearPresenceHeatmap: compiledYear,
      printedMonthPresenceHeatmap: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearPresenceHeatmap: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  presenceDetailsNextMonth = () => {
    var changedMonth = this.state.selectedMonthPresenceDetails + 1;

    if (changedMonth === this.state.currentMonthPresenceDetails) {
      this.setState({
        noNextPresenceDetails: true,
      });
    }

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearPresenceDetails,
      changedMonth,
      0
    );
    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    this.reloadPresenceDetails(compiledMonth, compiledYear);

    this.setState({
      selectedMonthPresenceDetails: compiledMonth,
      selectedYearPresenceDetails: compiledYear,
      printedMonthPresenceDetails: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearPresenceDetails: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  presenceHeatmapNextMonth = () => {
    var changedMonth = this.state.selectedMonthPresenceHeatmap + 1;

    if (changedMonth === this.state.currentMonthPresenceDetails) {
      this.setState({
        noNextPresenceHeatmap: true,
      });
    }

    var dateFormat = require("dateformat");
    var date = new Date(
      this.state.selectedYearPresenceHeatmap,
      changedMonth,
      0
    );
    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    this.reloadPresenceHeatmap(compiledMonth, compiledYear);

    this.setState({
      selectedMonthPresenceHeatmap: compiledMonth,
      selectedYearPresenceHeatmap: compiledYear,
      printedMonthPresenceHeatmap: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearPresenceDetails: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  reloadPresenceHeatmap(month, year) {
    var dateFormat = require("dateformat");

    var selected = new Date(year, month - 1, 1);
    var firstday = dateFormat(
      new Date(selected.getFullYear(), selected.getMonth(), 1),
      "dddd"
    );
    var lastday = parseInt(
      dateFormat(
        new Date(selected.getFullYear(), selected.getMonth() + 1, 0),
        "dd"
      )
    );

    var firstdate_thismonth = dateFormat(
      new Date(year, month - 1, 1),
      "yyyy-mm-dd"
    );

    var firstdate_nextmonth = dateFormat(
      new Date(year, month, 1),
      "yyyy-mm-dd"
    );

    var intmonth = selected.getMonth() + 1;
    var intyear = selected.getFullYear();

    var skipday = 0;
    if (firstday === "Monday") {
      skipday = 0;
    } else if (firstday === "Tuesday") {
      skipday = 1;
    } else if (firstday === "Wednesday") {
      skipday = 2;
    } else if (firstday === "Thursday") {
      skipday = 3;
    } else if (firstday === "Friday") {
      skipday = 4;
    } else if (firstday === "Saturday") {
      skipday = 5;
    } else if (firstday === "Sunday") {
      skipday = 6;
    }

    var listtgl = [];
    for (var tgl = 1; tgl < lastday + 1; tgl++) {
      listtgl.push(tgl);
    }

    this.setState({
      skipday: skipday * -1,
      listTanggalHeatmap: listtgl,
    });

    var templistpresensi = [];
    const { ba_id } = queryString.parse(
      window.location.search.replace("?", "")
    );
    if (ba_id !== undefined) {
      //GET PRESENSI THIS MONTH
      const cookies = new Cookies()
      const userToken = cookies.get('userToken')
      axios({
        method: "GET",
        url: `presence?phase=[Awal,Izin)&write_date=(${firstdate_thismonth},${firstdate_nextmonth})&sort=write_date.desc&select=ba_id,write_date,phase,keterangan&ba_id=${ba_id}`,
        headers: { Authorization: `Bearer ${userToken}` }


      })
        .then((res) => {
          const presensi = res.data.data
          var idx = 0;
          var tgl = lastday;

          var tanggal = presensi.data;
          var bulan = presensi.data;
          var tahun = presensi.data;
          var pDate, pMonth, pYear;
          for (tgl; tgl > 0; tgl--) {
            pDate = parseInt(tanggal[idx].write_date.slice(8, 10));
            pMonth = parseInt(bulan[idx].write_date.slice(5, 7));
            pYear = parseInt(tahun[idx].write_date.slice(0, 4));
            if (pMonth === intmonth && pYear === intyear && pDate === tgl) {
              templistpresensi.push(100);

              if (idx !== presensi.data.length - 1) {
                idx++;
                while (
                  idx !== presensi.data.length - 1 &&
                  pDate === parseInt(tanggal[idx].write_date.slice(8, 10))
                ) {
                  idx++;
                }
              }
            } else {
              templistpresensi.push(20);
            }
          }

          for (var i = 0; i < skipday; i += 1) {
            templistpresensi.push(0);
          }

          const tempreverselistpresensi = templistpresensi.reverse();
          const sisabulan = 42 - tempreverselistpresensi.length;
          for (var j = 0; j < sisabulan; j += 1) {
            tempreverselistpresensi.push(0);
          }

          var datapresensi = [];
          var count = 0;
          for (var k = 0; k < 6; k += 1) {
            var temp = [];
            for (var index = 0; index < 7; index++) {
              temp.push(tempreverselistpresensi[count]);
              count++;
            }
            datapresensi.push(temp);
          }

          this.setState({
            listPresensi: datapresensi,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  reloadPresenceDetails(month, year) {
    var dateFormat = require("dateformat");

    const { ba_id } = queryString.parse(
      window.location.search.replace("?", "")
    );

    var selected = new Date(year, month - 1, 1);
    var lastday = parseInt(dateFormat(new Date(year, month, 0), "dd"));

    var borderDate;
    if (month === this.state.currentMonthPresenceDetails) {
      borderDate = new Date().getDate();
    } else {
      borderDate = lastday;
    }

    var firstdate_selectedmonth = dateFormat(
      new Date(year, month - 1, 1),
      "yyyy-mm-dd"
    );
    var firstdate_nextmonth = dateFormat(
      new Date(year, month, 1),
      "yyyy-mm-dd"
    );

    var intmonth = selected.getMonth() + 1;
    var intyear = selected.getFullYear();

    var tempPresenceData = [];
    var tempPresenceAkhir = [];
    var tempPresenceFull = [];

    const cookies = new Cookies()
    const userToken = cookies.get('userToken')
    axios({
      method: "GET",
      url: `presence?phase=[Awal,Izin]&write_date=(${firstdate_selectedmonth},${firstdate_nextmonth})&sort=write_date.desc&select=id,ba_id,write_date,phase,keterangan,address&ba_id=${ba_id}`,
      headers: { Authorization: `Bearer ${userToken}` }

    })
      .then((res) => {
        const presensi = res.data
        let attendanceIn = [...presensi.data.data];
        console.log('[debug] og arr:', attendanceIn)
        attendanceIn = uniqWith(
          sortedIndexBy(attendanceIn),
          (prev, next) => 
            dateFormat(prev.write_date, "yyyy-mm-dd", true) == 
            dateFormat(next.write_date, "yyyy-mm-dd", true)          
        );
        console.log('[debug]', presensi.data.data)
        presensi.data.data = sortedIndexDate(attendanceIn);
        console.log('[debug] 2nd', presensi.data.data)

        var idx = 0;
        var tgl = lastday;
        var tanggal = presensi.data.data;
        var bulan = presensi.data.data;
        var tahun = presensi.data.data;
        var pDate, pMonth, pYear;
        var items;

        for (tgl; tgl > 0; tgl--) {
          if (tanggal.length > 0) {
            pDate = parseInt(tanggal[idx].write_date.slice(8, 10));
            pMonth = parseInt(bulan[idx].write_date.slice(5, 7));
            pYear = parseInt(tahun[idx].write_date.slice(0, 4));
          } else {
            pDate = -1;
            pMonth = -1;
            pYear = -1;
          }
          if (pMonth === intmonth && pYear === intyear && pDate === tgl) {
            if (tgl <= borderDate) {
              console.log('[debug] presence awal:', presensi.data.data[idx].write_date.slice(0, 10), presensi.data.data[idx].write_date.slice(11, 16))
              items = {
                date: presensi.data.data[idx].write_date.slice(0, 10),
                time: presensi.data.data[idx].write_date.slice(11, 16),
                keterangan: presensi.data.data[idx].keterangan,
                address: presensi.data.data[idx].address,
                phase: presensi.data.data[idx].phase,
              };
              tempPresenceData.push(items);
            }

            if (idx !== presensi.data.data.length - 1) {
              idx++;
              while (
                idx !== presensi.data.data.length - 1 &&
                pDate === parseInt(tanggal[idx].write_date.slice(8, 10))
              ) {
                idx++;
              }
            }
          } else {
            if (tgl <= borderDate) {
              var tglPrint;
              if (tgl < 10) {
                tglPrint = "0" + tgl;
              } else {
                tglPrint = tgl;
              }
              items = {
                date: year + "-" + month + "-" + tglPrint,
                time: "-",
                keterangan: "Tidak Presensi",
                address: "-",
              };

              tempPresenceData.push(items);
            }
          }
        }

        axios({
          method: "GET",
          url: `presence?phase=Akhir&write_date=(${firstdate_selectedmonth},${firstdate_nextmonth})&sort=write_date.desc&select=id,ba_id,write_date,phase,keterangan,address&ba_id=${ba_id}`,
          headers: { Authorization: `Bearer ${userToken}` }

        })
          .then((res) => {
            const presensiAkhir = res.data
            let attendanceOut = [...presensiAkhir.data.data];
            attendanceOut = uniqWith(
              sortedIndexBy(attendanceOut),
              (prev, next) =>
                dateFormat(prev.write_date, "yyyy-mm-dd", true) ===
                dateFormat(next.write_date, "yyyy-mm-dd", true)
            );

            presensiAkhir.data.data = sortedIndexDate(attendanceOut);

            var idx = 0;
            var tgl = lastday;
            var tanggal = presensiAkhir.data.data;
            var bulan = presensiAkhir.data.data;
            var tahun = presensiAkhir.data.data;
            var pDate, pMonth, pYear;
            var items;

            for (tgl; tgl > 0; tgl--) {
              if (tanggal.length > 0) {
                pDate = parseInt(tanggal[idx].write_date.slice(8, 10));
                pMonth = parseInt(bulan[idx].write_date.slice(5, 7));
                pYear = parseInt(tahun[idx].write_date.slice(0, 4));
              } else {
                pDate = -1;
                pMonth = -1;
                pYear = -1;
              }
              if (pMonth === intmonth && pYear === intyear && pDate === tgl) {
                if (tgl <= borderDate) {
                  items = {
                    date: presensiAkhir.data.data[idx].write_date.slice(0, 10),
                    time: presensiAkhir.data.data[idx].write_date.slice(11, 16),
                    keterangan: presensiAkhir.data.data[idx].keterangan,
                    address: presensiAkhir.data.data[idx].address,
                  };
                  tempPresenceAkhir.push(items);
                }

                if (idx !== presensiAkhir.data.data.length - 1) {
                  idx++;
                  while (
                    idx !== presensiAkhir.data.data.length - 1 &&
                    pDate === parseInt(tanggal[idx].write_date.slice(8, 10))
                  ) {
                    idx++;
                  }
                }
              } else {
                if (tgl <= borderDate) {
                  var tglPrint;
                  if (tgl < 10) {
                    tglPrint = "0" + tgl;
                  } else {
                    tglPrint = tgl;
                  }
                  items = {
                    date: year + "-" + month + "-" + tglPrint,
                    time: "-",
                    keterangan: "Tidak Presensi",
                    address: "-",
                  };

                  tempPresenceAkhir.push(items);
                }
              }
            }

            tempPresenceData.forEach((awal, index) => {
              var itemsFull = {
                date: awal.date,
                dateEnd: tempPresenceAkhir[index].date,
                timestart: awal.time,
                addressStart: awal.address,
                timeend:
                  awal.phase === "Izin"
                    ? awal.time
                    : tempPresenceAkhir[index].time,
                keteranganstart: awal.keterangan,
                keteranganend:
                  awal.phase === "Izin"
                    ? awal.keterangan
                    : tempPresenceAkhir[index].keterangan,
                addressEnd:
                  awal.phase === "Izin"
                    ? awal.address
                    : tempPresenceAkhir[index].address,
              };

              tempPresenceFull.push(itemsFull);
            });

            const tempPresenceDetailsReversed = tempPresenceFull.reverse();

            this.setState({
              presenceDetails: tempPresenceDetailsReversed,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const noNextPresenceDetails = this.state.noNextPresenceDetails;
    const noNextPresenceHeatmap = this.state.noNextPresenceHeatmap;
    const presenceDetailsNextMonth = this.presenceDetailsNextMonth.bind(this);
    const presenceHeatmapNextMonth = this.presenceHeatmapNextMonth.bind(this);

    var salesDetails;
    if (this.state.isMonthly) {
      salesDetails = this.state.salesDetails;
    } else {
      salesDetails = this.state.salesDetailsDaily;
    }

    // PRESENSI HEAT MAP
    const yLabels = new Array(6).fill(0).map((_, i) => `week ${i + 1}`);
    const xLabelsVisibility = new Array(42)
      .fill(0)
      .map((_, i) => (i % 1 === 0 ? true : false));

    const xLabels = ["Sen", "Sel", "Rab", "Kam", "Jum", "Sab", "Min"];

    var data;
    if (this.state.listPresensi === undefined) {
      data = new Array(yLabels.length)
        .fill(0)
        .map((index) => new Array(xLabels.length).fill(0).map(() => 0));
    } else {
      data = this.state.listPresensi;
    }

    var count = this.state.skipday;
    const tanggalHeatmap = this.state.listTanggalHeatmap;

    var isPresenceDetailsDownload = false;
    const presenceDetails = this.state.presenceDetails;
    const presenceDetailsFileName =
      "Presence Details " +
      this.state.selectedBA.name +
      " " +
      this.state.printedMonthPresenceDetails +
      " " +
      this.state.printedYearPresenceDetails +
      ".csv";
    if (this.state.presenceDetails.length !== 0) {
      isPresenceDetailsDownload = true;
    }

    const { ba_id } = queryString.parse(
      window.location.search.replace("?", "")
    );

    if (ba_id === undefined || ba_id === null) {
      return <BANotSelected />;
    } else {
      return (
        <>
          <CardsHeaderBA
            name={this.state.selectedBA.name}
            parentName="Dashboards"
          />
          <Container className="mt--6" fluid>
            <Row>
              <Col xl="12">
                <Row>
                  <Col xl="6">
                    <Card>
                      <CardHeader>
                        <h5 className="h3 mb-0">
                          Presensi BA - {this.state.printedMonthPresenceHeatmap}{" "}
                          {this.state.printedYearPresenceHeatmap}
                        </h5>
                        <div style={{ marginLeft: "2%" }}>
                          <Row>
                            <Button
                              onClick={this.presenceHeatmapPrevMonth.bind(this)}
                              color="primary"
                              size="sm"
                              type="button"
                            >
                              Prev
                            </Button>

                            {(function () {
                              if (!noNextPresenceHeatmap) {
                                return (
                                  <Button
                                    onClick={presenceHeatmapNextMonth}
                                    color="primary"
                                    size="sm"
                                    type="button"
                                  >
                                    Next
                                  </Button>
                                );
                              }
                            })()}
                          </Row>
                        </div>
                      </CardHeader>

                      <CardBody>
                        <div style={{ fontSize: "10px" }}>
                          <HeatMap
                            xLabels={xLabels}
                            yLabels={yLabels}
                            xLabelsLocation={"bottom"}
                            xLabelsVisibility={xLabelsVisibility}
                            xLabelWidth={50}
                            data={data}
                            cellStyle={(
                              background,
                              value,
                              min,
                              max,
                              data,
                              x,
                              y
                            ) => ({
                              background: `rgb(0, 151, 230, ${1 - (100 - value) / (100 - 0)
                                })`,
                              fontSize: "10px",
                              color: "#000",
                            })}
                            cellRender={(x, y, z) => {
                              var tgl = tanggalHeatmap[count];
                              if (count < 0) {
                                count++;
                                return "";
                              } else {
                                count++;
                                return tgl;
                              }
                            }}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="6">
                    <Card>
                      <CardHeader>
                        <h5 className="h3 mb-0">Foto Presensi BA</h5>
                      </CardHeader>

                      <CardBody>
                        <Row>
                          <Col xl="6">
                            {this.state.selectedPhotoBA[0].phase ===
                              this.state.selectedPhotoBA[1].phase ? (
                              <div>
                                {Object.keys(this.state.selectedPhotoBA[2])
                                  .length !== 0 ? (
                                  <h5>
                                    Tanggal :{" "}
                                    {(this.state.selectedPhotoBA[2].write_date || '').slice(
                                      0,
                                      10
                                    )}{" "}
                                    <br /> Waktu :{" "}
                                    {(this.state.selectedPhotoBA[2].write_date || '').slice(
                                      11,
                                      16
                                    )}{" "}
                                    <br /> Fase :{" "}
                                    {this.state.selectedPhotoBA[2].phase || ''}
                                  </h5>
                                ) : (
                                  <span>Empty</span>
                                )}
                                {Object.keys(this.state.selectedPhotoBA[2])
                                  .length !== 0 ? (
                                  <img
                                    alt="presensi"
                                    src={this.state.selectedPhotoBA[2].image}
                                    style={{
                                      maxHeight: "480px",
                                      maxWidth: "270px",
                                    }}
                                  />
                                ) : null}
                              </div>
                            ) : (
                              <div>
                                {Object.keys(this.state.selectedPhotoBA[1])
                                  .length !== 0 ? (
                                  <h5>
                                    Tanggal :{" "}
                                    {(this.state.selectedPhotoBA[1].write_date || '').slice(
                                      0,
                                      10
                                    )}{" "}
                                    <br /> Waktu :{" "}
                                    {(this.state.selectedPhotoBA[1].write_date || '').slice(
                                      11,
                                      16
                                    )}{" "}
                                    <br /> Fase :{" "}
                                    {this.state.selectedPhotoBA[1].phase || ''}
                                  </h5>
                                ) : (
                                  <span>Empty</span>
                                )}
                                {Object.keys(this.state.selectedPhotoBA[1])
                                  .length !== 0 ? (
                                  <img
                                    alt="presensi"
                                    src={this.state.selectedPhotoBA[1].image}
                                    style={{
                                      maxHeight: "480px",
                                      maxWidth: "270px",
                                    }}
                                  />
                                ) : null}
                              </div>
                            )}
                          </Col>
                          <Col xl="6">
                            {Object.keys(this.state.selectedPhotoBA[0])
                              .length !== 0 ? (
                              <h5>
                                Tanggal :{" "}
                                {(this.state.selectedPhotoBA[0].write_date || '').slice(
                                  0,
                                  10
                                )}{" "}
                                <br /> Waktu :{" "}
                                {(this.state.selectedPhotoBA[0] || '').write_date.slice(
                                  11,
                                  16
                                )}{" "}
                                <br />
                                Fase : {
                                  this.state.selectedPhotoBA[0].phase || ''
                                }{" "}
                              </h5>
                            ) : (
                              <span>Empty</span>
                            )}
                            {Object.keys(this.state.selectedPhotoBA[0])
                              .length !== 0 ? (
                              <img
                                alt="presensi"
                                src={this.state.selectedPhotoBA[0].image}
                                style={{
                                  maxHeight: "480px",
                                  maxWidth: "270px",
                                }}
                              />
                            ) : null}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xl="4"></Col>
            </Row>
            <Row>
              <Col xl="12">
                <Row>
                  <div className="col">
                    <Card>
                      <CardHeader>
                        <h3 className="mb-0">
                          Presence Details -{" "}
                          {this.state.printedMonthPresenceDetails}{" "}
                          {this.state.printedYearPresenceDetails}
                        </h3>
                        <div style={{ marginLeft: "2%" }}>
                          <Row>
                            {(function () {
                              if (isPresenceDetailsDownload) {
                                return (
                                  <Button
                                    color="primary"
                                    size="sm"
                                    type="button"
                                  >
                                    <CSVLink
                                      data={presenceDetails}
                                      filename={presenceDetailsFileName}
                                      style={{ color: "#FFFFFF" }}
                                    >
                                      Download
                                    </CSVLink>
                                  </Button>
                                );
                              }
                            })()}

                            <Button
                              onClick={this.presenceDetailsPrevMonth.bind(this)}
                              color="primary"
                              size="sm"
                              type="button"
                            >
                              Prev
                            </Button>

                            {(function () {
                              if (!noNextPresenceDetails) {
                                return (
                                  <Button
                                    onClick={presenceDetailsNextMonth}
                                    color="primary"
                                    size="sm"
                                    type="button"
                                  >
                                    Next
                                  </Button>
                                );
                              }
                            })()}
                          </Row>
                        </div>
                      </CardHeader>
                      <ToolkitProvider
                        data={this.state.presenceDetails}
                        keyField="name"
                        columns={[
                          {
                            dataField: "partner_name",
                            text: "Nama Toko",
                            sort: true,
                          },
                          {
                            dataField: "date",
                            text: "Tanggal",
                            sort: true,
                          },
                          {
                            dataField: "timestart",
                            text: "Waktu Awal",
                            sort: true,
                          },
                          {
                            dataField: "timeend",
                            text: "Waktu Akhir",
                            sort: true,
                          },
                          {
                            dataField: "keteranganstart",
                            text: "Shift",
                            sort: true,
                          },
                          {
                            dataField: "keteranganend",
                            text: "Keterangan Pulang",
                            sort: true,
                          },
                          {
                            dataField: "addressStart",
                            text: "Lokasi Awal",
                            sort: true,
                          },
                          {
                            dataField: "addressEnd",
                            text: "Lokasi Akhir",
                            sort: true,
                          },
                        ]}
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={paginationTanggal}
                              bordered={false}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </Card>
                  </div>
                </Row>
              </Col>
              <Col xl="6">
                <Row>
                  <div className="col">
                    <Card>
                      <CardHeader>
                        {this.state.isMonthly ? (
                          <h3 className="mb-0">
                            Sales Details -{" "}
                            {this.state.printedMonthSalesDetails}{" "}
                            {this.state.printedYearSalesDetails}
                          </h3>
                        ) : (
                          <h3 className="mb-0">
                            Sales Details - {this.state.selectedDaySalesDetails}{" "}
                            {this.state.printedMonthSalesDetails}{" "}
                            {this.state.printedYearSalesDetails}
                          </h3>
                        )}
                        <div style={{ marginLeft: "2%" }}>
                          <Row style={{ marginBottom: "20px" }}>
                            {this.state.isMonthly ? (
                              <Button color="primary" size="sm" type="button">
                                Monthly
                              </Button>
                            ) : (
                              <Button
                                color="secondary"
                                size="sm"
                                type="button"
                                onClick={() =>
                                  this.setState({ isMonthly: true })
                                }
                              >
                                Monthly
                              </Button>
                            )}

                            {this.state.isMonthly ? (
                              <Button
                                color="secondary"
                                size="sm"
                                type="button"
                                onClick={() =>
                                  this.setState({ isMonthly: false })
                                }
                              >
                                Daily
                              </Button>
                            ) : (
                              <Button color="primary" size="sm" type="button">
                                Daily
                              </Button>
                            )}
                          </Row>
                          {this.state.isMonthly ? (
                            <Row>
                              <Button
                                onClick={this.salesDetailsPrevMonth.bind(this)}
                                color="primary"
                                size="sm"
                                type="button"
                              >
                                Prev
                              </Button>

                              {this.state.currentMonthPresenceDetails ===
                                this.state.selectedMonthSalesDetails ? null : (
                                <Button
                                  onClick={this.salesDetailsNextMonth.bind(
                                    this
                                  )}
                                  color="primary"
                                  size="sm"
                                  type="button"
                                >
                                  Next
                                </Button>
                              )}
                            </Row>
                          ) : (
                            <Row>
                              <ReactDatetime
                                value={this.state.selectedDateSalesDetails}
                                onChange={this.handleDailySalesDetailsChange}
                                //name='start_date'
                                //dateFormat="dd/MM/yyyy"
                                //selectsStart
                                timeFormat={false}
                              />
                            </Row>
                          )}
                        </div>
                      </CardHeader>
                      <ToolkitProvider
                        data={salesDetails}
                        keyField="name"
                        columns={[
                          {
                            dataField: "product_name",
                            text: "Nama Produk",
                            sort: true,
                          },
                          {
                            dataField: "total_sold",
                            text: "Total Terjual",
                            sort: true,
                          },
                        ]}
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={pagination}
                              bordered={false}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </Card>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              {/*<Col xl="5">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Activity feed</h5>
                </CardHeader>
                <CardHeader className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar"
                        src={require("assets/img/theme/team-1.jpg")}
                      />
                    </a>
                    <div className="mx-3">
                      <a
                        className="text-dark font-weight-600 text-sm"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        John Snow
                      </a>
                      <small className="d-block text-muted">3 days ago</small>
                    </div>
                  </div>
                  <div className="text-right ml-auto">
                    <Button
                      className="btn-icon"
                      color="primary"
                      size="sm"
                      type="button"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">Follow</span>
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <p className="mb-4">
                    Personal profiles are the perfect way for you to grab their
                    attention and persuade recruiters to continue reading your
                    CV because you’re telling them from the off exactly why they
                    should hire you.
                  </p>
                  <img
                    alt="..."
                    className="img-fluid rounded"
                    src={require("assets/img/theme/img-1-1000x600.jpg")}
                  />
                  <Row className="align-items-center my-3 pb-3 border-bottom">
                    <Col sm="6">
                      <div className="icon-actions">
                        <a
                          className="like active"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="ni ni-like-2" />
                          <span className="text-muted">150</span>
                        </a>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="ni ni-chat-round" />
                          <span className="text-muted">36</span>
                        </a>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="ni ni-curved-next" />
                          <span className="text-muted">12</span>
                        </a>
                      </div>
                    </Col>
                    <Col className="d-none d-sm-block" sm="6">
                      <div className="d-flex align-items-center justify-content-sm-end">
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-xs rounded-circle"
                            href="#pablo"
                            id="tooltip36177092"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/team-1.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip36177092"
                          >
                            Jessica Rowland
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-xs rounded-circle"
                            href="#pablo"
                            id="tooltip857639221"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip857639221"
                          >
                            Audrey Love
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-xs rounded-circle"
                            href="#pablo"
                            id="tooltip260223080"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip260223080"
                          >
                            Michael Lewis
                          </UncontrolledTooltip>
                        </div>
                        <small className="pl-2 font-weight-bold">
                          and 30+ more
                        </small>
                      </div>
                    </Col>
                  </Row>

                  <div className="mb-1">
                    <Media className="media-comment">
                      <img
                        alt="..."
                        className="avatar avatar-lg media-comment-avatar rounded-circle"
                        src={require("assets/img/theme/team-1.jpg")}
                      />
                      <Media>
                        <div className="media-comment-text">
                          <h6 className="h5 mt-0">Michael Lewis</h6>
                          <p className="text-sm lh-160">
                            Cras sit amet nibh libero nulla vel metus
                            scelerisque ante sollicitudin. Cras purus odio
                            vestibulum in vulputate viverra turpis.
                          </p>
                          <div className="icon-actions">
                            <a
                              className="like active"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              <i className="ni ni-like-2" />
                              <span className="text-muted">3 likes</span>
                            </a>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <i className="ni ni-curved-next" />
                              <span className="text-muted">2 shares</span>
                            </a>
                          </div>
                        </div>
                      </Media>
                    </Media>
                    <Media className="media-comment">
                      <img
                        alt="..."
                        className="avatar avatar-lg media-comment-avatar rounded-circle"
                        src={require("assets/img/theme/team-2.jpg")}
                      />
                      <Media>
                        <div className="media-comment-text">
                          <h6 className="h5 mt-0">Jessica Stones</h6>
                          <p className="text-sm lh-160">
                            Cras sit amet nibh libero, in gravida nulla. Nulla
                            vel metus scelerisque ante sollicitudin. Cras purus
                            odio, vestibulum in vulputate at, tempus viverra
                            turpis.
                          </p>
                          <div className="icon-actions">
                            <a
                              className="like active"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              <i className="ni ni-like-2" />
                              <span className="text-muted">10 likes</span>
                            </a>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <i className="ni ni-curved-next" />
                              <span className="text-muted">1 share</span>
                            </a>
                          </div>
                        </div>
                      </Media>
                    </Media>
                    <hr />
                    <Media className="align-items-center">
                      <img
                        alt="..."
                        className="avatar avatar-lg rounded-circle mr-4"
                        src={require("assets/img/theme/team-3.jpg")}
                      />
                      <Media body>
                        <Form>
                          <Input
                            placeholder="Write your comment"
                            rows="1"
                            type="textarea"
                          />
                        </Form>
                      </Media>
                    </Media>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="7">
              <Row>
                <div className="col">
                  <Card>
                    <CardHeader className="border-0">
                      <h3 className="mb-0">Light table</h3>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="sort" data-sort="name" scope="col">
                            Project
                          </th>
                          <th className="sort" data-sort="budget" scope="col">
                            Budget
                          </th>
                          <th className="sort" data-sort="status" scope="col">
                            Status
                          </th>
                          <th scope="col">Users</th>
                          <th
                            className="sort"
                            data-sort="completion"
                            scope="col"
                          >
                            Completion
                          </th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody className="list">
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/bootstrap.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  Argon Design System
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">$2500 USD</td>
                          <td>
                            <Badge className="badge-dot mr-4" color="">
                              <i className="bg-warning" />
                              <span className="status">pending</span>
                            </Badge>
                          </td>
                          <td>
                            <div className="avatar-group">
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip792717700"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-1.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip792717700"
                              >
                                Ryan Tompson
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip654289872"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-2.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip654289872"
                              >
                                Romina Hadid
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip409131762"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-3.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip409131762"
                              >
                                Alexander Smith
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip50788433"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-4.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip50788433"
                              >
                                Jessica Doe
                              </UncontrolledTooltip>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="completion mr-2">60%</span>
                              <div>
                                <Progress
                                  max="100"
                                  value="60"
                                  color="warning"
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/angular.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  Angular Now UI Kit PRO
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">$1800 USD</td>
                          <td>
                            <Badge className="badge-dot mr-4" color="">
                              <i className="bg-success" />
                              <span className="status">completed</span>
                            </Badge>
                          </td>
                          <td>
                            <div className="avatar-group">
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip545726644"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-1.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip545726644"
                              >
                                Ryan Tompson
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip823332447"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-2.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip823332447"
                              >
                                Romina Hadid
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip354076640"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-3.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip354076640"
                              >
                                Alexander Smith
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip625572621"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-4.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip625572621"
                              >
                                Jessica Doe
                              </UncontrolledTooltip>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="completion mr-2">100%</span>
                              <div>
                                <Progress
                                  max="100"
                                  value="100"
                                  color="success"
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/sketch.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  Black Dashboard
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">$3150 USD</td>
                          <td>
                            <Badge className="badge-dot mr-4" color="">
                              <i className="bg-danger" />
                              <span className="status">delayed</span>
                            </Badge>
                          </td>
                          <td>
                            <div className="avatar-group">
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip927457712"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-1.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip927457712"
                              >
                                Ryan Tompson
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip959509788"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-2.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip959509788"
                              >
                                Romina Hadid
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip239649821"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-3.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip239649821"
                              >
                                Alexander Smith
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip908443321"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-4.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip908443321"
                              >
                                Jessica Doe
                              </UncontrolledTooltip>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="completion mr-2">72%</span>
                              <div>
                                <Progress max="100" value="72" color="danger" />
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/react.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  React Material Dashboard
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">$4400 USD</td>
                          <td>
                            <Badge className="badge-dot mr-4" color="">
                              <i className="bg-info" />
                              <span className="status">on schedule</span>
                            </Badge>
                          </td>
                          <td>
                            <div className="avatar-group">
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip817843622"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-1.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip817843622"
                              >
                                Ryan Tompson
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip885824111"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-2.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip885824111"
                              >
                                Romina Hadid
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip426851535"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-3.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip426851535"
                              >
                                Alexander Smith
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip913358720"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-4.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip913358720"
                              >
                                Jessica Doe
                              </UncontrolledTooltip>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="completion mr-2">90%</span>
                              <div>
                                <Progress max="100" value="90" color="info" />
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/vue.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  Vue Paper UI Kit PRO
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">$2200 USD</td>
                          <td>
                            <Badge className="badge-dot mr-4" color="">
                              <i className="bg-success" />
                              <span className="status">completed</span>
                            </Badge>
                          </td>
                          <td>
                            <div className="avatar-group">
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip460474820"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-1.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip460474820"
                              >
                                Ryan Tompson
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip979995688"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-2.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip979995688"
                              >
                                Romina Hadid
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip732882700"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-3.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip732882700"
                              >
                                Alexander Smith
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip242724387"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-4.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip242724387"
                              >
                                Jessica Doe
                              </UncontrolledTooltip>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="completion mr-2">100%</span>
                              <div>
                                <Progress
                                  max="100"
                                  value="100"
                                  color="success"
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/bootstrap.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  Argon Design System
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">$2500 USD</td>
                          <td>
                            <Badge className="badge-dot mr-4" color="">
                              <i className="bg-warning" />
                              <span className="status">pending</span>
                            </Badge>
                          </td>
                          <td>
                            <div className="avatar-group">
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip318080952"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-1.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip318080952"
                              >
                                Ryan Tompson
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip221723068"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-2.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip221723068"
                              >
                                Romina Hadid
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip138748612"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-3.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip138748612"
                              >
                                Alexander Smith
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip431342349"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-4.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip431342349"
                              >
                                Jessica Doe
                              </UncontrolledTooltip>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="completion mr-2">60%</span>
                              <div>
                                <Progress
                                  max="100"
                                  value="60"
                                  color="warning"
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </Dropdown>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/angular.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  Angular Now UI Kit PRO
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">$1800 USD</td>
                          <td>
                            <Badge className="badge-dot mr-4" color="">
                              <i className="bg-success" />
                              <span className="status">completed</span>
                            </Badge>
                          </td>
                          <td>
                            <div className="avatar-group">
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip384464413"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-1.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip384464413"
                              >
                                Ryan Tompson
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip828512937"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-2.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip828512937"
                              >
                                Romina Hadid
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip409745485"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-3.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip409745485"
                              >
                                Alexander Smith
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip262162858"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-4.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip262162858"
                              >
                                Jessica Doe
                              </UncontrolledTooltip>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="completion mr-2">100%</span>
                              <div>
                                <Progress
                                  max="100"
                                  value="100"
                                  color="success"
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/sketch.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  Black Dashboard
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">$3150 USD</td>
                          <td>
                            <Badge className="badge-dot mr-4" color="">
                              <i className="bg-danger" />
                              <span className="status">delayed</span>
                            </Badge>
                          </td>
                          <td>
                            <div className="avatar-group">
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip711925042"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-1.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip711925042"
                              >
                                Ryan Tompson
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip664693924"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-2.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip664693924"
                              >
                                Romina Hadid
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip582913491"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-3.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip582913491"
                              >
                                Alexander Smith
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip699784330"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-4.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip699784330"
                              >
                                Jessica Doe
                              </UncontrolledTooltip>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="completion mr-2">72%</span>
                              <div>
                                <Progress max="100" value="72" color="danger" />
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/angular.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="name mb-0 text-sm">
                                  Angular Now UI Kit PRO
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td className="budget">$1800 USD</td>
                          <td>
                            <Badge className="badge-dot mr-4" color="">
                              <i className="bg-success" />
                              <span className="status">completed</span>
                            </Badge>
                          </td>
                          <td>
                            <div className="avatar-group">
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip912421317"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-1.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip912421317"
                              >
                                Ryan Tompson
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip912012329"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-2.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip912012329"
                              >
                                Romina Hadid
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip366509724"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-3.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip366509724"
                              >
                                Alexander Smith
                              </UncontrolledTooltip>
                              <a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id="tooltip569311457"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={require("assets/img/theme/team-4.jpg")}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip569311457"
                              >
                                Jessica Doe
                              </UncontrolledTooltip>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="completion mr-2">100%</span>
                              <div>
                                <Progress
                                  max="100"
                                  value="100"
                                  color="success"
                                />
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color=""
                                size="sm"
                                className="btn-icon-only text-light"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
              <div className="card-deck">
                <Card className="bg-gradient-danger">
                  <CardBody>
                    <Row className="justify-content-between align-items-center">
                      <div className="col">
                        <img
                          alt="..."
                          src={require("assets/img/icons/cards/bitcoin.png")}
                        />
                      </div>
                      <Col className="col-auto">
                        <Badge className="badge-lg" color="success">
                          Active
                        </Badge>
                      </Col>
                    </Row>
                    <div className="my-4">
                      <span className="h6 surtitle text-light">Username</span>
                      <div className="h1 text-white">@johnsnow</div>
                    </div>
                    <Row>
                      <div className="col">
                        <span className="h6 surtitle text-light">Name</span>
                        <span className="d-block h3 text-white">John Snow</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>*/}
            </Row>
            {/*<Row>
            <Col xl="8">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Page visits</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Page name</th>
                      <th scope="col">Visitors</th>
                      <th scope="col">Unique users</th>
                      <th scope="col">Bounce rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">/argon/</th>
                      <td>4,569</td>
                      <td>340</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/index.html</th>
                      <td>3,985</td>
                      <td>319</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />
                        46,53%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/charts.html</th>
                      <td>3,513</td>
                      <td>294</td>
                      <td>
                        <i className="fas fa-arrow-down text-warning mr-3" />
                        36,49%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/tables.html</th>
                      <td>2,050</td>
                      <td>147</td>
                      <td>
                        <i className="fas fa-arrow-up text-success mr-3" />
                        50,87%
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">/argon/profile.html</th>
                      <td>1,795</td>
                      <td>190</td>
                      <td>
                        <i className="fas fa-arrow-down text-danger mr-3" />
                        46,53%
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Social traffic</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Referral</th>
                      <th scope="col">Visitors</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Facebook</th>
                      <td>1,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">60%</span>
                          <div>
                            <Progress
                              max="100"
                              value="60"
                              color="gradient-danger"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Facebook</th>
                      <td>5,480</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">70%</span>
                          <div>
                            <Progress
                              max="100"
                              value="70"
                              color="gradient-success"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Google</th>
                      <td>4,807</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">80%</span>
                          <div>
                            <Progress
                              max="100"
                              value="80"
                              clor="gradient-primary"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Instagram</th>
                      <td>3,678</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">75%</span>
                          <div>
                            <Progress
                              max="100"
                              value="75"
                              color="gradient-info"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">twitter</th>
                      <td>2,645</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">30%</span>
                          <div>
                            <Progress
                              max="100"
                              value="30"
                              color="gradient-warning"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>*/}
          </Container>
        </>
      );
    }
  }
}

export default DashboardBA;

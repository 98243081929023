/* eslint-disable no-loop-func */
/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin that prints a given react component
//import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import Cookies from "universal-cookie";
import ReactDatetime from "react-datetime";
import Switch from "@material-ui/core/Switch";

import axios from "axios";
import Axios from "services/axios";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFormat from "dateformat";
import { CSVLink } from "react-csv";
import CircularProgress from "@material-ui/core/CircularProgress";
import SwitchGlobal from "../../../components/switch";
import { isEmpty } from "lodash";

import fetchAllBrand from "../../../services/fetch/brand/fetchAllBrand";
import fetchAllKeteranganPresensi from "../../../services/fetch/presence/fetchAllKeteranganPresensi";
import fetchAllDCexceptHO from "../../../services/fetch/masterDC/fetchAllDCexceptHO";
import fetchAllSalesRegionDashboard from "../../../services/fetch/salesRegion/dashboard/fetchAllSalesRegionDashboard";
import fetchAllSalesRegionSalesAreaDashboard from "../../../services/fetch/salesRegion/dashboard/fetchAllSalesRegionSalesAreaDashboard";
import fetchTableQuery from "../../../services/fetch/constructor/fetchTableQuery";
import fetchAllRecapPresensiWithIzin from "../../../services/fetch/presence/fetchAllRecapPresensiWithIzin";
import fetchAllbaPartnerDetailsDcNip from "../../../services/fetch/baPartner/fetchAllbaPartnerDetailsDcNip";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class ExportPresenceBA extends React.Component {
  constructor(props) {
    super(props);
    this.GetActionSelectFormat = this.GetActionSelectFormat.bind(this);
    this.GetActionDeselectFormat = this.GetActionDeselectFormat.bind(this);
    this.onDCSelectedChanged = this.onDCSelectedChanged.bind(this);
    this.onPresenceStatusSelectedChanged =
      this.onPresenceStatusSelectedChanged.bind(this);
  }
  state = {
    alert: null,
    dataBA: [],
    listDC: [],
    selectedDC: [],
    selectedPresenceStatus: [],
    isSelectAll: false,
    dataSelectedBA: [],
    fullDataBA: [],
    listPresenceStatus: [],
    createState: false,
    isResultShowed: false,
    showedData: [],
    isLoading: false,
    isListBALoading: false,
    listBrand: [],
    listSelectedBrand: [],
    checkedStatusFilter: false,
    tabActive: "Region",
    listSalesArea: [],
    selectedSalesArea: [],
    listRegion: [],
    listAreaByRegion: [],
  };

  getAllBrand = async () => {
    var listBrand = [];

    const response = await fetchAllBrand()
    response.forEach((brand) => {
      listBrand.push(brand);
    })

    this.setState({
      listBrand: listBrand,
    });
  };

  toggleModal = (state) => {
    var tempState = "bg-gradient-danger";
    var message = "Gagal! Harap Lengkapi Semua Kolom!";
    this.setState({
      [state]: !this.state[state],
      createState: tempState,
      modalMessage: message,
    });
  };

  onSalesAreaChanged = (arr) => {
    const arrArea = arr.map((item) => item.sales_area_name);
    this.setState({
      selectedSalesArea: arrArea,
      selectedDC: [],
    });
    this.getDataBA(
      arrArea,
      this.state.listSelectedBrand,
      this.state.checkedStatusFilter,
      true
    );
  };

  onDCSelectedChanged = (value, event) => {
    var arrayDC = [];
    value.forEach((item) => {
      arrayDC.push(item.text);
    });
    this.setState({
      selectedDC: arrayDC,
      selectedSalesArea: [],
    });

    this.getDataBA(
      arrayDC,
      this.state.listSelectedBrand,
      this.state.checkedStatusFilter
    );
  };

  handleSwitchStatusBox = (e) => {
    this.setState({
      checkedStatusFilter: !this.state.checkedStatusFilter,
    });
    const isSalesArea = this.state.tabActive === "Region";
    const arr = isSalesArea
      ? this.state.selectedSalesArea
      : this.state.selectedDC;
    this.getDataBA(
      arr,
      this.state.listSelectedBrand,
      e.target.checked,
      isSalesArea
    );
  };

  onBrandSelectedChanged = (value, event) => {
    var arrayBrand = [];
    value.forEach((item) => {
      arrayBrand.push(item.name);
    });
    this.setState({
      listSelectedBrand: arrayBrand,
    });
    const isSalesArea = this.state.tabActive === "Region";

    const arr = isSalesArea
      ? this.state.selectedSalesArea
      : this.state.selectedDC;

    this.getDataBA(
      arr,
      arrayBrand,
      this.state.checkedStatusFilter,
      isSalesArea
    );
  };

  onPresenceStatusSelectedChanged = (value, event) => {
    this.setState({
      selectedPresenceStatus: value,
    });
  };

  handleStartDateChange = (startDate) => {
    this.setState({
      selectedStartDate: startDate,
    });
  };

  getListPresenceStatus = async () => {
    var listStatus = ["All", "Tidak Presensi"];

    const response = await fetchAllKeteranganPresensi()
    response.forEach((status) => {
      listStatus.push(status.name)
    })

    this.setState({
      listPresenceStatus: listStatus,
    });
  };

  getListDC = async () => {
    var listDC = [];

    const response = await fetchAllDCexceptHO()
    response.forEach((item) => {
      var dcItem = {
        id: item.partner_name,
        text: item.partner_name,
      };
      listDC.push(dcItem);
    })

    this.setState({
      listDC: listDC,
    });
  };

  getDataBA = async (list, brand, statusBA, isSalesArea) => {
    var query;
    let table = "";
    this.setState({
      isListBALoading: true,
    });

    list = list.map((item) => item.replace(/ /g, "%20"));
    const cookies = new Cookies();

    const jwt = cookies.get("jwtKey");

    if (statusBA) {
      if (isSalesArea) {
        table = `dashboard/v_details_ba_partner_areasales_nip/list`;
        query = `sort=dc.asc,name.asc&salesarea_name=[${list}]&active=${statusBA}`;
      } else {
        table = `v_details_ba_partner_dc_nip`;
        query = `sort=dc.asc,name.asc&dc=[${list}]&active=${statusBA}`;
      }
    } else {
      if (isSalesArea) {
        table = `dashboard/v_details_ba_partner_areasales_nip/list`;
        query = `sort=dc.asc,name.asc&salesarea_name=[${list}]`;
      } else {
        table = `v_details_ba_partner_dc_nip`;
        query = `sort=dc.asc,name.asc&dc=[${list}]`;
      }
    }

    if (this.state.dataSelectedBA.length > 0) {
      var listIdBA = [];
      this.state.dataSelectedBA.forEach((item) => {
        listIdBA.push(item.ba_id);
      });
      query += "&ba_id=ni.[" + listIdBA + "]";
    }

    if (brand.length > 0 && brand.indexOf("All") === -1) {
      query += "&ba_brand=[" + brand + "]";
    }
    try {
      const response = await fetchTableQuery(table, query)

      response.forEach((item, id) => {
        item.isSelected = true;
        item.id = id;
      })

      if (this.state.dataSelectedBA.length > 0) {
        this.setState({
          dataBA: response,
          isListBALoading: false,
        });

        const response = await fetchTableQuery(table, query)
        this.setState({
          fullDataBA: response
        })
      } else {
        this.setState({
          dataBA: response,
          fullDataBA: response,
          isListBALoading: false,
        });
      }
    }
    catch (e) {
      this.setState({
        isListBALoading: false,
      });
    }
  };

  getDefaultBrand = () => {
    this.setState({
      listSelectedBrand: ["All"],
    });
  };

  getRegionList = async () => {
    try {
      const response = await fetchAllSalesRegionDashboard()

      this.setState({
        listRegion: response,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSelectedAreaList = async () => {
    try {
      const response = await fetchAllSalesRegionSalesAreaDashboard()

      this.setState({
        listAreaByRegion: response,
      });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.getAllBrand();
    this.getDefaultBrand();
    this.getListDC();
    this.getListPresenceStatus();
    this.getRegionList();
    this.getSelectedAreaList();
  }

  onBAClick = (cell, ba, rowIndex) => {
    const cookies = new Cookies();
    cookies.set("selectedBA", ba, { path: "/" });
  };

  buttonAction(cell, row, enumObject, rowIndex) {
    return (
      <Button
        className="buttons-copy buttons-html5"
        color="default"
        size="sm"
        id="copy-tooltip"
        onClick={this.onBAClick(cell, row, rowIndex)}
      >
        <span>Action</span>
      </Button>
    );
  }

  GetActionSelectFormat(cell, row) {
    return (
      <div>
        <button
          type="button"
          className="btn btn-primary btn-sm ts-buttom"
          size="sm"
          onClick={this.handleCheckBA.bind(this, cell, row)}
        >
          Select
        </button>
      </div>
    );
  }

  GetActionDeselectFormat(cell, row) {
    return (
      <div>
        <button
          type="button"
          className="btn btn-warning btn-sm ts-buttom"
          size="sm"
          onClick={this.handleUncheckBA.bind(this, cell, row)}
        >
          Cancel
        </button>
      </div>
    );
  }
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => this.setState({ alert: null })}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Copied to clipboard!
        </ReactBSAlert>
      ),
    });
  };

  handleCheckBox = () => {
    var tempDataBA = [...this.state.dataBA];
    this.setState({
      isSelectAll: !this.state.isSelectAll,
      dataSelectedBA: tempDataBA.length !== 0 ? this.state.fullDataBA : [],
      dataBA: tempDataBA.length !== 0 ? [] : this.state.fullDataBA,
    });
  };

  handleCheckBA = (e, cell, row) => {
    var tempSelectedBA = [...this.state.dataSelectedBA];
    var tempDataBA = [...this.state.dataBA];
    var idx = tempDataBA.indexOf(cell);
    if (idx !== -1) {
      tempSelectedBA.push(tempDataBA[idx]);
      tempDataBA.splice(idx, 1);
      this.setState({
        dataBA: tempDataBA,
        dataSelectedBA: tempSelectedBA,
      });
    }
  };

  handleUncheckBA = (e, cell, row) => {
    var tempSelectedBA = [...this.state.dataSelectedBA];
    var tempDataBA = [...this.state.dataBA];
    var idx = tempSelectedBA.indexOf(cell);
    if (idx !== -1) {
      var newArray = [tempSelectedBA[idx]].concat(tempDataBA);
      tempSelectedBA.splice(idx, 1);
      this.setState({
        dataBA: newArray,
        dataSelectedBA: tempSelectedBA,
      });
    }
  };

  handleReactDatetimeChange = async (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate + "") > new Date(date._d + "")
    ) {
      await this.getDateLabel(date._d, date._d);
      this.setState({
        startDate: date._d,
        endDate: date._d,
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate + "") < new Date(date._d + "")
    ) {
      await this.getDateLabel(date._d, date._d);
      this.setState({
        startDate: date._d,
        endDate: date._d,
      });
    } else {
      if (who === "startDate") {
        await this.getDateLabel(date._d, this.state.endDate);
      } else if (who === "endDate") {
        await this.getDateLabel(this.state.startDate, date._d);
      }
      this.setState({
        [who]: date._d,
      });
    }
  };

  getDateLabel = (dateStart, dateEnd) => {
    var dateLabel = [];
    var dateFormat = require("dateformat");
    var initDate;
    var newDateEnd;
    var newDateStart;
    var temp;

    if (dateStart === undefined && dateEnd === undefined) {
      temp = [];
      newDateEnd = new Date();
      initDate = dateFormat(newDateEnd, "yyyy-mm-dd");
      for (var i = 0; i < 30; i++) {
        temp.push(initDate);
        newDateEnd = new Date(
          newDateEnd.getFullYear(),
          newDateEnd.getMonth(),
          newDateEnd.getDate() - 1
        );
        initDate = dateFormat(newDateEnd, "yyyy-mm-dd");
      }

      dateLabel = temp.reverse();
    } else if (dateEnd === undefined) {
      newDateStart = new Date(dateStart + "");
      initDate = dateFormat(newDateStart, "yyyy-mm-dd");
      for (var j = 0; j < 30; j++) {
        dateLabel.push(initDate);
        newDateStart = new Date(
          newDateStart.getFullYear(),
          newDateStart.getMonth(),
          newDateStart.getDate() + 1
        );
        initDate = dateFormat(newDateStart, "yyyy-mm-dd");
      }
    } else if (dateStart === undefined) {
      newDateEnd = new Date(dateEnd + "");
      initDate = dateFormat(newDateEnd, "yyyy-mm-dd");
      temp = [];
      for (var k = 0; k < 30; k++) {
        temp.push(initDate);
        newDateEnd = new Date(
          newDateEnd.getFullYear(),
          newDateEnd.getMonth(),
          newDateEnd.getDate() - 1
        );
        initDate = dateFormat(newDateEnd, "yyyy-mm-dd");
      }
      dateLabel = temp.reverse();
    } else {
      newDateStart = new Date(dateStart + "");
      initDate = dateFormat(newDateStart, "yyyy-mm-dd");
      newDateEnd = new Date(dateEnd + "");
      var targetDate = dateFormat(newDateEnd, "yyyy-mm-dd");
      while (initDate !== targetDate) {
        dateLabel.push(initDate);
        newDateStart = new Date(
          newDateStart.getFullYear(),
          newDateStart.getMonth(),
          newDateStart.getDate() + 1
        );
        initDate = dateFormat(newDateStart, "yyyy-mm-dd");
      }
      dateLabel.push(initDate);
    }

    this.setState({
      dateLabel: dateLabel,
    });

    return dateLabel;
  };

  getClassNameReactDatetimeDays = (date) => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  verifyInput = () => {
    const verifyAreaSelected =
      this.state.tabActive === "Region"
        ? this.state.selectedSalesArea.length !== 0
        : this.state.selectedDC.length !== 0;

    return (
      verifyAreaSelected &&
      this.state.dataSelectedBA.length !== 0 &&
      this.state.selectedPresenceStatus.length !== 0 &&
      this.state.startDate !== undefined &&
      this.state.endDate !== undefined
    );
  };

  divideArray(totalArr, dataCase) {
    let dividedArray = [];
    let currentIteration = 0;
    const border = Math.ceil(dataCase.length / totalArr);
    let minIndex, maxIndex;

    while (currentIteration < totalArr) {
      minIndex = currentIteration * border;
      maxIndex = (currentIteration + 1) * border;
      if (currentIteration + 1 === totalArr) {
        maxIndex = dataCase.length;
      }
      dividedArray.push([...dataCase.slice(minIndex, maxIndex)]);
      currentIteration += 1;
    }

    return dividedArray;
  }

  checkAllArr(dividedArray, query) {
    let isPassed = true;
    let index = 0;
    let combinedQuery;
    while (isPassed && index < dividedArray.length) {
      combinedQuery = query + `&ba_id=in.(${dividedArray[index]})`;
      if (combinedQuery.length > 2048) {
        isPassed = false;
      } else {
        index += 1;
      }
    }

    return isPassed;
  }

  onRegionSelected = (arr) => {
    const { listAreaByRegion } = this.state;
    let result = [];
    arr.forEach((item) => {
      const temp = listAreaByRegion.filter(
        (area) => area.sales_region_name === item.value
      );
      result = [...result, ...temp];
    });

    if (arr.length) {
      this.setState({
        listSalesArea: result,
      });
    } else {
      this.setState({
        listSalesArea: [],
      });
    }
  };

  getDataPresence = async () => {
    this.setState({
      isLoading: true,
    });
    var listPresence = [];
    var selectedStartDate = this.state.dateLabel[0];
    var selectedEndDate = this.state.dateLabel[this.state.dateLabel.length - 1];
    var listIdBA = [];
    this.state.dataSelectedBA.forEach((item) => {
      listIdBA.push(item.ba_id);
    });
    var isGetNotPresence = true;

    var capturedPresenceWriteDateByBA = {};
    var listAllPresenceWithExclByBA = {};

    listIdBA.forEach((id) => {
      capturedPresenceWriteDateByBA[id] = [];
      listAllPresenceWithExclByBA[id] = [];
    });

    var query = `sort=name.asc,nip.asc,write_date.asc&write_date=(${selectedStartDate},${selectedEndDate})`;

    if (
      this.state.selectedPresenceStatus.indexOf("All") === -1 &&
      this.state.selectedPresenceStatus.indexOf("Tidak Presensi") === -1
    ) {
      //Status selain Tidak Presensi dan All
      isGetNotPresence = false;
      query +=
        "&or=(keterangan_akhir.in.(" +
        this.state.selectedPresenceStatus +
        "),keterangan_izin.in.(" +
        this.state.selectedPresenceStatus +
        "),keterangan_awal.in.(" +
        this.state.selectedPresenceStatus +
        "))";
    } else if (this.state.selectedPresenceStatus.indexOf("All") === -1) {
      if (this.state.selectedPresenceStatus.length === 1) {
        //Hanya Status Tidak Presensi
        query +=
          "&or=(keterangan_akhir.is.null,keterangan_awal.is.null)&keterangan_izin=is.null";
      } else {
        //Status Tidak Presensi dan Status Lainnya
        query +=
          "&or=(keterangan_akhir.in.(" +
          this.state.selectedPresenceStatus +
          "),keterangan_izin.in.(" +
          this.state.selectedPresenceStatus +
          "),keterangan_awal.in.(" +
          this.state.selectedPresenceStatus +
          "),keterangan_akhir.is.null,keterangan_awal.is.null)";
      }
    }

    let checkQuery = query + "&ba_id=[" + listIdBA + "]";
    let dividedArray;

    if (checkQuery.length <= 1978) {
      query += "&ba_id=[" + listIdBA + "]";

      listPresence = await fetchAllRecapPresensiWithIzin(query);
      listPresence.forEach((presence) => {
        capturedPresenceWriteDateByBA[presence.ba_id].push(presence.write_date);
      });

      if (isGetNotPresence) {
        let queryRecap =
          `sort=name.asc,nip.asc,write_date.asc&write_date=(${selectedStartDate},${selectedEndDate})` +
          "&ba_id=[" +
          listIdBA +
          "]";

        const response = await fetchAllRecapPresensiWithIzin(queryRecap);
        response.forEach((presence) => {
          if (
            capturedPresenceWriteDateByBA[presence.ba_id].indexOf(
              presence.write_date
            ) === -1
          ) {
            listAllPresenceWithExclByBA[presence.ba_id].push(
              presence.write_date
            );
          }
        });
      }
    } else {
      let divider = 2;
      dividedArray = this.divideArray(divider, listIdBA);
      let isAllAccepted = this.checkAllArr(dividedArray, query);
      while (!isAllAccepted) {
        divider += 1;
        dividedArray = this.divideArray(divider, listIdBA);
        isAllAccepted = this.checkAllArr(dividedArray, query);
      }

      for (let i = 0; i < dividedArray.length; i += 1) {
        let usedQuery = query + "&ba_id=[" + dividedArray[i] + "]";

        let response = await fetchAllRecapPresensiWithIzin(usedQuery);

        listPresence = [...listPresence, ...response];
        response.forEach((presence) => {
          capturedPresenceWriteDateByBA[presence.ba_id].push(
            presence.write_date
          );
        });

        if (isGetNotPresence) {
          let queryRecap =
            `sort=name.asc,nip.asc,write_date.asc&write_date=(${selectedStartDate},${selectedEndDate})` +
            "&ba_id=[" +
            dividedArray[i] +
            "]";

          let response = await fetchAllRecapPresensiWithIzin(queryRecap);

          response.forEach((presence) => {
            if (
              capturedPresenceWriteDateByBA[presence.ba_id].indexOf(
                presence.write_date
              ) === -1
            ) {
              listAllPresenceWithExclByBA[presence.ba_id].push(
                presence.write_date
              );
            }
          });
        }
      }
    }

    // //Jika ada presensi ditanggal yang sama, aggregate presensi tersebut atau ambil salah satu
    var newPresences = await this.aggregateDuplicate(listPresence);

    var withEmptyPresence = [];
    if (checkQuery.length <= 2048) {
      withEmptyPresence = await this.addEmptyPresence(newPresences, listIdBA);
    } else {
      for (const dividedIdBA of dividedArray) {
        withEmptyPresence = [
          ...withEmptyPresence,
          ...(await this.addEmptyPresence(newPresences, dividedIdBA)),
        ];
      }
    }

    // //Ambil tanggal yang tidak ada presensi sama sekali
    var completePresence = this.getCompletedPresence(
      withEmptyPresence,
      listAllPresenceWithExclByBA
    );

    //Kalau pilih selain all dan tidak presensi, tidak perlu ambil tanggal yang tidak ada presensi sama sekali
    this.setState({
      showedData: isGetNotPresence ? completePresence : newPresences,
      isLoading: false,
    });
  };

  addEmptyPresence = async (newPresences, listIdBA) => {
    var newList = newPresences;
    var baIdWithPresence = await newPresences.map((item) => {
      return item.ba_id;
    });
    var emptyList = [];

    var toBeAddedBAList = [];
    for (var i = 0; i < listIdBA.length; i++) {
      if (baIdWithPresence.indexOf(listIdBA[i]) === -1) {
        toBeAddedBAList.push(listIdBA[i]);
      }
    }

    const cookies = new Cookies();

    const jwt = cookies.get("jwtKey");

    // await Axios({
    //   method: "POST",
    //   url: "/auth.php",
    //   data: {
    //     jwt: jwt,
    //     table: "v_details_ba_partner_dc_nip",
    //     method: "get",
    //     query: "ba_id=in.(" + toBeAddedBAList + ")",
    //   },
    // })

    try {
      let queryDetails = "ba_id=[" + toBeAddedBAList + "]";
      let response = await fetchAllbaPartnerDetailsDcNip(queryDetails);

      if (response.length > 0) {
        emptyList = response.map((data) => {
          return {
            ba_id: data.ba_id,
            brand: data.ba_brand,
            cust_id: data.partner_id,
            dc: data.dc,
            name: data.name,
            nip: data.NIP,
            partner_name: data.partner_name,
            write_date: "",
            waktu_awal: "-",
            keterangan_awal: "Tidak Presensi",
            waktu_akhir: "-",
            keterangan_akhir: "Tidak Presensi",
          };
        });
      }
    } catch (error) {
      console.log(error);
    }

    for (var j = 0; j < emptyList.length; j++) {
      newList.push(emptyList[j]);
    }

    return newList;
  };

  getCompletedPresence = (listPresence, listAllPresenceWithExcl) => {
    var currentUserIndex = null;
    var currentUserId = null;
    var completePresence = [];

    listPresence.forEach((item, index) => {
      var indexPresence = 0;
      if (currentUserId === null) {
        currentUserId = item.ba_id;
        currentUserIndex = index;
        indexPresence = index;

        this.state.dateLabel.forEach((date) => {
          if (listPresence[indexPresence]) {
            if (date === listPresence[indexPresence].write_date) {
              completePresence.push(
                this.getChangedNullDate(listPresence, indexPresence)
              );
              indexPresence++;
            } else {
              if (listAllPresenceWithExcl[currentUserId].indexOf(date) === -1) {
                completePresence.push(
                  this.getEmptyItem(listPresence, currentUserIndex, date)
                );
              }
            }
          } else {
            completePresence.push(
              this.getEmptyItem(listPresence, currentUserIndex, date)
            );
          }
        });
      } else if (item.ba_id !== currentUserId) {
        currentUserId = item.ba_id;
        currentUserIndex = index;
        indexPresence = index;
        this.state.dateLabel.forEach((date) => {
          if (listPresence[indexPresence]) {
            if (date === listPresence[indexPresence].write_date) {
              completePresence.push(
                this.getChangedNullDate(listPresence, indexPresence)
              );
              indexPresence++;
            } else {
              if (listAllPresenceWithExcl[currentUserId].indexOf(date) === -1) {
                completePresence.push(
                  this.getEmptyItem(listPresence, currentUserIndex, date)
                );
              }
            }
          } else {
            completePresence.push(
              this.getEmptyItem(listPresence, currentUserIndex, date)
            );
          }
        });
      }
    });

    return completePresence;
  };

  getChangedNullDate = (item, index) => {
    if (item[index].waktu_izin !== null) {
      if (
        (item[index].dc === "DC ACEH" || item[index].dc === "DC TASIKMALAYA") &&
        item[index].waktu_awal !== null &&
        item[index].write_date >= "2022-03-08" &&
        item[index].write_date <= "2022-03-15"
      ) {
        item[index].waktu_izin = null;
      } else {
        if (
          item[index].waktu_izin >= item[index].waktu_awal ||
          item[index].waktu_awal === null
        ) {
          item[index].waktu_awal = item[index].waktu_izin;
          item[index].keterangan_awal = item[index].keterangan_izin;
        }
        if (
          item[index].waktu_izin >= item[index].waktu_akhir ||
          item[index].waktu_akhir === null
        ) {
          item[index].waktu_akhir = item[index].waktu_izin;
          item[index].keterangan_akhir = item[index].keterangan_izin;
        }
      }
    } else {
      if (item[index].waktu_akhir === null) {
        item[index].waktu_akhir = "-";
        item[index].keterangan_akhir = "Tidak Presensi";
      } else if (item[index].waktu_awal === null) {
        item[index].waktu_awal = "-";
        item[index].keterangan_awal = "Tidak Presensi";
      }
    }

    return item[index];
  };

  getEmptyItem = (item, index, date) => {
    return {
      ba_id: item[index].ba_id,
      brand: item[index].brand,
      cust_id: item[index].cust_id,
      dc: item[index].dc,
      name: item[index].name,
      nip: item[index].nip,
      partner_name: item[index].partner_name,
      write_date: date,
      waktu_awal: "-",
      keterangan_awal: "Tidak Presensi",
      waktu_akhir: "-",
      keterangan_akhir: "Tidak Presensi",
    };
  };

  aggregation = (aggregated, data) => {
    aggregated.cust_id = data.cust_id;
    aggregated.partner_name = data.partner_name;
    if (data.waktu_izin !== null) {
      aggregated.waktu_awal = data.waktu_awal;
      aggregated.waktu_akhir = data.waktu_akhir;
      aggregated.keterangan_awal = data.keterangan_awal;
      aggregated.keterangan_akhir = data.keterangan_akhir;
    } else {
      if (aggregated.waktu_akhir === null) {
        aggregated.waktu_akhir = data.waktu_akhir;
        aggregated.keterangan_akhir = data.keterangan_akhir;
      } else if (aggregated.waktu_awal === null) {
        aggregated.waktu_awal = data.waktu_awal;
        aggregated.keterangan_awal = data.keterangan_awal;
      }
    }

    return aggregated;
  };

  aggregateDuplicate = async (listPresence) => {
    var presences = [...listPresence];
    var aggregatedItem = {};
    var isDuplicateFound = false;
    var newPresences = [];
    presences.forEach((presence, index) => {
      if (index + 1 < presences.length) {
        if (
          presence.write_date === presences[index + 1].write_date &&
          presence.ba_id === presences[index + 1].ba_id
        ) {
          isDuplicateFound = true;
          aggregatedItem = presence;
        } else {
          if (isDuplicateFound) {
            aggregatedItem = this.aggregation(aggregatedItem, presence);
            newPresences.push(aggregatedItem);
            aggregatedItem = {};
            isDuplicateFound = false;
          } else {
            newPresences.push(presence);
          }
        }
      } else {
        if (isDuplicateFound) {
          aggregatedItem = this.aggregation(aggregatedItem, presence);
          newPresences.push(aggregatedItem);
          aggregatedItem = {};
          isDuplicateFound = false;
        } else {
          newPresences.push(presence);
        }
      }
    });

    return newPresences;
  };

  generateData = () => {
    if (this.verifyInput()) {
      this.getDataPresence();
    } else {
      this.toggleModal("notificationModal");
    }
  };

  render() {
    var defaultSelectedBrand = [];
    if (
      this.state.listBrand.length > 0 &&
      this.state.listSelectedBrand.length > 0
    ) {
      this.state.listBrand.forEach((item) => {
        var isFound = false;
        var idx = 0;
        while (idx < this.state.listSelectedBrand.length && !isFound) {
          if (item.name === this.state.listSelectedBrand[idx]) {
            defaultSelectedBrand.push(item);
            isFound = true;
          } else {
            idx++;
          }
        }
      });
    }

    const selectedPresenceFileName =
      "BA Presence_" + DateFormat(new Date(), "yyyy-mm-dd-HHMMss") + ".csv";
    const isOpenListBa =
      this.state.tabActive === "Region"
        ? this.state.selectedSalesArea.length > 0
        : this.state.selectedDC.length > 0;
    return (
      <>
        {this.state.alert}
        <SimpleHeader
          name="Ekspor Presensi"
          parentName="Manage"
          isDiff={true}
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h2 className="mb-0">Ekspor Presensi</h2>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <h5 className="mb-3">Type</h5>
                      <SwitchGlobal
                        tabList={{ left: "Region", right: "DC" }}
                        onChange={(value) =>
                          this.setState({
                            tabActive: value,
                            selectedDC: [],
                            selectedSalesArea: [],
                            dataSelectedBA: [],
                            dataBA: [],
                          })
                        }
                      />
                    </Col>
                  </Row>
                  {this.state.tabActive === "DC" ? (
                    <Row>
                      <Col xl="3">
                        <h3>Pilih DC</h3>
                      </Col>
                      <Col xl="6">
                        <Autocomplete
                          multiple
                          id="list-dc"
                          options={this.state.listDC}
                          getOptionLabel={(option) => option.text}
                          freeSolo
                          onChange={(event, value) =>
                            this.onDCSelectedChanged(value)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Pilih DC"
                              placeholder="Pilih DC"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <>
                      {/* <Row className="mb-4">
                        <Col xl="3">
                          <h3>Pilih Region</h3>
                        </Col>
                        <Col xl="6">
                          <Autocomplete
                            multiple
                            id="list-region"
                            options={this.state.listRegion}
                            getOptionLabel={(option) => option.value}
                            freeSolo
                            onChange={(event, value) =>
                              this.onRegionSelected(value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Pilih Region"
                                placeholder="Pilih Region"
                              />
                            )}
                          />
                        </Col>
                      </Row> */}
                      <Row>
                        <Col xl="3">
                          <h3>Pilih Area Sales</h3>
                        </Col>
                        <Col xl="6">
                          <Autocomplete
                            multiple
                            id="list-areasales"
                            options={this.state.listAreaByRegion}
                            disabled={isEmpty(this.state.listAreaByRegion)}
                            getOptionLabel={(option) => option.sales_area_name}
                            freeSolo
                            onChange={(event, value) =>
                              this.onSalesAreaChanged(value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Pilih Area Sales"
                                placeholder="Pilih Area Sales"
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                  {isOpenListBa || this.state.dataSelectedBA.length > 0 ? (
                    <>
                      <Row className="mt-4">
                        <Col xl="3">
                          <h3>Pilih Brand BA</h3>
                        </Col>
                        <Col xl="6">
                          {this.state.listBrand.length > 0 ? (
                            <Autocomplete
                              multiple
                              id="list-dc"
                              options={this.state.listBrand}
                              defaultValue={defaultSelectedBrand}
                              getOptionLabel={(option) => option.name}
                              freeSolo
                              onChange={(event, value) =>
                                this.onBrandSelectedChanged(value)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Pilih Brand"
                                  placeholder="Pilih Brand"
                                />
                              )}
                            />
                          ) : null}
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col xl="3">
                          <h3>Kecualikan BA Nonaktif</h3>
                        </Col>
                        <Col xl="6">
                          <Switch
                            checked={this.state.checkedStatusFilter}
                            onChange={this.handleSwitchStatusBox.bind(this)}
                            name="checkedA"
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col xl="3">
                          <h3>Pilih Semua</h3>
                        </Col>
                        <Col xl="6">
                          <Checkbox
                            checked={this.state.dataBA.length === 0}
                            onChange={this.handleCheckBox.bind(this)}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </Col>
                      </Row>
                      {this.state.isListBALoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      ) : (
                        <>
                          <Row className="mt-4">
                            <Col xl="6">
                              <h3>BA yang belum dipilih</h3>
                            </Col>
                            <Col xl="6">
                              <h3>BA yang sudah dipilih</h3>
                            </Col>
                            <Col xl="6">
                              <ToolkitProvider
                                data={this.state.dataBA}
                                keyField="ba_id"
                                columns={[
                                  {
                                    text: "Action",
                                    dataField: "",
                                    formatter: this.GetActionSelectFormat,
                                  },
                                  {
                                    dataField: "ba_id",
                                    text: "Bivi ID",
                                    sort: true,
                                  },
                                  {
                                    dataField: "name",
                                    text: "Nama",
                                    sort: true,
                                  },
                                  {
                                    dataField: "username",
                                    text: "Username",
                                    sort: true,
                                  },
                                  {
                                    dataField: "active",
                                    text: "Active",
                                    sort: true,
                                  },
                                  {
                                    dataField: "ba_brand",
                                    text: "Brand",
                                    sort: true,
                                  },
                                  {
                                    dataField: "partner_name",
                                    text: "Nama Store",
                                    sort: true,
                                  },
                                  {
                                    dataField: "ref",
                                    text: "Customer ID",
                                    sort: true,
                                  },
                                ]}
                                search
                              >
                                {(props) => (
                                  <div className="py-4 table-responsive">
                                    <div
                                      id="datatable-basic_filter"
                                      className="dataTables_filter px-4 pb-1"
                                    >
                                      <label>
                                        Search:
                                        <SearchBar
                                          className="form-control-sm"
                                          placeholder=""
                                          {...props.searchProps}
                                        />
                                      </label>
                                    </div>
                                    <BootstrapTable
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      pagination={pagination}
                                      bordered={false}
                                      onClick={this.onBAClick.bind(this)}
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </Col>
                            <Col xl="6">
                              <ToolkitProvider
                                data={this.state.dataSelectedBA}
                                keyField="ba_id"
                                columns={[
                                  {
                                    text: "Action",
                                    dataField: "",
                                    formatter: this.GetActionDeselectFormat,
                                  },
                                  {
                                    dataField: "ba_id",
                                    text: "Bivi ID",
                                    sort: true,
                                  },
                                  {
                                    dataField: "name",
                                    text: "Nama",
                                    sort: true,
                                  },
                                  {
                                    dataField: "username",
                                    text: "Username",
                                    sort: true,
                                  },
                                  {
                                    dataField: "active",
                                    text: "Active",
                                    sort: true,
                                  },
                                  {
                                    dataField: "ba_brand",
                                    text: "Brand",
                                    sort: true,
                                  },
                                  {
                                    dataField: "partner_name",
                                    text: "Nama Store",
                                    sort: true,
                                  },
                                  {
                                    dataField: "ref",
                                    text: "Customer ID",
                                    sort: true,
                                  },
                                ]}
                                search
                              >
                                {(props) => (
                                  <div className="py-4 table-responsive">
                                    <div
                                      id="datatable-basic_filter"
                                      className="dataTables_filter px-4 pb-1"
                                    >
                                      <label>
                                        Search:
                                        <SearchBar
                                          className="form-control-sm"
                                          placeholder=""
                                          {...props.searchProps}
                                        />
                                      </label>
                                    </div>
                                    <BootstrapTable
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      pagination={pagination}
                                      bordered={false}
                                      onClick={this.onBAClick.bind(this)}
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col xl="3">
                              <h3>Pilih Status Presensi</h3>
                            </Col>
                            <Col xl="6">
                              {this.state.listPresenceStatus.length > 0 ? (
                                <Autocomplete
                                  multiple
                                  id="list-dc"
                                  options={this.state.listPresenceStatus}
                                  getOptionLabel={(option) => option}
                                  freeSolo
                                  onChange={(event, value) =>
                                    this.onPresenceStatusSelectedChanged(value)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Pilih Status"
                                      placeholder="Pilih Status"
                                    />
                                  )}
                                />
                              ) : null}
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col xl="3">
                              <h3>Pilih Rentang Tanggal</h3>
                            </Col>
                            <Col xl="4">
                              <ReactDatetime
                                inputProps={{
                                  placeholder: "Pilih Tanggal Awal",
                                }}
                                value={this.state.selectedStartDate}
                                timeFormat={false}
                                onChange={(e) =>
                                  this.handleReactDatetimeChange("startDate", e)
                                }
                                renderDay={(
                                  props,
                                  currentDate,
                                  selectedDate
                                ) => {
                                  let classes = props.className;
                                  classes +=
                                    this.getClassNameReactDatetimeDays(
                                      currentDate
                                    );
                                  return (
                                    <td {...props} className={classes}>
                                      {currentDate.date()}
                                    </td>
                                  );
                                }}
                              />
                            </Col>
                            <Col xl="4">
                              <ReactDatetime
                                inputProps={{
                                  placeholder: "Pilih Tanggal Akhir",
                                }}
                                value={this.state.endDate}
                                timeFormat={false}
                                onChange={(e) =>
                                  this.handleReactDatetimeChange("endDate", e)
                                }
                                renderDay={(
                                  props,
                                  currentDate,
                                  selectedDate
                                ) => {
                                  let classes = props.className;
                                  classes +=
                                    this.getClassNameReactDatetimeDays(
                                      currentDate
                                    );
                                  return (
                                    <td {...props} className={classes}>
                                      {currentDate.date()}
                                    </td>
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col xl="3">
                              <Button
                                onClick={this.generateData.bind(this)}
                                className="mt-4"
                                color="info"
                              >
                                Tampilkan Data
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  ) : null}
                </CardBody>
              </Card>
              {this.state.isLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </div>
              ) : null}
              {this.state.showedData.length > 0 ? (
                <Card>
                  <CardHeader>
                    <h2 className="mb-0">Hasil Data</h2>
                    <Row>
                      <Col xl="4">
                        <h3 className="mb-4">
                          DC :{" "}
                          {this.state.selectedDC.map((item, index) =>
                            index === this.state.selectedDC.length - 1
                              ? item
                              : item + ", "
                          )}
                        </h3>
                      </Col>
                      <Col xl="4">
                        <h3 className="mb-4">
                          Tanggal : {this.state.dateLabel[0]} s/d{" "}
                          {
                            this.state.dateLabel[
                            this.state.dateLabel.length - 1
                            ]
                          }
                        </h3>
                      </Col>
                      <Col xl="4">
                        <h3 className="mb-4">
                          Status :{" "}
                          {this.state.selectedPresenceStatus.map(
                            (item, index) =>
                              index ===
                                this.state.selectedPresenceStatus.length - 1
                                ? item
                                : item + ", "
                          )}
                        </h3>
                      </Col>
                    </Row>
                    {this.state.showedData.length > 0 ? (
                      <Button color="primary" size="sm" type="button">
                        <CSVLink
                          data={this.state.showedData}
                          filename={selectedPresenceFileName}
                          style={{ color: "#FFFFFF" }}
                        >
                          Download
                        </CSVLink>
                      </Button>
                    ) : null}
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xl="12">
                        <ToolkitProvider
                          data={this.state.showedData}
                          keyField="id"
                          columns={[
                            {
                              dataField: "ba_id",
                              text: "Bivi ID",
                              sort: true,
                            },
                            {
                              dataField: "name",
                              text: "Nama",
                              sort: true,
                            },
                            {
                              dataField: "username",
                              text: "Username",
                              sort: true,
                            },
                            {
                              dataField: "nip",
                              text: "NIP",
                              sort: true,
                            },
                            {
                              dataField: "brand",
                              text: "Brand",
                              sort: true,
                            },
                            {
                              dataField: "partner_name",
                              text: "Nama Store",
                              sort: true,
                            },
                            {
                              dataField: "cust_id",
                              text: "Customer ID",
                              sort: true,
                            },
                            {
                              dataField: "dc",
                              text: "DC",
                              sort: true,
                            },
                            {
                              dataField: "write_date",
                              text: "Date",
                              sort: true,
                            },
                            {
                              dataField: "waktu_awal",
                              text: "Waktu Awal",
                              sort: true,
                            },
                            {
                              dataField: "keterangan_awal",
                              text: "Keterangan Awal",
                              sort: true,
                            },
                            {
                              dataField: "waktu_akhir",
                              text: "Waktu Akhir",
                              sort: true,
                            },
                            {
                              dataField: "keterangan_akhir",
                              text: "Keterangan Akhir",
                              sort: true,
                            },
                          ]}
                          search
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1"
                              >
                                <label>
                                  Search:
                                  <SearchBar
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                              <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                pagination={pagination}
                                bordered={false}
                                onClick={this.onBAClick.bind(this)}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ) : null}
            </div>
          </Row>
          {/* Modal component */}
          <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName={this.state.createState}
            isOpen={this.state.notificationModal}
            toggle={() => this.toggleModal("notificationModal")}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-notification"></h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i className="ni ni-bell-55 ni-3x" />
                <h4 className="heading mt-4">{this.state.modalMessage}</h4>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                OK
              </Button>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default ExportPresenceBA;

/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Redirect, Route, Switch } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import Axios from "api/axios";

import Cookies from "universal-cookie";

import routes from "routes.js";

class Admin extends React.Component {
  state = {
    sidenavOpen: true,
    loggedout: false,
  };

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };
  getNavbarTheme = () => {
    if (
      this.props.location.pathname.indexOf("admin/alternative-dashboard") === 1
    ) {
      return "light";
    } else if (
      this.props.location.pathname.indexOf("admin/dashboard-partner") === 1 ||
      this.props.location.pathname === "/admin/export-presence"
    ) {
      return "partner";
    } else return "dark";
  };

  logout = () => {
    const cookies = new Cookies();
    cookies.remove("userdashboard", { path: "/" });
    cookies.remove("loggedindashboard", { path: "/" });
    cookies.remove("roledashboard", { path: "/" });
    this.setState({
      loggedout: true,
    });
  };

  componentDidMount() {
    const cookies = new Cookies();

    const userToken = cookies.get("userToken");
    let user = cookies.get("userdashboard");
    Axios({
      method: "GET",
      url: `/user_admin?username=${user.username}`,
      headers: { Authorization: `Bearer ${userToken}` },
    })
      .then((_) => {})
      .catch(() => {
        this.logout();
      });
  }
  render() {
    const cookies = new Cookies();
    if (cookies.get("loggedindashboard") !== "true") {
      return <Redirect to="/" />;
    } else {
      return (
        <>
          <Sidebar
            {...this.props}
            routes={routes}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            logo={{
              innerLink: "/",
              imgSrc: require("assets/img/brand/logo_paragon.png"),
              imgAlt: "...",
            }}
          />
          <div
            className="main-content"
            ref="mainContent"
            onClick={this.closeSidenav}
          >
            <AdminNavbar
              {...this.props}
              theme={this.getNavbarTheme()}
              toggleSidenav={this.toggleSidenav}
              sidenavOpen={this.state.sidenavOpen}
              brandText={this.getBrandText(this.props.location.pathname)}
              logout={this.logout}
            />
            <Switch>{this.getRoutes(routes)}</Switch>
            <AdminFooter />
          </div>
          {this.state.sidenavOpen ? (
            <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
          ) : null}
        </>
      );
    }
  }
}

export default Admin;

import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Container, Row, Col, Card, CardBody, Form, Label, Button } from "reactstrap";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Typography } from "@material-ui/core";
import LoadingProvider from "../../../components/digitalize-current-report/LoadingProvider";
import SnackbarAlert from "../../../components/digitalize-current-report/SnackbarAlert";
import AuthHeader from "components/Headers/AuthHeader.jsx";
import Cookies from 'universal-cookie'
import Axios from "api/axios";

let cookies = new Cookies();
export default function EditAssignedFC() {
  const { id: currentBAId } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFC, setIsLoadingFC] = useState(false);
  const [alert, setAlert] = useState({
    isVisible: false,
    severity: "error",
    message: "",
  });

  const [listDC, setListDC] = useState([]);
  const [listFC, setListFC] = useState([]);
  const [currentBA, setCurrentBA] = useState(null);

  const [selectedDC, setSelectedDC] = useState(null);
  const [selectedFCs, setSelectedFCs] = useState([]);
  const [selectedUserRelation, setSelectedUserRelation] = useState();

  const cookies = new Cookies();
  const userToken = cookies.get("userToken");

  // fetch BA by route parameter
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        // const [{ data: fetchedDCs }, { data: fetchedBA }, { data: relations }] = await Promise.all([
        const [resMasterDc, resBA, resUserAdminRelation] = await Promise.all([
          Axios({
            headers: { Authorization: `Bearer ${userToken}` },
            method: "get",
            url: "/master-dc/list",
          }),
          Axios({
            headers: { Authorization: `Bearer ${userToken}` },
            method: "get",
            url: `/v_details_ba_partner_dc_nip?ba_id=${currentBAId}`,
          }),
          Axios({
            headers: { Authorization: `Bearer ${userToken}` },
            method: "GET",
            url: `/user_admin_relation?subordinate_id=${currentBAId}&is_admin=false`,
          }),
        ]);
        const relations = resUserAdminRelation.data.data.data;
        const fetchedBA = resBA.data.data.data;
        const fetchedDCs = resMasterDc.data.data.data;

        const BA = {
          username: fetchedBA[0].username,
          ba_id: fetchedBA[0].ba_id,
          name: fetchedBA[0].name,
          nip: fetchedBA[0].NIP,
        };

        setCurrentBA(BA);
        setListDC(fetchedDCs);
        setSelectedUserRelation(relations[0]);

        const superior_ids = relations.map((elem) => elem.user_id);
        const res = await Axios({
          headers: { Authorization: `Bearer ${userToken}` },
          method: "get",
          url: `/user_admin?id=[${superior_ids.join(",")}]`,
        });
        const superiors = res.data.data.data;

        setSelectedFCs(superiors);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // set selected DC after fetching data
  useEffect(() => {
    if (!currentBA) return;
    if (!listDC.length) return;
    if (!selectedFCs.length) return;

    const [exampleFC] = selectedFCs;
    const { dc_id } = exampleFC;

    setSelectedDC(listDC.find((dc) => dc.partner_id === dc_id));
  }, [currentBA, listDC, selectedFCs]);

  // set FC list if selected DC changed
  useEffect(() => {
    if (!selectedDC) return;

    (async () => {
      try {
        setIsLoadingFC(true);
        const userToken = cookies.get("userToken");

        const res = await Axios({
          headers: { Authorization: `Bearer ${userToken}` },
          method: "GET",
          url: `/user_admin?dc_id=${selectedDC.partner_id}&role=FC`,
        });

        const fetchedFCs = res.data.data.data;

        setListFC(fetchedFCs);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingFC(false);
      }
    })();
  }, [selectedDC]);

  // event handlers
  const handleDCChange = (_, value) => {
    setSelectedDC(value);
    setSelectedFCs([]);
  };

  const handleFCChange = (_, value) => {
    const values = Array.from(new Set(value));
    setSelectedFCs(values);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const payload = selectedFCs.map((fc) => {
        return {
          user_id: fc.id,
          subordinate_id: currentBAId,
          is_admin: false,
        };
      });

      const userToken = cookies.get("userToken");

      await Axios({
        method: "delete",
        url: `/user_admin_relation/subordinate/${currentBAId}`,
        headers: { Authorization: `Bearer ${userToken}` },
      });
      if (payload.length>0){

      await Axios({
        method: "POST",
        url: "/user_admin_relation/bulk",
        data: payload,
        headers: { Authorization: `Bearer ${userToken}` },
      });
    }
        setIsLoading(false);
        fireAlert("success", "Perubahan FC berhasil disimpan");
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      fireAlert(
        "error",
        "Terjadi kesalahan, periksa terlebih dahulu koneksi anda"
      );
    }
  };

  // HELPERS
  // open alert
  const fireAlert = (severity, message) => {
    setAlert({
      isVisible: true,
      severity,
      message,
    });
  };
  // close alert
  const closeAlert = () => {
    setAlert({
      isVisible: false,
      // severity: "error",
      message: "",
    });
  };

  return (
    <LoadingProvider isLoading={isLoading}>
      <AuthHeader title="Manage BA - Edit FC" />
      <SnackbarAlert
        isVisible={alert.isVisible}
        severity={alert.severity}
        message={alert.message}
        closeEvent={() => closeAlert()}
      />

      {!isLoading && currentBA ? (
        <Container className="mt--8 pb-5">
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <Form>
                    <Row>
                      <Col xs={12} sm={6}>
                        <div className="mb-4">
                          <Typography variant="h6">Data BA</Typography>
                        </div>
                        <UserDetail attribute="ID" value={currentBA.ba_id} />
                        <UserDetail
                          attribute="Username"
                          value={currentBA.username}
                        />
                        <UserDetail attribute="Nama" value={currentBA.name} />
                        <UserDetail attribute="NIP" value={currentBA.nip} />
                      </Col>
                      <Col xs={12} sm={6}>
                        <Row>
                          <Col xs={12} className="mb-3">
                            <Label>Pilih DC</Label>
                            <Autocomplete
                              value={selectedDC}
                              multiple={false}
                              freeSolo
                              options={listDC}
                              getOptionLabel={(dc) => dc.partner_name}
                              onChange={handleDCChange}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Pilih DC"
                                  style={{ backgroundColor: "#fff" }}
                                />
                              )}
                            />
                          </Col>
                          <Col xs={12} className="mb-5">
                            <Label>Pilih FC</Label>
                            {!isLoadingFC ? (
                              <Autocomplete
                                value={selectedFCs}
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                multiple={true}
                                freeSolo
                                options={listFC}
                                getOptionLabel={(FC) => FC.name}
                                onChange={handleFCChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Pilih FC"
                                    style={{ backgroundColor: "#fff" }}
                                  />
                                )}
                              />
                            ) : (
                              <div>Loading...</div>
                            )}
                          </Col>
                          <Col
                            xs={12}
                            className="d-flex justify-content-center"
                          >
                            <Button
                              type="submit"
                              color="primary"
                              onClick={handleSubmit}
                            >
                              Update FC
                            </Button>
                            <Button
                              type="button"
                              color="danger"
                              onClick={() => history.push("/admin/master-ba")}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : null}
    </LoadingProvider>
  );
}

// COMPONENTS
const UserDetail = ({ attribute, value }) => {
  return (
    <Row>
      <Col xs={4} className="mb-4">
        <Typography variant="body1">{attribute}</Typography>
      </Col>
      <Col xs={8} className="mb-4">
        <Typography variant="body1">: {value}</Typography>
      </Col>
    </Row>
  );
};

import React from "react";
import { Row, Col } from "reactstrap";

import toYearMonthString from "../../helpers/toYearMonthString";

export default function TargetTracker({
  yearMonths,
  arr,
  uploadedYearMonthDCs,
  isAreaSales,
}) {
  return (
    <>
      <Row>
        <Col xs={12}>
          <h3>Informasi Upload Target</h3>
        </Col>
        {yearMonths.map((month, i) => {
          return (
            <Col key={i} xs={12} sm={6} md={4} lg={3} className="p-3">
              <h4>{toYearMonthString(month)}</h4>
              {arr.map((item, j) => {
                return (
                  <div
                    key={j}
                    className={
                      uploadedYearMonthDCs.indexOf(
                        `${month}--${isAreaSales ? item.id : item.partner_id}`
                      ) === -1
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    {item.partner_name || item.value}
                  </div>
                );
              })}
            </Col>
          );
        })}
      </Row>
      <Row className="my-3">
        <Col xs={12} className="p-3">
          <div className="text-success">*sudah di-upload</div>
          <div className="text-danger">*belum di-upload</div>
        </Col>
      </Row>
    </>
  );
}

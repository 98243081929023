import React from "react";

import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export default function SnackbarAlert({
  isVisible,
  severity,
  message,
  closeEvent,
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isVisible}
      autoHideDuration={6000}
      onClose={closeEvent}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={severity}
        onClose={closeEvent}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

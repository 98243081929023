/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// react plugin that prints a given react component
//import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Cookies from 'universal-cookie';
// react component used to create sweet alerts

import Axios from "api/axios";

import { Autocomplete } from "@material-ui/lab";
import { TextField, Switch } from "@material-ui/core";
// reactstrap components
import {
  Button,
  Card,
  Container,
  Modal,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import LoadingProvider from "../../../components/ba-achievement/LoadingProvider";
import SnackbarAlert from "../../../components/ba-achievement/SnackbarAlert";
import { isEmpty, find, get } from "lodash";

let cookies = new Cookies();

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const ListCompetitor = () => {
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listCompetitor, setListCompetitor] = useState([]);
  const [selectedCompetitor, setSelectedCompetitor] = useState({});
  const [warningDeleteMessage, setWarningDeleteMessage] = useState("");
  const [isOpenWarningDelete, setIsOpenWarningDelete] = useState(false);
  const [isEditedOpen, setIsEditedOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const fetchCategory = async () => {
      const userToken = cookies.get("userToken");
      const res = await Axios({
        method: "GET",
        url: "/competitor_category?sort=id.asc",
        headers: { Authorization: `Bearer ${userToken}` },
      });
      const data = res.data.data.data;

      setCategoryList(data);
    };
    fetchCategory();
  }, []);

  const fetchCompetitor = async () => {
    const userToken = cookies.get("userToken");
    const res = await Axios({
      headers: { Authorization: `Bearer ${userToken}` },
      method: "GET",
      url: "/v_competitor_detail",
    });
    const data = res.data.data.data;
    setListCompetitor(data);
  };

  useEffect(() => {
    fetchCompetitor();
  }, []);

  const closeWarningDelete = () => {
    setIsOpenWarningDelete(false);
    setWarningDeleteMessage("");
    setSelectedCompetitor({});
  };

  const openWarningDelete = (_, row) => {
    setSelectedCompetitor(row);
    setWarningDeleteMessage(
      "Apakah kamu yakin ingin menghapus kompetitor ini?"
    );
    setIsOpenWarningDelete(true);
  };

  const openEdit = (_, row) => {
    let defaultCategory = [];
    categoryList.forEach((item) => {
      if (row.category_id.includes(item.id)) {
        defaultCategory.push(item);
      }
    });
    row.defaultCategory = defaultCategory;
    row.prevBrand = row.brand;
    setSelectedCompetitor(row);
    setIsEditedOpen(true);
  };

  const closeEdit = () => {
    setIsEditedOpen(false);
    setSelectedCompetitor({});
  };

  const submitDeleteCompetitor = async () => {
    try {
      setIsLoading(true);
      const userToken = cookies.get("userToken");

      await Axios({
        method: "DELETE",
        url: `/master_competitor/${selectedCompetitor.id}`,
        headers: { Authorization: `Bearer ${userToken}` },
      });

      setToastMessage("Competitor berhasil dihapus");
      setToastSeverity("success");
      setIsToastOpen(true);
      await fetchCompetitor();
    } catch (error) {
      setToastMessage("Terjadi kesalahan mohon coba lagi");
      setToastSeverity("error");
      setIsToastOpen(true);
    } finally {
      setIsLoading(false);
      closeWarningDelete();
    }
  };

  const formatTrueFalse = (cell) => {
    return (
      <div>
        <Row style={{ margin: "auto" }}>
          {cell ? (
            <CheckCircleIcon style={{ color: "#31B057" }} />
          ) : (
            <CancelIcon style={{ color: "#E53A34" }} />
          )}
        </Row>
      </div>
    );
  };

  const formatList = (cell) => {
    return (
      <div>
        <Row style={{ margin: "auto" }}>
          {cell.map((item, index) => (
            <span>
              {item}
              {index !== cell.length - 1 ? <span>,&nbsp;</span> : null}
            </span>
          ))}
        </Row>
      </div>
    );
  };

  const deleteCompetitor = (cell, row) => {
    return (
      <div>
        <Row style={{ margin: "auto" }}>
          <IconButton
            color="error"
            component="span"
            onClick={() => openWarningDelete(cell, row)}
          >
            <Delete style={{ color: "red" }} />
          </IconButton>
        </Row>
      </div>
    );
  };

  const editCompetitor = (cell, row) => {
    return (
      <div>
        <Row style={{ margin: "auto" }}>
          <IconButton
            color="error"
            component="span"
            onClick={() => openEdit(cell, row)}
          >
            <Edit color="primary" />
          </IconButton>
        </Row>
      </div>
    );
  };

  const closeAlert = () => {
    setIsToastOpen(false);
    setToastMessage("");
  };

  const editHandler = (e) => {
    let tempObj = { ...selectedCompetitor };
    tempObj[e.target.name] = e.target.value;
    setSelectedCompetitor(tempObj);
  };

  const onCategorySelected = (_, value) => {
    let tempObj = { ...selectedCompetitor };
    tempObj.category = value;
    setSelectedCompetitor(tempObj);
  };

  const switchChanged = (type) => {
    let tempObj = { ...selectedCompetitor };
    tempObj[type] = !tempObj[type];
    setSelectedCompetitor(tempObj);
  };

  const getDuplicate = async () => {
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    let { data } = await Axios({
      method: "GET",
      url: `/master_competitor?brand=${selectedCompetitor.brand}`,
      headers: { Authorization: `Bearer ${userToken}` },
    });

    return data.data.data;
  };

  const isValid = async () => {
    const result = await getDuplicate();

    if (isEmpty(selectedCompetitor.brand)) {
      return {
        isValid: false,
        errorMessage: "Mohon isi nama brand kompetitor",
      };
    } else if (isEmpty(selectedCompetitor.category)) {
      return {
        isValid: false,
        errorMessage: "Mohon pilih kategori kompetitor",
      };
    } else if (
      result.length > 0 &&
      selectedCompetitor.brand !== selectedCompetitor.prevBrand
    ) {
      return {
        isValid: false,
        errorMessage: `Kompetitor ${selectedCompetitor.brand} sudah ada`,
      };
    }

    return {
      isValid: true,
    };
  };

  console.log(selectedCompetitor, "out <<<<");

  const getCategoryByName = (name) => {
    return get(find(categoryList, { name }),"id");
  };

  const submitEdit = async () => {
    setIsLoading(true);
    let validationResponse = await isValid();

    if (validationResponse.isValid) {
      try {
        const cookies = new Cookies();
        var user = cookies.get("userdashboard");
        let submitObj = {
          brand: selectedCompetitor.brand,
          is_popup: selectedCompetitor.is_popup,
          is_prohibit_sellout: selectedCompetitor.is_prohibit_sellout,
          user_id: user.id,
          write_date: new Date(),
        };

        if (selectedCompetitor.category.length) {
          submitObj.category = selectedCompetitor.category.map(
            (item) => item.id || getCategoryByName(item)
          );
        }

        const userToken = cookies.get("userToken");

        await Axios({
          method: "PATCH",
          url: `/master_competitor/${selectedCompetitor.id}`,
          data: {
            ...submitObj,
          },
          headers: { Authorization: `Bearer ${userToken}` },
        });

        setToastMessage("Kompetitor berhasil disubmit");
        setToastSeverity("success");
        setIsToastOpen(true);
        closeEdit();
        fetchCompetitor();
      } catch (error) {
        setToastMessage("Terjadi kesalahan mohon coba lagi");
        setToastSeverity("error");
        setIsToastOpen(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setToastMessage(validationResponse.errorMessage);
      setToastSeverity("error");
      setIsToastOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <SimpleHeader name="List Competitor" parentName="Competitor Report" />
      <Container className="mt--6" fluid>
        <LoadingProvider isLoading={isLoading} />
        <SnackbarAlert
          isVisible={isToastOpen}
          severity={toastSeverity}
          message={toastMessage}
          closeEvent={() => closeAlert()}
        />
        <Row>
          <Col xl={12}>
            <Card>
              <ToolkitProvider
                data={listCompetitor}
                keyField="id"
                columns={[
                  {
                    dataField: "brand",
                    text: "Brand",
                    sort: true,
                  },
                  {
                    dataField: "category",
                    text: "Kategori",
                    sort: true,
                    formatter: (cell) => formatList(cell),
                  },
                  {
                    dataField: "is_popup",
                    text: "Reminder",
                    sort: true,
                    formatter: (cell) => formatTrueFalse(cell),
                    style: {
                      whiteSpace: "unset",
                    },
                  },
                  {
                    dataField: "is_prohibit_sellout",
                    text: "Tidak Boleh Sell Out",
                    sort: true,
                    formatter: (cell) => formatTrueFalse(cell),
                    style: {
                      whiteSpace: "unset",
                    },
                  },
                  {
                    text: "Edit",
                    dataField: "",
                    formatter: (cell, row) => editCompetitor(cell, row),
                    style: {
                      whiteSpace: "unset",
                    },
                  },
                  {
                    text: "Delete",
                    dataField: "",
                    formatter: (cell, row) => deleteCompetitor(cell, row),
                    style: {
                      whiteSpace: "unset",
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        Search:
                        <SearchBar
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                        />
                      </label>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>

        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName={"bg-gradient-danger"}
          isOpen={isOpenWarningDelete}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Notifikasi
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={closeWarningDelete}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <h4 className="heading mt-4">{warningDeleteMessage}</h4>
              {selectedCompetitor ? (
                <h4 className="heading mt-4">{selectedCompetitor.brand}</h4>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={closeWarningDelete}
            >
              Tutup
            </Button>
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick={submitDeleteCompetitor}
            >
              Hapus
            </Button>
          </div>
        </Modal>
        <Modal className="modal-dialog-centered" isOpen={isEditedOpen}>
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Edit
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={closeEdit}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <div className="modal-body">
            <Form role="form">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect1"
                >
                  Nama Brand
                </label>
                <InputGroup
                  className="input-group-merge input-group-alternative mb-3"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                  }}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="brand"
                    placeholder="Name"
                    type="text"
                    value={selectedCompetitor.brand}
                    onChange={(e) => editHandler(e)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect1"
                >
                  Kategori
                </label>
                {!isEmpty(selectedCompetitor) && (
                  <Autocomplete
                    multiple
                    freeSolo
                    defaultValue={selectedCompetitor.defaultCategory}
                    options={categoryList || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => onCategorySelected(_, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{
                          background: "white",
                          boxShadow:
                            "0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)",
                        }}
                        variant="outlined"
                        placeholder="Pilih Kategori"
                      />
                    )}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlSelect1"
                >
                  Jika laporan kompetitor ini belum diisi, maka...
                </label>
                <Row>
                  <Col xl={6} l={6} md={6}>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Muncul pengingat
                    </label>
                    <br />
                    <Switch
                      color="primary"
                      defaultChecked={selectedCompetitor.is_popup}
                      onChange={() => switchChanged("is_popup")}
                    />
                  </Col>
                  <Col xl={6} l={6} md={6}>
                    <label
                      className="form-control-label"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Tidak bisa iisi sell out
                    </label>
                    <br />
                    <Switch
                      color="primary"
                      defaultChecked={selectedCompetitor.is_prohibit_sellout}
                      onChange={() => switchChanged("is_prohibit_sellout")}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </div>

          <div className="modal-footer">
            <Button
              className="text-black ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={closeEdit}
            >
              Tutup
            </Button>

            <Button color="primary" variant="contained" onClick={submitEdit}>
              Submit
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default ListCompetitor;

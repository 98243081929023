/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim
* Edited by : IT Paragon
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import md5 from "md5";
import Cookies from "universal-cookie";
// react library for routing
import { Link, Redirect } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeaderSignin from "components/Headers/AuthHeaderSignin.jsx";
import Axios from "api/axios";

class IndexHeader extends React.Component {
  state = {
    username: "temp",
    password: "temp",
    login: false,
    errorMessage: "",
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  encrypt = (event, obj) => {
    var encrypted = "";
    var keyLength = 100;
    var ascii;

    for (var i = 0; i < event.length; i++) {
      ascii = event.charCodeAt(i);
      if (ascii >= 65 && ascii <= 90) {
        ascii = ascii + (keyLength % 26);
        if (ascii > 90) {
          ascii = ascii - 26;
        }
      } else if (ascii >= 97 && ascii <= 122) {
        ascii = ascii + (keyLength % 26);
        if (ascii > 122) {
          ascii = ascii - 26;
        }
      }
      var res = String.fromCharCode(ascii);
      encrypted = encrypted + res;
    }

    return encrypted;
  };

  signInClicked = async (e) => {
    if (this.state.username !== '' && this.state.password !== '') {
      try {
        const { data } = await Axios({
          method: "POST",
          url: "/dashboard/login",
          data: {
            username: this.state.username,
            password: this.state.password,
            source: "bivi",
          },
        });

        const errorStatus = data.errorStatus;

        const cookies = new Cookies();

        if (errorStatus === false) {
          cookies.set("userToken", data.data.token, { path: "/" });
          cookies.set("userdashboard", data.data.user, { path: "/" });
          cookies.set("loggedindashboard", true, { path: "/" });
          cookies.set("roledashboard", data.data.role, { path: "/" });
          this.setState({
            errorMessage: "",
            login: true,
          });
        } else {
          const cookies = new Cookies();
          cookies.remove("userdashboard", { path: "/" });
          cookies.remove("loggedindashboard", { path: "/" });
          cookies.remove("roledashboard", { path: "/" });
          this.setState({
            errorMessage: "Something's wrong. Please Try Again",
          });
        }
      } catch (error) {
        console.log(error);
        this.setState({
          errorMessage:
            "Username/Password is wrong. Please check your credentials.",
        });
      }
    } else {
      const cookies = new Cookies();
      cookies.remove("userdashboard", { path: "/" });
      cookies.remove("loggedindashboard", { path: "/" });
      cookies.remove("roledashboard", { path: "/" });
      this.setState({
        errorMessage: "Username/Password cannot be empty.",
      });
    }
  };

  handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.signInClicked();
    }
  };

  render() {
    if (this.state.login) {
      return <Redirect from="*" to="/admin/dashboard" />;
    } else {
      return (
        <>
          <div className="header bg-info pt-1 pb-1">
            <Container>
              <div className="header-body">
                <Row>
                  <div className="mt-4 mb-4">
                    <h1 style={{ color: "#11cdef" }}> &nbsp; &nbsp;</h1>
                  </div>
                </Row>
                <Row className="align-items-center">
                  <Col lg="6">
                    <div className="pr-5">
                      <h1 className="display-2 text-white font-weight-bold mb-0">
                        <b>Bivi Dashboard</b>
                      </h1>
                      <h1 className="display-3 text-white font-weight-bold mb-0">
                        Version 1.0.0
                      </h1>
                      <h2 className="display-4 text-white font-weight-light">
                        PT Paragon Technology and Innovation
                      </h2>
                      <p className="text-white mt-4">
                        Bivi merupakan Platform untuk Sistem Pelaporan BA, FC,
                        dan BP secara online milik PT Paragon Tecnology and
                        Innovation
                        <br />
                        <br />
                        Dashboard ini digunakan untuk melihat data keseluruhan
                        secara umum dari hasil Bivi Apps yang digunakan oleh BA,
                        FC dan BP PT Paragon Technology and Innovation
                      </p>
                      <div className="mt-5">
                        <Button
                          className="btn-neutral my-2"
                          color="default"
                          to="/admin/dashboard"
                          tag={Link}
                        >
                          Explore Dashboard
                        </Button>
                        <Button
                          style={{ width: "30%" }}
                          className="my-2"
                          color="default"
                          href="https://bivi.pti-cosmetics.com"
                        >
                          Go To Bivi
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <Row className="justify-content-center">
                      <AuthHeaderSignin
                        title="Welcome!"
                        lead="Please Login to See Bivi Dashboard"
                      />
                      <Container className="mt--7 pb-5">
                        <Row className="justify-content-center">
                          <Col lg="9" md="7">
                            <Card className="bg-secondary border-0 mb-0">
                              <CardBody className="px-lg-5 py-lg-5">
                                <div className="text-center text-muted mb-4">
                                  <small>Sign In with Bivi Account</small>
                                </div>
                                <Form role="form">
                                  <FormGroup
                                    className={classnames("mb-3", {
                                      focused: this.state.focusedEmail,
                                    })}
                                    onChange={this.handleChange(
                                      "username"
                                    ).bind(this)}
                                  >
                                    <InputGroup className="input-group-merge input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                          <i className="ni ni-email-83" />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input
                                        placeholder="Username"
                                        type="email"
                                        onFocus={() =>
                                          this.setState({ focusedEmail: true })
                                        }
                                        onBlur={() =>
                                          this.setState({ focusedEmail: false })
                                        }
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup
                                    className={classnames({
                                      focused: this.state.focusedPassword,
                                    })}
                                    onChange={this.handleChange(
                                      "password"
                                    ).bind(this)}
                                    onKeyDown={this.handleEnter.bind(this)}
                                  >
                                    <InputGroup className="input-group-merge input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                          <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input
                                        placeholder="Password"
                                        type="password"
                                        onFocus={() =>
                                          this.setState({
                                            focusedPassword: true,
                                          })
                                        }
                                        onBlur={() =>
                                          this.setState({
                                            focusedPassword: false,
                                          })
                                        }
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                  {this.state.errorMessage !== "" && (
                                    <p style={{ color: "red", textAlign:"center", fontWeight:"600", fontSize:"0.8rem" }}>
                                      {this.state.errorMessage}
                                    </p>
                                  )}
                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="info"
                                      type="button"
                                      onClick={this.signInClicked.bind(this)}
                                    >
                                      Sign in
                                    </Button>
                                  </div>
                                </Form>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <div className="mt-4 mb-4">
                    <h1 style={{ color: "#11cdef" }}> &nbsp; &nbsp;</h1>
                  </div>
                </Row>
              </div>
            </Container>
          </div>
        </>
      );
    }
  }
}

export default IndexHeader;

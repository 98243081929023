import React from "react";
import DatePicker from "react-multi-date-picker";
import { Button } from "reactstrap";

export default function YearMonthPicker({ setMonths }) {
  const handleChange = (value) => {
    const valueString = value.toString();
    setMonths(!valueString ? [] : valueString.split(",").sort());
  };

  return (
    <div>
      <DatePicker
        format="YYYY-MM"
        multiple
        onlyMonthPicker
        minDate={new Date()}
        onChange={handleChange}
        render={(value, openCalendar) => {
          return (
            <Button
              type="button"
              color={value.toString() === "" ? "light" : "success"}
              onClick={() => openCalendar()}
            >
              Pilih Bulan
            </Button>
          );
        }}
      />
    </div>
  );
}

import Axios from "api/axios";
import Cookies from "universal-cookie";
import qs from "querystring";

export default async function postTargetPersonal(data, query) {
  try {
  const cookies = new Cookies();
  const userToken = cookies.get("userToken");

  await Axios({
    method: "DELETE",
    url: `ach_ba_target_personal/bulk`,
    headers: {
      Authorization: "Bearer " + userToken,
    },
    data: {
      ...query,
    },
  });

    await Axios({
      method: "POST",
      url: `ach_ba_target_personal/bulk`,
      data,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  } catch (err) {
    throw err;
  }
}

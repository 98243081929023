import XLSX from "xlsx";

import fetchSchemeById from "../services/fetchSchemeById";
import fetchDCsById from "../services/fetchDCsById";
import fetchAllAreaSales from "../services/fetchAllAreaSales";
import fetchParametersById from "../services/fetchParametersById";
import fetchAllDCs from "../services/fetchAllDCs";
import validateSheetData from "../helpers/validateSheetData";

export default function useParseXls({
  setTemplateDetail,
  setSheetData,
  setSheetError,
  resetState,
  setIsLoading,
}) {
  const handleFileChange = (event) => {
    setIsLoading(true);
    resetState();

    const sheetFile = event.target.files[0];
    const { name } = sheetFile;

    if (name.split(".").pop() !== "xlsx") {
      setSheetError({
        name: "invalidFileExtension",
      });
      setIsLoading(false);
      return;
    }

    const reader = new FileReader();

    reader.onload = async (onloadEvent) => {
      try {
        const { result: bufferArray } = onloadEvent.target;
        const workbook = XLSX.read(bufferArray, { type: "buffer" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const identifier = sheet["C2"].v;
        const [scheme_id, parameter_ids, dc_ids, yearMonth] =
          identifier.split("/");

        const [scheme, DCs, parameters, allDCs, allAreaSales] =
          await Promise.all([
            fetchSchemeById(scheme_id),
            fetchDCsById(dc_ids.split(",")),
            fetchParametersById(parameter_ids.split(",")),
            fetchAllDCs(),
            fetchAllAreaSales(),
          ]);

        // identifier validation
        if (
          !identifier ||
          !scheme_id ||
          !parameter_ids ||
          !dc_ids ||
          !yearMonth ||
          !scheme ||
          !DCs ||
          !parameters ||
          !allDCs ||
          !allAreaSales
        ) {
          var err = {
            name: "invalidIdentifier",
          };
          throw err;
        }

        let schemeDCs = [];
        let schemeSalesArea = [];
        if (scheme.dc_id.length) {
          schemeDCs = allDCs.filter(
            (DC) => scheme.dc_id.indexOf(DC.partner_id) !== -1
          );
        }
        if (scheme.areasales_id.length) {
          schemeSalesArea = allAreaSales.filter(
            (item) => scheme.areasales_id.indexOf(item.id) !== -1
          );
        }

        const jsonData = XLSX.utils
          .sheet_to_json(sheet, { range: 7 })
          .map((elem) => {
            return {
              ...elem,
              "Sell In": elem["Sell In"] || null,
              "Sell Out": elem["Sell Out"] || null,
            };
          });

        setSheetError(null);
        const validationError = validateSheetData(jsonData);

        if (validationError.errors.length) {
          setSheetError(validationError);
        }

        setSheetData(jsonData);
        setTemplateDetail({
          scheme,
          parameters,
          DCs,
          yearMonth,
          schemeDCs,
          schemeSalesArea,
        });
      } catch (err) {
        setSheetError(err);
      } finally {
        setIsLoading(false);
      }
    };

    reader.onerror = () => console.log("error");

    reader.readAsArrayBuffer(sheetFile);
  };

  return { handleFileChange };
}

import React from "react";
import "./card-detail.scss";
import dateFormat from "dateformat";
import { isEmpty } from "lodash";
import {Col,  Row} from "reactstrap";

const CardDetail = ({ data }) => {
  const { awal, akhir, date } = data;
  return (
    <div className="correction-att__card-detail__container">
      <div className="correction-att__card-detail__header">
        <p className="header-date">{date}</p>
      </div>
      {!isEmpty(data) && (
        <div className="correction-att__card-detail__body">
          <div className="flex-between">
            <Row>
              <Col>
                <div className="box-container">
                  <p className="card-name">Awal</p>
                  <p className="card-time">
                    {awal.time_before
                      ? dateFormat(new Date(awal.time_before), "HH:MM")
                      : "-"}{" "}
                    |{" "}
                    {akhir.time_before
                      ? dateFormat(new Date(akhir.time_before), "HH:MM")
                      : "-"}
                  </p>
                  <p className="card-description">
                    {awal.ket_before || "-"} | {akhir.ket_before || "-"}
                  </p>
                </div>
              </Col>
              <Col>
                <div className="box-container" style={{ paddingLeft: "12px" }}>
                  <p className="card-name">Perubahan</p>
                  <p className="card-time" style={{ color: "#0B525B" }}>
                    {awal.time_edited
                      ? dateFormat(new Date(awal.time_edited), "HH:MM")
                      : "-"}{" "}
                    |{" "}
                    {akhir.time_edited
                      ? dateFormat(new Date(akhir.time_edited), "HH:MM")
                      : "-"}
                  </p>
                  <p className="card-description" style={{ color: "#0B525B" }}>
                    {awal.ket_edited || "-"} | {akhir.ket_edited || "-"}
                  </p>
                </div>
              </Col>
            </Row>
            
          </div>
          <p className="reason-title">Alasan:</p>
          <p className="reason-text">{awal.alasan || akhir.alasan}</p>
        </div>
      )}
    </div>
  );
};

export default CardDetail;

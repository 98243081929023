import Axios from "services/axios";
import Cookies from "universal-cookie";

export default async function postSchemeLinesUpdate(payload) {
  try {
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    for (let i = 0; i < payload.length; i++){
      await Axios({
        method: "POST",
        url: process.env.REACT_APP_BASE_URL + `/v1/ach-scheme-line/`,
        headers: {
            "Authorization": "Bearer "+ userToken,
            Prefer: "return=representation",
        },
        data: payload[i]
      }).then((res) => {
        console.log(res)
      })
    }
  } catch (err) {
    throw err;
  }
}

import Axios from "services/axios";
import Cookies from "universal-cookie";

const fetchAllSalesRegionSalesArea = async () => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get("userToken");

        let { data } = await Axios({
            method: "GET",
            url: process.env.REACT_APP_BASE_URL + `/v1/v-sales-region-sales-area`,
            headers: {
                "Authorization": "Bearer "+ userToken
            },
            data: {},
        })
        return data.data.data;
    } catch (err) {
        throw err;
    }
}
 
export default fetchAllSalesRegionSalesArea;
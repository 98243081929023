import React, { Component } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export default class ParameterPicker extends Component {
  state = {
    listParameter: [],
  };

  componentDidMount() {
    this.setState({ listParameter: this.props.parameters });
  }

  handleChange = (event, value) => {
    this.props.setParameters(value);
  };

  render() {
    return (
      <>
        <Autocomplete
          multiple={true}
          freeSolo
          options={this.state.listParameter}
          getOptionLabel={(parameter) => parameter.name}
          onChange={this.handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Pilih Parameter"
            />
          )}
        />
      </>
    );
  }
}

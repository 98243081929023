/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import ReactDatetime from "react-datetime";
import Cookies from "universal-cookie";
// nodejs library that concatenates classes
import classnames from "classnames";
import { find } from "lodash";
import Axios from "services/axios";
import md5 from "md5";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Modal,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
// core components
import AuthHeader from "components/Headers/AuthHeader.jsx";
import Select2 from "react-select2-wrapper";
import { Link } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import LoadingProvider from "../../../components/digitalize-current-report/LoadingProvider";

class Register extends React.Component {
  state = {
    isLoading: false,

    brand: [],
    fc: [],
    listPartner: [],
    listOptionPartner: [],
    listOptionFC: [],
    selectedPartner: {},
    selectedOptionPartner: {},
    selectedFC: {},
    selectedOptionFC: {},
    selectedBrand: {},
    name: null,
    nip: null,
    email: null,
    username: null,
    password: null,
    notificationModal: false,
    createState: false,
    modalMessage: null,
    accountCreated: false,
    verifyStatus: "null",
    verifyStatusNIP: "null",
    isCreateAccountDisable: false,
    textPassword: null,
    isSuperUser: false,
    listDC: [],
    selectedDC: null,
    selectedFCs: [],
    birthdate: new Date(),
  };

  toggleModal = async (state) => {
    this.setState({
      isCreateAccountDisable: true,
    });
    var tempState;
    var message;
    console.log(this.state.birthdate);
    if (
      this.state.name !== null &&
      this.state.name !== "" &&
      this.state.birthdate &&
      this.state.selectedDC
    ) {
      if (!this.state.isCreateAccountDisable) {
        this.createAccount(state);
      } else {
        this.setState({
          [state]: !this.state[state],
        });
      }
    } else {
      tempState = "bg-gradient-danger";
      message = "Gagal! Harap Lengkapi Semua Kolom!";
      this.setState({
        [state]: !this.state[state],
        createState: tempState,
        modalMessage: message,
        isCreateAccountDisable: false,
      });
    }
  };

  async getListBrandHandler() {
    try {
      const cookies = new Cookies();
      const jwt = cookies.get("jwtKey");

      let { data } = await Axios({
        method: "POST",
        url: "/auth.php",
        data: {
          jwt: jwt,
          table: "brand",
          method: "get",
          query: `sort=id`,
        },
      });

      data = data.data;

      const filteringBrand = data.filter((brand) => brand.name !== "All");
      const formattedBrand = filteringBrand.map((brand) => {
        return {
          id: brand.id,
          text: brand.name,
        };
      });
      return formattedBrand;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  listOfColumnHandler = () => {
    const cookies = new Cookies();
    const getUser = cookies.get("userdashboard");

    if (getUser) {
      const isSuperUser = getUser.is_super_admin;
      this.setState({
        isSuperUser: isSuperUser,
      });
    }
  };
  async componentDidMount() {
    var listBrand = [];
    var itemInit = {
      id: 0,
      text: "-- Pilih Brand --",
    };

    this.listOfColumnHandler();

    const brands = await this.getListBrandHandler();

    listBrand = [itemInit, ...brands];

    var tempListOptionFC = [];
    //FOR HIDE UNUSED FC ?id=neq.32&id=neq.25&id=neq.31&id=neq.27&id=neq.23

    this.setState({ isLoading: true });

    const cookies = new Cookies();
    const jwt = cookies.get("jwtKey");

    await Axios({
      method: "POST",
      url: "/auth.php",
      data: {
        jwt: jwt,
        table: "master_fc",
        method: "get",
        query: ``,
      },
    })
      .then((response) => {
        response = response.data;
        var initItem = {
          id: -1,
          text: "-- Pilih FC --",
        };
        tempListOptionFC.push(initItem);

        response.data.forEach((fc, index) => {
          var item = {
            id: index,
            text: fc.name,
          };

          tempListOptionFC.push(item);
        });

        this.setState({
          fc: response.data,
          listOptionFC: tempListOptionFC,
          selectedOptionFC: tempListOptionFC[0],
        });

        return Axios({
          method: "POST",
          url: "/auth.php",
          data: {
            jwt: jwt,
            table: "master_dc",
            method: "get",
            query: ``,
          },
        });
      })
      .then(({ data: listDC }) => {
        // console.log(listDC)
        this.setState({ listDC: listDC.data });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });

    this.setState({
      brand: listBrand,
    });
  }

  onFCSelected = (e) => {
    var selectedID = parseInt(e.target.value);
    var tempFC = this.state.fc;
    var tempSelectedFC = tempFC[selectedID];
    var selectedOptionID = selectedID + 1;
    this.setState({
      selectedFC: tempSelectedFC,
      selectedOptionFC: this.state.listOptionFC[selectedOptionID],
    });
  };

  onPartnerSelect = (e) => {
    var selectedID = parseInt(e.target.value);
    var selectedOptionID = selectedID + 1;

    this.setState({
      selectedPartner: this.state.listPartner[selectedID],
      selectedOptionPartner: this.state.listOptionPartner[selectedOptionID],
    });
  };

  onBrandSelected = (e) => {
    const findBrand = find(this.state.brand, { id: Number(e.target.value) });
    console.log(findBrand);
    this.setState({
      selectedBrand: findBrand,
    });
  };

  inputNIP = (e) => {
    this.setState({
      nip: e.target.value,
      verifyStatusNIP: "null",
    });
  };

  inputName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  inputEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  inputUsername = (e) => {
    this.setState({
      username: e.target.value,
      email: e.target.value + "@mail.com",
      verifyStatus: "null",
    });
  };

  inputPassword = (e) => {
    this.setState({
      password: md5(e.target.value),
      textPassword: e.target.value,
    });
  };

  createAccount = async (state) => {
    var itemMasterBA = {
      name: this.state.name,
      brand: this.state.selectedBrand.id,
      NIP: this.state.nip,
      birthdate: moment(this.state.birthdate).format("YYYY-MM-DD"),
    };

    const cookies = new Cookies();
    const jwt = cookies.get("jwtKey");

    Axios({
      method: "POST",
      url: "/auth.php",
      data: {
        jwt: jwt,
        table: "master_ba",
        method: "post",
        data: itemMasterBA,
      },
    })
      .then(async () => {
        var tempState = "bg-gradient-success";
        var message = "Akun Berhasil Dibuat";
        this.setState({
          [state]: !this.state[state],
          createState: tempState,
          modalMessage: message,
          accountCreated: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  verifyUsernameandNIP = () => {
    const cookies = new Cookies();
    const jwt = cookies.get("jwtKey");

    Axios({
      method: "POST",
      url: "/auth.php",
      data: {
        jwt: jwt,
        table: "master_ba",
        method: "get",
        query: `NIP=eq.${this.state.nip}`,
      },
    })
      .then((response) => {
        response = response.data;
        if (response.data.length > 0) {
          this.setState({
            verifyStatusNIP: "unavailable",
          });
        } else {
          this.setState({
            verifyStatusNIP: "available",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const accountCreated = this.state.accountCreated;
    const toggleModal = this.toggleModal;
    return (
      <LoadingProvider isLoading={this.state.isLoading}>
        <AuthHeader
          title={
            this.state.isSuperUser
              ? "Create BA account"
              : "You don't have access to this service."
          }
        />

        {this.state.isSuperUser && (
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="6" md="8">
                <Card className="bg-secondary border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup
                        className={classnames({
                          focused: this.state.focusedName,
                        })}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Nama Lengkap
                        </label>
                        <InputGroup className="input-group-merge input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Name"
                            type="text"
                            onFocus={() => this.setState({ focusedName: true })}
                            onBlur={() => this.setState({ focusedName: false })}
                            onChange={this.inputName.bind(this)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.focusedNIP,
                        })}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Nomor Induk Pegawai (NIP)
                        </label>
                        <InputGroup className="input-group-merge input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-credit-card" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="NIP"
                            type="text"
                            onFocus={() => this.setState({ focusedNIP: true })}
                            onBlur={() => this.setState({ focusedNIP: false })}
                            onChange={this.inputNIP.bind(this)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.focusedName,
                        })}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Brand
                        </label>
                        <InputGroup className="input-group-merge input-group-alternative mb-3">
                          <Select2
                            className="form-control"
                            defaultValue="0"
                            value={this.state.selectedBrand.id}
                            options={{
                              placeholder: "Select",
                            }}
                            onSelect={this.onBrandSelected.bind(this)}
                            data={this.state.brand}
                          />
                        </InputGroup>
                      </FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect1"
                      >
                        Tanggal lahir
                      </label>
                      <ReactDatetime
                        inputProps={{
                          placeholder: "Pilih Tanggal Lahir",
                        }}
                        value={moment(this.state.birthdate).format(
                          "YYYY-MM-DD"
                        )}
                        timeFormat={false}
                        onChange={(e) => this.setState({ birthdate: e })}
                        renderDay={(props, currentDate) => {
                          return <td {...props}>{currentDate.date()}</td>;
                        }}
                      />

                      {/* <FormGroup
                        className={classnames({
                          focused: this.state.focusedUsername,
                        })}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Username
                        </label>
                        <InputGroup className="input-group-merge input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Username"
                            type="text"
                            onFocus={() =>
                              this.setState({ focusedUsername: true })
                            }
                            onBlur={() =>
                              this.setState({ focusedUsername: false })
                            }
                            onChange={this.inputUsername.bind(this)}
                          />
                        </InputGroup>
                      </FormGroup> */}
                      {/* <FormGroup
                        className={classnames({
                          focused: this.state.focusedPassword,
                        })}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Password
                        </label>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type="password"
                            onFocus={() =>
                              this.setState({ focusedPassword: true })
                            }
                            onBlur={() =>
                              this.setState({ focusedPassword: false })
                            }
                            onChange={this.inputPassword.bind(this)}
                          />
                        </InputGroup>
                      </FormGroup> */}

                      {this.state.listDC.length ? (
                        <FormGroup>
                          <FCSelection
                            listDC={this.state.listDC}
                            selectedDC={this.state.selectedDC}
                            selectedFCs={this.state.selectedFCs}
                            setSelectedDC={(value) =>
                              this.setState({ selectedDC: value })
                            }
                            setSelectedFCs={(value) =>
                              this.setState({ selectedFCs: value })
                            }
                          />
                        </FormGroup>
                      ) : null}

                      <div className="text-center">
                        {this.state.verifyStatus === "unavailable" ? (
                          <span style={{ color: "red" }}>
                            Username tidak dapat digunakan
                            <br />
                          </span>
                        ) : null}
                        {this.state.verifyStatusNIP === "unavailable" ? (
                          <span style={{ color: "red" }}>
                            NIP tidak dapat digunakan
                            <br />
                          </span>
                        ) : null}
                        {this.state.verifyStatus === "available" ? (
                          <span style={{ color: "green" }}>
                            Username dapat digunakan
                            <br />
                          </span>
                        ) : null}
                        {this.state.verifyStatusNIP === "available" ? (
                          <span style={{ color: "green" }}>
                            NIP dapat digunakan
                            <br />
                          </span>
                        ) : null}
                        {/* {this.state.verifyStatusNIP === "null" ||
                        this.state.verifyStatusNIP === "unavailable" ? (
                          <Button
                            onClick={this.verifyUsernameandNIP.bind(this)}
                            className="mt-4"
                            color="info"
                          >
                            Verify
                          </Button>
                        ) : null} */}

                        {this.state.isCreateAccountDisable ? (
                          <Button
                            className="mt-4"
                            color="info"
                            type="button"
                            disabled={this.state.isCreateAccountDisable}
                          >
                            {this.state.isCreateAccountDisable
                              ? "Loading..."
                              : "Create account"}
                          </Button>
                        ) : (
                          <Button
                            onClick={() =>
                              !this.state.isCreateAccountDisable
                                ? this.toggleModal("notificationModal")
                                : null
                            }
                            className="mt-4"
                            color="info"
                            type="button"
                            disabled={this.state.isCreateAccountDisable}
                          >
                            {this.state.isCreateAccountDisable
                              ? "Loading..."
                              : "Create account"}
                          </Button>
                        )}

                        <Modal
                          className="modal-dialog-centered modal-danger"
                          contentClassName={this.state.createState}
                          isOpen={this.state.notificationModal}
                          toggle={() => this.toggleModal("notificationModal")}
                        >
                          <div className="modal-header">
                            <h6
                              className="modal-title"
                              id="modal-title-notification"
                            >
                              Your attention is required
                            </h6>
                            <button
                              aria-label="Close"
                              className="close"
                              data-dismiss="modal"
                              type="button"
                              onClick={() =>
                                this.toggleModal("notificationModal")
                              }
                            >
                              <span aria-hidden={true}>×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="py-3 text-center">
                              <i className="ni ni-bell-55 ni-3x" />
                              <h4 className="heading mt-4">
                                {this.state.modalMessage}
                              </h4>
                            </div>
                          </div>
                          <div className="modal-footer">
                            {(function () {
                              if (accountCreated) {
                                return (
                                  <Link to="/admin/master-ba">
                                    <Button
                                      className="btn-white"
                                      color="default"
                                      type="button"
                                    >
                                      OK
                                    </Button>
                                  </Link>
                                );
                              } else {
                                return (
                                  <Button
                                    className="btn-white"
                                    color="default"
                                    type="button"
                                    onClick={() =>
                                      toggleModal("notificationModal")
                                    }
                                  >
                                    OK
                                  </Button>
                                );
                              }
                            })()}
                          </div>
                        </Modal>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </LoadingProvider>
    );
  }
}

const FCSelection = ({
  listDC,
  selectedDC,
  selectedFCs,
  setSelectedDC,
  setSelectedFCs,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [listFC, setListFC] = useState([]);

  useEffect(() => {
    (async () => {
      if (selectedDC) {
        try {
          setIsLoading(true);
          const { partner_id: dc_id } = selectedDC;
          const cookies = new Cookies();
          const jwt = cookies.get("jwtKey");

          const { data: FCs } = await Axios({
            method: "POST",
            url: "/auth.php",
            data: {
              jwt: jwt,
              table: "user_admin",
              method: "get",
              query: `role=eq.FC&dc_id=eq.${dc_id}`,
            },
          });

          setListFC(FCs.data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [selectedDC]);

  const handleDCChange = (_, value) => {
    // console.log(value)
    setSelectedDC(value);
  };

  const handleFCChange = (_, value) => {
    setSelectedFCs(value);
  };

  return (
    <>
      <div className="my-5"></div>

      {/* DC Selection */}
      <div className="mb-3">
        <label className="form-control-label">Pilih DC</label>
        <Autocomplete
          value={selectedDC}
          multiple={false}
          freeSolo
          options={listDC}
          getOptionLabel={(dc) => dc.partner_name}
          onChange={handleDCChange}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ backgroundColor: "#fff" }}
              variant="outlined"
              placeholder="Pilih DC"
            />
          )}
        />
      </div>

      {/* FC SELECTION */}
      {/* {selectedDC ? (
        <>
          <div>
            <label className="form-control-label">Pilih FC</label>

            {!isLoading ? (
              <>
                <Autocomplete
                  value={selectedFCs}
                  multiple={true}
                  freeSolo
                  options={listFC}
                  getOptionLabel={(FC) => FC.name}
                  onChange={handleFCChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ backgroundColor: "#fff" }}
                      variant="outlined"
                      placeholder="Pilih FC"
                    />
                  )}
                />
              </>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </>
      ) : null} */}
    </>
  );
};

export default Register;

import Axios from "services/axios";
import Cookies from "universal-cookie";

export default async function fetchAllRecapPresensiWithIzin(query) {
  const cookies = new Cookies();
  const userToken = cookies.get("userToken");

  try {
    let { data } = await Axios({
      method: "GET",
      url:
        process.env.REACT_APP_BASE_URL +
        `/v1/dashboard/v_fullrecap_presence_with_izin/list?${query}`,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });

    return data.data.data;
  } catch (err) {
    throw err;
  }
}

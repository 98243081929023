import React, { useState, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

import toYearMonthString from "../../helpers/toYearMonthString";

export default function YearMonthPickerAutocomplete({
  yearMonths,
  onInputChange,
  setSelectedYearMonth,
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const newValues = yearMonths.map((yearMonth) => {
      return {
        id: yearMonth,
        name: toYearMonthString(yearMonth).split(" ").reverse().join(" "),
      };
    });

    setOptions(newValues);
  }, [yearMonths]);

  const handleChange = (event, value) => {
    setSelectedYearMonth(value.id);
  };

  return (
    <Autocomplete
      disableClearable
      freeSolo
      options={options || []}
      getOptionLabel={(options) => options.name}
      onInputChange={onInputChange}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" placeholder="Pilih Bulan" />
      )}
    />
  );
}

import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
    Switch,
    Button,
    Snackbar,
    Backdrop,
    CircularProgress,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
import Axios from "services/axios";
import fetchAllParametersById from "../../../services/fetch/parameter/fetchAllParametersById";
import fetchAllTargetTable from "../../../services/fetch/target/fetchAllTargetTable";
import PatchParameterById from "../../../services/patch/patchParameterById";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ParameterDetails = () => {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [paramID, setParamID] = useState(0);
  const [paramName, setParamName] = useState("");
  const [paramSatuan, setParamSatuan] = useState("");
  const [paramTypeTarget, setParamTypeTarget] = useState("");
  const [paramIsTimebased, setParamIsTimebased] = useState(false);
  const [paramTableName, setParamTableName] = useState("");
  const [paramIsActive, setParamIsActive] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    message: "",
    alert: "success",
  });

  const [listTipeTarget, setListTipeTarget] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const cookies = new Cookies();
      var fromCookie = cookies.get("userdashboard");
      setUser(fromCookie);
      try {
        let result = await fetchAllParametersById(id)

        setParamID(result[0].id);
        setParamName(result[0].name);
        setParamSatuan(result[0].unit);
        setParamIsTimebased(result[0].is_timebased);
        setParamTableName(result[0].table_name);
        setParamIsActive(result[0].is_active);
        if (result[0].table_name === "ach_ba_target_personal") {
          setParamTypeTarget("Personal");
        } else if (result[0].table_name === "ach_ba_target_brand") {
          setParamTypeTarget("Brand");
        } else {
          //ach_ba_target_global
          setParamTypeTarget("Global");
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [id]);

  const closeSnackbar = () => {
    const alertItem = {
      isOpen: false,
      message: "",
      type: "success",
    };
    setAlert(alertItem);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await fetchAllTargetTable()
        setListTipeTarget(result);
      } catch (error) {
        setListTipeTarget([]);
      }
    };
    fetch();
  }, []);

  const formHandler = (e) => {
    if (e.target.name === "name") {
      setParamName(e.target.value);
    } else if (e.target.name === "unit") {
      setParamSatuan(e.target.value);
    } else if (e.target.name === "table_name") {
      setParamTableName(e.target.value);
    } else if (e.target.name === "is_timebased") {
      setParamIsTimebased(e.target.checked);
    } else if (e.target.name === "is_active") {
      setParamIsActive(e.target.checked);
    }
  };

  const submitParameter = async () => {
    setIsLoading(true);
    var dateFormat = require("dateformat");
    var today = new Date();
    var parameter = {
      user_id: user.ba_id ? user.ba_id : user.id,
      is_active: paramIsActive,
      is_timebased: paramIsTimebased,
      name: paramName,
      unit: paramSatuan,
      table_name: paramTableName,
      date_modified: dateFormat(today, "isoDateTime"),
    };
    
    PatchParameterById(paramID, parameter).then((response) => {
      setAlert((prev) => ({
        ...prev,
        isOpen: true,
        message: "Parameter berhasil disubmit",
        alert: "success",
      }));
    })
    .catch((error) => {
      console.log(error);
      setAlert((prev) => ({
        ...prev,
        isOpen: true,
        message: "Parameter gagal disubmit",
        alert: "success",
      }));
    })
    .finally(setIsLoading(false));
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert.isOpen}
        autoHideDuration={6000}
        onClose={() => closeSnackbar()}
      >
        <Alert onClose={() => closeSnackbar()} severity={alert.type}>
          {alert.message}
        </Alert>
      </Snackbar>
      <SimpleHeader name="Test" />
      <Container className="mt--6" fluid>
        <Backdrop open={isLoading} style={{ zIndex: "1000" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Row>
          <Col xl="8">
            <div className="col">
              <Card>
                <CardHeader>
                  <h2 className="mb-0">Test</h2>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl="12" className="mb-4">
                      <h3>Nama Parameter</h3>
                      <TextField
                        name="name"
                        onChange={(e) => formHandler(e)}
                        variant="outlined"
                        value={paramName}
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col xl="4" className="mb-4">
                      <h3>Satuan</h3>
                      <TextField
                        name="unit"
                        onChange={(e) => formHandler(e)}
                        value={paramSatuan}
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col xl="4" className="mb-4">
                      <h3>Tipe Target Sekarang</h3>
                      <TextField
                        name="unit"
                        value={paramTypeTarget}
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col xl="4" className="mb-4">
                      <h3>Tipe Target Baru</h3>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Tipe Target
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name="table_name"
                          onChange={(e) => formHandler(e)}
                          label="Tipe Target"
                        >
                          <MenuItem key={0} value="">
                            <em>None</em>
                          </MenuItem>
                          {listTipeTarget.map((tipe) => (
                            <MenuItem key={tipe.id} value={tipe.table_name}>
                              {tipe.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col xl="4">
                      <h3>Apakah parameter ini bergantung terhadap waktu?</h3>
                    </Col>
                    <Col xl="2" className="mb-6">
                      <Switch
                        checked={paramIsTimebased}
                        onChange={(e) => formHandler(e)}
                        name="is_timebased"
                        color="primary"
                      />
                    </Col>
                    <Col xl="4">
                      <h3>Apakah parameter ini aktif?</h3>
                    </Col>
                    <Col xl="2" className="mb-6">
                      <Switch
                        checked={paramIsActive}
                        onChange={(e) => formHandler(e)}
                        name="is_active"
                        color="primary"
                      />
                    </Col>
                    <Col xl="12">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "100%" }}
                        onClick={() => submitParameter()}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ParameterDetails;

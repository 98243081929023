import React from "react";
import { Alert } from "reactstrap";

export default function InvalidYearMonthAlert({ children, isSalesArea }) {
  return (
    <Alert color="secondary" className="text-danger">
      <h3 className="alert-heading">
        Skema untuk {isSalesArea ? "Area" : "DC"} dan bulan di bawah ini sudah
        pernah dibuat!
      </h3>
      <hr />
      {children}
    </Alert>
  );
}

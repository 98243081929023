import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Autocomplete } from "@material-ui/lab";
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";

import listDCRegion1 from "../../helpers/listDCRegion1.json";
import listDCRegion2 from "../../helpers/listDCRegion2.json";
import listDCRegion3 from "../../helpers/listDCRegion3.json";

export default class DCPicker extends Component {
  state = {
    selectedRadio: "manual",
  };

  handleRadioChange = (event) => {
    const { value } = event.target;
    this.setState({ selectedRadio: value });

    const filterRegion = (listDC) => {
      return this.props.DCs.filter((DC) => {
        return listDC.indexOf(DC.partner_name) !== -1;
      });
    };

    switch (value) {
      case "nasional":
        this.props.setSelectedDC(this.props.DCs);
        break;
      case "all":
        this.props.setSelectedDC(this.props.DCs);
        break;
      case "region1":
        this.props.setSelectedDC(filterRegion(listDCRegion1));
        break;
      case "region2":
        this.props.setSelectedDC(filterRegion(listDCRegion2));
        break;
      case "region3":
        this.props.setSelectedDC(filterRegion(listDCRegion3));
        break;
      default:
        this.props.setSelectedDC([]);
    }
  };

  handleChange = (event, value) => {
    this.props.setSelectedDC &&
      this.props.setSelectedDC(
        value.sort((a, b) => {
          return a.partner_name > b.partner_name ? 1 : -1;
        })
      );
  };

  render() {
    return (
      <>
        {this.props.showNationalFilter && (
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={this.state.selectedRadio}
            onChange={this.handleRadioChange}
          >
            <Row>
              <Col xs={3}>
                <FormControlLabel
                  value="nasional"
                  control={<Radio />}
                  label="Nasional"
                />
              </Col>
              <Col xs={3}>
                <FormControlLabel
                  value="region1"
                  control={<Radio />}
                  label="Region 1"
                />
              </Col>
              <Col xs={3}>
                <FormControlLabel
                  value="region2"
                  control={<Radio />}
                  label="Region 2"
                />
              </Col>
              <Col xs={3}>
                <FormControlLabel
                  value="region3"
                  control={<Radio />}
                  label="Region 3"
                />
              </Col>
              <Col xs={12}>
                <FormControlLabel
                  value="manual"
                  control={<Radio />}
                  label="Pilih Manual..."
                />
              </Col>
            </Row>
          </RadioGroup>
        )}

        {this.props.showSelectAllFilter && (
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={this.state.selectedRadio}
            onChange={this.handleRadioChange}
          >
            <Row>
              <Col xs={12}>
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="Pilih Semua DC di Skema"
                />
              </Col>
              <Col xs={12}>
                <FormControlLabel
                  value="manual"
                  control={<Radio />}
                  label="Pilih Manual..."
                />
              </Col>
            </Row>
          </RadioGroup>
        )}

        {this.state.selectedRadio === "manual" && (
          <Row>
            <Col xs={12}>
              <Autocomplete
                multiple={this.props.multiple || false}
                freeSolo
                options={this.props.DCs || []}
                getOptionLabel={(option) => option.partner_name}
                onChange={this.handleChange}
                onInputChange={this.props.onInputChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Pilih DC"
                  />
                )}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}

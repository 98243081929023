import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Axios from "api/axios";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Button,
} from "reactstrap";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Typography } from "@material-ui/core";
import LoadingProvider from "../../../components/digitalize-current-report/LoadingProvider";
import SnackbarAlert from "../../../components/digitalize-current-report/SnackbarAlert";
import AuthHeader from "components/Headers/AuthHeader.jsx";
import { find } from "lodash";
import Cookies from "universal-cookie";

export default function EditAssignedFC() {
  const { id: currentBAId } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    isVisible: false,
    severity: "error",
    message: "",
  });

  const [listArea, setListArea] = useState([]);
  const [currentBA, setCurrentBA] = useState(null);

  const [selectedArea, setSelectedArea] = useState({});

  const getMasterSalesArea = async ({ areaId }) => {
    try {
      const cookies = new Cookies();
      const userToken = cookies.get("userToken");

      const config = {
        headers: { Authorization: `Bearer ${userToken}` },
        method: "get",
        url: `/v-master-salesarea/list`,
      };

      const { data: areaList } = await Axios(config);
      const findArea = find(areaList.data.data, { id: areaId });
      setSelectedArea(findArea);
      setListArea(areaList.data.data);
    } catch (error) {}
  };

  const handleChangeArea = (_, value) => {
    setSelectedArea(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedArea && selectedArea.id) {
      try {
        const cookies = new Cookies();
        const userToken = cookies.get("userToken");

        const config = {
          headers: { Authorization: `Bearer ${userToken}` },
          method: "patch",
          url: `/master-ba/${currentBAId}`,
          data: {
            sales_area_id: selectedArea.id,
          },
        };

        await Axios(config);

        fireAlert("success", "Perubahan Area berhasil disimpan");
      } catch (error) {
        fireAlert("error", "Terjadi kesalahan!");
      }
    } else {
      fireAlert(
        "error",
        "Terjadi kesalahan, harap pilih area terlebih dahulu!"
      );
    }
  };

  const fireAlert = (severity, message) => {
    setAlert({
      isVisible: true,
      severity,
      message,
    });
  };

  const closeAlert = () => {
    setAlert({
      isVisible: false,
      message: "",
    });
  };

  // fetch BA by route parameter
  useEffect(() => {
    (async () => {
      try {
        const cookies = new Cookies();
        const userToken = cookies.get("userToken");

        const config = {
          headers: { Authorization: `Bearer ${userToken}` },
          method: "get",
          url: `/master-ba/${currentBAId}`,
        };

        const { data: masterBA } = await Axios(config);
        await getMasterSalesArea({ areaId: masterBA.data.data.sales_area_id });

        setCurrentBA(masterBA.data.data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      } finally {
      }
    })();
  }, []);

  return (
    <LoadingProvider isLoading={isLoading}>
      <AuthHeader title="Manage BA - Edit Area" />
      <SnackbarAlert
        isVisible={alert.isVisible}
        severity={alert.severity}
        message={alert.message}
        closeEvent={() => closeAlert()}
      />
      {!isLoading && currentBA ? (
        <Container className="mt--8 pb-5">
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <Form>
                    <Row>
                      <Col xs={12} sm={6}>
                        <div className="mb-4">
                          <Typography variant="h6">Data BA</Typography>
                        </div>
                        <UserDetail attribute="ID" value={currentBA.id} />
                        <UserDetail attribute="Nama" value={currentBA.name} />
                        <UserDetail attribute="NIP" value={currentBA.NIP} />
                        {selectedArea && (
                          <UserDetail
                            attribute="Area"
                            value={selectedArea.value || "-"}
                          />
                        )}
                      </Col>
                      <Col xs={12} sm={6}>
                        <Row>
                          <Col xs={12} className="mb-3">
                            <Label>Pilih Area</Label>
                            <Autocomplete
                              value={selectedArea}
                              multiple={false}
                              freeSolo
                              options={listArea}
                              getOptionLabel={(area) => area.value}
                              onChange={handleChangeArea}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Pilih Area"
                                  style={{ backgroundColor: "#fff" }}
                                />
                              )}
                            />
                          </Col>

                          <Col xs={12} className="d-flex justify-content-right">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={handleSubmit}
                            >
                              Update Area
                            </Button>
                            <Button
                              type="button"
                              color="danger"
                              onClick={() => history.push("/admin/master-ba")}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : null}
    </LoadingProvider>
  );
}

// COMPONENTS
const UserDetail = ({ attribute, value }) => {
  return (
    <Row>
      <Col xs={4} className="mb-4">
        <Typography variant="body1">{attribute}</Typography>
      </Col>
      <Col xs={8} className="mb-4">
        <Typography variant="body1">: {value}</Typography>
      </Col>
    </Row>
  );
};

import React from "react";

import CorrectionRequestListHeader from "./CorrectionRequestListHeader.jsx";
import RequestListTable from "./RequestListTable.jsx";

function CorretionRequestList() {
  
  return (
    <>
      <CorrectionRequestListHeader />
      <RequestListTable/>
    </>
  );
}

export default CorretionRequestList;



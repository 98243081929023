import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Autocomplete } from "@material-ui/lab";
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";

export default class SalesAreaPicker extends Component {
  state = {
    selectedRadio: "manual",
  };

  handleRadioChange = (event) => {
    const { value } = event.target;
    this.setState({ selectedRadio: value });

    switch (value) {
      case "all":
        this.props.setSelectedArea(this.props.listAreaSales);
        break;

      default:
        this.props.setSelectedArea([]);
    }
  };

  handleChange = (event, value) => {
    this.props.setSelectedArea &&
      this.props.setSelectedArea(
        value.sort((a, b) => {
          return a.partner_name > b.partner_name ? 1 : -1;
        })
      );
  };

  render() {
    return (
      <>
        {this.props.showSelectAllFilter && (
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={this.state.selectedRadio}
            onChange={this.handleRadioChange}
          >
            <Row>
              <Col xs={12}>
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="Pilih Semua Area di Skema"
                />
              </Col>
              <Col xs={12}>
                <FormControlLabel
                  value="manual"
                  control={<Radio />}
                  label="Pilih Manual..."
                />
              </Col>
            </Row>
          </RadioGroup>
        )}

        {this.state.selectedRadio === "manual" && (
          <Row>
            <Col xs={12}>
              <Autocomplete
                multiple={this.props.multiple || false}
                freeSolo
                options={this.props.listAreaSales || []}
                getOptionLabel={(option) => option.value}
                onChange={this.handleChange}
                onInputChange={this.props.onInputChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Pilih AreaSales "
                  />
                )}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}

import Axios from "api/axios";
import Cookies from "universal-cookie";
import qs from "querystring";

export default async function fetchBAByAreaSalesNames(arr) {
  const query = qs.stringify({
    salesarea_name: `[${arr.join(",")}]`,
  });

  const cookies = new Cookies();
  const userToken = cookies.get("userToken");

  try {
    const { data } = await Axios({
      method: "GET",
      url: `v_details_ba_historical_salesarea?${query}`,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
    return data.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

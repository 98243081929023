/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, HorizontalBar } from "react-chartjs-2";
import Cookies from "universal-cookie";
import axios from "../../../services/axios";
import ReactDatetime from "react-datetime";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import queryString from "querystring";
// core components
import CardsHeaderPartner from "components/Headers/CardsHeaderPartner.jsx";
import PartnerNotSelected from "../examples/PartnerNotSelected.jsx";

import { CSVLink } from "react-csv";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import {
  chartOptions,
  parseOptions,
  chartPartnerSales,
  chartMonthlyMostSoldSKU,
} from "variables/charts.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import fetchPartnerByID from "../../../services/fetch/partner/fetchPartnerByID";
import fetchMasterDCByID from "../../../services/fetch/masterDC/fetchMasterDCByID";
import fetchMonthContributeBA from "../../../services/fetch/monthContributeBA/fetchMonthContributeBA";
import fetchDailyFormB from "../../../services/fetch/dailyFormB/fetchDailyFormB";
import fetchMonthPartnerReport2 from "../../../services/fetch/monthlyPartner/fetchMonthPartnerReport2";
import fetchDailyPartnerReport2 from "../../../services/fetch/dailyPartner/fetchDailyPartnerReport";
import fetchMonthPartnerProductSold from "../../../services/fetch/monthlyPartner/fetchMonthPartnerProductSold";
import fetchBAPartner from "../../../services/fetch/baPartner/fetchBAPartner";
import fetchStockOpname from "../../../services/fetch/stockOpname/fetchStockOpname";
import fetchStockOpnameStore from "../../../services/fetch/stockOpname/fetchStockOpnameStore";
import fetchMonthlyBAProductSold from "../../../services/fetch/monthlyBA/fetchMonthBAProductSold";
import fetchDailyBAProductSold from "../../../services/fetch/dailyBA/fetchDailyBAProductSold";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ _, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

class DashboardPartner extends React.Component {
  state = {
    activeNav: 2,
    chartExample1Data: "data1",
    chartPartnerSales: "data1",
    selectedPartner: {},
    listMonthlySales: [],
    listDailySales: [],
    listTanggal: [],
    listMostSoldSKU: [],
    listMostSoldSKUQty: [],
    dataBA: [],
    stockOpnameResult: [],
    currentMonth: null,
    currentYear: null,
    stockOpnameDate: null,
    selectedMonthSalesDetails: null,
    selectedYearSalesDetails: null,
    selectedDaySalesDetails: null,
    printedMonthSalesDetails: null,
    printedYearSalesDetails: null,
    selectedMonthStockOpname: null,
    selectedYearStockOpname: null,
    printedMonthStockOpname: null,
    printedYearStockOpname: null,
    printedMonthFormB: null,
    printedYearFormB: null,
    selectedMonthFormB: null,
    selectedYearFormB: null,
    noNextStockOpname: true,
    isMonthly: true,
    salesDetails: [],
    salesDetailsDaily: [],
    formBDatasets: [],
    rawFormBDatasets: [],
    contributedBa: [],
    columnFormB: [
      {
        dataField: "product_name",
        text: "Nama Produk",
        sort: true,
      },
    ],
    isFormBLoading: false,
    isSalesDetailsLoading: false,
  };

  constructor() {
    super();
    this.reloadData = this.reloadData.bind(this);
    this.reloadStockOpname = this.reloadStockOpname.bind(this);
  }

  getPartnerInfo = async ({ id }) => {
    try {
      const response = await fetchPartnerByID(id);
      return response[0];
    } catch (error) {
      console.log(error);
    }
  };

  getDCInfo = async ({ id }) => {
    try {
      const response = await fetchMasterDCByID(id);
      return response[0];
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidMount() {
    const { partner_id, brand } = queryString.parse(
      window.location.search.replace("?", "")
    );

    let tempselectedPartner = {};
    const partnerInfo = await this.getPartnerInfo({ id: partner_id });

    if (partner_id && brand) {
      const dc = await this.getDCInfo({ id: partnerInfo.dc });
      tempselectedPartner = {
        partner_id,
        partner_name: partnerInfo.partner_name,
        partner_ref: partnerInfo.ref,
        konsi_putus: partnerInfo.is_consigment ? "K" : "P",
        brand,
        dc: dc.partner_name,
      };
    }

    var dateFormat = require("dateformat");
    //GET THIS MONTH AND LAST MONTH
    var today = new Date();

    var thisMonth = today.getMonth() + 1;
    var thisYear = today.getFullYear();
    var thisDay = today.getDate();

    var lastday = parseInt(
      dateFormat(new Date(today.getFullYear(), today.getMonth() + 1, 0), "dd")
    );

    var listtgl = [];
    for (var tgl = 1; tgl < lastday + 1; tgl++) {
      listtgl.push(tgl);
    }

    if (partnerInfo !== undefined) {
      this.setState({
        selectedPartner: tempselectedPartner,
        totalDayThisMonth: lastday,
        listTanggal: listtgl,
        currentMonth: today.getMonth() + 1,
        currentYear: today.getFullYear(),
        selectedMonthStockOpname: today.getMonth() + 1,
        selectedYearStockOpname: today.getFullYear(),
        printedMonthStockOpname: dateFormat(today, "mmmm"),
        printedYearStockOpname: dateFormat(today, "yyyy"),
        selectedDaySalesDetails: thisDay,
        selectedMonthSalesDetails: thisMonth,
        selectedYearSalesDetails: thisYear,
        selectedDateSalesDetails: today,
        printedMonthSalesDetails: dateFormat(today, "mmmm"),
        printedYearSalesDetails: dateFormat(today, "yyyy"),
        printedMonthFormB: dateFormat(today, "mmmm"),
        printedYearFormB: dateFormat(today, "yyyy"),
        selectedMonthFormB: today.getMonth() + 1,
        selectedYearFormB: today.getFullYear(),
      });

      this.reloadData({ partner_id, brand });

      this.reloadSalesTable(thisMonth, thisYear);
      this.reloadSalesTableDaily(thisDay, thisMonth, thisYear);
      this.reloadFormBTable(thisMonth, thisYear);
      this.loadFormBColumn(thisMonth, thisYear);
    }
  }

  loadFormBColumn = (month, year) => {
    var sub;
    var formBColumn = [];
    var lastDate = parseInt(new Date(year, month + 1, 0).getDate());
    var productCode = {
      dataField: "default_code",
      text: "Kode Produk",
      sort: true,
    };
    var productNameColumn = {
      dataField: "product_name",
      text: "Nama Produk",
      sort: true,
    };
    var productPriceColumn = {
      dataField: "product_value",
      text: "Harga",
      sort: true,
    };
    formBColumn.push(productCode);
    formBColumn.push(productNameColumn);
    formBColumn.push(productPriceColumn);
    for (var i = 1; i < lastDate + 1; i++) {
      var items = {
        dataField: "tgl" + i,
        text: i + "",
        sort: true,
      };
      formBColumn.push(items);
      if (i === 7) {
        sub = {
          dataField: "sub1",
          text: "sub total",
          sort: true,
        };
        formBColumn.push(sub);
      } else if (i === 14) {
        sub = {
          dataField: "sub2",
          text: "sub total",
          sort: true,
        };
        formBColumn.push(sub);
      } else if (i === 21) {
        sub = {
          dataField: "sub3",
          text: "sub total",
          sort: true,
        };
        formBColumn.push(sub);
      } else if (i === 28) {
        sub = {
          dataField: "sub4",
          text: "sub total",
          sort: true,
        };
        formBColumn.push(sub);
      }
    }

    var totalQty = {
      dataField: "qty_total",
      text: "Unit",
      sort: true,
    };
    var totalValue = {
      dataField: "total_value",
      text: "Value",
      sort: true,
    };

    formBColumn.push(totalQty);
    formBColumn.push(totalValue);
    this.setState({
      columnFormB: formBColumn,
    });
  };

  reloadFormBTable = async (month, year) => {
    this.setState({
      isFormBLoading: true,
      formBDatasets: [],
    });

    let queryMCBA =
      `select=ba_name,nip&month=${month}&year=${year}&partner_id=${this.state.selectedPartner.partner_id}&brand=${this.state.selectedPartner.brand}`.replace(
        / /g,
        "%20"
      );
    const responseMCBA = await fetchMonthContributeBA(queryMCBA);
    
      this.setState({
        contributedBa: responseMCBA,
      });

    // const configFormB = {
    //   jwt: userToken,
    //   method: "get",
    //   table: "v_daily_form_b",
    //   query:
    // `default_code=not.eq.null&month=eq.${month}&year=eq.${year}&partner_id=eq.${this.state.selectedPartner.partner_id}&ba_brand=eq.${this.state.selectedPartner.brand}`.replace(
    //   / /g,
    //   "%20"
    // ),
    // };
    let queryCFB =
      `default_code=ne.null&month=${month}&year=${year}&partner_id=${this.state.selectedPartner.partner_id}&ba_brand=${this.state.selectedPartner.brand}`.replace(
        / /g,
        "%20"
      );
    try{
    const responseCFB = await fetchDailyFormB(queryCFB);
   
      var processedFormB = [];
      var rawProcessedFormB = [];
      var totalItem = {
        tgl1: 0,
        tgl2: 0,
        tgl3: 0,
        tgl4: 0,
        tgl5: 0,
        tgl6: 0,
        tgl7: 0,
        tgl8: 0,
        tgl9: 0,
        tgl10: 0,
        tgl11: 0,
        tgl12: 0,
        tgl13: 0,
        tgl14: 0,
        tgl15: 0,
        tgl16: 0,
        tgl17: 0,
        tgl18: 0,
        tgl19: 0,
        tgl20: 0,
        tgl21: 0,
        tgl22: 0,
        tgl23: 0,
        tgl24: 0,
        tgl25: 0,
        tgl26: 0,
        tgl27: 0,
        tgl28: 0,
        tgl29: 0,
        tgl30: 0,
        tgl31: 0,
        sub1: 0,
        sub2: 0,
        sub3: 0,
        sub4: 0,
        qty_total: 0,
        total_value: 0,
      };
      totalItem.product_name = "TOTAL";
      totalItem.product_value = null;
      totalItem.default_code = null;
      responseCFB.forEach((prod) => {
        totalItem.tgl1 = totalItem.tgl1 + (prod.tgl1 ? parseInt(prod.tgl1) : 0);
        totalItem.tgl2 = totalItem.tgl2 + (prod.tgl2 ? parseInt(prod.tgl2) : 0);
        totalItem.tgl3 = totalItem.tgl3 + (prod.tgl3 ? parseInt(prod.tgl3) : 0);
        totalItem.tgl4 = totalItem.tgl4 + (prod.tgl4 ? parseInt(prod.tgl4) : 0);
        totalItem.tgl5 = totalItem.tgl5 + (prod.tgl5 ? parseInt(prod.tgl5) : 0);
        totalItem.tgl6 = totalItem.tgl6 + (prod.tgl6 ? parseInt(prod.tgl6) : 0);
        totalItem.tgl7 = totalItem.tgl7 + (prod.tgl7 ? parseInt(prod.tgl7) : 0);
        totalItem.tgl8 = totalItem.tgl8 + (prod.tgl8 ? parseInt(prod.tgl8) : 0);
        totalItem.tgl9 = totalItem.tgl9 + (prod.tgl9 ? parseInt(prod.tgl9) : 0);
        totalItem.tgl10 = totalItem.tgl10 + (prod.tgl10 ? parseInt(prod.tgl10) : 0);
        totalItem.tgl11 = totalItem.tgl11 + (prod.tgl11 ? parseInt(prod.tgl11) : 0);
        totalItem.tgl12 = totalItem.tgl12 + (prod.tgl12 ? parseInt(prod.tgl12) : 0);
        totalItem.tgl13 = totalItem.tgl13 + (prod.tgl13 ? parseInt(prod.tgl13) : 0);
        totalItem.tgl14 = totalItem.tgl14 + (prod.tgl14 ? parseInt(prod.tgl14) : 0);
        totalItem.tgl15 = totalItem.tgl15 + (prod.tgl15 ? parseInt(prod.tgl15) : 0);
        totalItem.tgl16 = totalItem.tgl16 + (prod.tgl16 ? parseInt(prod.tgl16) : 0);
        totalItem.tgl17 = totalItem.tgl17 + (prod.tgl17 ? parseInt(prod.tgl17) : 0);
        totalItem.tgl18 = totalItem.tgl18 + (prod.tgl18 ? parseInt(prod.tgl18) : 0);
        totalItem.tgl19 = totalItem.tgl19 + (prod.tgl19 ? parseInt(prod.tgl19) : 0);
        totalItem.tgl20 = totalItem.tgl20 + (prod.tgl20 ? parseInt(prod.tgl20) : 0);
        totalItem.tgl21 = totalItem.tgl21 + (prod.tgl21 ? parseInt(prod.tgl21) : 0);
        totalItem.tgl22 = totalItem.tgl22 + (prod.tgl22 ? parseInt(prod.tgl22) : 0);
        totalItem.tgl23 = totalItem.tgl23 + (prod.tgl23 ? parseInt(prod.tgl23) : 0);
        totalItem.tgl24 = totalItem.tgl24 + (prod.tgl24 ? parseInt(prod.tgl24) : 0);
        totalItem.tgl25 = totalItem.tgl25 + (prod.tgl25 ? parseInt(prod.tgl25) : 0);
        totalItem.tgl26 = totalItem.tgl26 + (prod.tgl26 ? parseInt(prod.tgl26) : 0);
        totalItem.tgl27 = totalItem.tgl27 + (prod.tgl27 ? parseInt(prod.tgl27) : 0);
        totalItem.tgl28 = totalItem.tgl28 + (prod.tgl28 ? parseInt(prod.tgl28) : 0);
        totalItem.tgl29 = totalItem.tgl29 + (prod.tgl29 ? parseInt(prod.tgl29) : 0);
        totalItem.tgl30 = totalItem.tgl30 + (prod.tgl30 ? parseInt(prod.tgl30) : 0);
        totalItem.tgl31 = totalItem.tgl31 + (prod.tgl31 ? parseInt(prod.tgl31) : 0);
        prod.product_value =
          "Rp." +
          prod.product_price.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          });
        prod.sub1 =
          (parseInt(prod.tgl1) || 0) +
          (parseInt(prod.tgl2) || 0) +
          (parseInt(prod.tgl3) || 0) +
          (parseInt(prod.tgl4) || 0) +
          (parseInt(prod.tgl5) || 0) +
          (parseInt(prod.tgl6) || 0) +
          (parseInt(prod.tgl7) || 0);
        prod.sub2 =
          (parseInt(prod.tgl8)|| 0) +
          (parseInt(prod.tgl9)|| 0) +
          (parseInt(prod.tgl10) || 0) +
          (parseInt(prod.tgl11) || 0) +
          (parseInt(prod.tgl12) || 0) +
          (parseInt(prod.tgl13) || 0) +
          (parseInt(prod.tgl14) || 0);
        prod.sub3 =
          (parseInt(prod.tgl15) || 0) +
          (parseInt(prod.tgl16) || 0) +
          (parseInt(prod.tgl17) || 0) +
          (parseInt(prod.tgl18) || 0) +
          (parseInt(prod.tgl19) || 0) +
          (parseInt(prod.tgl20) || 0) +
          (parseInt(prod.tgl21) || 0);
        prod.sub4 =
          (parseInt(prod.tgl22) || 0) +
          (parseInt(prod.tgl23) || 0) +
          (parseInt(prod.tgl24) || 0) +
          (parseInt(prod.tgl25) || 0) +
          (parseInt(prod.tgl26) || 0) +
          (parseInt(prod.tgl27) || 0) +
          (parseInt(prod.tgl28) || 0);
        prod.qty_total =
          prod.sub1 +
          prod.sub2 +
          prod.sub3 +
          prod.sub4 +
          (parseInt(prod.tgl29) || 0) +
          (parseInt(prod.tgl30) || 0) +
          (parseInt(prod.tgl31) || 0);
        prod.total_value =
          "Rp." +
          (prod.qty_total * prod.product_price).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          });
        totalItem.sub1 = totalItem.sub1 + prod.sub1;
        totalItem.sub2 = totalItem.sub2 + prod.sub2;
        totalItem.sub3 = totalItem.sub3 + prod.sub3;
        totalItem.sub4 = totalItem.sub4 + prod.sub4;
        totalItem.qty_total = totalItem.qty_total + prod.qty_total;
        totalItem.total_value =
          totalItem.total_value + prod.qty_total * prod.product_price;

        var processedItem = {
          "PARTNER NAME": this.state.selectedPartner.partner_name,
          "CUST ID": this.state.selectedPartner.partner_ref,
          "K/P": this.state.selectedPartner.konsi_putus,
          BRAND: this.state.selectedPartner.brand,
          "KODE PRODUK": prod.default_code,
          "NAMA PRODUK": prod.product_name,
          "HARGA PRODUK": prod.product_price,
          "TGL 1": prod.tgl1 || 0,
          "TGL 2": prod.tgl2 || 0,
          "TGL 3": prod.tgl3 || 0,
          "TGL 4": prod.tgl4 || 0,
          "TGL 5": prod.tgl5 || 0,
          "TGL 6": prod.tgl6 || 0,
          "TGL 7": prod.tgl7 || 0,
          SUBTOTAL: prod.sub1 || 0,
          "TGL 8": prod.tgl8 || 0,
          "TGL 9": prod.tgl9 || 0,
          "TGL 10": prod.tgl10 || 0,
          "TGL 11": prod.tgl11 || 0,
          "TGL 12": prod.tgl12 || 0,
          "TGL 13": prod.tgl13 || 0,
          "TGL 14": prod.tgl14 || 0,
          "SUBTOTAL-2": prod.sub2 || 0,
          "TGL 15": prod.tgl15 || 0,
          "TGL 16": prod.tgl16 || 0,
          "TGL 17": prod.tgl17 || 0,
          "TGL 18": prod.tgl18 || 0,
          "TGL 19": prod.tgl19 || 0,
          "TGL 20": prod.tgl20 || 0,
          "TGL 21": prod.tgl21 || 0,
          "SUBTOTAL-3": prod.sub3 || 0,
          "TGL 22": prod.tgl22 || 0,
          "TGL 23": prod.tgl23 || 0,
          "TGL 24": prod.tgl24 || 0,
          "TGL 25": prod.tgl25 || 0,
          "TGL 26": prod.tgl26 || 0,
          "TGL 27": prod.tgl27 || 0,
          "TGL 28": prod.tgl28 || 0,
          "SUBTOTAL-4": prod.sub4 || 0,
          "TGL 29": prod.tgl29 || 0,
          "TGL 30": prod.tgl30 || 0,
          "TGL 31": prod.tgl31 || 0,
          QTY: prod.qty_total,
          "TOTAL VALUE": prod.qty_total * prod.product_price,
        };
        rawProcessedFormB.push(processedItem);
        processedFormB.push(prod);
      });

      totalItem.total_value =
        "Rp." +
        totalItem.total_value.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        });
      processedFormB.push(totalItem);
      this.setState({
        formBDatasets: processedFormB,
        rawFormBDatasets: rawProcessedFormB,
      });
    }catch (err) {
      console.error(err);
  }

    this.setState({
      isFormBLoading: false,
    });
  };

  reloadData = async (partner) => {
    var dateFormat = require("dateformat");
    //GET THIS MONTH AND LAST MONTH
    var today = new Date();
    var thismonth = dateFormat(today, "m");
    var year = dateFormat(today, "yyyy");
    var lastday = parseInt(
      dateFormat(new Date(today.getFullYear(), today.getMonth() + 1, 0), "dd")
    );

    var listtgl = [];
    for (var tgl = 1; tgl < lastday + 1; tgl++) {
      listtgl.push(tgl);
    }
    var currentMonth = today.getMonth() + 1;
    var currentYear = today.getFullYear();

    //TOTAL VISITOR, TOTAL PENJUALAN, TOTAL PCS SOLD RECORD
    var month;
    var idx_getdata_monthly = 0;

    if (partner !== undefined) {
      let queryMPR2 =
        `brand=${partner.brand}&partner_id=${partner.partner_id}&year=${year}`.replace(
          / /g,
          "%20"
        );
      const responseMPR2 = await fetchMonthPartnerReport2(queryMPR2);
        var tempListMonthlySales = [];
        for (month = 1; month <= 12; month++) {
          if (month === responseMPR2[idx_getdata_monthly].month) {
            tempListMonthlySales.push(
              responseMPR2[idx_getdata_monthly].monthly_total_sold / 1000000
            );
            idx_getdata_monthly++;
          } else {
            tempListMonthlySales.push(0);
          }
        }
        this.setState({
          listMonthlySales: tempListMonthlySales,
        });

      // GET DAILY SALES PARTNER
      var idx_getdata_daily = 0;
      let queryDPR2 =
        `brand=${partner.brand}&partner_id=${partner.partner_id}&month=${thismonth}&year=${year}`.replace(
          / /g,
          "%20"
        );
        try{
      const responseDPR2 = await fetchDailyPartnerReport2(queryDPR2);
        var tempListDailySales = [];

        var length = responseDPR2.length - 1;
        var lastDayDailySales = responseDPR2[length].day;
        listtgl.forEach((date) => {
          if (date > lastDayDailySales) {
            //DO NOTHING
          } else if (parseInt(date) === parseInt(responseDPR2[idx_getdata_daily].day)) {
            tempListDailySales.push(
              responseDPR2[idx_getdata_daily].daily_total_sold / 1000
            );
            idx_getdata_daily++;
          } else {
            tempListDailySales.push(0);
          }
        });

        this.setState({
          listDailySales: tempListDailySales,
        });
    }catch (err) {
      console.error(err);
      
    }
      //GET TOTAL SOLD SKU BY PARTNER
      let queryMPPS =
        `brand=${partner.brand}&month=${thismonth}&year=${year}&partner_id=${partner.partner_id}`.replace(
          / /g,
          "%20"
        );
        try{
      const responseMPPS = await fetchMonthPartnerProductSold(queryMPPS);
        var listSKU = [];
        var listQty = [];
        var top = 10;
        if (responseMPPS.length < top) {
          top = responseMPPS.length;
        }
        var idx = 0;
        while (idx < top) {
          listSKU.push(responseMPPS[idx].product_name);
          listQty.push(responseMPPS[idx].total_sold);
          idx++;
        }

        this.setState({
          listMostSoldSKU: listSKU,
          listMostSoldSKUQty: listQty,
        });
    }catch (err) {
      console.error(err);
      
  }
      let queryBAP = `ba_id=or(lte.979,gte.1070)`;
      const responseBAP = await fetchBAPartner(queryBAP);
        this.setState({
          dataBA: responseBAP,
        });

      //GET STOCK OPNAME RESULT
      let querySO = `partner_id=${partner.partner_id}&month=${currentMonth}&year=${currentYear}`;
      const responseSO = await fetchStockOpname(querySO);
        if (responseSO.length > 0) {
          let querySOS = `select=write_date&limit=1&sort=write_date.desc&partner_id=${partner.partner_id}`;
          const responseSOS = await fetchStockOpnameStore(querySOS);
          responseSOS.then((lastDate) => {
            this.setState({
              stockOpnameDate: (lastDate[0].write_date || "").slice(0, 10),
            });
          });
        }
        this.setState({
          stockOpnameResult: responseSO,
        });
    }
  };

  async reloadSalesTable(month, year) {
    this.setState({
      isSalesDetailsLoading: true,
      salesDetails: [],
    });
    const partner = this.state.selectedPartner;
    let queryMBAPS =
      `select=product_name,total_sold&ba_brand=${partner.brand}&partner_id=${partner.partner_id}&month=${month}&year=${year}`.replace(
        / /g,
        "%20"
      );
      try {
        const responseMBAPS = await fetchMonthlyBAProductSold(queryMBAPS);
        this.setState({
          salesDetails: responseMBAPS,
          isSalesDetailsLoading: false,
        });
      } catch (err) {
          console.error(err);
          this.setState({
            isSalesDetailsLoading: false,
          });
      }
  }

  async reloadSalesTableDaily(day, month, year) {
    this.setState({
      isSalesDetailsLoading: true,
      salesDetailsDaily: [],
    });

    const { partner_id, brand } = queryString.parse(
      window.location.search.replace("?", "")
    );

    const cookies = new Cookies();
    const userToken = cookies.get("jwtKey");
    // const config = {
    //   jwt: userToken,
    //   method: "get",
    //   table: "v_daily_ba_product_sold",
    //   query:
    // `select=product_name,total_sold&ba_brand=eq.${brand}&partner_id=eq.${partner_id}&day=eq.${day}&month=eq.${month}&year=eq.${year}`.replace(
    //   / /g,
    //   "%20"
    // ),
    // };
    let queryDBPS =
      `select=product_name,total_sold&ba_brand=${brand}&partner_id=${partner_id}&day=${day}&month=${month}&year=${year}`.replace(
        / /g,
        "%20"
      );
      try{
    const responseDBPS = await fetchDailyBAProductSold(queryDBPS);
        this.setState({
          salesDetailsDaily: responseDBPS,
          isSalesDetailsLoading: false,
        });

    }catch(err){
        console.log(err);
        this.setState({
          isSalesDetailsLoading: false,
        });
    }
  }

  formBPrevMonth() {
    var dateFormat = require("dateformat");
    var month = this.state.selectedMonthFormB;
    var year = this.state.selectedYearFormB;
    var fullDate = new Date(year, month - 2, 1);
    this.reloadFormBTable(fullDate.getMonth() + 1, fullDate.getFullYear());

    this.setState({
      selectedMonthFormB: fullDate.getMonth() + 1,
      selectedYearFormB: fullDate.getFullYear(),
      printedMonthFormB: dateFormat(
        new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearFormB: dateFormat(
        new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 0),
        "yyyy"
      ),
    });
  }

  formBNextMonth() {
    var dateFormat = require("dateformat");
    var month = this.state.selectedMonthFormB;
    var year = this.state.selectedYearFormB;
    var fullDate = new Date(year, month, 1);
    this.reloadFormBTable(fullDate.getMonth() + 1, fullDate.getFullYear());

    this.setState({
      selectedMonthFormB: fullDate.getMonth() + 1,
      selectedYearFormB: fullDate.getFullYear(),
      printedMonthFormB: dateFormat(
        new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearFormB: dateFormat(
        new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 0),
        "yyyy"
      ),
    });
  }

  salesDetailsPrevMonth() {
    var dateFormat = require("dateformat");
    var day = this.state.selectedDaySalesDetails;
    var month = this.state.selectedMonthSalesDetails;
    var year = this.state.selectedYearSalesDetails;
    var fullDate = new Date(year, month - 2, day);

    this.reloadSalesTable(fullDate.getMonth() + 1, fullDate.getFullYear());
    this.reloadSalesTableDaily(
      day,
      fullDate.getMonth() + 1,
      fullDate.getFullYear()
    );

    this.setState({
      selectedMonthSalesDetails: fullDate.getMonth() + 1,
      selectedYearSalesDetails: fullDate.getFullYear(),
      printedMonthSalesDetails: dateFormat(
        new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearSalesDetails: dateFormat(
        new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 0),
        "yyyy"
      ),
    });
  }

  salesDetailsNextMonth() {
    var dateFormat = require("dateformat");
    var day = this.state.selectedDaySalesDetails;
    var month = this.state.selectedMonthSalesDetails;
    var year = this.state.selectedYearSalesDetails;
    var fullDate = new Date(year, month, day);
    this.reloadSalesTable(fullDate.getMonth() + 1, fullDate.getFullYear());
    this.reloadSalesTableDaily(
      day,
      fullDate.getMonth() + 1,
      fullDate.getFullYear()
    );

    this.setState({
      selectedMonthSalesDetails: fullDate.getMonth() + 1,
      selectedYearSalesDetails: fullDate.getFullYear(),
      printedMonthSalesDetails: dateFormat(
        new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 0),
        "mmmm"
      ),
      printedYearSalesDetails: dateFormat(
        new Date(fullDate.getFullYear(), fullDate.getMonth() + 1, 0),
        "yyyy"
      ),
    });
  }

  handleDailySalesDetailsChange = (date) => {
    var dateFormat = require("dateformat");
    var day = parseInt(dateFormat(date._d, "d"));
    var month = parseInt(dateFormat(date._d, "m"));
    var year = parseInt(dateFormat(date._d, "yyyy"));

    this.reloadSalesTableDaily(day, month, year);
    this.reloadSalesTable(month, year);

    this.setState({
      selectedDaySalesDetails: day,
      selectedMonthSalesDetails: month,
      selectedYearSalesDetails: year,
      selectedDateSalesDetails: new Date(year, month - 1, day),
      printedMonthSalesDetails: dateFormat(new Date(year, month, 0), "mmmm"),
      printedYearSalesDetails: dateFormat(new Date(year, month, 0), "yyyy"),
    });
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  stockOpnamePrevMonth = () => {
    var changedMonth = this.state.selectedMonthStockOpname - 1;

    var dateFormat = require("dateformat");
    var date = new Date(this.state.selectedYearStockOpname, changedMonth, 0);

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    this.reloadStockOpname(compiledMonth, compiledYear);

    this.setState({
      noNextStockOpname: false,
      selectedMonthStockOpname: compiledMonth,
      selectedYearStockOpname: compiledYear,
      printedMonthStockOpname: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearStockOpname: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  stockOpnameNextMonth = () => {
    var changedMonth = this.state.selectedMonthStockOpname + 1;

    if (changedMonth === this.state.currentMonth) {
      this.setState({
        noNextStockOpname: true,
      });
    }

    var dateFormat = require("dateformat");
    var date = new Date(this.state.selectedYearStockOpname, changedMonth, 0);

    var compiledMonth = date.getMonth() + 1;
    var compiledYear = date.getFullYear();

    if (compiledMonth === 0) {
      compiledMonth = 12;
    }

    this.reloadStockOpname(compiledMonth, compiledYear);

    this.setState({
      selectedMonthStockOpname: compiledMonth,
      selectedYearStockOpname: compiledYear,
      printedMonthStockOpname: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "mmmm"
      ),
      printedYearStockOpname: dateFormat(
        new Date(compiledYear, compiledMonth, 0),
        "yyyy"
      ),
    });
  };

  async reloadStockOpname(month, year) {
    //GET STOCK OPNAME RESULT
    var dateFormat = require("dateformat");
    var date = dateFormat(
      new Date(this.state.selectedYearStockOpname, month, 0),
      "yyyy-mm-dd'T'23:59:59"
    );
    const querySO_ = `partner_id=${this.state.selectedPartner.partner_id}&month=${month}&year=${year}`;
    const responseSO_ = await fetchStockOpname(querySO_);
    responseSO_.then(async (res) => {
      if (res.length > 0) {
        // WHAT IS LTE?
        const querySOS_ = `select=write_date&write_date=lte.${date}&limit=1&sort=write_date.desc&partner_id=${this.state.selectedPartner.partner_id}`;
        const responseSOS_ = await fetchStockOpnameStore(querySOS_);
        responseSOS_.then((lastDate) => {
          this.setState({
            stockOpnameDate: (lastDate[0].write_date || "").slice(0, 10),
          });
        });
      }
      this.setState({
        stockOpnameResult: res,
      });
    });
  }

  render() {
    var dateFormat = require("dateformat");

    var salesDetails;
    if (this.state.isMonthly) {
      salesDetails = this.state.salesDetails;
    } else {
      salesDetails = this.state.salesDetailsDaily;
    }

    const activeNav = this.state.activeNav;
    const listMonthlySales = this.state.listMonthlySales;
    const listDailySales = this.state.listDailySales;
    const tanggal = this.state.listTanggal;
    const month = dateFormat(new Date(), "mmmm");
    const listMostSoldSKU = this.state.listMostSoldSKU;
    const listMostSoldSKUQty = this.state.listMostSoldSKUQty;

    var partnerNameHeader = "";
    if (this.state.selectedPartner.brand !== undefined) {
      partnerNameHeader =
        this.state.selectedPartner.partner_name +
        " " +
        this.state.selectedPartner.brand.slice(6);
    }

    const formBFileName =
      "Form B " +
      this.state.selectedPartner.partner_name +
      " " +
      this.state.selectedPartner.dc +
      " " +
      this.state.printedMonthFormB +
      " " +
      this.state.printedYearFormB +
      ".csv";

    if (
      this.state.selectedPartner.partner_id === undefined ||
      this.state.selectedPartner.partner_id === null
    ) {
      return <PartnerNotSelected />;
    } else {
      return (
        <>
          <CardsHeaderPartner
            name={partnerNameHeader}
            parentName="Dashboards Partner"
          />
          <Container className="mt--6" fluid>
            <Row>
              <Col xl="8">
                <Card className="bg-default">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-light text-uppercase ls-1 mb-1">
                          Overview
                        </h6>
                        <h5 className="h3 text-white mb-0">
                          Partner Sales value
                        </h5>
                      </div>
                      <div className="col">
                        <Nav className="justify-content-end" pills>
                          <NavItem>
                            <NavLink
                              className={classnames("py-2 px-3", {
                                active: this.state.activeNav === 2,
                              })}
                              data-toggle="tab"
                              href="#"
                              onClick={(e) => this.toggleNavs(e, 2)}
                            >
                              <span className="d-none d-md-block">Daily</span>
                              <span className="d-md-none">W</span>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      {(function () {
                        if (activeNav === 1) {
                          return (
                            <Line
                              data={{
                                labels: [
                                  "Jan",
                                  "Feb",
                                  "Mar",
                                  "Apr",
                                  "May",
                                  "Jun",
                                  "Jul",
                                  "Aug",
                                  "Sep",
                                  "Oct",
                                  "Nov",
                                  "Dec",
                                ],
                                datasets: [
                                  {
                                    label: "Performance",
                                    data: listMonthlySales,
                                  },
                                ],
                              }}
                              options={chartPartnerSales.options}
                              id="chart-sales-dark"
                              className="chart-canvas"
                            />
                          );
                        } else {
                          return (
                            <Line
                              data={{
                                labels: tanggal,
                                datasets: [
                                  {
                                    label: "Performance",
                                    data: listDailySales,
                                  },
                                ],
                              }}
                              options={chartPartnerSales.options}
                              id="chart-sales-dark"
                              className="chart-canvas"
                            />
                          );
                        }
                      })()}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Trend Product - {month}
                        </h6>
                        <h5 className="h3 mb-0">
                          Most Sold SKU by{" "}
                          {this.state.selectedPartner.partner_name}
                        </h5>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <HorizontalBar
                        data={{
                          labels: listMostSoldSKU,
                          datasets: [
                            {
                              label: "Performance",
                              data: listMostSoldSKUQty,
                            },
                          ],
                        }}
                        options={chartMonthlyMostSoldSKU.options}
                        className="chart-canvas"
                        id="chart-bars"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="6">
                <Row>
                  <div className="col">
                    <Card>
                      <CardHeader>
                        {this.state.isMonthly ? (
                          <h3 className="mb-0">
                            Sales Details -{" "}
                            {this.state.printedMonthSalesDetails}{" "}
                            {this.state.printedYearSalesDetails}
                          </h3>
                        ) : (
                          <h3 className="mb-0">
                            Sales Details - {this.state.selectedDaySalesDetails}{" "}
                            {this.state.printedMonthSalesDetails}{" "}
                            {this.state.printedYearSalesDetails}
                          </h3>
                        )}
                        <div style={{ marginLeft: "2%" }}>
                          <Row style={{ marginBottom: "20px" }}>
                            {this.state.isMonthly ? (
                              <Button color="primary" size="sm" type="button">
                                Monthly
                              </Button>
                            ) : (
                              <Button
                                color="secondary"
                                size="sm"
                                type="button"
                                onClick={() =>
                                  this.setState({ isMonthly: true })
                                }
                              >
                                Monthly
                              </Button>
                            )}

                            {this.state.isMonthly ? (
                              <Button
                                color="secondary"
                                size="sm"
                                type="button"
                                onClick={() =>
                                  this.setState({ isMonthly: false })
                                }
                              >
                                Daily
                              </Button>
                            ) : (
                              <Button color="primary" size="sm" type="button">
                                Daily
                              </Button>
                            )}
                          </Row>
                          {this.state.isMonthly ? (
                            <Row>
                              <Button
                                onClick={this.salesDetailsPrevMonth.bind(this)}
                                color="primary"
                                size="sm"
                                type="button"
                              >
                                Prev
                              </Button>

                              {this.state.currentMonthPresenceDetails ===
                              this.state.selectedMonthSalesDetails ? null : (
                                <Button
                                  onClick={this.salesDetailsNextMonth.bind(
                                    this
                                  )}
                                  color="primary"
                                  size="sm"
                                  type="button"
                                >
                                  Next
                                </Button>
                              )}
                            </Row>
                          ) : (
                            <Row>
                              <ReactDatetime
                                value={this.state.selectedDateSalesDetails}
                                onChange={this.handleDailySalesDetailsChange}
                                //name='start_date'
                                //dateFormat="dd/MM/yyyy"
                                //selectsStart
                                timeFormat={false}
                              />
                            </Row>
                          )}
                        </div>
                      </CardHeader>
                      {this.state.isSalesDetailsLoading ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <CircularProgress />
                        </div>
                      ) : null}
                      <ToolkitProvider
                        data={salesDetails}
                        keyField="name"
                        columns={[
                          {
                            dataField: "product_name",
                            text: "Nama Produk",
                            sort: true,
                          },
                          {
                            dataField: "total_sold",
                            text: "Total Terjual",
                            sort: true,
                          },
                        ]}
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={pagination}
                              bordered={false}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </Card>
                  </div>
                </Row>
              </Col>
              <Col xl="12">
                <Row>
                  <div className="col">
                    <Card>
                      <CardHeader>
                        <h3 className="mb-0">
                          Form B Details - {this.state.printedMonthFormB}{" "}
                          {this.state.printedYearFormB}
                        </h3>
                        <div style={{ marginLeft: "2%" }}>
                          <Row>
                            <Col xl="6">
                              {this.state.rawFormBDatasets.length !== 0 ? (
                                <Button color="primary" size="sm" type="button">
                                  <CSVLink
                                    data={this.state.rawFormBDatasets}
                                    filename={formBFileName}
                                    style={{ color: "#FFFFFF" }}
                                  >
                                    Download
                                  </CSVLink>
                                </Button>
                              ) : null}
                              <Button
                                onClick={this.formBPrevMonth.bind(this)}
                                color="primary"
                                size="sm"
                                type="button"
                              >
                                Prev
                              </Button>

                              {this.state.currentMonth ===
                              this.state.selectedMonthFormB ? null : (
                                <Button
                                  onClick={this.formBNextMonth.bind(this)}
                                  color="primary"
                                  size="sm"
                                  type="button"
                                >
                                  Next
                                </Button>
                              )}
                            </Col>

                            <Col xl="6">
                              <Row>
                                <h4>BA yang Mensubmit Laporan : </h4>
                              </Row>
                              {this.state.contributedBa.map((ba) => (
                                <Row>
                                  <p>
                                    {ba.ba_name} - {ba.nip}
                                  </p>
                                </Row>
                              ))}
                            </Col>
                          </Row>
                        </div>
                      </CardHeader>
                      {this.state.isFormBLoading ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <CircularProgress />
                        </div>
                      ) : null}
                      <ToolkitProvider
                        data={this.state.formBDatasets}
                        keyField="name"
                        columns={this.state.columnFormB}
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={pagination}
                              bordered={false}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </Card>
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default DashboardPartner;

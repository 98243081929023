/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Container,
  Row,
  Col
} from "reactstrap";
import {Link} from "react-router-dom"

class Lock extends React.Component {
  state = {};
  render() {
    return (
      <>
        <Container className="mt--14 pb-5 py-7 py-lg-8 pt-lg-9" style={{width:'100%'}}>
          <Row className="justify-content-center">
            <Col lg="8" md="7">
              <Card className="card-profile bg-secondary mt-5">
                <CardBody className="pt-7 px-5 pb-7">
                  <div className="text-center mb-4">
                    <h1>BA Belum Dipilih</h1>
                    <h3>Silahkan pilih BA terlebih dahulu di Tabel Master BA</h3>
                  </div>
                  <Form role="form">
                    <div className="text-center">
                      <Link to={'/admin/master-ba'}>
                        <Button className="mt-2" color="info" type="button">
                          Pilih BA
                        </Button>
                      </Link>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Lock;

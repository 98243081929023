/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import MainDashboard from "views/pages/dashboards/MainDashboard.jsx";
import DashboardBA from "views/pages/dashboards/DashboardBA.jsx";
import DashboardPartner from "views/pages/dashboards/DashboardPartner.jsx";
import SubmitMateri from "views/pages/examples/SubmitMateri.jsx";
import ManageMateri from "views/pages/examples/ManageMateri.jsx";
import MasterBA from "views/pages/tables/MasterBA.jsx";
import MasterPartner from "views/pages/tables/MasterPartner.jsx";
import RegisterBA from "views/pages/examples/RegisterBA.jsx";
import DataShopper from "views/pages/tables/DataShopper.jsx";
import ExportPresenceBA from "./views/pages/tables/ExportPresenceBA";
import ChangeStatusBA from "./views/pages/tables/ChangeStatusBA";

// CORRECTION ATTENDANCE
import CorrectionRequestList from "views/pages/correction-attendance/CorrectionRequestList.jsx";

// BA ACHIEVEMENT
import DownloadTempateTarget from "./views/pages/ba-achievement/DownloadTemplateTarget";
import UploadTarget from "./views/pages/ba-achievement/UploadTarget";
import ReportingAchievement from "./views/pages/tables/ReportingAchievement";
import ManageParameter from "./views/pages/tables/ManageParameter";
import ParameterCreation from "./views/pages/tables/ParameterCreation";
import ParameterDetails from "./views/pages/tables/ParameterDetails";
import ParameterEdit from "./views/pages/tables/ParameterEdit";
import ManageSchema from "./views/pages/tables/ManageSchema";
import SchemaDetails from "./views/pages/tables/SchemaDetails";
import SchemaEdit from "./views/pages/tables/SchemaEdit";
import SchemeCreation from "./views/pages/ba-achievement/SchemeCreation";
import RegisterCompetitor from "./views/pages/examples/RegisterCompetitor";
import ListCompetitor from "./views/pages/tables/ListCompetitor";
import OtherCompetitor from "./views/pages/examples/OtherCompetitor";

// DIGITALIZE CURRENT REPORT
import UserRegistration from "./views/pages/digitalize-current-report/UserRegistration";
import ListUser from "./views/pages/digitalize-current-report/ListUser";
import UserEdit from "./views/pages/digitalize-current-report/UserEdit";
import MateriEdit from "./views/pages/digitalize-current-report/MateriEdit";

import EditAssignedFC from "./views/pages/digitalize-current-report/EditAssignedFC";
import EditArea from "./views/pages/area/editArea";

const routes = [
  {
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Dashboard Utama",
        component: MainDashboard,
        layout: "/admin",
      },
      {
        path: "/dashboard-ba",
        name: "Dashboard BA",
        component: DashboardBA,
        layout: "/admin",
      },
      {
        path: "/dashboard-partner",
        name: "Dashboard Partner",
        component: DashboardPartner,
        layout: "/admin",
      },
      // {
      //   path: "/alternative-dashboard",
      //   name: "Alternative",
      //   component: Alternative,
      //   layout: "/admin"
      // }
    ],
  },
  {
    collapse: true,
    name: "Manage",
    icon: "ni ni-ungroup text-orange",
    state: "manageCollapse",
    views: [
      {
        path: "/register-ba",
        name: "Register BA",
        component: RegisterBA,
        layout: "/admin",
      },
      {
        path: "/export-presence",
        name: "Ekspor Presensi",
        component: ExportPresenceBA,
        layout: "/admin",
      },
      {
        path: "/change-status-ba",
        name: "Ganti Status BA",
        component: ChangeStatusBA,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "BA Achievement",
    icon: "fas fa-trophy text-yellow",
    state: "baAchievement",
    views: [
      {
        path: "/reporting-achievement",
        name: "Reporting Achievement",
        component: ReportingAchievement,
        layout: "/admin",
      },
      {
        collapse: true,
        name: "Scheme",
        icon: "ni ni-align-left-2 text-default",
        state: "schemeCollapse",
        views: [
          {
            path: "/scheme-creation",
            name: "Create Scheme",
            component: SchemeCreation,
            layout: "/admin",
          },
          {
            path: "/manage-schema",
            name: "Manage Scheme",
            component: ManageSchema,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Parameter",
        icon: "ni ni-align-left-2 text-default",
        state: "achievementParameter",
        views: [
          {
            path: "/create-parameter",
            name: "Create Parameter",
            component: ParameterCreation,
            layout: "/admin",
          },
          {
            path: "/manage-parameter",
            name: "Manage Parameter",
            component: ManageParameter,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Target",
        icon: "ni ni-align-left-2 text-default",
        state: "uploadTargetCollapse",
        views: [
          {
            path: "/download-target-template",
            name: "Download Target Template",
            component: DownloadTempateTarget,
            layout: "/admin",
          },
          {
            path: "/upload-target",
            name: "Upload Target",
            component: UploadTarget,
            layout: "/admin",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Competitor Report",
    icon: "fas fa-user-friends text-green",
    state: "competitorReport",
    views: [
      {
        path: "/register-competitor",
        name: "Register Competitor",
        component: RegisterCompetitor,
        layout: "/admin",
      },
      {
        path: "/list-competitor",
        name: "List Competitor",
        component: ListCompetitor,
        layout: "/admin",
      },
      {
        path: "/competitor-analytic",
        name: "Analytics",
        component: OtherCompetitor,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    icon: "ni ni-align-left-2 text-default",
    state: "tablesCollapse",
    views: [
      {
        path: "/master-ba",
        name: "Master BA",
        component: MasterBA,
        layout: "/admin",
      },
      {
        path: "/master-partner-table",
        name: "Master Partner",
        component: MasterPartner,
        layout: "/admin",
      },
      {
        path: "/datashopper",
        name: "Database Shopper",
        component: DataShopper,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Correction Attendance BA",
    icon: "ni ni-ui-04 text-info",
    state: "correctionAttendance",
    views: [
      {
        path: "/correctionrequest",
        name: "Correction Request",
        component: CorrectionRequestList,
        layout: "/admin",
      },
    ],
  },
  // {
  //   collapse: true,
  //   name: "Diamond of Knowledge",
  //   icon: "ni ni-ui-04 text-info",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       path: "/submitmateri",
  //       name: "Submit Materi",
  //       component: SubmitMateri,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/managemateri",
  //       name: "Manage Materi",
  //       component: ManageMateri,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: "Manage User",
    icon: "ni ni-ui-04 text-info",
    state: "manageUserCollapse",
    views: [
      {
        path: "/manage-user/user-registration",
        name: "User Registration",
        component: UserRegistration,
        layout: "/admin",
      },
      {
        path: "/manage-user/list-user",
        name: "List User",
        component: ListUser,
        layout: "/admin",
      },
    ],
  },

  // NOT SHOW IN SIDEBAR MENU
  {
    redirect: true,
    collapse: false,
    path: "/area/:id",
    name: "Master BA - Edit Area",
    component: EditArea,
    layout: "/admin",
  },
  {
    redirect: true,
    collapse: false,
    path: "/edit-parameter/:id",
    name: "Edit Parameter",
    component: ParameterEdit,
    layout: "/admin",
  },
  {
    redirect: true,
    collapse: false,
    path: "/detail-parameter/:id",
    name: "Detail Parameter",
    component: ParameterDetails,
    layout: "/admin",
  },
  {
    redirect: true,
    collapse: false,
    path: "/edit-schema/:id",
    name: "Edit Schema",
    component: SchemaEdit,
    layout: "/admin",
  },
  {
    redirect: true,
    collapse: false,
    path: "/detail-schema/:id",
    name: "Detail Schema",
    component: SchemaDetails,
    layout: "/admin",
  },
  // Edit User Admin
  {
    redirect: true,
    collapse: false,
    path: "/manage-user/edit/:id",
    name: "Edit User",
    component: UserEdit,
    layout: "/admin",
  },
  // Edit BA - Assign FC
  {
    redirect: true,
    collapse: false,
    path: "/manage-ba/edit-fc/:id",
    name: "Master BA - Edit FC",
    component: EditAssignedFC,
    layout: "/admin",
  },
  // {
  //   redirect: true,
  //   collapse: false,
  //   path: "/manage-materi/edit/:id",
  //   name: "Edit Materi",
  //   component: MateriEdit,
  //   layout: "/admin",
  // },

  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "ni ni-map-big text-primary",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google",
  //       name: "Google",
  //       component: Google,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/vector",
  //       name: "Vector",
  //       component: Vector,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   icon: "ni ni-archive-2 text-green",
  //   component: Widgets,
  //   layout: "/admin"
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/admin"
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "ni ni-calendar-grid-58 text-red",
  //   component: Calendar,
  //   layout: "/admin"
  // }
];

export default routes;

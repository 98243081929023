import { Row, Col } from 'reactstrap'
import React from 'react'

export default function DisplayDetailRow({ title, value }) {
	return (
		<Row>
			<Col xs={2}>
				<h5>{title}</h5>
			</Col>
			<Col xs={10}>
				<p>{value}</p>
			</Col>
		</Row>
	)
}

import Axios from "services/axios";
import Cookies from "universal-cookie";

export default async function fetchStockOpname(query) {
  const cookies = new Cookies();
  const userToken = cookies.get("userToken");

  try {
    let { data } = await Axios({
      method: "GET",
      url:
        process.env.REACT_APP_BASE_URL +
        `/v1/dashboard/v_so_store/list?` +
        query,
      headers: {
        Authorization: "Bearer " + userToken,
      },
      data: {},
    });
    return data.data.data;
  } catch (err) {
    throw err;
  }
}

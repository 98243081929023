import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardHeader,
  CardBody,
  Badge,
} from "reactstrap";
import Cookies from "universal-cookie";
import Axios from "services/axios";

import FullrowCard from "../../../components/ba-achievement/FullrowCard";
import DCPicker from "../../../components/ba-achievement/DCPicker";
import YearMonthPicker from "../../../components/ba-achievement/YearMonthPickerMultiple";
import SalesAreaPicker from "../../../components/ba-achievement/SalesAreaPicker";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import ParameterPicker from "../../../components/ba-achievement/ParameterPicker";
import SnackbarAlert from "../../../components/ba-achievement/SnackbarAlert";
import InvalidYearMonthAlert from "../../../components/ba-achievement/InvalidYearMonthAlert";
import LoadingProvider from "../../../components/ba-achievement/LoadingProvider";
import dateFormat from "dateformat";
import postScheme from "../../../services/post/scheme/postScheme";
import postSchemeLinesCreation from "../../../services/post/schemeLines/postSchemeLinesCreation";

import fetchAllSalesArea from "../../../services/fetchAllAreaSales";
import fetchAllDCs from "../../../services/fetchAllDCs";
import fetchAllParameters from "../../../services/fetch/parameter/fetchAllParameters";
import fetchAllSchemes from "../../../services/fetchAllSchemes";

import toYearMonthString from "../../../helpers/toYearMonthString";
import fetchSchemeByUniqueId from "../../../services/fetch/scheme/fetchSchemeByUniqueId";

import fetchAllSalesRegion from "../../../services/fetch/salesRegion/fetchAllSalesRegion";
import fetchAllSalesRegionSalesArea from "../../../services/fetch/salesRegion/fetchAllSalesRegionSalesArea";

export default class SchemeCreation extends Component {
  state = {
    isLoading: false,
    fetchedDCs: null,
    fetchedParameters: null,
    fetchedSchemes: null,
    DCYearMonthsMap: null,
    areaSalesYearMonthsMap: [],
    invalidDCYearMonth: null,
    name: "",
    description: "",
    DCs: [],
    months: [],
    parameters: [],
    alert: {
      isVisible: false,
      severity: "error",
      message: "",
    },
    user: null,
    areaSales: [],
    regions: [],
    invalidAreaSalesYearMonth: null,
    listRegion: [],
    listAreaByRegion: [],
  };

  getRegionList = async () => {
    try {
      let result = await fetchAllSalesRegion()

      this.setState({
        listRegion: result,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSelectedAreaList = async () => {
    try {
      let result = await fetchAllSalesRegionSalesArea()

      this.setState({
        listAreaByRegion: result,
      });
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });

      const cookies = new Cookies();
      const fromCookie = cookies.get("userdashboard");

      this.setState({ user: fromCookie });

      const [fetchedDCs, fetchedParameters, fetchedSchemes] = await Promise.all(
        [fetchAllDCs(), fetchAllParameters(), fetchAllSchemes()]
      );

      this.setState({
        // DCs: fetchedDCs,
        fetchedDCs,
        fetchedParameters,
        fetchedSchemes,
        isLoading: false,
      });

      this.mapDCYearMonths();
      this.mapAreaSalesYearMonths();
      this.getRegionList();
      this.getSelectedAreaList();
    } catch (err) {
      console.log(err);
      this.fireAlert(
        "error",
        "Terjadi kesalahan, periksa kembali koneksi anda!"
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.DCs !== this.state.DCs ||
      prevState.months !== this.state.months ||
      prevState.areaSales !== this.state.areaSales
    ) {
      if (this.state.DCs.length) {
        this.validateDCYearMonths();
        return;
      }

      if (this.state.areaSales.length) {
        this.validateAreaSalesYearMonths();
        return;
      }

      if (!this.state.DCs || !this.state.months) {
        this.setState({ invalidDCYearMonth: null });
        return;
      }
    }
  }

  // map DCs and YearMonths to validate existing scheme for specific DC and yearMonth
  mapDCYearMonths = () => {
    const { fetchedSchemes: schemes } = this.state;
    if (schemes) {
      const map = schemes.reduce((result, scheme) => {
        const { dc_id: DCIds, year_month: yearMonths } = scheme;

        if (!!DCIds && DCIds.length) {
          DCIds.forEach((id) => {
            if (!result[id]) result[id] = [];
            yearMonths.forEach((yearMonth) => {
              result[id] = [...result[id], yearMonth].sort();
            });
          });
        }

        return result;
      }, {});

      this.setState({ DCYearMonthsMap: map });
    }
  };

  // map salesarea and YearMonths to validate existing scheme for specific salesarea and yearMonth
  mapAreaSalesYearMonths = () => {
    const { fetchedSchemes: schemes } = this.state;
    if (schemes) {
      const map = schemes.reduce((result, scheme) => {
        const { areasales_id, year_month: yearMonths } = scheme;

        if (!!areasales_id && areasales_id.length) {
          areasales_id.forEach((id) => {
            if (!result[id]) result[id] = [];
            yearMonths.forEach((yearMonth) => {
              result[id] = [...result[id], yearMonth].sort();
            });
          });
        }

        return result;
      }, {});

      this.setState({ areaSalesYearMonthsMap: map });
    }
  };

  validateAreaSalesYearMonths() {
    let isValid = true;
    const invalidAreaSalesYearMonth = {};
    const { areaSales, months, areaSalesYearMonthsMap } = this.state;
    // const areaSales = areaSales.map((item) => item);
    for (let i = 0; i < areaSales.length; i++) {
      const id = areaSales[i].sales_area_id;
      const areaSalesYearMonths = areaSalesYearMonthsMap[id];
      if (areaSalesYearMonths) {
        for (const month of areaSalesYearMonths) {
          if (months.indexOf(month) !== -1) {
            isValid = false;

            invalidAreaSalesYearMonth[id]
              ? (invalidAreaSalesYearMonth[id] = [
                  ...invalidAreaSalesYearMonth[id],
                  month,
                ].sort())
              : (invalidAreaSalesYearMonth[id] = [month].sort());
          }
        }
      }
    }

    if (!isValid) {
      const result = {};
      const keys = Object.keys(invalidAreaSalesYearMonth);
      keys.forEach((key) => {
        if (invalidAreaSalesYearMonth[key].length) {
          result[key] = Array.from(new Set(invalidAreaSalesYearMonth[key]));
        }
      });

      this.setState({
        invalidAreaSalesYearMonth: Object.keys(result).length ? result : null,
      });
      return;
    }

    this.setState({ invalidAreaSalesYearMonth: null });
  }

  validateDCYearMonths() {
    let isValid = true;
    const invalidDCYearMonth = {};
    const { DCs, months, DCYearMonthsMap } = this.state;
    const dc_ids = DCs.map((DC) => DC.partner_id);

    for (let i = 0; i < dc_ids.length; i++) {
      const id = dc_ids[i];
      const dcYearMonths = DCYearMonthsMap[id];

      if (dcYearMonths) {
        for (const month of dcYearMonths) {
          if (months.indexOf(month) !== -1) {
            isValid = false;

            invalidDCYearMonth[id]
              ? (invalidDCYearMonth[id] = [
                  ...invalidDCYearMonth[id],
                  month,
                ].sort())
              : (invalidDCYearMonth[id] = [month].sort());
          }
        }
      }
    }

    if (!isValid) {
      const result = {};
      const keys = Object.keys(invalidDCYearMonth);
      keys.forEach((key) => {
        if (invalidDCYearMonth[key].length) {
          result[key] = Array.from(new Set(invalidDCYearMonth[key]));
        }
      });

      this.setState({
        invalidDCYearMonth: Object.keys(result).length ? result : null,
      });
      return;
    }

    this.setState({ invalidDCYearMonth: null });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    // validate already created DC/yearMonth scheme combination
    if (this.state.invalidDCYearMonth) {
      this.fireAlert(
        "error",
        "Skema untuk DC dan bulan yang sama sudah pernah dibuat!"
      );
      return;
    }

    // validate already created DC/yearMonth scheme combination
    if (this.state.invalidAreaSalesYearMonth) {
      this.fireAlert(
        "error",
        "Skema untuk Sales Area dan bulan yang sama sudah pernah dibuat!"
      );
      return;
    }

    const {
      name,
      description,
      DCs,
      parameters,
      months: year_month,
      user,
      areaSales,
    } = this.state;

    // validation: empty input
    if (
      !name ||
      !description ||
      (!DCs && !areaSales) ||
      (!DCs.length && !areaSales.length) ||
      !parameters ||
      !parameters.length ||
      !year_month ||
      !year_month.length
    ) {
      this.fireAlert("error", "Pastikan semua field telah terisi!");
      return;
    }

    const date_created = new Date();
    const user_id = user.ba_id ? user.ba_id : user.id || 9016;
    const is_active = true;
    const is_submitted = true;
    const dc_id = DCs.map((dc) => dc.partner_id);
    const areasales_id = areaSales.map((item) => item.sales_area_id);
    const uniqueId = `${year_month}-${areasales_id}-${dateFormat(
      new Date(),
      "yy-mm-dd"
    )}`;
    // POST new Scheme to API/database
    try {
      this.setState({ isLoading: true });
      const scheme_id = await postScheme({
        name,
        description,
        year_month,
        date_created,
        user_id,
        is_active,
        dc_id: [],
        areasales_id,
        uniqueId,
      });

      // Unused since scheme_id defined on postScheme
      // const scheme_id = await fetchSchemeByUniqueId(uniqueId);

      // POST Scheme Lines
      await postSchemeLinesCreation(parameters, {
        user_id,
        date_created,
        is_active,
        is_submitted,
        scheme_id,
      });

      // success POST request
      this.fireAlert("success", "Skema berhasil ditambahkan!");
      this.setState({ fetchedSchemes: await fetchAllSchemes() });
      this.mapDCYearMonths();
    } catch (err) {
      console.log(err);
      // failed POST request
      this.fireAlert(
        "error",
        "Terjadi kesalahan, periksa kembali koneksi anda!"
      );
    } finally {
      this.setState({ isLoading: false, DCs: [] });
    }
  };

  // open alert
  fireAlert = (severity, message) => {
    this.setState({
      alert: {
        isVisible: true,
        severity,
        message,
      },
    });
  };

  // close alert
  closeAlert = () => {
    this.setState({
      alert: {
        isVisible: false,
        severity: "error",
        message: "",
      },
    });
  };

  renderInvalidDCYearMonth() {
    const { invalidDCYearMonth, fetchedDCs } = this.state;
    const invalidDCIds = Object.keys(invalidDCYearMonth).map((key) => +key);

    const invalidDCs = fetchedDCs.filter(
      (DC) => invalidDCIds.indexOf(DC.partner_id) !== -1
    );

    return invalidDCs.map((DC) => {
      const DC_NAME = DC.partner_name;
      const yearMonths = invalidDCYearMonth[DC.partner_id.toString()];

      return (
        <Col xs={2}>
          <h4 className="mb-3">{DC_NAME}</h4>
          {yearMonths.map((month) => {
            return <div className="my-1">{toYearMonthString(month)}</div>;
          })}
        </Col>
      );
    });
  }

  renderInvalidAreaSalesYearMonth() {
    const { invalidAreaSalesYearMonth, listAreaByRegion } = this.state;
    const invalidAreaSalesIds = Object.keys(invalidAreaSalesYearMonth).map(
      (key) => +key
    );

    const checkedArea = listAreaByRegion.filter(
      (area) => invalidAreaSalesIds.indexOf(area.sales_area_id) !== -1
    );

    return checkedArea.map((item) => {
      // const DC_NAME = DC.partner_name;
      const yearMonths = invalidAreaSalesYearMonth[item.sales_area_id];

      return (
        <Col xs={2}>
          <h4 className="mb-3">{item.sales_area_name}</h4>
          {yearMonths.map((month) => {
            return <div className="my-1">{toYearMonthString(month)}</div>;
          })}
        </Col>
      );
    });
  }

  render() {
    return (
      <>
        <SimpleHeader name="Create Scheme" parentName="BA Achievement" />

        <SnackbarAlert
          isVisible={this.state.alert.isVisible}
          severity={this.state.alert.severity}
          message={this.state.alert.message}
          closeEvent={() => this.closeAlert()}
        />

        <Container className="mt--6" fluid>
          <LoadingProvider isLoading={this.state.isLoading}>
            <FullrowCard>
              <CardHeader>
                <h2>Buat Skema Baru</h2>
              </CardHeader>
              <CardBody>
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label>
                      <h3>Nama Skema</h3>
                    </Label>
                    <Input
                      style={{ border: "0.1rem solid #c4c4c4" }}
                      type="text"
                      placeholder="Nama skema"
                      onChange={(event) =>
                        this.setState({ name: event.target.value })
                      }
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>
                      <h3>Deskripsi Skema</h3>
                    </Label>
                    <Input
                      style={{ border: "0.1rem solid #c4c4c4" }}
                      type="textarea"
                      placeholder="Deskripsi skema"
                      onChange={(event) =>
                        this.setState({ description: event.target.value })
                      }
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>
                      <h3>Area Sales Aktif</h3>
                    </Label>
                    <SalesAreaPicker
                      handleChange={(_, value) => {
                        if (!value.length) {
                          this.setState({ invalidAreaSalesYearMonth: null });
                        }
                        this.setState({ areaSales: value });
                      }}
                      listAreaByRegion={this.state.listAreaByRegion}
                      listRegion={this.state.listRegion}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>
                      <h3>Bulan Aktif</h3>
                    </Label>
                    <Row>
                      <Col xs={2}>
                        <YearMonthPicker
                          setMonths={(values) =>
                            this.setState({ months: values })
                          }
                        />
                      </Col>
                      <Col xs={10}>
                        {this.state.months.map((month) => {
                          return (
                            <h2 className="d-inline-block mx-1">
                              <Badge color="primary">
                                {toYearMonthString(month)}
                              </Badge>
                            </h2>
                          );
                        })}
                      </Col>
                    </Row>
                  </FormGroup>

                  {this.state.invalidDCYearMonth ? (
                    <div>
                      <InvalidYearMonthAlert>
                        <Row>{this.renderInvalidDCYearMonth()}</Row>
                      </InvalidYearMonthAlert>
                    </div>
                  ) : null}

                  {this.state.invalidAreaSalesYearMonth && (
                    <div>
                      <InvalidYearMonthAlert isSalesArea={true}>
                        <Row>{this.renderInvalidAreaSalesYearMonth()}</Row>
                      </InvalidYearMonthAlert>
                    </div>
                  )}

                  <FormGroup>
                    <Label>
                      <h3>Parameter Aktif</h3>
                    </Label>
                    <ParameterPicker
                      parameters={this.state.fetchedParameters}
                      setParameters={(parameters) =>
                        this.setState({ parameters })
                      }
                    />
                  </FormGroup>

                  <FormGroup>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="w-100"
                      disabled={this.invalidDCYearMonth ? true : false}
                    >
                      Submit
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </FullrowCard>
          </LoadingProvider>
        </Container>
      </>
    );
  }
}

import { Row, Col } from 'reactstrap'
import FormGroupUploadTarget from './FormGroupUploadTarget'
import React from 'react'
import { formatNumber } from '../../../helpers/formatNumber'

export default function TotalVisitorInputCard(props) {
	const { brandLabel, value, handleConfigurableAttributeChange, totalVisitorValue } = props

	return (
		<Row>
			<Col xs={12}>
				<h3 className="text-primary">{brandLabel}</h3>
			</Col>
			<Col xs={6}>
				<FormGroupUploadTarget
					smallLabel={true}
					label="Conversion Rate (%)"
					placeholder="configurable attribute: conversion rate"
					name={brandLabel}
					value={value['caConversionRate']}
					handleChange={e => handleConfigurableAttributeChange(e, 'caConversionRate')}
				/>
			</Col>
			<Col xs={6}>
				<FormGroupUploadTarget
					smallLabel={true}
					label="Average Basket Size"
					placeholder="configurable attribute: average basket size"
					name={brandLabel}
					value={value['caAvgBasketSize']}
					handleChange={e => handleConfigurableAttributeChange(e, 'caAvgBasketSize')}
				/>
			</Col>
			<Col xs={12}>
				<h5>Total Visitor Divider (Average Basket Size * Conversion Rate / 100) :</h5>
				<p>{formatNumber(totalVisitorValue)}</p>
			</Col>
		</Row>
	)
}

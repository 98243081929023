import Axios from "services/axios";
import Cookies from "universal-cookie";

const fetchAllSalesRegionDashboard = async () => {
  try {
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    let { data } = await Axios({
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + `/v1/dashboard/v_master_salesregion/list`,
      headers: {
        "Authorization": "Bearer "+ userToken
      },
      data: {},
    })
    return data.data.data;

  } catch (err) {
    throw err;
  }
}

export default fetchAllSalesRegionDashboard;
/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// core components
import ManageMateriHeader from "components/Headers/ManageMateriHeader.jsx";
import ManageMateriInaco from "views/pages/tables/ManageMateriInaco.jsx";

export class Profile extends React.Component {
	constructor() {
		super();
		this.state = {
			userloggedin : {},
			color : [],
		}
	}

  componentDidMount(){
    
  }

  render() {

    return (
      <>
        <ManageMateriHeader />
        <ManageMateriInaco/>
      </>
    );
  }
}

export default Profile;

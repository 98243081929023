import qs from "querystring";

import postTargetPersonalService from "../services/postTargetPersonal";
import postTargetBrandService from "../services/postTargetBrand";
import postTargetGlobalService from "../services/postTargetGlobal";

export default function usePostTarget() {
  const postTargetPersonal = async (payload) => {
    try {
      const { year, month, salesarea_id } = payload[0];

      let query = "";
      if ((salesarea_id || salesarea_id === 0) && salesarea_id.toString()) {
        const salesarea_ids = getSalesAreaList(payload);
        query = {
          year,
          month,
          salesarea_id: salesarea_ids,
        };
      } else {
        const dc_ids = getDCList(payload);
        query = {
          year: year,
          month: month,
          dc_id: dc_ids.join(","),
        };
      }

      await postTargetPersonalService(payload, query);
    } catch (err) {
      throw err;
      console.log(err);
    }
  };

  const postTargetBrand = async (payload) => {
    try {
      const { year, month, salesarea_id } = payload[0];
      let query = "";
      if ((salesarea_id || salesarea_id === 0) && salesarea_id.toString()) {
        const salesarea_ids = getSalesAreaList(payload);
        query = {
          year,
          month,
          salesarea_id: salesarea_ids,
        };
      } else {
        const dc_ids = getDCList(payload);
        query = {
          year: year,
          month: month,
          dc_id: dc_ids,
        };
      }

      await postTargetBrandService(payload, query);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const postTargetGlobal = async (payload) => {
    try {
      const { year, month, salesarea_id } = payload[0];

      let query = "";
      if ((salesarea_id || salesarea_id === 0) && salesarea_id.toString()) {
        const salesarea_ids = getSalesAreaList(payload);
        query = {
          year: year,
          month: month,
          salesarea_id: salesarea_ids,
        };
      } else {
        const dc_ids = getDCList(payload);
        query = {
          year: year,
          month: month,
          dc_id: dc_ids,
        };
      }

      await postTargetGlobalService(payload, query);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  return { postTargetPersonal, postTargetBrand, postTargetGlobal };
}

function getDCList(payload) {
  return Array.from(new Set(payload.map((elem) => elem.dc_id)));
}

function getSalesAreaList(payload) {
  return Array.from(new Set(payload.map((elem) => elem.salesarea_id)));
}

import Axios from "services/axios";
import Cookies from "universal-cookie";

export default async function postSchemeLinesCreation(parameters, otherData) {
  try {
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    const payload = parameters.map((parameter) => {
      return {
        ...otherData,
        parameter_id: parameter.id,
      };
    });

    await Axios({
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + `/v1/ach-scheme-line/bulk`,
      headers: {
        Authorization: "Bearer " + userToken,
        Prefer: "return=representation",
      },
      data: payload,
    });
  } catch (err) {
    throw err;
  }
}

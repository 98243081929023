import Axios from "services/axios";
import Cookies from "universal-cookie";

export default async function fetchTargetTracker({ scheme }) {
  const cookies = new Cookies();
  const userToken = cookies.get("userToken");
  const { year_month, areasales_id } = scheme;
  const query = `year_month=[${year_month}]&salesarea_id=[${areasales_id}]`;

  try {
    let { data } = await Axios({
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + `/v1/v_ach_target_tracker_salesarea?`+query,
      headers: {
        "Authorization": "Bearer "+ userToken
      },
      data: {},
    });
    return data.data.data;
  } catch (err) {
    throw err;
  }
}
import Axios from "services/axios";
import Cookies from "universal-cookie";

const DeleteSchemeLinesById = async (SchemeId) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get("userToken");
        console.log(process.env.REACT_APP_BASE_URL + `/v1/ach-scheme-line/`+SchemeId)
        await Axios({
            method: "DELETE",
            url: process.env.REACT_APP_BASE_URL + `/v1/ach-scheme-line/`+SchemeId,
            headers: {
                "Authorization": "Bearer "+ userToken
            },
            data: {},
        }).then((res) => {
            console.log(res)
        })
    } catch (err) {
        throw err;
    }
}
 
export default DeleteSchemeLinesById;
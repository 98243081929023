import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";

import Cookies from 'universal-cookie'
import Axios from "services/axios";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
} from "reactstrap";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;
let cookies = new Cookies();

class ReactBSTables extends React.Component {

  constructor(props) {
    super();
    this.state = {
      alert: null,
      dataShopper: [],
      dataMateri: [],
    }
  }
  state = {

  };

  componentDidMount(){
    const userToken = cookies.get("jwtKey");

    Axios({
      method: "POST",
      url: "/auth.php",
      data: {
        jwt: userToken,
        table: "dk_submit_materi",
        method: "get",
        query: `sort=id.desc&title=not.is.null&select=id,title,theme,description,type,timestamp,require,tumbnail_name`,
      },
      })
    .then(res => {
      const response = res.data.data;

      this.setState({
        dataMateri: response
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  ShowTumbnailThisLine(cell, row) {
    return (
        <div>
          <img style={{
            width:'100px'
          }}
            alt='thumbnail'
            src={row.tumbnail_name}
          />
        </div>
    );
  }

  GetActionFormat(cell, row) {
    return (
        <div>
          <Row style={{marginBottom:'10px'}}>
          <>
          <Link
          to={`/admin/manage-materi/edit/${row.id}`}
              type="button"
              className="btn btn-outline-primary btn-sm ts-buttom"
              size="sm"
              onClick={this.onPartnerClick.bind(this,cell,row)}
            >
              Edit
            </Link>
             
              </>
          </Row>
          <Row>
            <button
              type="button"
              className="btn btn-warning btn-sm ts-buttom"
              size="sm"
              onClick={this.deleteMateriChoosed.bind(this,cell,row)}
            >
              Delete
            </button>
          </Row>
        </div>
    );
  }

  onPartnerClick = (cell, partner, rowIndex) => {
  }

  deleteMateriChoosed = async ( cell, partner, rowIndex) => {
    const {id } = partner;
    
      const result = await Swal.fire({
        title: `Hapus materi ber-id : ${id}?`,
        text: `apakah anda yakin?, data yang dihapus tidak akan kembali`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Delete",
      });
  
      if (result.isConfirmed) {
        try {
           const userToken = cookies.get("jwtKey");

           await Axios({
           method: "POST",
           url: "/auth.php",
           data: {
             jwt: userToken,
             table: "dk_submit_materi",
             method: "delete",
             query: `id=eq.${id}`
           },
           })
  
           Swal.fire({
            icon: 'success',
            text: 'Your Materials has been deleted',
            confirmButtonColor: 'blue',
           });
           window.location.reload();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            text: 'Materi cant be deleted!',
            confirmButtonColor: '#e55555',
          });
        }
      }
  }

  render() {
    return (
      <>
        {this.state.alert}
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Diamond of Knowledge</h3>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.dataMateri}
                  keyField="name"
                  columns={[
                    {
                      dataField: "id",
                      text: "ID",
                      sort: true,
                      style:{
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      dataField: "title",
                      text: "Title",
                      sort: true,
                      style:{
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      dataField: "theme",
                      text: "Tema Materi",
                      sort: true,
                      style:{
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      dataField: "description",
                      text: "Description",
                      sort: true,
                      style:{
                        width: '500px',
                        whiteSpace: 'unset',
                        textAlign: 'justify'
                      }
                    },
                    {
                      dataField: "require",
                      text: "Mandatory",
                      sort: true,
                      style:{
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      dataField: "type",
                      text: "File Type",
                      sort: true
                    },
                    {
                      text: "Tumbnail",
                      dataField: "",
                      formatter:this.ShowTumbnailThisLine.bind(this),
                      style:{
                        width: '200px',
                        whiteSpace: 'unset',
                        textAlign: 'justify'
                      }
                    },
                    {
                      text: "Action",
                      dataField: "",
                      formatter:this.GetActionFormat.bind(this),
                      style:{
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      dataField: "timestamp",
                      text: "Timestamp",
                      sort: true,
                      style:{
                        whiteSpace: 'unset'
                      }
                    },
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default ReactBSTables;

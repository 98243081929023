/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// react plugin that prints a given react component
//import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
// react component used to create sweet alerts

// reactstrap components
import { Card, CardBody, CardHeader, Container, Row, Col } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import TextField from "@material-ui/core/TextField";
import Axios from "services/axios";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  Switch,
  Button,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Cookies from "universal-cookie";
import axios from "axios";
import fetchAllTargetTable from "../../../services/fetch/target/fetchAllTargetTable";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ParameterCreation() {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [parameterItem, setParameterItem] = useState({
    name: "",
    unit: "",
    table_name: "",
    is_timebased: false,
  });
  const [alert, setAlert] = useState({
    isOpen: false,
    message: "",
    alert: "success",
  });
  const [listTipeTarget, setListTipeTarget] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const cookies = new Cookies();
      var fromCookie = cookies.get("userdashboard");
      setUser(fromCookie);
      try {
        const response = await fetchAllTargetTable();
        setListTipeTarget(response);
      } catch (error) {
        setListTipeTarget([]);
      }
    };
    fetch();
  }, []);

  const formHandler = (e) => {
    let tempItemParameter = { ...parameterItem };
    if (e.target.name === "is_timebased") {
      tempItemParameter[e.target.name] = !tempItemParameter[e.target.name];
    } else {
      tempItemParameter[e.target.name] = e.target.value;
    }
    setParameterItem(tempItemParameter);
  };

  const isValid = () => {
    if (parameterItem.name === "") {
      return {
        message: "Harap isi nama parameter",
        type: "error",
      };
    }
    if (parameterItem.unit === "") {
      return {
        message: "Harap isi satuan parameter",
        type: "error",
      };
    }
    if (parameterItem.table_name === "") {
      return {
        message: "Harap isi tipe target parameter",
        type: "error",
      };
    }
    return {
      message: "Parameter berhasil disubmit",
      type: "success",
    };
  };

  const closeSnackbar = () => {
    const alertItem = {
      isOpen: false,
      message: "",
      type: "success",
    };

    setAlert(alertItem);
  };

  const submitParameter = async () => {
    setIsLoading(true);
    let parameter = { ...parameterItem };
    const validationResponse = isValid();
    if (validationResponse.type === "success") {
      parameter.user_id = user.ba_id ? user.ba_id : user.id;
      parameter.is_active = true;
      const cookies = new Cookies();
      const jwt = cookies.get("jwtKey");

      await Axios({
        method: "POST",
        url: "/auth.php",
        data: {
          jwt: jwt,
          table: "ach_master_parameter",
          method: "post",
          data: parameter,
        },
      });
    }
    validationResponse.isOpen = true;
    setAlert(validationResponse);
    setIsLoading(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert.isOpen}
        autoHideDuration={6000}
        onClose={() => closeSnackbar()}
      >
        <Alert onClose={() => closeSnackbar()} severity={alert.type}>
          {alert.message}
        </Alert>
      </Snackbar>
      <SimpleHeader name="Create Parameter" parentName="BA Achievement" />
      <Container className="mt--6" fluid>
        <Backdrop open={isLoading} style={{ zIndex: "1000" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Row>
          <Col xl="8">
            <div className="col">
              <Card>
                <CardHeader>
                  <h2 className="mb-0">Create Parameter</h2>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl="12" className="mb-4">
                      <h3>Nama Parameter</h3>
                      <TextField
                        name="name"
                        onChange={(e) => formHandler(e)}
                        label="Parameter"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col xl="6" className="mb-4">
                      <h3>Satuan</h3>
                      <TextField
                        name="unit"
                        onChange={(e) => formHandler(e)}
                        label="Rupiah / Orang / Persen"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col xl="6" className="mb-4">
                      <h3>Tipe Target</h3>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Tipe Target
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name="table_name"
                          onChange={(e) => formHandler(e)}
                          label="Tipe Target"
                        >
                          <MenuItem key={0} value="">
                            <em>None</em>
                          </MenuItem>
                          {listTipeTarget.map((tipe) => (
                            <MenuItem key={tipe.id} value={tipe.table_name}>
                              {tipe.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col xl="6">
                      <h3>Apakah parameter ini bergantung terhadap waktu?</h3>
                    </Col>
                    <Col xl="3" className="mb-6">
                      <Switch
                        checked={parameterItem.is_timebased}
                        onChange={(e) => formHandler(e)}
                        name="is_timebased"
                        color="primary"
                      />
                    </Col>
                    <Col xl="12">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ width: "100%" }}
                        onClick={() => submitParameter()}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ParameterCreation;

import React, { useState, useEffect } from "react";
import qs from "querystring";
import Swal from "sweetalert2";
import Cookies from 'universal-cookie'
import Axios from "services/axios";


// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { Link } from "react-router-dom";
import LoadingProvider from "../../../components/digitalize-current-report/LoadingProvider";
import SnackbarAlert from "../../../components/digitalize-current-report/SnackbarAlert";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

// reactstrap components
import { Card, CardHeader, Container, Row, Spinner, Button, CardBody, Input, Col, Label } from "reactstrap";

// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { uniq } from "lodash";

let cookies = new Cookies();
const userToken = cookies.get("userToken");

// pagination
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

// search bar
const { SearchBar } = Search;

export default function ListUser() {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    isVisible: false,
    severity: "error",
    message: "",
  });
  const [users, setUsers] = useState([]);
  const [listDC, setListDC] = useState([]);

  const [filterRole, setFilterRole] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDCs, setSelectedDCs] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");

  // event handlers
  const handleRoleChange = (event) => {
    resetRoleParameters();
    setSelectedRole(event.target.value);
  };
  const handleRegionChange = (event) => setSelectedRegion(event.target.value);
  const handleDCChange = (_, value) => {
    setSelectedDCs(value);
  };
  const resetRoleParameters = () => {
    setSelectedDCs([]);
    setSelectedRegion("");
  };

  const handleSubmitFilter = async () => {
    if (!selectedRole) {
      fireAlert("error", "Pilih role terlebih dahulu");
      return;
    }

    if (DCNeededRoles.indexOf(selectedRole) !== -1) {
      if (!selectedDCs.length) {
        fireAlert(
          "error",
          `Pilih DC terlebih dahulu untuk role ${selectedRole}`
        );
        return;
      }
    }

    try {
      setIsLoading(true);

      const queryParameter = {
        role: `${selectedRole}`,
      };

      if (selectedRegion) {
        queryParameter.region = `${selectedRegion}`;
      }

      if (selectedDCs && selectedDCs.length) {
        const DCNames = selectedDCs.map((dc) => dc.partner_name);
        queryParameter.dc_name = `${DCNames}`;
      }

      const query = qs.stringify(queryParameter);

      const res = await Axios({
        method: "GET",
        url:
          process.env.REACT_APP_BASE_URL + `/v1/v_user_admin_details?` + query,
        headers: {
          Authorization: "Bearer " + userToken,
        },
        data: {},
      });

      const fetchedUsers = res.data.data.data;
      // const { data: fetchedUsers } = await A(`/v_user_admin_details?${query}`);

      setUsers(fetchedUsers);
    } catch (error) {
      console.log(error);
      fireAlert("error", `Terjadi kesalahan, periksa koneksi terlebih dahulu`);
    } finally {
      setFilterRole(selectedRole);
      setIsLoading(false);
    }
  };

  const handleDelete = async (user) => {
    const { id: userId, username, role } = user;

    const result = await Swal.fire({
      title: `Hapus user ${username}?`,
      text: `role - ${role}`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
    });

    if (result.isConfirmed) {
      try {
        await Axios({
          method: "DELETE",
          url: process.env.REACT_APP_BASE_URL + `/v1/user_admin/${userId}`,
          headers: {
            Authorization: "Bearer " + userToken,
          },
          data: {},
        });

        await Axios({
          method: "DELETE",
          url:
            process.env.REACT_APP_BASE_URL +
            `/v1/user_admin_relation/${userId}`,
          headers: {
            Authorization: "Bearer " + userToken,
          },
          data: {},
        });

        await Axios({
          method: "DELETE",
          url:
            process.env.REACT_APP_BASE_URL +
            `/v1/user_admin_relation/${userId}`,
          headers: {
            Authorization: "Bearer " + userToken,
          },
          data: {},
        });
        setUsers(users.filter((user) => user.id !== userId));
      } catch (error) {
        fireAlert(
          "error",
          "Terjadi kesalahan, periksa koneksi terlebih dahulu"
        );
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await Axios({
          method: "GET",
          url: process.env.REACT_APP_BASE_URL + `/v1/master-dc/list`,
          headers: {
            Authorization: "Bearer " + userToken,
          },
          data: {},
        });
        const DCs = res.data.data.data;
        setListDC(DCs);
      } catch (error) {
        fireAlert(
          "error",
          `Terjadi kesalahan, periksa koneksi terlebih dahulu`
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // HELPERS
  // open alert
  const fireAlert = (severity, message) => {
    setAlert({
      isVisible: true,
      severity,
      message,
    });
  };
  // close alert
  const closeAlert = () => {
    setAlert({
      isVisible: false,
      severity: "error",
      message: "",
    });
  };

  const getDCColumn = () => {
    if (DCNeededRoles.indexOf(filterRole) === -1) return [];

    return [
      {
        dataField: "dc_name",
        text: "DC",
        sort: true,
        style: {
          width: "150px",
          whiteSpace: "unset",
          textAlign: "justify",
        },
      },
    ];
  };

  const getRegionColumn = () => {
    if (filterRole !== "RMDM") return [];

    return [
      {
        dataField: "region",
        text: "Region",
        sort: true,
        style: {
          width: "150px",
          whiteSpace: "unset",
          textAlign: "justify",
        },
      },
    ];
  };

  const getSuperiorColumn = () => {
    if (!filterRoleHasSuperior) return [];

    return [
      {
        text: superiorMap[filterRole] || "Superior",
        sort: true,
        style: {
          width: "300px",
          whiteSpace: "unset",
          textAlign: "justify",
        },
        formatter: (cell, row) => {
          if (!row.superior_names) return null;

          return (
            <>
              {uniq(row.superior_names).map((elem, i) => {
                return <div key={i}>{`- ${elem}`}</div>;
              })}
            </>
          );
        },
      },
    ];
  };

  const getSubordinateColumn = () => {
    if (!filterRoleHasSubordinate) return [];

    return [
      {
        text: subordinateMap[filterRole] || "Subordinate",
        sort: true,
        style: {
          width: "300px",
          whiteSpace: "unset",
          textAlign: "justify",
        },
        formatter: (cell, row) => {
          if (!row.subordinate_names) return null;

          return (
            <>
              {row.subordinate_names.map((elem, i) => {
                return <div key={i}>{`- ${elem}`}</div>;
              })}
            </>
          );
        },
      },
    ];
  };

  const superRoles = ["Report HO", "Admin", "Super Admin"];
  const DCNeededRoles = ["AMDM", "FC", "BA"];
  const filterRoleHasSuperior = ["AMDM", "FC", "BA"].indexOf(filterRole) !== -1;
  const filterRoleHasSubordinate =
    ["FC", "AMDM", "RMDM"].indexOf(filterRole) !== -1;

  const superiorMap = {
    BA: "FC",
    FC: "AMDM",
    AMDM: "RMDM",
  };

  const subordinateMap = {
    FC: "BA",
    AMDM: "FC",
    RMDM: "AMDM",
  };

  return (
    <LoadingProvider isLoading={isLoading}>
      <SimpleHeader parentName="Manage User" name="List User" />

      <SnackbarAlert
        isVisible={alert.isVisible}
        severity={alert.severity}
        message={alert.message}
        closeEvent={() => closeAlert()}
      />

      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Filter User</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12" sm="6" md="4" lg="3">
                      <CustomFormGroup label="Pilih Role">
                        <Input
                          type="select"
                          onChange={handleRoleChange}
                          value={selectedRole}
                        >
                          <option value="">-- Pilih Role --</option>
                          {/* <option value="Admin">Admin</option>
                          <option value="Super Admin">Super Admin</option> */}
                          <option value="Report HO">Report HO</option>
                          <option value="RMDM">RMDM</option>
                          <option value="AMDM">AMDM</option>
                          <option value="FC">FC</option>
                          {/* <option value="BA">BA</option> */}
                        </Input>
                      </CustomFormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {selectedRole && superRoles.indexOf(selectedRole) === -1 ? (
                      <>
                        {selectedRole === "RMDM" ? (
                          <>
                            {/* select region */}
                            <Col xs="12" sm="6" md="4" lg="3">
                              <CustomFormGroup label="Pilih Region">
                                <Input
                                  type="select"
                                  onChange={handleRegionChange}
                                  value={selectedRegion}
                                >
                                  <option value="">-- Pilih Region --</option>
                                  <option value="Region 1">Region 1</option>
                                  <option value="Region 2">Region 2</option>
                                  <option value="Region 3">Region 3</option>
                                </Input>
                              </CustomFormGroup>
                            </Col>
                          </>
                        ) : (
                          <>
                            {/* select DC */}
                            <Col>
                              <CustomFormGroup label="Pilih DC">
                                <Autocomplete
                                  value={selectedDCs}
                                  multiple={true}
                                  freeSolo
                                  options={listDC}
                                  getOptionLabel={(dc) => dc.partner_name}
                                  onChange={handleDCChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      placeholder="Pilih DC"
                                    />
                                  )}
                                />
                              </CustomFormGroup>
                            </Col>
                          </>
                        )}
                      </>
                    ) : null}
                  </Row>
                  <Row className="mt-3">
                    <Col xs="12" sm="6" md="4" lg="3">
                      <Button color="primary" onClick={handleSubmitFilter}>
                        Cari User
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">List User</h3>
                </CardHeader>
                {!isLoading ? (
                  <ToolkitProvider
                    data={users}
                    keyField="id"
                    columns={[
                      {
                        dataField: "id",
                        text: "User ID",
                        sort: true,
                        style: {
                          width: "50px",
                          whiteSpace: "unset",
                          textAlign: "left",
                        },
                      },
                      {
                        dataField: "name",
                        text: "Nama",
                        sort: true,
                        style: {
                          width: "300px",
                          whiteSpace: "unset",
                          textAlign: "left",
                        },
                      },
                      {
                        dataField: "nip",
                        text: "NIP",
                        sort: true,
                        style: {
                          width: "200px",
                          whiteSpace: "unset",
                          textAlign: "left",
                        },
                      },
                      {
                        dataField: "username",
                        text: "Username",
                        sort: true,
                        style: {
                          width: "300px",
                          whiteSpace: "unset",
                          textAlign: "justify",
                        },
                      },
                      {
                        dataField: "role",
                        text: "Role",
                        sort: true,
                        style: {
                          width: "150px",
                          whiteSpace: "unset",
                          textAlign: "justify",
                        },
                      },

                      ...getDCColumn(),
                      ...getRegionColumn(),
                      ...getSuperiorColumn(),
                      ...getSubordinateColumn(),

                      {
                        editable: false,
                        text: "Edit",
                        formatter: (cell, row) => {
                          return (
                            <>
                              <Link
                                to={`/admin/manage-user/edit/${row.id}`}
                                className="btn btn-secondary"
                                title="Edit"
                              >
                                <i className="fas fa-user-edit" />
                              </Link>
                            </>
                          );
                        },
                      },
                      {
                        editable: false,
                        text: "Delete",
                        formatter: (cell, row) => {
                          return (
                            <>
                              <Button
                                className="btn btn-secondary"
                                title="Delete"
                                onClick={() => handleDelete(row)}
                              >
                                <i className="fas fa-trash-alt" />
                              </Button>
                            </>
                          );
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1"
                        >
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="id"
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                ) : (
                  <CardBody>
                    <div className="text-center" style={{ minHeight: "50vh" }}>
                      <Spinner />
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </LoadingProvider>
  );
}

// COMPONENTS

const CustomFormGroup = ({ children, label }) => {
  return (
    <Row className="mb-3">
      <Col xs="12">
        <Label>{label}</Label>
      </Col>
      <Col xs="12">{children}</Col>
    </Row>
  );
};

import FormGroupUploadTarget from './FormGroupUploadTarget'
import { Row, Col } from 'reactstrap'
import React from 'react'
import { formatNumber } from '../../../helpers/formatNumber'

export default function AvgBasketSizeInputCard(props) {
	const { label, name, value, handleChange } = props

	return (
		<Row>
			<Col xs={12} sm={6}>
				<FormGroupUploadTarget label={label} placeholder="target average basket size" name={name} value={value} handleChange={handleChange} />
			</Col>
			<Col
				xs={12}
				sm={6}
				style={{
					display: 'flex',
					alignItems: 'flex-end',
					paddingBottom: '1rem',
				}}
			>
				<h5>Average Basket Size: Rp {formatNumber(value)}</h5>
			</Col>
		</Row>
	)
}

/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin that prints a given react component
//import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import Cookies from "universal-cookie";

import Axios from "api/axios";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Modal,
  Input,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";
import { CSVLink } from "react-csv";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import DateFormat from "dateformat";
import axios from "axios";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;
class ReactBSTables extends React.Component {
  constructor(props) {
    super(props);
    this.GetDetailsFormat = this.GetDetailsFormat.bind(this);
  }
  state = {
    alert: null,
    listSelectedDC: [],
    listSelectedBrand: [],
    dataBA: [],
    dataBA2: [],
    dataBAUpdateStatus: [],
    dataBAUpdateNIP: [],
    openModalUpdateBAStatus: false,
    openModalUpdateNIPBA: false,
    isBAChoosedActive: -1,
    statusBAChoosed: null,
    newNIPUpdate: null,
    listDC: [],
    listBrand: [],
    isLoading: false,
    openModalUpdateAlert: false,
    modalAlertStatus: "info",
    modalAlertMessage: "",
    modalAlertData: {
      name: "",
      changes: "",
    },
    noticeShow: false,
  };

  onDCSelectedChanged = (value, event) => {
    var arrayDC = [];
    value.forEach((item) => {
      arrayDC.push(item.partner_name);
    });
    const cookies = new Cookies();
    cookies.set("listSelectedDCBA", arrayDC, { path: "/" });
    this.setState({
      listSelectedDC: arrayDC,
    });

    this.getListBA(arrayDC, this.state.listSelectedBrand);
  };

  onBrandSelectedChanged = (value, event) => {
    var arrayBrand = [];
    value.forEach((item) => {
      arrayBrand.push(item.name);
    });
    const cookies = new Cookies();
    cookies.set("listSelectedBrandBA", arrayBrand, { path: "/" });
    this.setState({
      listSelectedBrand: arrayBrand,
    });

    this.getListBA(this.state.listSelectedDC, arrayBrand);
  };

  getAllDC = async () => {
    var listDC = [];
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    await Axios({
      url: "/master-dc/list?select=partner_name,partner_id&sort=partner_name.asc&partner_name=ne.HEAD%20OFFICE",
      method: "GET",
      headers: { Authorization: `Bearer ${userToken}` },
    }).then((response) => {
      var dcItem = {
        partner_name: "DC ALL",
      };

      listDC.push(dcItem);

      response.data.data.data.forEach((dc) => {
        listDC.push(dc);
      });
    });

    this.setState({
      listDC: listDC,
    });
  };

  getAllBrand = async () => {
    var listBrand = [];
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    await Axios({
      url: "/brand/list?select=name&sort=name.asc&id=ne.5",
      method: "GET",
      headers: { Authorization: `Bearer ${userToken}` },
    }).then((response) => {
      response.data.data.data.forEach((brand) => {
        listBrand.push(brand);
      });
    });

    this.setState({
      listBrand: listBrand,
    });
  };

  getListBA = async (filterDC, filterBrand) => {
    var templistBA = [];
    this.setState({
      dataBA: [],
      isLoading: true,
    });
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    let query = "";

    if (filterDC.length > 0 && filterDC.indexOf("DC ALL") === -1) {
      query += "&dc=[" + filterDC + "]";
    }

    if (filterBrand.length > 0 && filterBrand.indexOf("All") === -1) {
      query += "&ba_brand=[" + filterBrand + "]";
    }

    await Axios({
      url: `/v_details_ba_partner_dc_nip?sort=dc.asc,name.asc${query}`,
      method: "GET",
      headers: { Authorization: `Bearer ${userToken}` },
    })
      .then((response) => {
        response.data.data.data.forEach((data) => {
          templistBA.push(data);
        });
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({
      dataBA: templistBA,
      isLoading: false,
    });
  };

  getDefaultDC = () => {
    const cookies = new Cookies();
    var listSelectedDC = ["DC JAKARTA"];
    if (
      cookies.get("listSelectedDCBA") === undefined ||
      cookies.get("listSelectedDCBA").length === 0
    ) {
      this.setState({
        listSelectedDC: ["DC JAKARTA"],
      });
    } else {
      listSelectedDC = cookies.get("listSelectedDCBA");
      this.setState({
        listSelectedDC: cookies.get("listSelectedDCBA"),
      });
    }

    return listSelectedDC;
  };

  getDefaultBrand = () => {
    const cookies = new Cookies();
    var listSelectedBrand = ["All"];
    if (
      cookies.get("listSelectedBrandBA") === undefined ||
      cookies.get("listSelectedBrandBA").length === 0
    ) {
      this.setState({
        listSelectedBrand: ["All"],
      });
    } else {
      listSelectedBrand = cookies.get("listSelectedBrandBA");
      this.setState({
        listSelectedBrand: cookies.get("listSelectedBrandBA"),
      });
    }

    return listSelectedBrand;
  };

  componentDidMount() {
    this.getAllDC();
    this.getAllBrand();
    this.getListBA(this.getDefaultDC(), this.getDefaultBrand());
  }

  onBAClick = (cell, ba, rowIndex) => {
    const cookies = new Cookies();
    cookies.set("selectedBA", ba, { path: "/" });
  };

  buttonAction(cell, row, enumObject, rowIndex) {
    return (
      <Button
        className="buttons-copy buttons-html5"
        color="default"
        size="sm"
        id="copy-tooltip"
        onClick={this.onBAClick(cell, row, rowIndex)}
      >
        <span>Action</span>
      </Button>
    );
  }

  GetDetailsFormat(cell, row) {
    return (
      <div>
        <Link to={`/admin/dashboard-ba?ba_id=${row.ba_id}`}>
          <button
            type="button"
            className="btn btn-outline-primary btn-sm ts-buttom"
            size="sm"
          >
            View
          </button>
        </Link>
      </div>
    );
  }

  GetActionFormatStatusActive(cell, row) {
    return (
      <div>
        <Row style={{ margin: "auto" }}>
          <button
            type="button"
            className="btn btn-default btn-sm ts-buttom"
            size="sm"
            onClick={this.openModalUpdateStatusBA.bind(this, cell, row)}
          >
            Edit Status
          </button>
        </Row>
      </div>
    );
  }

  GetActionEditArea(cell, row) {
    return (
      <div>
        <Row style={{ margin: "auto" }}>
          <Link to={`/admin/area/${row.ba_id}`}>
            <button
              type="button"
              className="btn btn-default btn-sm ts-buttom"
              size="sm"
            >
              Edit Area
            </button>
          </Link>
        </Row>
      </div>
    );
  }

  getStatusFormat(cell, row) {
    return (
      <div>
        <Row style={{ margin: "auto" }}>
          {cell ? (
            <CheckCircleIcon style={{ color: "#31B057" }} />
          ) : (
            <CancelIcon style={{ color: "#E53A34" }} />
          )}
        </Row>
      </div>
    );
  }

  GetActionFormatEditNIP(cell, row) {
    return (
      <div>
        <Row style={{ margin: "auto" }}>
          <button
            type="button"
            className="btn btn-outline-default btn-sm ts-buttom"
            size="sm"
            onClick={this.openModalUpdateNIPBA.bind(this, cell, row)}
          >
            Edit NIP
          </button>
        </Row>
      </div>
    );
  }

  openModalUpdateStatusBA = (cell, ba, rowIndex) => {
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    Axios({
      method: "get",
      url: `/master-ba/list?id=${ba.ba_id}`,
      headers: { Authorization: `Bearer ${userToken}` },
    })
      .then((response) => {
        response = response.data;
        var isBAChoosedActive;
        if (response.data.data[0].active === true) {
          isBAChoosedActive = 1;
        } else if (response.data.data[0].active === false) {
          isBAChoosedActive = 0;
        } else {
          isBAChoosedActive = 2; // null
        }
        this.setState({
          openModalUpdateBAStatus: true,
          dataBAUpdateStatus: ba,
          statusBAChoosed: response.data.data[0].active,
          isBAChoosedActive: isBAChoosedActive,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  openModalUpdateNIPBA = (cell, ba, rowIndex) => {
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: "get",
      url: `/master-ba/${ba.ba_id}`,
    };

    Axios(config)
      .then((response) => {
        this.setState({
          openModalUpdateNIPBA: true,
          dataBAUpdateNIP: ba,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  closeModalUpdateStatusBA = () => {
    this.setState({
      openModalUpdateBAStatus: false,
      dataBAUpdateStatus: [],
    });
  };

  closeModalUpdateAlert = () => {
    this.setState({
      openModalUpdateAlert: false,
    });
  };

  closeModalUpdateNIPBA = () => {
    this.setState({
      openModalUpdateNIPBA: false,
      dataBAUpdateNIP: [],
      newNIPUpdate: null,
    });
  };

  handleClickUpdateNIP = (e) => {
    this.setState({
      newNIPUpdate: e.target.value,
    });
  };

  saveChangesUpdateStatusBA = () => {
    var status;
    if (this.state.isBAChoosedActive === 1) {
      status = true;
    } else if (this.state.isBAChoosedActive === 0) {
      status = false;
    } else {
      status = null;
    }

    var items = {
      active: status,
    };

    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: "patch",
      url: `/master-ba/${this.state.dataBAUpdateStatus.ba_id}`,
      data: items,
    };

    Axios(config)
      .then((result) => {
        this.closeModalUpdateAlert();
        this.closeModalUpdateStatusBA();
        this.alertSaveResponse("success");
        this.getListBA(this.state.listSelectedDC, this.state.listSelectedBrand);
      })
      .catch((error) => {
        console.log(error);
        this.closeModalUpdateAlert();
        this.closeModalUpdateStatusBA();
        this.alertSaveResponse("danger");
      });
  };

  alertSaveResponse = (status) => {
    this.setState({
      modalAlertMessage:
        status === "success"
          ? "Data berhasil diubah"
          : "Data gagal diubah karena terdapat kesalahan, mohon dicoba beberapa saat lagi",
      modalAlertData: null,
      modalAlertStatus: status,
      openModalUpdateAlert: true,
      modalAlertChangedData: "close",
      noticeShow: true,
    });
  };

  alertSaveStatusChanged = () => {
    this.setState({
      modalAlertMessage: "Apakah anda yakin ingin mengubah Status BA berikut?",
      modalAlertData: {
        name: this.state.dataBAUpdateStatus.name,
        changes: this.state.isBAChoosedActive === 1 ? "Active" : "Non-Active",
      },
      modalAlertStatus: "info",
      openModalUpdateAlert: true,
      modalAlertChangedData: "status",
    });
  };

  alertSaveNIPChanged = () => {
    if (this.state.newNIPUpdate == null || this.state.newNIPUpdate === "") {
      this.toggleModal("notificationModal");
    } else {
      this.setState({
        modalAlertMessage: "Apakah anda yakin ingin mengubah NIP BA berikut?",
        modalAlertData: {
          name: this.state.dataBAUpdateNIP.name,
          changes: this.state.newNIPUpdate,
        },
        modalAlertStatus: "info",
        openModalUpdateAlert: true,
        modalAlertChangedData: "nip",
      });
    }
  };

  toggleModal = (state) => {
    var tempState = "bg-gradient-danger";
    var message = "Gagal! NIP Baru Tidak Boleh Kosong!";
    this.setState({
      [state]: !this.state[state],
      createState: tempState,
      modalMessage: message,
    });
  };

  saveChangesUpdateNIPBA = () => {
    var items = {
      NIP: this.state.newNIPUpdate,
    };
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      method: "patch",
      url: `/master-ba/${this.state.dataBAUpdateNIP.ba_id}`,
      data: items,
    };

    Axios(config)
      .then((result) => {
        // window.location.reload();
        this.closeModalUpdateAlert();
        this.closeModalUpdateNIPBA();
        this.alertSaveResponse("success");
        this.getListBA(this.state.listSelectedDC, this.state.listSelectedBrand);
      })
      .catch((error) => {
        console.log(error);
        this.closeModalUpdateAlert();
        this.closeModalUpdateNIPBA();
        this.alertSaveResponse("warning");
      });
  };

  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => this.setState({ alert: null })}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Copied to clipboard!
        </ReactBSAlert>
      ),
    });
  };

  listOfColumnHandler = () => {
    const cookies = new Cookies();
    const getUser = cookies.get("userdashboard");
    const isSuperUser = getUser.is_super_admin;
    console.log(getUser);

    let arr = [
      {
        dataField: "ba_id",
        text: "ID",
        sort: true,
      },
      {
        dataField: "name",
        text: "Nama",
        sort: true,
      },
      {
        dataField: "username",
        text: "Username",
        sort: true,
      },
      {
        dataField: "ba_brand",
        text: "Brand",
        sort: true,
      },
      {
        dataField: "partner_name",
        text: "Nama Store",
        sort: true,
      },
      {
        dataField: "ref",
        text: "Customer ID",
        sort: true,
      },
      {
        dataField: "dc",
        text: "DC",
        sort: true,
      },
      // {
      //   dataField: "salesarea_name",
      //   text: "SALES AREA",
      //   sort: true,
      // },
      {
        dataField: "NIP",
        text: "NIP",
        sort: true,
      },
      {
        dataField: "active",
        text: "Active",
        sort: true,
        formatter: this.getStatusFormat.bind(this),
        style: {
          whiteSpace: "unset",
        },
      },
      {
        text: "Edit Area",
        dataField: "",
        formatter: this.GetActionEditArea.bind(this),
        style: {
          whiteSpace: "unset",
        },
      },
    ];

    if (isSuperUser) {
      arr = [
        ...arr,
        {
          text: "Edit Active",
          dataField: "",
          formatter: this.GetActionFormatStatusActive.bind(this),
          style: {
            whiteSpace: "unset",
          },
        },
        {
          text: "Edit NIP",
          dataField: "",
          formatter: this.GetActionFormatEditNIP.bind(this),
          style: {
            whiteSpace: "unset",
          },
        },
      ];
    }

    arr = [
      ...arr,
      {
        text: "Edit FC",
        dataField: "",
        formatter: (cell, row) => <FCEditColumn row={row} />,
        style: {
          whiteSpace: "unset",
        },
      },
      {
        text: "Details",
        dataField: "",
        formatter: this.GetDetailsFormat,
      },
    ];

    return arr;
  };
  render() {
    var defaultSelectedDC = [];
    if (this.state.listDC.length > 0 && this.state.listSelectedDC.length > 0) {
      this.state.listDC.forEach((item) => {
        var isFound = false;
        var idx = 0;
        while (idx < this.state.listSelectedDC.length && !isFound) {
          if (item.partner_name === this.state.listSelectedDC[idx]) {
            defaultSelectedDC.push(item);
            isFound = true;
          } else {
            idx++;
          }
        }
      });
    }

    var defaultSelectedBrand = [];
    if (
      this.state.listBrand.length > 0 &&
      this.state.listSelectedBrand.length > 0
    ) {
      this.state.listBrand.forEach((item) => {
        var isFound = false;
        var idx = 0;
        while (idx < this.state.listSelectedBrand.length && !isFound) {
          if (item.name === this.state.listSelectedBrand[idx]) {
            defaultSelectedBrand.push(item);
            isFound = true;
          } else {
            idx++;
          }
        }
      });
    }

    const masterBAFileName =
      "BA Data_" + DateFormat(new Date(), "yyyy-mm-dd-HHMMss") + ".csv";

    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Master BA" parentName="Tables" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xl="12">
                      <h1>List BA Bivi</h1>
                    </Col>
                    <Col xl="12">
                      <h3 className="mb-4">Filter</h3>
                    </Col>
                    <Col xl="4">
                      {this.state.listDC.length > 0 ? (
                        <Autocomplete
                          multiple
                          id="list-dc"
                          options={this.state.listDC}
                          defaultValue={defaultSelectedDC}
                          getOptionLabel={(option) => option.partner_name}
                          freeSolo
                          onChange={(event, value) =>
                            this.onDCSelectedChanged(value)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Pilih DC"
                              placeholder="Pilih DC"
                            />
                          )}
                        />
                      ) : null}
                    </Col>
                    <Col xl="4">
                      {this.state.listBrand.length > 0 ? (
                        <Autocomplete
                          multiple
                          id="list-dc"
                          options={this.state.listBrand}
                          defaultValue={defaultSelectedBrand}
                          getOptionLabel={(option) => option.name}
                          freeSolo
                          onChange={(event, value) =>
                            this.onBrandSelectedChanged(value)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Pilih Brand"
                              placeholder="Pilih Brand"
                            />
                          )}
                        />
                      ) : null}
                    </Col>
                    {this.state.dataBA.length > 0 ? (
                      <>
                        <Col xl="12" className="mt-4">
                          <CSVLink
                            data={this.state.dataBA}
                            filename={masterBAFileName}
                            style={{ color: "#FFFFFF" }}
                          >
                            <Button color="primary" size="sm" type="button">
                              Download
                            </Button>
                          </CSVLink>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                </CardHeader>
                {this.state.isLoading ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </div>
                ) : null}
                <ToolkitProvider
                  data={this.state.dataBA}
                  keyField="ba_id"
                  columns={this.listOfColumnHandler()}
                  search
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        onClick={this.onBAClick.bind(this)}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
              {/*
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Action buttons</h3>
                  <p className="text-sm mb-0">
                    This is an exmaple of data table using the well known
                    react-bootstrap-table2 plugin. This is a minimal setup in
                    order to get started fast.
                  </p>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.dataBA}
                  keyField="name"
                  columns={[
                    {
                      dataField: "name",
                      text: "Nama",
                      sort: true
                    },
                    {
                      dataField: "partner_name",
                      text: "Nama Store",
                      sort: true
                    },
                    {
                      dataField: "ba_brand",
                      text: "Brand",
                      sort: true
                    },
                    {
                      dataField: "active",
                      text: "Active",
                      sort: true
                    },
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={6}>
                            <ButtonGroup>
                              <Button
                                className="buttons-copy buttons-html5"
                                color="default"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  this.copyToClipboardAsTable(
                                    document.getElementById("react-bs-table")
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint.default
                                trigger={() => (
                                  <Button
                                    color="default"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => this.componentRef}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>
                          </Col>
                          <Col xs={12} sm={6}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-right"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={el => (this.componentRef = el)}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>*/}
            </div>
          </Row>
        </Container>

        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName={"bg-gradient-" + this.state.modalAlertStatus}
          isOpen={this.state.openModalUpdateAlert}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Notifikasi
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModalUpdateAlert.bind(this)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <h4 className="heading mt-4">{this.state.modalAlertMessage}</h4>
              {this.state.modalAlertData ? (
                <h4 className="heading mt-4">
                  {this.state.modalAlertData.name} -{" "}
                  {this.state.modalAlertData.changes === "" ||
                  this.state.modalAlertData.changes === null
                    ? "(NIP KOSONG)"
                    : this.state.modalAlertData.changes}
                </h4>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModalUpdateAlert.bind(this)}
            >
              Tutup
            </Button>
            {this.state.modalAlertChangedData === "status" ||
            this.state.modalAlertChangedData === "nip" ? (
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={
                  this.state.modalAlertChangedData === "status"
                    ? this.saveChangesUpdateStatusBA.bind(this)
                    : this.state.modalAlertChangedData === "nip"
                    ? this.saveChangesUpdateNIPBA.bind(this)
                    : () => {
                        window.location.reload();
                      }
                }
              >
                Simpan
              </Button>
            ) : null}
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-info"
          isOpen={this.state.openModalUpdateBAStatus}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Edit Status BA
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModalUpdateStatusBA.bind(this)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <h4 className="heading mt-4">
                {this.state.dataBAUpdateStatus.name}
              </h4>
              <h4 className="heading mt-4">
                ID : {this.state.dataBAUpdateStatus.ba_id} | NIP :{" "}
                {this.state.dataBAUpdateStatus.NIP} |{" "}
                {this.state.dataBAUpdateStatus.dc}
              </h4>
              <p>
                {this.state.dataBAUpdateStatus.partner_name} |{" "}
                {this.state.dataBAUpdateStatus.ref}
              </p>
              <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                <Button
                  className={classnames({
                    active: this.state.isBAChoosedActive === 1,
                  })}
                  onClick={() => this.setState({ isBAChoosedActive: 1 })}
                  style={{
                    backgroundColor:
                      this.state.isBAChoosedActive === 1
                        ? "forestgreen"
                        : "white",
                  }}
                >
                  <input
                    autoComplete="off"
                    name="options"
                    type="radio"
                    value={this.state.isBAChoosedActive === 1}
                  />
                  Active
                </Button>
                <Button
                  className={classnames({
                    active: this.state.isBAChoosedActive === 0,
                  })}
                  color="danger"
                  onClick={() => this.setState({ isBAChoosedActive: 0 })}
                  style={{
                    backgroundColor:
                      this.state.isBAChoosedActive === 0 ? "grey" : "white",
                  }}
                >
                  <input
                    autoComplete="off"
                    name="options"
                    type="radio"
                    value={this.state.isBAChoosedActive === 0}
                  />
                  Non-Active
                </Button>
              </ButtonGroup>
              <p style={{ marginTop: "10px" }}>
                {this.state.dataBAUpdateStatus.name} changed to{" "}
                <b>
                  {this.state.isBAChoosedActive === 1
                    ? "Active"
                    : this.state.isBAChoosedActive === 0
                    ? "Non Active"
                    : "Null"}
                </b>
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModalUpdateStatusBA.bind(this)}
            >
              Kembali
            </Button>
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick={this.saveChangesUpdateStatusBA.bind(this)}
            >
              Simpan Perubahan
            </Button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-info"
          isOpen={this.state.openModalUpdateNIPBA}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Edit NIP BA
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModalUpdateNIPBA.bind(this)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <h4 className="heading mt-4">
                {this.state.dataBAUpdateNIP.name}
              </h4>
              <h4 className="heading mt-4">
                ID : {this.state.dataBAUpdateNIP.ba_id} | NIP :{" "}
                {this.state.dataBAUpdateNIP.NIP} |{" "}
                {this.state.dataBAUpdateNIP.dc}
              </h4>
              <p>
                {this.state.dataBAUpdateNIP.partner_name} |{" "}
                {this.state.dataBAUpdateNIP.ref}
              </p>
              <p>
                <b>Silahkan Input NIP BA Disini :</b>
              </p>
              <Input
                placeholder={this.state.dataBAUpdateNIP.NIP}
                type="text"
                style={{ width: "70%", margin: "auto" }}
                onChange={this.handleClickUpdateNIP.bind(this)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModalUpdateNIPBA.bind(this)}
            >
              Kembali
            </Button>
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick={this.alertSaveNIPChanged.bind(this)}
            >
              Simpan Perubahan
            </Button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName={this.state.createState}
          isOpen={this.state.notificationModal}
          toggle={() => this.toggleModal("notificationModal")}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              &nbsp;
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("notificationModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading mt-4">{this.state.modalMessage}</h4>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick={() => this.toggleModal("notificationModal")}
            >
              OK
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

const FCEditColumn = ({ row }) => {
  return (
    <div>
      <Row style={{ margin: "auto" }}>
        <Link to={`/admin/manage-ba/edit-fc/${row.ba_id}`}>
          <button
            type="button"
            className="btn btn-primary btn-sm ts-buttom"
            size="sm"
          >
            Edit FC
          </button>
        </Link>
      </Row>
    </div>
  );
};

export default ReactBSTables;

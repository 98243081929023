import React, { Component } from "react";
import {
  Container,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import XLSX from "xlsx";

import SimpleHeader from "../../../components/Headers/SimpleHeader";
import FullrowCard from "../../../components/ba-achievement/FullrowCard";
import SnackbarAlert from "../../../components/ba-achievement/SnackbarAlert";
import LoadingProvider from "../../../components/ba-achievement/LoadingProvider";
import SchemePicker from "../../../components/ba-achievement/SchemePicker";
import DCPicker from "../../../components/ba-achievement/DCPicker";
import AreaSalesPicker from "../../../components/ba-achievement/SalesAreaPickerDownloaded";
import YearMonthPickerAutocomplete from "../../../components/ba-achievement/YearMonthPickerAutocomplete";
import TargetTracker from "../../../components/ba-achievement/TargetTracker";

import fetchBAByDCNames from "../../../services/fetchBAByDCNames";
import fetchBAByAreaSalesNames from "../../../services/fetchBAByAreaSalesNames";
import fetchAllDCs from "../../../services/fetchAllDCs";
import fetchAllAreaSales from "../../../services/fetchAllAreaSales";

import fetchAllSchemes from "../../../services/fetchAllSchemes";
import fetchAllParameters from "../../../services/fetch/parameter/fetchAllParameters";
import fetchSchemeLineBySchemeId from "../../../services/fetchSchemeLineBySchemeId";
import fetchTargetTracker from "../../../services/fetchTargetTracker";
import fetchTargetTrackerBySalesArea from "../../../services/fetchTargetTrackerBySalesArea";

import toYearMonthString from "../../../helpers/toYearMonthString";
import { find } from "lodash";

export default class DownloadTemplateTarget extends Component {
  state = {
    isLoading: false,
    fetchedDCs: [],
    fetchedSchemes: [],
    fetchedParameters: [],
    componentKey: 1,
    selectedScheme: null,
    selectedSchemeDCs: [],
    selectedAreaSales: [],
    selectedSchemeAreaSales: [],
    selectedSchemeParametersFull: [],
    selectedSchemeParameters: [],
    selectedSchemeYearMonths: [],
    selectedDCs: [],
    selectedYearMonth: null,
    uploadedYearMonthDCs: [],
    listAreaSales: [],
    alert: {
      isVisible: false,
      severity: "error",
      message: "",
    },
  };

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });

      const [fetchedDCs, fetchedSchemes, fetchedParameters, allAreaSales] =
        await Promise.all([
          fetchAllDCs(),
          fetchAllSchemes(),
          fetchAllParameters(),
          fetchAllAreaSales(),
        ]);

      this.setState({
        fetchedDCs,
        fetchedSchemes,
        fetchedParameters,
        isLoading: false,
        listAreaSales: allAreaSales,
      });
    } catch (err) {
      this.fireAlert(
        "error",
        "Terjadi kesalahan, periksa kembali koneksi anda!"
      );
    }
  }

  handleDownload = async () => {
    try {
      if (this.validateEmptyInput() === false) {
        this.fireAlert("error", "Pastikan semua field telah terisi!");
        return;
      }

      this.setState({ isLoading: true });

      const { selectedSchemeParameters } = this.state;
      const parameters = selectedSchemeParameters
        .filter((elem) => {
          return elem === "Sell In" || elem === "Sell Out";
        })
        .reduce((result, elem) => {
          result[elem] = null;
          return result;
        }, {});

      let listBA = [];

      if (this.state.selectedAreaSales.length) {
        listBA = await fetchBAByAreaSalesNames(
          this.state.selectedAreaSales.map((item) => item.value)
        );
      } else {
        listBA = await fetchBAByDCNames(
          this.state.selectedDCs.map((DC) => DC.partner_name)
        );
      }

      let mappedBA = [];
      const isSalesArea = this.state.selectedAreaSales.length;

      if (isSalesArea) {
        mappedBA = listBA
          .map((ba) => {
            const {
              ba_id,
              name,
              username,
              ba_brand,
              partner_name,
              active,
              salesarea_name,
              partner_id,
              ref,
            } = ba;

            return {
              ba_id,
              name,
              username,
              salesarea_name,
              ba_brand,
              partner_name,
              partner_id,
              active,
              ref,
              ...parameters,
            };
          })
          .sort((a, b) => (a.salesarea_name > b.salesarea_name ? 1 : -1));
      } else {
        mappedBA = listBA
          .map((ba) => {
            const {
              ba_id,
              name,
              username,
              ba_brand,
              partner_name,
              active,
              dc,
              partner_id,
              ref,
            } = ba;

            return {
              ba_id,
              name,
              username,
              dc,
              ba_brand,
              partner_name,
              partner_id,
              active,
              ref,
              ...parameters,
            };
          })
          .sort((a, b) => (a.dc > b.dc ? 1 : -1));
      }
      console.log(this.getJointAreaNames(), "<<<<");

      this.downloadSpreadsheet(
        mappedBA,
        `BA-target-${this.state.selectedYearMonth}-${
          isSalesArea
            ? this.getJointAreaNames()
            : this.state.selectedDCs.length <= 5
            ? this.getJointDCNames()
            : "multiple"
        }.xlsx`,
        isSalesArea
      );

      this.setState({
        isLoading: false,
        selectedScheme: null,
        selectedDCs: [],
      });
    } catch (err) {
      this.fireAlert(
        "error",
        "Terjadi kesalahan, periksa kembali koneksi anda!"
      );
    }
  };

  downloadSpreadsheet = (sheetData, fileName, isSalesArea) => {
    const workbook = XLSX.utils.book_new();

    const sortedSheetData = sheetData.sort((a, b) => {
      if (a.partner_name === b.partner_name) {
        return a.name < b.name ? -1 : 1;
      }

      return a.partner_name < b.partner_name ? -1 : 1;
    });

    const sheet = XLSX.utils.json_to_sheet(sortedSheetData, { origin: 7 });

    const { selectedScheme, selectedSchemeParametersFull, selectedYearMonth } =
      this.state;
    const {
      getJointDCNames,
      getJointDCIds,
      getJointAreaIds,
      getJointAreaNames,
    } = this;

    const parameter_ids = selectedSchemeParametersFull.map(
      (parameter) => parameter.id
    );

    const ids = isSalesArea ? getJointAreaIds() : getJointDCIds();
    const areaHead = isSalesArea
      ? ["", "Area Sales", getJointAreaNames()]
      : ["", "DC", getJointDCNames()];
    const identifier = `${selectedScheme.id}/${parameter_ids}/${ids}/${selectedYearMonth}`;

    const header = [
      ["", "Target Achievement Beauty Advisor (BA)"],
      ["", "sheet_id", identifier],
      [""],
      ["", "Nama Skema", selectedScheme.name],
      areaHead,
      ["", "Bulan", toYearMonthString(selectedYearMonth)],
    ];

    const colSizes = [
      { wch: 8 }, // ba_id
      { wch: 25 }, // name
      { wch: 15 }, // username
      { wch: 15 }, // DC
      { wch: 15 }, // ba_brand
      { wch: 30 }, // partner_name
      { wch: 10 }, // partner_id
      { wch: 5 }, // active
      { wch: 15 }, // ref
      { wch: 20 }, // parameters
      { wch: 20 }, // parameters
    ];

    sheet["!cols"] = colSizes;

    XLSX.utils.sheet_add_aoa(sheet, header, {
      origin: "A1",
    });

    XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
    XLSX.writeFile(workbook, fileName);
  };

  getJointDCNames = () => {
    return this.state.selectedDCs
      .map((DC) => DC.partner_name.split(" ").join("_"))
      .join(",");
  };

  getJointDCIds = () => {
    return this.state.selectedDCs.map((DC) => DC.partner_id).join(",");
  };

  getJointAreaNames = () => {
    return this.state.selectedAreaSales
      .map((item) => item.value.split(" ").join("_"))
      .join(",");
  };

  getJointAreaIds = () => {
    return this.state.selectedAreaSales.map((item) => item.id).join(",");
  };

  handleSchemeChange = async (event, value) => {
    try {
      const {
        dc_id: dc_ids,
        year_month: selectedSchemeYearMonths,
        areasales_id,
      } = value;
      const { fetchedDCs, componentKey, listAreaSales } = this.state;

      let selectedSchemeDCs = [];
      let selectedSchemeAreaSales = [];

      if (areasales_id && areasales_id.length) {
        selectedSchemeAreaSales = listAreaSales.filter((area) => {
          const findAreaById = find(areasales_id, (item) => {
            return item === area.id;
          });

          return findAreaById >= 0;
        });
      } else {
        selectedSchemeDCs = fetchedDCs.filter((DC) => {
          return dc_ids.indexOf(DC.partner_id) !== -1;
        });
      }

      // get parameters on current scheme
      const SchemeLines = await fetchSchemeLineBySchemeId(value.id);

      const selectedSchemeParametersFull = SchemeLines.map((elem) => {
        return this.state.fetchedParameters.find(
          (parameter) => parameter.id === elem.parameter_id
        );
      });

      const selectedSchemeParameters = SchemeLines.map((elem) => {
        return this.state.fetchedParameters.find(
          (parameter) => parameter.id === elem.parameter_id
        ).name;
      });

      this.setState({
        selectedScheme: value,
        selectedSchemeDCs,
        selectedSchemeAreaSales,
        selectedSchemeYearMonths,
        selectedSchemeParametersFull,
        selectedSchemeParameters,
        selectedDCs: [],
        selectedAreaSales: [],
        selectedYearMonth: null,
        componentKey: componentKey + 1,
      });

      // TRACKER
      let targetTracker = [];

      if (areasales_id && areasales_id.length) {
        targetTracker = await fetchTargetTrackerBySalesArea({
          scheme: value,
        });
      } else {
        targetTracker = await fetchTargetTracker();
      }
      
      console.log(targetTracker, "<<<");
      const uploadedYearMonthDCs = targetTracker.reduce((result, target) => {
        const { year_month, dc_id, is_target_submitted, salesarea_id } = target;

        if (is_target_submitted) {
          result = [
            ...result,
            `${year_month}--${
              salesarea_id || salesarea_id >= 0 ? salesarea_id : dc_id
            }`,
          ];
        }

        return result;
      }, []);

      this.setState({ uploadedYearMonthDCs });
    } catch (err) {
      console.log(err);
    }
  };

  handleNoSelectedScheme = () => {
    if (!this.state.selectedScheme) {
      this.fireAlert("error", "Pilih skema terlebih dahulu!");
    }
  };

  // open alert
  fireAlert = (severity, message) => {
    this.setState({
      alert: {
        isVisible: true,
        severity,
        message,
      },
    });
  };

  // close alert
  closeAlert = () => {
    this.setState({
      alert: {
        isVisible: false,
        severity: "error",
        message: "",
      },
    });
  };

  // validate empty input
  validateEmptyInput = () => {
    if (
      !this.state.selectedScheme ||
      (!this.state.selectedDCs.length && !this.state.selectedAreaSales) ||
      !this.state.selectedYearMonth
    ) {
      return false;
    }

    return true;
  };

  render() {
    const isAreaSales = this.state.selectedSchemeAreaSales.length > 0;

    return (
      <>
        <SimpleHeader
          name="Download Template Target"
          parentName="BA Achievement"
        />

        <SnackbarAlert
          isVisible={this.state.alert.isVisible}
          severity={this.state.alert.severity}
          message={this.state.alert.message}
          closeEvent={() => this.closeAlert()}
        />

        <Container className="mt--6" fluid>
          <LoadingProvider isLoading={this.state.isLoading}>
            <FullrowCard>
              <CardHeader>
                <h2>Download Template Target (Sell In & Sell Out)</h2>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>
                    <h3>Pilih Skema</h3>
                  </Label>
                  <SchemePicker
                    schemes={this.state.fetchedSchemes}
                    changeEvent={this.handleSchemeChange}
                  />
                </FormGroup>

                {/* TRACKER */}
                {this.state.selectedScheme ? (
                  <TargetTracker
                    yearMonths={this.state.selectedSchemeYearMonths}
                    uploadedYearMonthDCs={this.state.uploadedYearMonthDCs}
                    arr={
                      isAreaSales
                        ? this.state.selectedSchemeAreaSales
                        : this.state.selectedSchemeDCs
                    }
                    isAreaSales={isAreaSales}
                  />
                ) : null}

                {this.state.selectedScheme && (
                  <>
                    <FormGroup>
                      <Label>
                        <h3>Pilih {isAreaSales ? "Area" : "DC"}</h3>
                      </Label>
                      {isAreaSales ? (
                        <AreaSalesPicker
                          showSelectAllFilter={true}
                          // key={this.state.componentKey}
                          multiple={true}
                          listAreaSales={this.state.selectedSchemeAreaSales}
                          setSelectedArea={(value) =>
                            this.setState({ selectedAreaSales: value })
                          }
                          // onInputChange={this.handleNoSelectedScheme}
                        />
                      ) : (
                        <DCPicker
                          showSelectAllFilter={true}
                          key={this.state.componentKey}
                          multiple={true}
                          DCs={this.state.selectedSchemeDCs}
                          setSelectedDC={(value) =>
                            this.setState({ selectedDCs: value })
                          }
                          onInputChange={this.handleNoSelectedScheme}
                        />
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label>
                        <h3>Pilih Bulan</h3>
                      </Label>
                      <YearMonthPickerAutocomplete
                        key={this.state.componentKey}
                        yearMonths={this.state.selectedSchemeYearMonths}
                        onInputChange={this.handleNoSelectedScheme}
                        setSelectedYearMonth={(value) => {
                          this.setState({
                            selectedYearMonth: value,
                          });
                        }}
                      />
                    </FormGroup>
                  </>
                )}
              </CardBody>
            </FullrowCard>

            {this.state.selectedScheme && (
              <FullrowCard>
                <Button color="primary" onClick={this.handleDownload}>
                  Download Template Target
                </Button>
              </FullrowCard>
            )}
          </LoadingProvider>
        </Container>
      </>
    );
  }
}

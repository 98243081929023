import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import React, { useState } from "react";

const SalesAreaPicker = ({ handleChange, listRegion, listAreaByRegion }) => {
  const [listSalesArea, setListSalesArea] = useState([]);
  const changeRegionHandler = (arr) => {
    let result = [];
    arr.forEach((item) => {
      const temp = listAreaByRegion.filter(
        (area) => area.sales_region_name === item.value
      );
      result = [...result, ...temp];
    });

    setListSalesArea(arr.length ? result : []);
  };

  return (
    <div>
      <div className="mb-4">
        <Autocomplete
          multiple={true}
          options={listRegion}
          getOptionLabel={(option) => option.value}
          onChange={(_, value) => changeRegionHandler(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Pilih Region"
            />
          )}
        />
      </div>
      <div>
        {listSalesArea.length > 0 && (
          <Autocomplete
            multiple={true}
            options={listSalesArea}
            getOptionLabel={(option) => option.sales_area_name}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Pilih Sales Area"
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default SalesAreaPicker;

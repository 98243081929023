import { useEffect, useState } from 'react'
import fetchAllBrand from '../services/fetchAllBrand'

export default function useUploadTargetInput() {
	const [parameterInput, setParameterInput] = useState()
	const [configurableAttributes, setConfigurableAttributes] = useState()
	const [conversionRate, setConversionRate] = useState('')
	const [totalVisitor, setTotalVisitor] = useState()
	const [brands, setBrands] = useState([])

	useEffect(() => {
		;(async () => {
			const brands = await fetchAllBrand()
			const inputPerBrand = brands.filter(brand => brand.name !== 'All')

			setBrands(inputPerBrand.map(brand => brand.name.split(':')[1]))

			const objParameterInput = {}
			const objConfigAttr = {}
			const objTotalVisitor = {}
			inputPerBrand.forEach(brand => {
				const brandName = brand.name.split(':')[1]
				objParameterInput[brandName] = ''
				objConfigAttr[brandName] = {
					caConversionRate: '',
					caAvgBasketSize: '',
				}
				objTotalVisitor[brandName] = '-'
			})

			setParameterInput(objParameterInput)
			setConfigurableAttributes(objConfigAttr)
			setTotalVisitor(objTotalVisitor)
		})()
	}, [])

	const clearInput = () => {
		setConversionRate('')

		const objParameterInput = {}
		const objConfigAttr = {}
		const objTotalVisitor = {}
		brands.forEach(brand => {
			objParameterInput[brand] = ''
			objConfigAttr[brand] = {
				caConversionRate: '',
				caAvgBasketSize: '',
			}
			objTotalVisitor[brand] = '-'
		})

		setParameterInput(objParameterInput)
		setConfigurableAttributes(objConfigAttr)
		setTotalVisitor(objTotalVisitor)
	}

	return {
		parameterInput,
		setParameterInput,
		configurableAttributes,
		setConfigurableAttributes,
		totalVisitor,
		setTotalVisitor,
		brands,
		conversionRate,
		setConversionRate,
		clearInput,
	}
}

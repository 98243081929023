/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ImageUploader from 'react-images-upload';
import { useParams, useHistory } from "react-router-dom";

import Cookies from 'universal-cookie'
import Axios from "services/axios";

import Swal from 'sweetalert2';
import { Typography,  FormControl } from '@material-ui/core';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import SubmitMateriHeader from "components/Headers/SubmitMateriHeader.jsx";

let cookies = new Cookies();

export class Profile extends React.Component {
 
	constructor() {
  
		super();
		this.state = {
			userloggedin : {},
			color : [],
      title : null,
      theme : "Semua Kategori",
      require : "NO",
      description : null,
      filetype : "PDF",
      filelink : null,
      uploaded_by : null,
      ktp_pdf : null,
      pictureDataURLs: null,
			isNewUpload: false,
      isSelectPhotoValid: true,
      progressStatus: null,
      file_uploaded: {},
		}
	}
  componentDidMount = async() => {
    const { id } = this.props.match.params;
    const userToken = cookies.get("jwtKey");

    const res= await Axios({
      method: "POST",
      url: "/auth.php",
      data: {
        jwt: userToken,
        table: "dk_submit_materi",
        method: "get",
        query: `id=eq.${id}`,
      },
      })
    const data= res.data.data[0]
    this.setState({
      description: data.description,
      pictureDataURLs: data.tumbnail_name,
      theme: data.theme,
      require: data.require,
      title: data.title,

    });
   
  }  
  onDrop(pictureFiles, pictureDataURLs) {
		//var idx_picture = pictureFiles.length - 1
		this.setState({
			isNewUpload: false,
		});
    this.setState({
      pictures: pictureFiles,
      pictureDataURLs: pictureDataURLs[pictureFiles.length - 1],
      isNewUpload: true,
    });

    if(pictureDataURLs !== undefined){
			this.setState({
	      pictures: pictureFiles,
				pictureDataURLs: pictureDataURLs[pictureFiles.length - 1],
				isNewUpload: true,
	    });
		}
  }

  handleChooseTitleMaterial = event => {
    this.setState({
      title : event.target.value,
    })
  }

  handleChooseThemeMaterial = (event, row) => {
    this.setState({
      theme : event.target.value,
    })
  }

  handleChooseRequireMaterial = (event, row) => {
    this.setState({
      require : event.target.value,
    })
  }

  handleChooseDescriptionMaterial = event => {
    this.setState({
      description : event.target.value,
    })
  }

  handleChooseFileTypeMaterial = (event, row) => {
    this.setState({
      filetype : event.target.value,
    })
  }

  handleChooseLinkMaterial = event => {
    this.setState({
      filelink : event.target.value,
    })
  }

  handleFileInput = event => {
    this.setState({[event.target.name]: event.target.files[0]})
  }



  handleClickSubmitMaterial = event => {
    const { id } = this.props.match.params;
    if (this.state.title == null || this.state.theme == null || this.state.description == null || this.state.filetype == null || this.state.pictureDataURLs == null){
      Swal.fire({
        icon: 'error',
        text: 'Complete your forms!',
        confirmButtonColor: '#e55555',
      });
    } else {
      var materi = {
        "title" : this.state.title,
        "theme" : this.state.theme,
        "description" : this.state.description,
        "type" : this.state.filetype,
        // "link" : this.state.filelink,
        "tumbnail_name" : this.state.pictureDataURLs,
        "require" : this.state.require,
      }
      const userToken = cookies.get("jwtKey");

      Axios({
        method: "POST",
        url: "/auth.php",
        data: {
          jwt: userToken,
          table: "dk_submit_materi",
          method: "patch",
          query: `id=eq.${id}`,
          data: materi,
        },
        })
      .then(response => {
        Swal.fire({
         icon: 'success',
         text: 'Your Materials has been updated',
         confirmButtonColor: 'blue',
        });
        window.location.reload();
      })
      .catch(error => {
        console.log(error)
        Swal.fire({
          icon: 'error',
          text: 'Materi cant be updated!',
          confirmButtonColor: '#e55555',
        });
      })
    }
  }


  render() {

    const isNewUpload = this.state.isNewUpload
		const pictureDataURLs = this.state.pictureDataURLs
		const title = this.state.title
		const theme = this.state.theme
		const require = this.state.require
		const description = this.state.description




    const file_uploaded = this.state.file_uploaded

    const handleChooseTitleMaterial = this.handleChooseTitleMaterial.bind(this)
    const handleChooseThemeMaterial = this.handleChooseThemeMaterial.bind(this)
    const handleChooseRequireMaterial = this.handleChooseRequireMaterial.bind(this)
    const handleChooseDescriptionMaterial = this.handleChooseDescriptionMaterial.bind(this)
    const onDrop = this.onDrop.bind(this)
    const handleClickSubmitMaterial = this.handleClickSubmitMaterial.bind(this)

    return (
      <>
        <SubmitMateriHeader />
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">

              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        Materi Diamond of Knowledge
                      </h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Change Name
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                   
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                        
                        </Col>
                        {/*
                        <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Link Materi
                            </label>
                            <Input
                              defaultValue=""
                              id="input-country"
                              placeholder="Link Here"
                              type="text"
                              onChange={this.handleChooseLinkMaterial.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                        */}
                        <Col lg="8" style={{marginBottom:'10px'}}>
                         
                          
                        
                        </Col>

                      </Row>
                    </div>

                    {(function() {
                          return(
                            <div>
                              <h6 className="heading-small text-muted mb-4">
                                Informasi Materi
                              </h6>
                              <div className="pl-lg-4">
                                <Row>
                                  <Col lg="6">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-country"
                                      >
                                        Judul Materi
                                      </label>
                                      <Input
                                        defaultValue={title}
                                        id="input-country"
                                        placeholder="Title"
                                        type="text"
                                        onChange={handleChooseTitleMaterial}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="3">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect3"
                                      >
                                        Tema Materi
                                      </label>
                                      <Input
                                        id="exampleFormControlSelect3"
                                        type="select"
                                        value={theme}
                                        onClick={handleChooseThemeMaterial}
                                      >
                                        <option>Semua Kategori</option>
                                        <option>Service Excellence</option>
                                        <option>Digital Marketing</option>
                                        <option>Consumer Engagement</option>
                                        <option>Beauty Knowledge</option>
                                        <option>Make Up Advisor</option>
                                        <option>Product Advisor</option>
                                        <option>Personal Branding</option>
                                        <option>Administrasi</option>
                                        <option>Store Management</option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="3">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect3"
                                      >
                                        Apakah Materi Ini Wajib ?
                                      </label>
                                      <Input
                                        id="exampleFormControlSelect3"
                                        type="select"
                                        value={require}
                                        onClick={handleChooseRequireMaterial}
                                      >
                                        <option>NO</option>
                                        <option>YES</option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup>
                                      <label className="form-control-label">Deskripsi Materi</label>
                                      <Input
                                        placeholder="Description here"
                                        rows="7"
                                        type="textarea"
                                        value={description}
                                        onChange={handleChooseDescriptionMaterial}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <div>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-country"
                                      >
                                        Upload Tumbnail
                                      </label>
                                      <ImageUploader
                                        withIcon={true}
                                        buttonText='Choose images'
                                        label='Max image size: 1 Mb (jpg,png,gif,jpeg)'
                                        withPreview={false}
                                        onChange={onDrop}
                                        singleImage={true}
                                        sizeFactor = {0.3}
                                        imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                                        maxFileSize={1000000}
                                      />
                                      <div className='centerobject'style={{marginBottom:'15px', textAlign:'center'}}>
                                        {(function(){
                                            return (
                                              <img style={{
                                                width: '50%',
                                                zIndex: '0',
                                              }}
                                                alt='upload'
                                                src={pictureDataURLs}
                                              />
                                            )
                                        })()}
                                      </div>
                                    </div>
                                  </Col>

                                  <Col lg="12">
                                    <Button
                                      color="primary"
                                      href="#pablo"
                                      size="lg"
                                      style={{marginBottom:'20px'}}
                                      onClick={() => (handleClickSubmitMaterial())}
                                    >
                                      Submit Materi
                                    </Button>
                                  </Col>
                                </Row>

                              </div>
                            </div>
                          )
                    })()}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </>
    );
  }
}

export default Profile;

export default function validateSheetData(sheetData) {
	try {
		const validationError = sheetData.reduce(
			(error, BA) => {
				if (!BA.active) return error

				checkError(error, 'Sell Out', BA)

				return error
			},
			{
				name: 'invalidRowValue',
				errors: [],
			}
		)

		return validationError
	} catch (err) {
		throw err
	}
}

function checkError(error, property, BA) {
	const { ba_id, username } = BA

	if (BA.hasOwnProperty(property) === true) {
		const errorMessage = validate(BA[property])

		if (errorMessage) {
			error.errors.push({
				ba_id,
				username,
				column: property,
				errorMessage,
			})
		}
	}
}

function validate(value) {
	if (!value) return 'cell belum diisi'
	if (isNaN(value)) return 'cell memiliki nilai tidak valid (bukan angka)'
	if (value <= 0) return 'cell memiliki nilai yang tidak valid (angka nol atau negatif)'
}

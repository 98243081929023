import Axios from "services/axios";
import Cookies from "universal-cookie";
import qs from "querystring";

export default async function fetchPartnerByID(id) {
  const cookies = new Cookies();
  const userToken = cookies.get("userToken");

  try {
    const query = qs.stringify({
      id: `${id}`,
    });

    let { data } = await Axios({
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + `/v1/partner?` + query,
      headers: {
        Authorization: "Bearer " + userToken,
      },
      data: {},
    });
    return data.data.data;
  } catch (err) {
    throw err;
  }
}

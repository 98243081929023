import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

export default function FormGroupUploadTarget({ label, name, placeholder, handleChange, value, smallLabel }) {
	return (
		<FormGroup>
			<Label>{!smallLabel ? <h3 className="text-primary">{label}</h3> : <h5>{label}</h5>}</Label>
			<Input className="input-number__upload-target" type="number" value={value} name={name} placeholder={placeholder} onChange={handleChange} />
		</FormGroup>
	)
}

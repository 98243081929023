import Axios from "services/axios";
import Cookies from "universal-cookie";

export default async function postScheme(payload) {
  const cookies = new Cookies();
  const userToken = cookies.get("userToken");
  try {
    let { data: createdScheme } = await Axios({
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + `/v1/ach-master-scheme`,
      headers: {
        "Authorization": "Bearer "+ userToken,
        Prefer: "return=representation",
      },
      data: payload,
    });
    return createdScheme.data.data.id;
  } catch (err) {
    throw err;
  }
}

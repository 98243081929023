import Axios from 'services/axios'
import Cookies from 'universal-cookie'

export default async function fetchAllBrand() {
	const cookies = new Cookies()
	const userToken = cookies.get('userToken')

	try {
		const { data } = await Axios({
			method: 'GET',
			url: process.env.REACT_APP_BASE_URL + `/v1/brand/list?sort=id.asc`,
			headers: {
				Authorization: 'Bearer ' + userToken,
			},
			data: {},
		})
		return data.data.data
	} catch (err) {
		throw err
	}
}

import Axios from "services/axios";
import Cookies from "universal-cookie";
import qs from "querystring";

export default async function fetchSchemeLineBySchemeId(SchemeId) {
  try {
    const cookies = new Cookies();
    const userToken = cookies.get("userToken");

    const query = qs.stringify({
      scheme_id: `${SchemeId}`,
    });

    let { data } = await Axios({
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + `/v1/ach-scheme-line?`+query,
      headers: {
        "Authorization": "Bearer "+ userToken
      },
      data: {},
    });

    return data.data.data;
  } catch (err) {
    throw err;
  }
}

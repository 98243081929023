export default function useParseTarget() {
	const parseTargetPersonal = (sheetData, { parameter, DCs, yearMonth, schemeSalesArea }) => {
		const { id: parameter_id, unit, name } = parameter
		const [year, month] = yearMonth.split('-').map(elem => +elem)

		return sheetData.map(BA => {
			const { ba_id, ba_brand: brand, partner_id, dc, salesarea_name } = BA

			const obj = {
				year,
				month,
				parameter_id,
				unit,
				// from template
				ba_id: +ba_id,
				brand,
				partner_id: +partner_id,
				target_nominal: BA[name] || 0, // sellin/sellout
			}

			if (schemeSalesArea.length) {
				const foundItem = schemeSalesArea.find(item => item.value === salesarea_name)

				if (foundItem) {
					const findSalesAreaId = foundItem.id
					obj['salesarea_id'] = (findSalesAreaId || findSalesAreaId === 0) && findSalesAreaId >= 0 ? findSalesAreaId : null
				} else {
					obj['salesarea_id'] = null // Item not found, set to null
				}
			} else {
				const foundDC = DCs.find(DC => DC.partner_name === dc)
				obj['dc_id'] = foundDC ? foundDC.partner_id : null
			}
			return obj
		})
	}

	const parseTargetBrand = (targetBrands, { parameter, DCs, yearMonth, schemeSalesArea }, brands) => {
		try {
			const { id: parameter_id, unit } = parameter
			const [year, month] = yearMonth.split('-').map(elem => +elem)

			// validations
			for (let i = 0; i < Object.keys(targetBrands).length; i++) {
				if (targetBrands[Object.keys(targetBrands)[i]] === '') {
					throw new Error('emptyFields')
				}
			}

			for (let i = 0; i < Object.keys(targetBrands).length; i++) {
				if (isNaN(targetBrands[Object.keys(targetBrands)[i]]) || +targetBrands[Object.keys(targetBrands)[i]] < 0) {
					throw new Error('notANumber')
				}
			}

			const targetNominalMap = {}
			brands.forEach(brand => {
				const target = Object.keys(targetBrands).find(tb => tb === brand.split(':')[1])
				targetNominalMap[brand] = +targetBrands[target]
			})

			const result = []

			if (schemeSalesArea.length) {
				schemeSalesArea.forEach(item => {
					brands.forEach(brand => {
						result.push({
							salesarea_id: item.id,
							brand,
							year,
							month,
							parameter_id,
							unit,
							target_nominal: targetNominalMap[brand],
						})
					})
				})
			} else {
				DCs.forEach(DC => {
					brands.forEach(brand => {
						result.push({
							dc_id: DC.partner_id,
							brand,
							year,
							month,
							parameter_id,
							unit,
							target_nominal: targetNominalMap[brand],
						})
					})
				})
			}
			return result
		} catch (err) {
			throw err
		}
	}

	const parseTargetGlobal = (target_nominal, { parameter, DCs, yearMonth, schemeSalesArea }) => {
		try {
			const { id: parameter_id, unit } = parameter
			const [year, month] = yearMonth.split('-').map(elem => +elem)

			// validations
			if (!target_nominal) {
				throw new Error('emptyFields')
			}
			if (isNaN(target_nominal) || +target_nominal < 0) {
				throw new Error('notANumber')
			}

			let result = []

			if (schemeSalesArea.length) {
				const salesarea_id = schemeSalesArea.map(item => item.id)
				result = salesarea_id.map(id => {
					return {
						salesarea_id: id,
						year,
						month,
						parameter_id,
						unit,
						// from input
						target_nominal: +target_nominal,
					}
				})
			} else {
				const dc_ids = DCs.map(DC => DC.partner_id)
				result = dc_ids.map(dc_id => {
					return {
						dc_id,
						year,
						month,
						parameter_id,
						unit,
						// from input
						target_nominal: +target_nominal,
					}
				})
			}

			return result
		} catch (err) {
			throw err
		}
	}

	return { parseTargetPersonal, parseTargetBrand, parseTargetGlobal }
}


import Axios from "api/axios";
import Cookies from "universal-cookie";
import qs from "querystring";

export default async function fetchParametersById(parameter_ids) {
  const cookies = new Cookies();
  const userToken = cookies.get("userToken");

  const query = qs.stringify({
    id: `[${parameter_ids.join(",")}]`,
  });

  try {
    const { data } = await Axios({
      method: "GET",
      url: `ach-master-parameter?${query}`,
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
    return data.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}
